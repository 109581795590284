/* eslint-disable eqeqeq */
import React from 'react';
import { colors } from '../../../../assets/colors';
import { LoaderGif } from '../../../../assets/icons';
import { PrintArea } from '../../../../interface/catalog-interface-v2';

interface Props {
  onClick(angle: string, src: any): void;
  selectedImage: string | undefined;
  angle: string;
  isAnglesLoading: boolean;
  angleImages: { [key: string]: string } | undefined;
  images:
    | {
        [key: string]: string;
      }
    | any;
  printArea: PrintArea | undefined;
}

const ProductAngles: React.FC<Props> = ({
  onClick,
  selectedImage,
  angle,
  isAnglesLoading,
  images,
  angleImages,
  printArea,
}) => {
  return (
    <div className="angles-listing show-no-scrollbar" data-src={selectedImage}>
      {printArea?.sides.map((sideData, index) => (
        <div key={index} className="angle-image">
          {isAnglesLoading && (
            <img src={LoaderGif} alt="loader" className="angles-loader" />
          )}
          <img
            style={{
              border:
                sideData.side === angle
                  ? `2px solid ${colors.blue[700]}`
                  : '2px solid transparent',
            }}
            src={
              (angleImages
                ? angleImages[sideData.side]
                : images[sideData.side]) || images[sideData.side]
            }
            onClick={(e) => onClick(sideData.side, e)}
            alt="Product angles"
          />
          <p className="fs-14 capitalize">{sideData.side} Side</p>
        </div>
      ))}
    </div>
  );
};

export default ProductAngles;
