import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder?: string;
  type?: string;
  name?: string;
  onBlure?: (value: React.FocusEvent<HTMLInputElement, Element>) => void;
}
const Input: React.FC<Props> = ({
  onChange,
  value,
  placeholder,
  type = 'text',
  name,
  onBlure,
  ...props
}) => {
  return (
    <StyledInput
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={(e) => onChange(e)}
      name={name}
      onBlur={(e) => {
        if (onBlure) {
          onBlure(e);
        }
      }}
      {...props}
    />
  );
};

export default Input;

const StyledInput = styled.input`
  width: -webkit-fill-available;
  padding: 6px 12px;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  color: ${colors.grey[800]};
  height: 20px;
  margin: 0 auto;
  font-size: 14px;
  border: none;
  outline: none;
`;
