import React, { useState } from 'react';
import styled from 'styled-components';
import { Option } from '../../interface';
import { DropDownIcon } from '../../assets/icons';
import Checkbox from '../checkbox/Checkbox';
import { colors } from '../../assets/colors';
import { ClickAwayListener } from '@mui/material';

const MultiSelectDropDown: React.FC<{
  items: Option[];
  label?: string;
  width?: string;
  selectedValues: Option[];
  onChange: (array: Option[]) => void;
}> = ({ items, label, width, selectedValues, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChange = (selectedItem: Option) => {
    const index = selectedValues.findIndex(
      (selection: Option) => selection.value === selectedItem.value,
    );
    if (index >= 0) {
      onChange(
        selectedValues.filter((item) => item.value !== selectedItem.value),
      );
    } else {
      onChange(selectedValues.concat(selectedItem));
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Select
        className="box select-list pointer-cursor"
        width={width}
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      >
        <div className="dropdown-header">
          <span className="selected-list">
            {selectedValues.length > 0
              ? selectedValues
                  .map((selection: Option) => selection.label)
                  .join(',')
              : label}
          </span>
          <img src={DropDownIcon} alt="Drop Down" />
        </div>

        <div
          className={`dropdown-body box show-no-scrollbar ${
            isOpen ? 'open' : ''
          }`}
        >
          {items.map((item, index) => (
            <div key={index} className="dropdown-item pointer-cursor">
              <Checkbox
                onChange={() => handleChange(item)}
                label={item.label}
                name={label}
                value={item.value}
                checked={
                  selectedValues.findIndex(
                    (text) => text.value === item.value,
                  ) >= 0
                }
              />
            </div>
          ))}
        </div>
      </Select>
    </ClickAwayListener>
  );
};

export default MultiSelectDropDown;

export const Select = styled.div<{ isOpen: boolean; width?: string }>`
  width: ${(props) => (props.width ? props.width : 'max-content')};
  max-width: ${(props) => (props.width ? props.width : 'max-content')};
  padding: 11px;
  min-width: 140px;
  position: relative;
  span {
    font-size: 12px;
  }
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-left: 8px;
    }
    .selected-list {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
  }
  .dropdown-body {
    border-top: 1px solid #e5e8ec;
    display: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background: ${colors.white};
    &.open {
      display: block;
      position: absolute;
      z-index: 1;
      top: ${(props) =>
        props.isOpen
          ? document.getElementsByClassName('select-list')?.[0]?.clientHeight -
            1
          : 0}px;
      left: 0px;
      width: ${(props) => (props.width ? props.width : 'max-content')};
      max-width: ${(props) => (props.width ? props.width : 'max-content')};
      font-size: 12px;
      max-height: 200px;
    }
  }

  .dropdown-item {
    padding: 10px;
  }
`;
