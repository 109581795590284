import React from 'react';
import { Route, Routes as WebRoutes } from 'react-router-dom';
import EditorPage from '../pages/editor';
import MockupsPage from '../pages/mockups';
import DetailsPage from '../pages/details';
import MyProductsPage from '../pages/my-products';
import OrdersPage from '../pages/orders';
import OrdersDetailsPage from '../pages/orders-details';
import CreateOrderPage from '../pages/create-order';
import HomePage from '../pages/home';
import SingleProductPage from '../pages/single-product';
import UserSettingsPage from '../pages/user-settings';
import PageNotFound from '../pages/page-not-found';
import { ProtectedRoutes } from './ProtectedRoutes';
import { useMediaQuery } from '@mui/material';

const Routes: React.FC = () => {
  const isTablet = useMediaQuery('(max-width: 834px)');
  return (
    <WebRoutes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<HomePage />} />

        <Route
          path="/editor/:catalogProductId/:printProviderId"
          element={<EditorPage isTablet={isTablet} />}
        />
        <Route
          path="/catalog/:catalogProductId"
          element={<SingleProductPage />}
        />
        <Route
          path="/personalize/:productId/:printProviderId"
          element={<EditorPage isTablet={isTablet} />}
        />
        <Route
          path="/editor/:catalogProductId/:printProviderId/:productId"
          element={<EditorPage isTablet={isTablet} />}
        />
        <Route
          path="/mockups/:productId/:modelNumber?"
          element={<MockupsPage isTablet={isTablet} />}
        />
        <Route path="/details" element={<DetailsPage isTablet={isTablet} />} />
        <Route
          path="/details/:productId"
          element={<DetailsPage isTablet={isTablet} />}
        />
        <Route
          path="/my-products"
          element={<MyProductsPage isTablet={isTablet} />}
        />
        <Route path="/orders" element={<OrdersPage isTablet={isTablet} />} />
        <Route
          path="/orders/:orderId"
          element={<OrdersDetailsPage isTablet={isTablet} />}
        />
        <Route path="/create-order" element={<CreateOrderPage />} />
        <Route
          path="/user-settings/*"
          element={<UserSettingsPage isTablet={isTablet} />}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </WebRoutes>
  );
};

export default Routes;
