import {
  DataGridPro,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCallbackDetails,
  GridColDef,
  GridEventListener,
  GridRow,
  GridSortModel,
  gridClasses,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import React from 'react';
import styled from 'styled-components';
// import Checkbox from '../checkbox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getDetails, updateSeletedRows } from '../../store/slices/detailsSlice';
import { Pagination } from '@mui/material';
import { colors } from '../../assets/colors';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

interface Props {
  rowData: any[];
  columns: GridColDef[];
  rowHeight?: number;
  pagination?: boolean;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  totalPages?: number;
  currentPage?: number;
  rowClickHandler?: GridEventListener<'rowClick'> | undefined;
  showCheckBox?: boolean;
  treeView?: boolean;
  apiRef?: React.MutableRefObject<GridApiPro>;
  sortModel?: any;
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails<any>,
  ) => void;
}
const MuiDataTable: React.FC<Props> = ({
  rowData,
  columns,
  rowHeight = 40,
  pagination,
  currentPage,
  setCurrentPage = () => {},
  totalPages,
  rowClickHandler,
  showCheckBox = true,
  treeView,
  apiRef,
  sortModel,
  onSortModelChange,
}) => {
  const { selectedRows } = useAppSelector(getDetails);
  const dispatch = useAppDispatch();

  function CustomFooterComponent() {
    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      value: number,
    ) => {
      setCurrentPage(value);
    };
    return pagination ? (
      <Pagination
        shape="circular"
        color="primary"
        size="medium"
        page={currentPage}
        count={totalPages}
        sx={{ width: '100%', color: colors.blue[900] }}
        onChange={handlePageChange}
      />
    ) : (
      <></>
    );
  }

  /** groupingColDef */

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    hideDescendantCount: true,
    valueFormatter: () => '',
    width: 50,
  };

  /** Adding gray color to child rows */
  const CustomRow = (props: React.ComponentProps<typeof GridRow>) => {
    const api = useGridApiContext();
    const rowNode = api.current.getRowNode(props.rowId);

    return (
      <GridRow
        {...props}
        style={
          (rowNode?.depth ?? 0) > 0
            ? { backgroundColor: colors.grey[200] }
            : undefined
        }
      />
    );
  };

  return (
    <StyledWrapper className="show-no-scrollbar">
      <DataGridPro
        apiRef={apiRef}
        sx={{
          fontFamily: 'Satoshi Medium',
          /** to remove scrollbar from mui table */
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: 0,
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },

          /** make action button visible on row hover */
          [`& .${gridClasses.row}:hover`]: {
            backgroundColor: 'white',
            cursor: 'pointer',
            '.action-buttons': {
              visibility: 'visible',
            },
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 1 } },
          pinnedColumns: {
            right: [GRID_TREE_DATA_GROUPING_FIELD],
          },
        }}
        sortingMode="server"
        sortModel={sortModel && [sortModel]}
        onSortModelChange={onSortModelChange}
        sortingOrder={['asc', 'desc']}
        pageSizeOptions={[10, 20, 50]}
        rows={rowData}
        columns={columns}
        checkboxSelection={showCheckBox}
        components={{
          Pagination: CustomFooterComponent,
          Row: CustomRow,
        }}
        onRowSelectionModelChange={(selectedRowsId) => {
          dispatch(updateSeletedRows(selectedRowsId));
        }}
        rowHeight={rowHeight}
        rowSelectionModel={selectedRows}
        disableRowSelectionOnClick
        paginationMode="server"
        pagination={pagination}
        hideFooter={!pagination}
        hideFooterSelectedRowCount
        onRowClick={rowClickHandler}
        rowCount={1}
        treeData={treeView}
        getTreeDataPath={(row) => row.hierarchy}
        groupingColDef={groupingColDef}
      />
    </StyledWrapper>
  );
};

export default MuiDataTable;

const StyledWrapper = styled.div`
  height: inherit;
  max-height: 850px;
  .MuiDataGrid-cellContent {
    font-size: 0.875rem;
  }
  /* Focus disable in column header */
  .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-cellContent,
  .MuiDataGrid-columnHeaderTitle {
    font-family: 'Satoshi Medium';
  }
  /* Focus disable in table cell */
  .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .MuiCheckbox-root svg {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }

  .MuiCheckbox-root svg path {
    display: none;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
    box-shadow: 0px 0px 0px 2px rgba(85, 164, 242, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate)::after {
    position: absolute;
    content: '';
    left: 14px;
    top: 11px;
    display: block;
    width: 4px;
    height: 8px;
    border: ${({ theme }) => `2px solid #2E8AE5`};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .MuiCheckbox-root.Mui-checked.MuiCheckbox-indeterminate svg {
    box-shadow: 0px 0px 0px 2px rgba(85, 164, 242, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }

  .MuiCheckbox-root.Mui-checked.MuiCheckbox-indeterminate::after {
    position: absolute;
    content: '';
    left: 12px;
    display: block;
    width: 8px;
    height: 0px;
    border: ${({ theme }) => `2px solid #2E8AE5`};
    border-width: 0 2px 2px 0;
    transform: none;
  }
`;
