import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import FilterLabel from '../filter-label';
import { nanoid } from '@reduxjs/toolkit';

interface Props {
  handleRemoveItem: (value: string) => void;
  inputValue: string;
  label?: string | ReactNode;
  onChange: (key: string) => void;
  onInputChange: (value: string) => void;
  placeholder?: string;
  selectedOptions: string[];
  disabled?: boolean;
}

const MultiSelectInput: React.FC<Props> = ({
  inputValue,
  handleRemoveItem,
  label,
  onChange,
  onInputChange,
  placeholder,
  selectedOptions,
  disabled,
}) => {
  return (
    <MultiSelectWrapper>
      {label && <label>{label}</label>}
      <SelectInput>
        {selectedOptions?.length > 0 &&
          selectedOptions.map((option) => (
            <FilterLabel
              key={nanoid()}
              text={option}
              onClick={() => handleRemoveItem(option)}
            />
          ))}

        <input
          placeholder={placeholder ?? 'Type something...'}
          type="text"
          value={inputValue}
          onChange={(e) => onInputChange(e.target.value)}
          onKeyDown={(e) => onChange(e.key)}
          disabled={disabled}
        />
      </SelectInput>
    </MultiSelectWrapper>
  );
};

export default MultiSelectInput;

const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: flex-start;
  font-weight: 400;
  width: 100%;

  label {
    margin: 0.3rem 0;
  }
`;

const SelectInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  padding: 4px 12px;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  color: ${colors.grey[800]};
  min-height: 36px;
  margin: 0 auto;

  input {
    flex: 1;
    height: 24px;
    // width: max-content;
    border: none;
    outline: none;
  }
`;
