/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';
import TopBar from '../editor/subComponents/top-bar';
import Description from './description';
import PreviewStore from './preview-store';
import VariantTable from './variant-component';
import PublishingSettings from './publishing-settings';
import DetailsFooter from './details-footer';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetProductDetailsForDetailsPageQuery,
  useUploadMockupsMutation,
} from '../../store/services/products';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getDetails } from '../../store/slices/detailsSlice';
import EditModel from './edit-model';
import Loader from '../common/loader';
import { productDetails } from '../../store/slices/detailsSlice';
import {
  setCatalogueId,
  setIsDetailsVisited,
  setModelNumber,
  setPrintProviderId,
  setProductId,
} from '../../store/slices/utilitySlice';
import { socket } from '../../helper/socket';
import { FlexBox } from '../common/wrappers';
import { LoaderGif, SuccessCheckIcon } from '../../assets/icons';
import Typography from '../typography';
import { useIsElementVisable } from '../../hooks/useIsElementVisable';
import Button from '../common/button';
import { Drawer } from '@mui/material';
import { TabletProps } from '../../interface';
import { getUserData } from '../../store/slices/authSlice';
import amplitude from 'amplitude-js';

const Details: React.FC<TabletProps> = ({ isTablet }) => {
  const { userInfo } = useAppSelector(getUserData);
  const shopCurrency = userInfo.salesChannelCurrency;

  const leftSection = useRef<HTMLDivElement>(null);
  const publishSettingRef = useRef<HTMLDivElement>(null);
  const isElementVisable = useIsElementVisable(publishSettingRef);
  const [isMockupsUploading, setIsMockupsUploading] = useState<boolean>(false);
  const [mockupuStatus, setMockupsStatus] = useState<string>('');
  const [openPreviewStore, setOpenPreviewStore] = useState<boolean>(false);
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { editField } = useAppSelector(getDetails);
  const { title } = useAppSelector(productDetails);
  const [uploadMockups] = useUploadMockupsMutation();

  const { data: productData, isLoading } =
    useGetProductDetailsForDetailsPageQuery(
      {
        productId: productId ?? '',
      },
      {
        skip: productId ? false : true,
      },
    );
  useEffect(() => {
    const mockupsDataToPerformUpload = location.state
      ? location.state.mockupsDataToPerformUpload
      : null;
    if (mockupsDataToPerformUpload !== null) {
      window.history.replaceState({}, document.title);
      const newFormData = new FormData();
      for (const key in mockupsDataToPerformUpload.formData) {
        if (
          Object.prototype.hasOwnProperty.call(
            mockupsDataToPerformUpload.formData,
            key,
          )
        ) {
          if (key === 'images') {
            const images = mockupsDataToPerformUpload.formData[key];
            images.forEach((image: File, index: number) => {
              newFormData.append(
                'images',
                image,
                index === 0
                  ? `${productId}-primary-mockup-${index}-id=${
                      image.name.split('-id=')[1]
                    }`
                  : `${productId}-mockup-${index}-id=${
                      image.name.split('-id=')[1]
                    }`,
              );
            });
          } else {
            newFormData.append(key, mockupsDataToPerformUpload.formData[key]);
          }
        }
      }
      location.state.mockupsDataToPerformUpload = null;
      const data = {
        formData: newFormData,
        productId: mockupsDataToPerformUpload.productId,
      };
      setMockupsStatus(
        'We are processing your images, Feel free to update product details',
      );
      setIsMockupsUploading(true);

      uploadMockups(data)
        .then(() => {
          setMockupsStatus('Your mockups have been successfully uploaded.');
          setIsMockupsUploading(false);
        })
        .catch((error) => {
          setIsMockupsUploading(false);
          console.error('Upload error:', error);
        });
    }
  }, [location.state]);
  useEffect(() => {
    dispatch(setCatalogueId(productData?.data.catalogId));
    dispatch(setProductId(productId));
    dispatch(setPrintProviderId(productData?.data.printProviderId));
    dispatch(
      setModelNumber(
        productData?.data.model === '3001C' ? '3001' : productData?.data.model,
      ),
    );
  }, [
    productData?.data.catalogId,
    productData?.data._id,
    productData?.data.model,
  ]);

  useEffect(() => {
    dispatch(setIsDetailsVisited(true));
    const onMockupsUploaded = (data: any) => {
      if (productId === data.productId) {
        setIsMockupsUploading(data.isMockupsUploading);
        if (data.isMockupsUploading) {
          setMockupsStatus(
            'We are processing your images, Feel free to update product details',
          );
        } else {
          // amplitude event
          const selectedImages = location.state
            ? location.state.selectedImageToShow
            : [];

          const amplitudeData = {
            product_name: productData?.data.title,
            color: productData?.data.variants
              .map((varinat: any) => varinat.color)
              .join(', '),
            mockups: selectedImages.length,
          };
          amplitude.getInstance().logEvent('Mockup', amplitudeData);

          setMockupsStatus('Images updated successfully.');
          setTimeout(() => {
            setMockupsStatus('');
          }, 2000);
        }
      }
    };
    socket.on('mockupsUploaded', onMockupsUploaded);
    return () => {
      socket.off('mockupsUploaded', onMockupsUploaded);
    };
  }, []);
  const replaceHistory = () => {
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => replaceHistory);
    return () => {
      window.history.replaceState({}, document.title);
      window.removeEventListener('beforeunload', replaceHistory);
    };
  }, []);

  const scrollDown = () => {
    if (leftSection.current) {
      leftSection.current.scrollTo({
        top: publishSettingRef?.current?.offsetHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Section isTablet={isTablet}>
      {isLoading && <Loader />}
      <LeftSection
        className="show-no-scrollbar"
        ref={leftSection}
        isTablet={isTablet}
      >
        <TopBar title={title} />
        {mockupuStatus && (
          <FlexBox className="loader-section" alignItems="center">
            <img
              src={isMockupsUploading ? LoaderGif : SuccessCheckIcon}
              alt="loader"
            />
            <Typography text={mockupuStatus} fontSize="0.9rem" />
          </FlexBox>
        )}
        <Wrapper>
          {isTablet && (
            <FlexBox justifyContent="space-between" alignItems="center">
              <label style={{ fontSize: '24px' }}>Details</label>
              <Button
                label="Preview in Store"
                onClick={() => setOpenPreviewStore(true)}
                appearance="secondary"
                size="small"
              />
            </FlexBox>
          )}

          <Description isTablet={isTablet} />
          <VariantTable shopCurrency={shopCurrency} currentCurrency="USD" />
          <PublishingSettings
            ref={publishSettingRef}
            shopCurrency={shopCurrency}
          />
          {!isElementVisable && (
            <FloatingButton onClick={scrollDown}>&darr;</FloatingButton>
          )}
        </Wrapper>
      </LeftSection>
      {isTablet ? (
        <Drawer
          open={openPreviewStore}
          anchor={'right'}
          PaperProps={{
            sx: {
              width: '100vw',
            },
          }}
        >
          <RightSection className="show-no-scrollbar" isTablet={isTablet}>
            <PreviewStore setOpenPreviewStore={setOpenPreviewStore} />
          </RightSection>
        </Drawer>
      ) : (
        <RightSection className="show-no-scrollbar" isTablet={isTablet}>
          <PreviewStore />
        </RightSection>
      )}
      <DetailsFooter isMockupsUploading={isMockupsUploading} />
      {editField !== 'none' && <EditModel />}
    </Section>
  );
};

export default Details;

const Section = styled.div<{ isTablet: boolean }>`
  display: flex;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  gap: 1rem;
  overflow-y: hidden;
  position: relative;
  .loader-section {
    padding-inline: 25px;
    padding-top: 12px;
    img {
      width: 40px;
    }
  }
`;

const LeftSection = styled.div<{ isTablet: boolean }>`
  background-color: ${colors.white};
  display: flex;
  flex-flow: column;
  width: ${(props) => (props.isTablet ? '100%' : '52%')};
`;

const RightSection = styled.div<{ isTablet: boolean }>`
  background-color: ${colors.grey[100]};
  display: flex;
  flex-direction: column;
  ${(props) =>
    !props.isTablet &&
    css`
      width: 50%;
    `};
  overflow: hidden;
  right: 0;
  height: 100%;
`;

const Wrapper = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0px;
  gap: 2rem;
  .div:first-child {
    position: relative;
  }
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 12%;
  left: 48.5%;
  background-color: ${colors.blue[700]};
  color: ${colors.white};
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  z-index: 99;
  transition: background-color 0.3s ease;
  animation: bounce 2s;
  :hover {
    background-color: ${colors.blue[900]};
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;
