import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import { ArrowIcon, DeleteIcon } from '../../../assets/icons';
import Typography from '../../typography';
import Button from '../../common/button';
import { useNavigate } from 'react-router-dom';

const Title: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <FlexBox
        alignItems="center"
        gap="1rem"
        className="navigate-back"
        onClick={() => navigate('/orders')}
      >
        <img src={ArrowIcon} alt="go-back" />
        <Typography fontSize="1rem" text="Back to Orders" />
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography text="Create Order" fontSize="1.5rem" />
        <Button
          className="discard-btn"
          appearance="secondary"
          label="Discard order"
          size="medium"
          icon={DeleteIcon}
          onClick={() => console.log('ss')}
        />
      </FlexBox>
    </StyledWrapper>
  );
};

export default Title;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .navigate-back {
    width: fit-content;
    * {
      cursor: pointer;
    }
  }
  .discard-btn {
    padding-block: 3px;
  }
`;
