import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../assets/colors';
import MultiSelectInput from '../../common/multi-select-input';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addTag,
  removeTag,
  updateSingleData,
  productDetails,
} from '../../../store/slices/detailsSlice';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import SearchBar from '../../common/search-bar';
import { tagsRegExp } from '../../../helper/commonUtils';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import CustomToolTip from '../../custom-tooltip';
import { toast } from 'react-toastify';
import { TabletProps } from '../../../interface';

const Description: React.FC<TabletProps> = ({ isTablet }) => {
  const dispatch = useAppDispatch();
  const {
    title,
    tags,
    //  isSizeTableAdded,
    descriptionResponse,
  } = useAppSelector(productDetails);
  const [editorState, setEditorState] = useState<any>();
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (descriptionResponse) {
      const html = draftToHtml(descriptionResponse);
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [descriptionResponse]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    const markdown = convertToRaw(editorState.getCurrentContent());
    dispatch(updateSingleData({ fieldName: 'description', value: markdown }));
  };

  const tagsOnchangeHandler = (key: string) => {
    if (key === 'Enter') {
      const tagsList = inputValue.split(',');
      const tagsExceedingLength = tagsList.filter((tag) => tag.length > 20);

      const duplicatesTags = tagsList.filter(
        (item, index) => tagsList.indexOf(item) !== index,
      );

      if (duplicatesTags.length > 0) {
        toast.warn('You cannot add the same tag more than once.');
        return;
      }

      if (inputValue.trim().length === 0 || tagsExceedingLength.length > 0) {
        toast.warn('Tags must be between 1 and 20 characters.');
        return;
      }

      tagsList.forEach((tag) => {
        const trimmedTag = tag.trim();
        if (trimmedTag.length > 0) {
          if (tags.includes(trimmedTag)) {
            toast.warn('You cannot add the same tag more than once.');
          } else {
            dispatch(addTag(trimmedTag));
          }
        }
      });
      setInputValue('');
    }
  };

  return (
    <StyledWrapper>
      <Heading>
        <div className="heading">Description</div>
        {/* <FlexBox gap="0.8rem" alignItems="center">
          <Checkbox
            label={'Include size table'}
            checked={isSizeTableAdded}
            onChange={handleSelect}
          />
        </FlexBox> */}
      </Heading>
      <ContentWrapper>
        <FlexBox className="w-100" direction="column" gap="3px" style={{ position: 'relative' }}>
          <SearchBar
            className="title-feild"
            label={'Title'}
            onChange={(value: string) => {
              value.length <= 140 &&
                dispatch(updateSingleData({ fieldName: 'title', value }));
            }}
            value={title}
            type='textarea'
            placeholder="Add Title"
          />
          <Typography
            classname="title-count"
            text={`${title.length}/140`}
            fontSize="0.8rem"
            secondaryColorValue={400}
          />
        </FlexBox>
        {/* Tags section */}

        <MultiSelectInput
          label={
            <FlexBox direction="column">
              <FlexBox alignItems="center">
                <Typography text="Tags" fontSize="14px" />
                <CustomToolTip
                  placement="top"
                  arrow
                  title={
                    <InfoWrapper>
                      <ul>
                        <li>Maximum of 13 tags allowed</li>
                        <li>You cannot add the same tag more than once</li>
                        <li>Each tag can have a maximum of 20 characters</li>
                        <li>Separate tags with comma and press enter</li>
                      </ul>
                    </InfoWrapper>
                  }
                >
                  <InfoIcon width={20} className="pointer-cursor" />
                </CustomToolTip>
              </FlexBox>
              <Typography
                text="Add tag and press enter"
                fontSize="12px"
                secondaryColorValue={700}
              />
            </FlexBox>
          }
          inputValue={inputValue}
          selectedOptions={tags}
          handleRemoveItem={(value: string) =>
            dispatch(removeTag({ value: value }))
          }
          onInputChange={(value: string) => {
            if (value) {
              if (tagsRegExp(value)) setInputValue(value);
            } else {
              setInputValue(value);
            }
          }}
          onChange={tagsOnchangeHandler}
          disabled={tags.length === 13}
          placeholder="Please enter a tag and press enter to add it"
        />

        <EditorWrapper isTablet={isTablet} className="editorWrapper">
          <label>Description</label>

          <Editor
            wrapperStyle={EditorWrapperStyle}
            toolbarStyle={ToolbarStyle}
            editorStyle={EditorStyle}
            editorState={editorState}
            stripPastedStyles={true}
            onEditorStateChange={onEditorStateChange}
            editorClassName="show-no-scrollbar custom-styles"
            toolbar={{
              // options: ['inline', 'list', 'textAlign'],
              options: [],
              // inline: {
              //   options: ['bold', 'italic', 'underline'],
              //   bold: {
              //     icon: Bold,
              //   },
              //   italic: {
              //     icon: Italics,
              //   },
              //   underline: {
              //     icon: Underline,
              //   },
              // },
              // list: {
              //   options: ['unordered', 'ordered'],
              //   unordered: {
              //     icon: UnorderedList,
              //   },
              //   ordered: {
              //     icon: OrderedList,
              //   },
              // },
              // textAlign: {
              //   options: ['left', 'center', 'right'],
              //   left: {
              //     icon: AlignLeftIcon,
              //   },
              //   center: {
              //     icon: AlignCenterIcon,
              //   },
              //   right: {
              //     icon: AlignRightIcon,
              //   },
              // },
            }}
          />
        </EditorWrapper>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default Description;

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 520px;
  height: auto;
  padding-bottom: 16px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
`;

const Heading = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.grey[300]};

  .heading {
    font-size: 18px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  gap: 1rem;
  .title-feild {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    input {
      padding-block: 8px;
    }
  }
  .title-count {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 4px;
  }
`;

const EditorWrapper = styled.div<{ isTablet: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  .custom-styles {
    min-height: 180px;
    ${(props) =>
      props.isTablet &&
      css`
        height: 260px;
      `};
  }
  ${(props) =>
    props.isTablet &&
    css`
      height: 320px;
    `};
`;

const EditorWrapperStyle = {
  width: '100%',
};

const ToolbarStyle = {
  border: 'none',
  padding: '0px',
};

const EditorStyle = {
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
  borderRadius: '6px',
  border: 'none',
  padding: '0px 12px',
  lineHeight: '15px',
};

const InfoWrapper = styled.div`
  padding: 2px;
  box-shadow: ${colors.shadows.elevation2};
  border-radius: 6px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: ${colors.grey[700]};
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    padding-left: 20px;
  }
`;
