import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Loader from '../../common/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImagePlaceholder } from '../../common/image-placeholder';
import { GalleryIcon, NewFolderIcon } from '../../../assets/icons';
import ImageBox from '../../common/image-box';
import {
  useAddImageToMyMockupsMutation,
  useGetAllMyMockupsQuery,
  useLazyGetAllMyMockupsQuery,
} from '../../../store/services/my-mockups';
import { MyMockupResponseObject } from '../../../interface';
import {
  MyMockupsInitialState,
  deleteImageOnDeselect,
  getMockups,
  setClickedImage,
  setSelectedImages,
  setMyMockupsData,
} from '../../../store/slices/mockupSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { increaseExtraMockupCount } from '../../../store/slices/utilitySlice';
import { SIZE_CHARTS } from '../../../constants/sizeCharts';
import SmallLoader from '../../common/small-loader';
import Checkbox from '../../common/checkbox';
import Button from '../../common/button';
import AddFolderModel from './add-folder-modal';
import Typography from '../../typography';
import Dropzone from 'react-dropzone';
import { FlexBox } from '../../common/wrappers';

interface Props {
  isSizeCharts?: boolean;
  modelNumber?: string;
  onClose?: () => void;
}

const MyMockups: React.FC<Props> = ({ isSizeCharts, modelNumber, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [idsToExclude, setIdsToExclude] = useState<any[]>([]);
  const [allFolders, setAllFolders] = useState<string[]>([]);

  const [activeFolderData, setActiveFolderData] = useState<any>({
    name: '',
    images: [],
  });
  const [myMockupQueryParams, setMyMockupQueryParams] = useState<{
    currentPage: number;
    limit: number;
    uploadedMockupsIds: string[];
  }>({
    currentPage: 1,
    limit: 10,
    uploadedMockupsIds: [],
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [getFolderArray, { isLoading: isGettingFolderImages }] =
    useLazyGetAllMyMockupsQuery();
  const [addToMyMockups, { isLoading: isUpdating }] =
    useAddImageToMyMockupsMutation();
  const {
    data: responseData,
    isFetching,
    refetch,
  } = useGetAllMyMockupsQuery(
    {
      page: myMockupQueryParams.currentPage,
      limit: myMockupQueryParams.limit,
      idsToRemoveFromResponse: myMockupQueryParams.uploadedMockupsIds,
      folderName: activeFolderData.name,
    },
    {
      skip: isSizeCharts,
    },
  );
  const handleFolder: any = async (folderName: any) => {
    setMyMockupQueryParams((prev) => ({
      ...prev,
      currentPage: 1,
    }));

    try {
      const res = await getFolderArray({
        page: 1,
        limit: 10,
        idsToRemoveFromResponse: myMockupQueryParams.uploadedMockupsIds,
        folderName: folderName,
      });

      if (res.isSuccess) {
        setActiveFolderData({
          name: folderName,
          images: res.data.data.results,
        });
      }
    } catch (error) {}
  };

  const filteredMockupData = useMemo(() => {
    const { name, images } = activeFolderData;
    if (name !== '') {
      return images;
    }
  }, [activeFolderData]);

  const { selectedImages, myMockupsData }: MyMockupsInitialState =
    useAppSelector(getMockups);
  useEffect(() => {
    if (
      responseData &&
      activeFolderData.name === '' &&
      responseData.data.currentFolder === ''
    ) {
      if (myMockupQueryParams.currentPage !== 1) {
        const newData = responseData.data.results.filter((obj) =>
          myMockupsData.every((existingObj) => existingObj.id !== obj.id),
        );
        if (newData.length > 0) {
          dispatch(setMyMockupsData([...myMockupsData, ...newData]));
        }
      } else {
        dispatch(setMyMockupsData(responseData.data.results));
      }
    }
  }, [
    activeFolderData,
    dispatch,
    myMockupQueryParams.currentPage,
    myMockupsData,
    responseData,
  ]);

  useEffect(() => {
    if (responseData && responseData.data.currentFolder !== '') {
      if (myMockupQueryParams.currentPage !== 1) {
        const newData = responseData.data.results.filter((obj) =>
          activeFolderData.images.every(
            (existingObj: any) => existingObj.id !== obj.id,
          ),
        );
        if (newData.length > 0) {
          setActiveFolderData({
            ...activeFolderData,
            images: [...activeFolderData.images, ...newData],
          });
        }
      }
    }
  }, [activeFolderData, myMockupQueryParams.currentPage, responseData]);
  const uploadToMyMockups = async (acceptedFiles: any) => {
    const filesArray = Array.from(acceptedFiles);
    const updatedImage = filesArray.map((file: any) => file);

    try {
      if (activeFolderData.name !== '') {
        const formData = new FormData();
        updatedImage.forEach((image) => formData.append('images', image));

        const res = await addToMyMockups({
          folderName: activeFolderData.name,
          formData: formData,
        });

        if (res && 'data' in res) {
          const newImages = Array.isArray(res.data.data)
            ? res.data.data
            : [res.data.data];

          handleAddMyMockup(newImages);
          setActiveFolderData({
            ...activeFolderData,
            images: [...newImages, ...activeFolderData.images],
          });
        }
      } else {
        const formData = new FormData();
        updatedImage.forEach((image) => formData.append('images', image));

        const mockupImage = await addToMyMockups({ formData }).unwrap();

        if (Array.isArray(mockupImage.data) && mockupImage.data.length > 0) {
          refetch();
          const newImageIds = mockupImage.data.map((image) => image.id);
          setIdsToExclude(newImageIds);
          const uniqueNewImages = mockupImage.data.filter(
            (newImage) =>
              !myMockupsData.some(
                (existingImage) => existingImage.id === newImage.id,
              ),
          );
          dispatch(setMyMockupsData([...uniqueNewImages, ...myMockupsData]));
        }

        if ('data' in mockupImage) {
          handleAddMyMockup(mockupImage.data);
        }
      }
    } catch (error) {}
    if (inputRef.current) inputRef.current.value = '';
  };

  const isMyMockupAlreadyAdded = (myMockupObj: MyMockupResponseObject) => {
    for (let mockupItem of selectedImages) {
      if (myMockupObj.id === mockupItem.mockupId) return true;
    }
    return false;
  };

  const handleAddMyMockup = (
    myMockupObj: MyMockupResponseObject | MyMockupResponseObject[],
  ) => {
    const myMockupArray = Array.isArray(myMockupObj)
      ? myMockupObj
      : [myMockupObj];

    const availableSlots = 10 - selectedImages.length;
    const loopCount = Math.min(availableSlots, myMockupArray.length);

    for (let i = 0; i < loopCount; i++) {
      const item = myMockupArray[i];

      if (!isMyMockupAlreadyAdded(item)) {
        dispatch(
          setSelectedImages({
            mockupId: item.id,
            mockupImage: item.imageUrl,
            isMymockup: true,
          }),
        );
        dispatch(
          setClickedImage({
            imgId: item.id,
            imageUrl: item.imageUrl,
          }),
        );
        dispatch(increaseExtraMockupCount());
      } else {
        dispatch(
          deleteImageOnDeselect({
            mockupId: item.id,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (responseData) {
      setAllFolders(responseData?.data.allFolders);
      setTotalPages(responseData.data.totalPages);
    }
  }, [responseData]);

  const isMockupDataAvailable = useMemo(() => {
    const myMockupSectionData =
      (filteredMockupData && filteredMockupData.length > 0) ||
      (myMockupsData && myMockupsData.length > 0) ||
      (responseData &&
        responseData.data.allFolders &&
        responseData.data.allFolders.length > 0);

    return !myMockupSectionData;
  }, [filteredMockupData, myMockupsData, responseData]);

  return (
    <>
      {open && (
        <AddFolderModel
          setOpen={setOpen}
          allFolders={allFolders}
          refetch={refetch}
        />
      )}
      {isGettingFolderImages ? (
        <SmallLoader text="Loading images..." />
      ) : (
        <Section>
          {!isSizeCharts && !activeFolderData.name && (
            <FlexBox justifyContent="space-between" alignItems="center">
              <span color="grey900">My Images</span>
              <Button
                appearance="secondary"
                label="Add Folder"
                onClick={() => setOpen(true)}
                size="small"
                className="add-button"
              />
            </FlexBox>
          )}
          {isUpdating && <Loader />}

          {isSizeCharts ? (
            <MyMockupsImageSelector className="show-no-scrollbar">
              {SIZE_CHARTS.map((chartItem) => {
                if (chartItem.imageUrl.includes(modelNumber as string)) {
                  const disableSelection =
                    !isMyMockupAlreadyAdded(chartItem) &&
                    selectedImages.length === 10;
                  return (
                    <ImageBox
                      isClickable
                      key={chartItem.id}
                      image={chartItem.imageUrl}
                      onClick={() => {
                        handleAddMyMockup(chartItem);
                      }}
                      disabled={disableSelection}
                    >
                      <Checkbox
                        className="image-item"
                        checked={isMyMockupAlreadyAdded(chartItem)}
                        disabled={disableSelection}
                        onChange={() => {
                          handleAddMyMockup(chartItem);
                        }}
                      />
                    </ImageBox>
                  );
                }
                return null;
              })}
            </MyMockupsImageSelector>
          ) : (
            <>
              {activeFolderData.name && activeFolderData.name?.length > 0 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    text="All Folders "
                    fontSize="16px"
                    primaryColorValue={800}
                    className="pointer-cursor"
                    onClick={() => {
                      setActiveFolderData({ name: '', images: [] });
                    }}
                  />
                  {'  '}
                  <span style={{ padding: '0 5px' }}>/</span>{' '}
                  {activeFolderData.name}
                </div>
              )}
              <InfiniteScroll
                height="calc(100vh - 223px)"
                className="infinite-scroll show-no-scrollbar"
                hasMore={
                  myMockupQueryParams.currentPage < totalPages && !isFetching
                }
                next={() => {
                  if (
                    myMockupQueryParams.currentPage < totalPages &&
                    !isFetching
                  ) {
                    setMyMockupQueryParams((prev) => ({
                      ...prev,
                      currentPage: prev.currentPage + 1,
                      uploadedMockupsIds: idsToExclude,
                    }));
                  }
                }}
                loader={<SmallLoader text="Loading mockups..." />}
                dataLength={
                  (activeFolderData.name !== ''
                    ? filteredMockupData
                    : myMockupsData
                  ).length
                }
              >
                <MyMockupsImageSelector
                  className="show-no-scrollbar"
                  isDataAvailable={isMockupDataAvailable}
                >
                  <ImagePlaceholder height="280px" width="22vw">
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadToMyMockups(acceptedFiles)
                      }
                      accept={{
                        'image/png': ['.png'],
                        'image/jpg': ['.jpg'],
                        'image/jpeg': ['.jpeg'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <FlexBox
                            alignItems="center"
                            className="uploadArea"
                            justifyContent="center"
                            gap="0.5rem"
                            direction="column"
                            ref={inputRef}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <img
                              src={GalleryIcon}
                              alt="Dropzone"
                              style={{
                                height: isMockupDataAvailable ? '80px' : '50px',
                                width: isMockupDataAvailable ? '80px' : '50px',
                              }}
                            />{' '}
                            <span
                              className={`text-align-center ${
                                !isMockupDataAvailable ? 'fs-12' : 'fs-14'
                              }`}
                              color="grey900"
                            >
                              Upload your own mockups/previews.
                            </span>
                            <Button
                              appearance={'primary'}
                              onClick={() => inputRef?.current?.click()}
                              label={'Upload'}
                            />
                          </FlexBox>
                        </section>
                      )}
                    </Dropzone>
                  </ImagePlaceholder>
                  {/* {myImagesData?.allFolders &&
                myImagesData?.allFolders.map((folderName: any) => ( */}
                  {responseData?.data.allFolders.map((folderName: string) => (
                    <ImageBox
                      isClickable
                      className="item-header"
                      key={folderName}
                      image={NewFolderIcon}
                      height='100%'
                      itemId={folderName}
                      leftScrollItem
                      deleteIcon={true}
                      onClick={() => {
                        handleFolder(folderName);
                      }}
                      isFolder={true}
                      imgHeight={'80px'}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        {/* <img src={NewFolderIcon} alt="aslllasasfdsfvwew" /> */}
                        <p>{folderName}</p>
                      </div>
                    </ImageBox>
                  ))}

                  {(activeFolderData.name !== ''
                    ? filteredMockupData
                    : myMockupsData
                  ).map((myMockupObj: any, index: any) => {
                    const disableSelection =
                      !isMyMockupAlreadyAdded(myMockupObj) &&
                      selectedImages.length === 10;
                    return (
                      <ImageBox
                        isClickable
                        key={index}
                        itemId={myMockupObj.id}
                        image={myMockupObj.imageUrl}
                        disabled={disableSelection}
                        deleteIcon={true}
                        isFolder={true}
                        myMockup={true}
                        leftScrollItem
                        activeFolderData={activeFolderData}
                        setActiveFolderData={setActiveFolderData}
                        filteredMockupData={filteredMockupData}
                        onClick={() => {
                          onClose && onClose();
                          handleAddMyMockup(myMockupObj);
                        }}
                      >
                        <Checkbox
                          className="image-item"
                          checked={isMyMockupAlreadyAdded(myMockupObj)}
                          disabled={disableSelection}
                          onChange={() => {
                            onClose && onClose();
                            handleAddMyMockup(myMockupObj);
                          }}
                        />
                      </ImageBox>
                    );
                  })}
                </MyMockupsImageSelector>
              </InfiniteScroll>
            </>
          )}
        </Section>
      )}
    </>
  );
};

export default MyMockups;
const Section = styled.div`
  width: 100%;
  padding: 4px 0px;
  margin: 8px 0 0 0px;
  overflow: hidden;
  .infinite-scroll {
    height: 100%;
    width: 100%;
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it appears above other elements */
  }

  .add-button {
    margin-right: 1px;
  }
  .loader {
    position: relative;
  }

  .loader img {
    width: 100px;
    height: 100px;
  }
`;
const MyMockupsImageSelector = styled.div<{ isDataAvailable?: boolean }>`
  display: grid;
  margin: 15px 0 40px 0;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, 154px);
  gap: 1rem;
  overflow-y: scroll;
  & > div {
    width: ${(props) => (props.isDataAvailable ? '22vw' : '100%')};
    max-height: ${(props) => (props.isDataAvailable ? '250px' : '154px')};
  }
`;
