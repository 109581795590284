import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const AlertIconComponent: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/bold/danger">
        <path
          id="Vector"
          d="M29.0133 21.56L20.48 6.2C19.3333 4.13333 17.7466 3 16 3C14.2533 3 12.6666 4.13333 11.52 6.2L2.98664 21.56C1.90664 23.52 1.78664 25.4 2.6533 26.88C3.51997 28.36 5.22664 29.1733 7.46664 29.1733H24.5333C26.7733 29.1733 28.48 28.36 29.3466 26.88C30.2133 25.4 30.0933 23.5067 29.0133 21.56ZM15 12.3333C15 11.7867 15.4533 11.3333 16 11.3333C16.5466 11.3333 17 11.7867 17 12.3333V19C17 19.5467 16.5466 20 16 20C15.4533 20 15 19.5467 15 19V12.3333ZM16.9466 23.9467C16.88 24 16.8133 24.0533 16.7466 24.1067C16.6666 24.16 16.5866 24.2 16.5066 24.2267C16.4266 24.2667 16.3466 24.2933 16.2533 24.3067C16.1733 24.32 16.08 24.3333 16 24.3333C15.92 24.3333 15.8266 24.32 15.7333 24.3067C15.6533 24.2933 15.5733 24.2667 15.4933 24.2267C15.4133 24.2 15.3333 24.16 15.2533 24.1067C15.1866 24.0533 15.12 24 15.0533 23.9467C14.8133 23.6933 14.6666 23.3467 14.6666 23C14.6666 22.6533 14.8133 22.3067 15.0533 22.0533C15.12 22 15.1866 21.9467 15.2533 21.8933C15.3333 21.84 15.4133 21.8 15.4933 21.7733C15.5733 21.7333 15.6533 21.7067 15.7333 21.6933C15.9066 21.6533 16.0933 21.6533 16.2533 21.6933C16.3466 21.7067 16.4266 21.7333 16.5066 21.7733C16.5866 21.8 16.6666 21.84 16.7466 21.8933C16.8133 21.9467 16.88 22 16.9466 22.0533C17.1866 22.3067 17.3333 22.6533 17.3333 23C17.3333 23.3467 17.1866 23.6933 16.9466 23.9467Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default AlertIconComponent;
