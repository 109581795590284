export const appendQueryParams = (url: string, params: any) => {
  // Check if the URL already has query parameters
  const getSeparator = () => {
    let hasQueryParams = url.includes('?');
    return hasQueryParams ? '&' : '?';
  };

  if (params.search) {
    url += `${getSeparator()}search=${params.search}`;
  }
  if (params.brands && params.brands.length > 0) {
    url += `${getSeparator()}brands[]=${params.brands.join('&brands[]=')}`;
  }
  if (params.categories && params.categories.length > 0) {
    url += `${getSeparator()}categories[]=${params.categories.join(
      '&categories[]=',
    )}`;
  }
  if (params.printProviders && params.printProviders.length > 0) {
    url += `${getSeparator()}printProviders[]=${params.printProviders.join(
      '&printProviders[]=',
    )}`;
  }
  if (params.sizes && params.sizes.length > 0) {
    url += `${getSeparator()}sizes[]=${params.sizes.join('&sizes[]=')}`;
  }
  if (params.page) {
    url += `${getSeparator()}page=${params.page}`;
  }
  if (params.limit) {
    url += `${getSeparator()}limit=${params.limit}`;
  }
  if (params.minPrice && params.maxPrice) {
    url += `${getSeparator()}minPrice=${params.minPrice}&maxPrice=${
      params.maxPrice
    }`;
  }
  if (params.sortDir) {
    url += `${getSeparator()}sortBy=price:${params.sortDir}`;
  }
  if (params.status) {
    url += `${
      params.status !== 'all' ? `${getSeparator()}status=${params.status}` : ''
    }`;
  }
  if (params.fromDate && params.toDate) {
    url += `${getSeparator()}fromDate=${params.fromDate}&toDate=${
      params.toDate
    }`;
  }
  if (params.sortBy) {
    url += `${getSeparator()}sortBy=${params?.sortBy.field}:${
      params?.sortBy.sort
    }`;
  }
  if (params?.idsToRemoveFromResponse?.length > 0) {
    url += `${getSeparator()}ids[]=${params.idsToRemoveFromResponse.join(
      '&ids[]=',
    )}`;
  }
  if (params.folderName || params.folderName === '') {
    url += `${getSeparator()}folderName=${params.folderName}`;
  }
  if (params?.mockupId || params?.imageId) {
    url += `${getSeparator()}id=${params.mockupId || params?.imageId}`;
  }
  if (params?.sampleOrder) {
    url += `${getSeparator()}sampleOrder=${params?.sampleOrder}`;
  }
  return url;
};
