import { Application, ICanvas } from 'pixi.js';

import { getMockupImageForAngle } from '../../utils';
import {
  CatalogProductInfo,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';

export const generatePreviewImages = async (
  blob: Blob | null,
  catalogData: CatalogProductInfo | undefined,
  selectedVariant: VariantsV2 | undefined,
  pixiApp: Application<ICanvas> | undefined,
  angle: string,
  preloadedImages?: {
    [key: string]: string;
  },
) => {
  if (catalogData && selectedVariant && pixiApp) {
    try {
      const url = blob ? URL.createObjectURL(blob) : null;
      const image: any = await getMockupImageForAngle(
        url,
        angle,
        catalogData,
        selectedVariant,
        pixiApp,
        preloadedImages,
      );
      return image.src;
    } catch (error) {
      console.error('Error generating mockup image:', error);
      return null;
    }
  }
};
