import React from 'react';
import MuiDataTable from '../../table/MuiDataTable';
import styled from 'styled-components';
import { ordersColumns } from './orders-columns';
import { useNavigate } from 'react-router-dom';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { SortFilter } from '../../../interface/orders-interface';

interface Props {
  rowData: any[];
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  currentPage: number;
  apiRef?: React.MutableRefObject<GridApiPro>;
  sortModel: any;
  setSortModel: React.Dispatch<React.SetStateAction<SortFilter>>;
  isTablet: boolean;
}
const OrdersTable: React.FC<Props> = ({
  rowData,
  setCurrentPage,
  currentPage,
  totalPages,
  apiRef,
  sortModel,
  setSortModel,
  isTablet,
}) => {
  const navigate = useNavigate();

  const handleSortChange = (model: GridSortModel) => {
    if (model[0]) {
      setSortModel({
        field: model[0].field,
        sort: model[0].sort as 'asc' | 'desc',
      });
    }
  };

  return (
    <StyledWrapper isTablet={isTablet}>
      <MuiDataTable
        apiRef={apiRef}
        columns={ordersColumns()}
        rowData={rowData}
        rowHeight={100}
        pagination={true}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowClickHandler={(props) => navigate(`/orders/${props.row.orderId}`)}
        sortModel={sortModel}
        onSortModelChange={handleSortChange}
      />
    </StyledWrapper>
  );
};

export default OrdersTable;

const StyledWrapper = styled.div<{ isTablet: boolean }>`
  max-height: 820px;
  width: 100%;
  height: ${(props) => (props.isTablet ? '68vh' : '60vh')};
`;
