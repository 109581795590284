import { GridColDef } from '@mui/x-data-grid-pro';

export const columns: GridColDef = {
  field: '',
  headerName: '',
  disableReorder: true,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};
