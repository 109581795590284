import { GridColDef } from '@mui/x-data-grid-pro';
import {
  RenderText,
  RenderTextWithColorMarkAndHover,
} from '../../../../utils/render-cells';
import { columns } from '../../../../constants/tableColumnConstants';

export const moreDetailsColumns = (isGroupByColor: boolean) => {
  const moreDetailsColumn: GridColDef[] = [
    {
      ...columns,
      field: 'sizes',
      headerName: isGroupByColor ? 'No. of Sizes' : 'Size',
      flex: 1,
      renderCell: (params) => RenderText(params, '14px'),
    },
    {
      ...columns,
      field: 'colors',
      headerName: !isGroupByColor ? 'No. of Colors' : 'Color',
      flex: 1,
      renderCell: (params) =>
        RenderTextWithColorMarkAndHover(params, isGroupByColor),
    },
    {
      ...columns,
      field: 'inventory',
      headerName: 'inventory',
      flex: 1,
      renderCell: (params) => RenderText(params, '14px'),
    },
    {
      ...columns,
      field: 'price',
      headerName: 'Price',
      renderCell: (params) =>
        RenderText({ ...params, value: `$ ${params.value}` }, '14px'),
    },
    {
      ...columns,
      field: 'growthPrice',
      headerName: 'EverBee Growth Plan Price',
      flex: 1,
      renderCell: (params) =>
        RenderText({ ...params, value: `$ ${params.value}` }, '14px'),
    },
  ];
  return isGroupByColor
    ? moveElement(moreDetailsColumn, 0, 1)
    : moreDetailsColumn;
};
function moveElement(array: GridColDef[], fromIndex: number, toIndex: number) {
  const arrayCopy = [...array];
  const element = arrayCopy.splice(fromIndex, 1)[0];
  arrayCopy.splice(toIndex, 0, element);

  return arrayCopy;
}
