import React, { useState } from 'react';
import amplitude from 'amplitude-js';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import { ColoredCircle } from '../../common/elements';
import CustomToolTip from '../../custom-tooltip';
import { ProductInfo } from '../../../interface/catalog-interfaces';
import MoreDetailsModal from '../single-product/more-details-modal/more-details-modal';
import { USAFlagIcon } from './../../../assets/icons';
import { useAppSelector } from '../../../store/hooks';
import { getUserData } from '../../../store/slices/authSlice';
import PriceSection from './price-section';
import { CatalogSuppliers } from '../../../interface/catalog-interface-v2';
import { sortSizes } from './sorted-sizes';
import Typography from '../../typography';
interface Props {
  className?: string;
  images: { url: string; color: string }[];
  productDetails?: ProductInfo | any;
  printProvidersAndVariants: CatalogSuppliers[];
  colorGrouping: any[];
  sizeGrouping: any[];
}
const ProductView: React.FC<Props> = ({
  className,
  images,
  productDetails,
  printProvidersAndVariants,
  colorGrouping,
  sizeGrouping,
}) => {
  const { isGrowth } = useAppSelector(getUserData);
  const [src, setSrc] = useState<{ url: string; color: string }>(images[0]);
  const [groupByColors, setGroupByColors] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const changeImgHandler = (image: { url: string; color: string }) => {
    setSrc(image);
  };

  return (
    productDetails && (
      <>
        <div
          className={`product-view w-full lg:flex justify-between md:flex ${
            className || ''
          }`}
          style={{ overflow: 'hidden' }}
        >
          <div className="flex flex-col show-no-scrollbar">
            {(images || []).map((img, index) => (
              <div
                onClick={() => changeImgHandler(img)}
                key={index}
                className="border border-qgray-border cursor-pointer left-side-img-container"
              >
                <img
                  src={img.url}
                  alt=""
                  className={`w-full h-full object-contain ${
                    src.url !== img.url ? 'opacity-50' : ''
                  } `}
                />
              </div>
            ))}
          </div>
          <div
            data-aos="fade-right"
            className="lg:w-1/2 xl:mr-[70px] lg:mr-[50px] w-fit"
            style={{ width: 'fit-content' }}
          >
            <div className="w-fit">
              <div
                className={`center-img border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3 ${
                  productDetails.productType === 'Drinkware' ? 'p-[15px]' : ''
                }`}
              >
                <span
                  className="absolute z-10 top-0 right-0 text-xs p-1 rounded-bl "
                  style={{
                    backgroundColor: colors.blue[900],
                    color: colors.white,
                  }}
                >
                  {src.color}
                </span>
                <img
                  src={src.url}
                  alt="product"
                  className="object-contain"
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="" style={{ width: 300 }}>
            <div className="product-details w-full ">
              <span
                data-aos="fade-up"
                className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
              >
                {productDetails.category}
              </span>
              <p
                data-aos="fade-up"
                className="text-xl font-medium text-qblack mb-4"
              >
                {productDetails?.title}
              </p>

              <p className="text-qgray text-base text-normal leading-7 mb-4 ">
                {productDetails?.brand}
              </p>

              <ul>
                {Object.keys(productDetails?.bulletPoints ?? {}).map(
                  (description) =>
                    !productDetails?.bulletPoints[description]
                      .toLowerCase()
                      .includes('Print Method'.toLocaleLowerCase()) && (
                      <li
                        key={description}
                        data-aos="fade-up"
                        className="text-qgray text-sm text-normal leading-7 list-disc capitalize"
                      >
                        {productDetails?.bulletPoints[description]}
                      </li>
                    ),
                )}
              </ul>
              {/* <a
              href="#description"
              className="text-qgray text-sm text-normal leading-7 "
              style={{ color: colors.blue[900] }}
            >
              Read more
            </a> */}

              <p className="flex items-center gap-[10px] mt-[18px]">
                <img
                  className="w-[20px] h-[18px]"
                  src={USAFlagIcon}
                  alt="USA Flag"
                />
                <span className="text-sm">Fulfilled in 1 country</span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ position: 'relative' }}
          className="product-design-details"
        >
          {printProvidersAndVariants.map((supplier, index) => {
            const sizes = sortSizes(
              supplier.properties
                .find((property) => property.propertyName === 'size')
                ?.values.map((a) => a.value),
            );

            return (
              <div
                key={index}
                style={{
                  background: '#f7f7f7',
                  marginBottom: 20,
                  border: '1px solid #e3e4e5',
                }}
              >
                <div className="pd-details-inner">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      width: '30%',
                      height: '100%',
                    }}
                  >
                    <button
                      type="button"
                      className="black-btn text-sm font-semibold w-[150px] h-full"
                      onClick={() => setShowDetailsModal(true)}
                      style={{
                        width: '50%',
                        border: `1px solid ${colors.grey[300]}`,
                        backgroundColor: colors.white,
                        borderRadius: '6px',
                      }}
                    >
                      More Details
                    </button>

                    <button
                      type="button"
                      className="yellow-btn text-sm font-semibold w-[150px] h-full"
                      onClick={() => {
                        amplitude.getInstance().logEvent('Start Design', {
                          product_name: productDetails?.title,
                        });
                        window.location.href = `/editor/${productDetails?._id}/${supplier.providerDetails._id}`;
                      }}
                      style={{
                        marginLeft: 25,
                        display: 'inline',
                        width: '50%',
                        backgroundColor: colors.blue[900],
                        color: colors.white,
                        borderRadius: '6px',
                      }}
                    >
                      Start Designing
                    </button>
                  </div>
                </div>

                <dl className="print-provider-info">
                  <div>
                    <dt>Price</dt>
                    <dd>
                      <FlexBox direction="column">
                        <PriceSection
                          isGrowth={isGrowth}
                          price={supplier.cost?.hobby}
                          growthPrice={supplier.cost?.growth}
                        />
                      </FlexBox>
                    </dd>
                  </div>

                  <div>
                    <dt>Shipping</dt>
                    <dd>
                      {isGrowth ? (
                        <FlexBox gap="4px" alignItems="flex-end">
                          From USD{' '}
                          <p className="discount-price">
                            {productDetails.shippingCosts?.hobby}
                          </p>
                          <Typography
                            primaryColorValue={600}
                            fontSize={'16px'}
                            text={productDetails.shippingCosts?.growth}
                          />
                        </FlexBox>
                      ) : (
                        <>
                          <p>From USD {productDetails.shippingCosts?.hobby}</p>
                          <span
                            className="main-price text-qred  font-500 text-[14px]"
                            style={{ color: colors.blue[600] }}
                          >
                            From USD {productDetails.shippingCosts?.growth} with
                            EverBee Growth Plan
                          </span>
                        </>
                      )}
                    </dd>
                  </div>
                  <div>
                    <dt>Avg. production time</dt>
                    <dd>2.9 business days</dd>
                  </div>
                  <div>
                    <dt>
                      Print areas • {productDetails.printAreasForCatalog.length}
                    </dt>
                    <dd className="capitalize">
                      {productDetails.printAreasForCatalog.join(', ')}
                    </dd>
                  </div>
                  {sizes && (
                    <div>
                      <dt>Sizes • {sizes.length}</dt>
                      {sizes.length === 1 ?
                        <dd>{`${sizes[0]}`}</dd>
                        :
                        <dd>{`${sizes[0]} - ${sizes[sizes.length - 1]}`}</dd>
                      }
                    </div>
                  )}
                  <div>
                    <dt>
                      Colors •{' '}
                      {
                        supplier.properties.find(
                          (property) => property.propertyName === 'color',
                        )?.values.length
                      }
                    </dt>
                    <dd>
                      <FlexBox gap="3px" className="print-provider-colors">
                        {supplier.properties
                          .find((property) => property.propertyName === 'color')
                          ?.values.map((variant, index) => (
                            <ColorsInfo
                              key={index}
                              colorName={variant.value}
                              colorCode={`#${variant.meta?.colorHex}`}
                            />
                          ))}
                      </FlexBox>
                    </dd>
                  </div>
                </dl>
              </div>
            );
          })}
          {showDetailsModal && (
            <MoreDetailsModal
              groupedColorData={colorGrouping}
              groupedSizesData={sizeGrouping}
              onClose={() => setShowDetailsModal(false)}
              onClickSize={() => setGroupByColors(false)}
              onClickColor={() => setGroupByColors(true)}
              isGroupByColors={groupByColors}
            />
          )}
        </div>
      </>
    )
  );
};

export default ProductView;
interface ColorProps {
  colorName: string;
  colorCode: string;
}
const ColorsInfo: React.FC<ColorProps> = React.forwardRef(
  ({ colorName, colorCode }, ref) => {
    return (
      <CustomToolTip
        title={
          <div
            className="p-2"
            style={{
              backgroundColor: colors.blue[900],
              borderRadius: '6px',
            }}
          >
            <p className="text-[13px]" style={{ color: colors.white }}>
              {colorName}
            </p>
          </div>
        }
      >
        <ColoredCircle
          colorCode={colorName.includes('/') ? colorName : colorCode}
        />
      </CustomToolTip>
    );
  },
);
