import React from 'react';
import styled from 'styled-components';
import Input from '../inputs';
import { TextareaAutosize } from '@mui/material';


interface Props {
  icon?: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
  className?: string;
  type?: string;
  addStaticText?: string;
  name?: string;
}

const SearchBar: React.FC<Props> = ({
  icon,
  onChange,
  value,
  placeholder,
  label,
  className,
  addStaticText,
  type = 'text',
  name,
}) => {
  return (
    <SearchWrapper className={className} icon={icon}>
      {label && <span>{label}</span>}
      {type === 'textarea' ? (
        <TextareaAutosize
          maxRows={2}
          className="text-area"
          maxLength={140}
          value={value}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder ?? 'Search'}
        ></TextareaAutosize>
      ) : (
        <Input
          placeholder={placeholder ?? 'Search'}
          type={type}
          value={value}
          name={name}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {icon && <img src={icon} alt="Search" />}
      {addStaticText && <span className="static-text">{addStaticText}</span>}
    </SearchWrapper>
  );
};

export default SearchBar;

const SearchWrapper = styled.div<{ icon?: string }>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-left: ${(props) => props.icon && '35px !important '};
  }
  img {
    position: absolute;
    margin-left: 5px;
    left: 0;
  }
  .static-text {
    position: absolute;
    margin-right: 5px;
    right: 0;
  }
  .text-area {
    overflow: hidden;
    resize: none;
    width: -webkit-fill-available;
    padding: 6px;
  }
`;
