import * as PIXI from 'pixi.js';
import {
  MockupApiData,
  MockupVariant,
} from '../../interface/mockups-interface';
import setupPixi, { replaceUrl } from './mockup-generator';
import { VariantsV2 } from '../../interface/catalog-interface-v2';

export const combineCatalogVariantAndProductVariant = (
  productVariants: VariantsV2[],
  catalogVariants: VariantsV2[],
) => {
  const idMap = new Map<string, VariantsV2>();
  catalogVariants.forEach((item) => {
    idMap.set(item.id, item);
    item.connectedVariants.forEach((connectedVariant) => {
      idMap.set(connectedVariant.id, item);
    });
  });

  const mergedArray: MockupVariant[] = [];
  productVariants.forEach((item) => {
    const catalogVariant = idMap.get(item.id);
    if (catalogVariant) {
      mergedArray.push({
        ...item,
        label: catalogVariant.label,
        colorHex: catalogVariant.colorHex,
        isHeather: catalogVariant.label.toLowerCase().includes('heather'),
      });
    }
  });
  return mergedArray;
};

interface PreloadedImages {
  [key: string]: string;
}

export interface ImageQueueItem {
  id: string | number;
  mockupInfo: MockupApiData;
  selectedColorHex: string;
  designImage: string;
  sideOfMockup: string;
}

// function to add an item to the queue to generate high-quality images
export const addToQueueToGenerateHighQualityImages = (
  id: string | number,
  mockupInfo: MockupApiData,
  selectedColorHex: string,
  designImage: string,
  sideOfMockup: string,
  setImageQueue: React.Dispatch<React.SetStateAction<ImageQueueItem[]>>,
) => {
  // add new item to the image processing queue
  setImageQueue((prevQueue) => [
    ...prevQueue,
    { id, mockupInfo, selectedColorHex, designImage, sideOfMockup },
  ]);
};

// function to process the image processing queue
export const processQueue = async (
  processingQueueRef: React.MutableRefObject<boolean>,
  imageQueue: ImageQueueItem[],
  setImageQueue: React.Dispatch<React.SetStateAction<ImageQueueItem[]>>,
  generateAndDispatchHighQualityImage: (
    id: string | number,
    mockupInfo: MockupApiData,
    selectedColorHex: string,
    designImage: string,
    sideOfMockup: string,
  ) => Promise<void>,
) => {
  // if queue is already processing or empty, exit early
  if (processingQueueRef.current || imageQueue.length === 0) return;

  processingQueueRef.current = true;

  // process the first item in the queue
  const { id, mockupInfo, selectedColorHex, designImage, sideOfMockup } =
    imageQueue[0];

  await generateAndDispatchHighQualityImage(
    id,
    mockupInfo,
    selectedColorHex,
    designImage,
    sideOfMockup,
  );

  // remove processed item from the queue
  setImageQueue((prevQueue) => prevQueue.slice(1));

  processingQueueRef.current = false;
};

// function to generate high-resolution mockup for a selected image
export const generateHighResolutionMockupForSelectedImage = async (
  mockup: MockupApiData,
  preloadedImages: PreloadedImages,
  pixiApp: PIXI.Application<PIXI.ICanvas> | undefined,
  selectedColorHex: string,
  setIsHighResolutionMockupLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  designFile: string,
  sideOfMockup: string,
): Promise<string> => {
  try {
    setIsHighResolutionMockupLoading(true);

    if (!pixiApp) {
      throw new Error('High resolution Mockup is not found');
    }

    // replace URLs with full resolution URLs
    const mainImageUrl = replaceUrl(mockup.main_image_url, 'full', 'full');
    const alphaImageUrl = replaceUrl(
      mockup.full_alpha_image_url,
      'full',
      'full',
    );

    // check if images are preloaded
    const preloadedImage = preloadedImages[mainImageUrl];
    const preloadedAlpha = preloadedImages[alphaImageUrl];

    const generatedImage = await setupPixi(
      preloadedImage || mainImageUrl,
      preloadedAlpha || alphaImageUrl,
      designFile,
      mockup.boundary_width,
      mockup.boundary_height,
      mockup.boundary_x,
      mockup.boundary_y,
      `${mockup.id}-full`,
      mockup.curves_difficulty,
      selectedColorHex,
      pixiApp,
      mockup.org_width,
      mockup.org_height,
      'full',
      'full',
      sideOfMockup ? sideOfMockup + selectedColorHex : undefined,
    );

    return generatedImage.src;
  } catch (error) {
    console.error('Error generating high resolution mockup:', error);
    return '';
  } finally {
    setIsHighResolutionMockupLoading(false);
  }
};

export const imageWorkerCallBack = (
  data: { [key: string]: Blob },
  err: any,
  setPreloadedImages: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >,
) => {
  if (err) {
    console.error(`Error in processing images at worker`, err);
    return;
  } else {
    const imagesObj = Object.keys(data).reduce((acc, key) => {
      const blob = data[key];
      if (blob instanceof Blob) {
        acc[key] = URL.createObjectURL(blob);
      } else {
        console.error(`Expected Blob, but received ${typeof blob}`);
      }
      return acc;
    }, {} as { [key: string]: string });
    setPreloadedImages((prev) => ({
      ...prev,
      ...imagesObj,
    }));
  }
};
