import styled from 'styled-components';
import { FlexBox } from '../common/wrappers';
import Typography from '../typography';
import Button from '../common/button';
import { colors } from '../../assets/colors';
import IconButton from '../icon-button';
import { EditIcon } from '../../assets/icons';
import { getUserData } from '../../store/slices/authSlice';
import { useAppSelector } from '../../store/hooks';

const MyAccounts = () => {
  const { userInfo } = useAppSelector(getUserData);
  return (
    <StyledWrapper>
      <FlexBox className="main-wrapper" direction="column">
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className=" p-16px b-b"
        >
          <Typography text="Monthly subscription" fontSize="18px" />
          <FlexBox gap="1rem">
            <Button
              appearance="secondary"
              label="Cancel subscription"
              onClick={() => console.log('ssa')}
            />
            <Button
              appearance="primary"
              label="Upgrade to yearly plan"
              onClick={() => console.log('ssa')}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox className="p-16px" direction="column" gap="1rem">
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Monthly payment" fontSize="14px" />
            <Typography text="USD 29.00" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Next payment" fontSize="14px" />
            <Typography text="Apr 30, 2023" fontSize="14px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox className="main-wrapper" direction="column">
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className=" p-16px b-b"
        >
          <Typography text="My details" fontSize="18px" />
          <FlexBox gap="1rem" alignItems="center">
            <Button
              appearance="secondary"
              label="Change password"
              onClick={() => console.log('ssa')}
            />
            <IconButton
              appearance="secondary"
              icon={EditIcon}
              onClick={() => console.log('ssa')}
              height="32px"
              width="32px"
            />
          </FlexBox>
        </FlexBox>
        <FlexBox className="p-16px" direction="column" gap="1rem">
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Full name" fontSize="14px" />
            <Typography text={userInfo.name} fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Business name" fontSize="14px" />
            <Typography text={userInfo.storeName} fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Email" fontSize="14px" />
            <Typography text={userInfo.email} fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Phone" fontSize="14px" />
            <Typography text="123-456-7890" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Address" fontSize="14px" />
            <Typography
              text="2081 Fairview street unit 502, Burlington, ON, L7R0E4, CA"
              fontSize="14px"
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox className="main-wrapper" direction="column">
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className=" p-16px b-b"
        >
          <Typography text="Payment settings" fontSize="18px" />

          <IconButton
            appearance="secondary"
            icon={EditIcon}
            onClick={() => console.log('ssa')}
            height="32px"
            width="32px"
          />
        </FlexBox>
        <FlexBox className="p-16px" direction="column" gap="1rem">
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Card" fontSize="14px" />
            <Typography text="1111 2222 3333 4444" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Billing currency" fontSize="14px" />
            <Typography text="USD" fontSize="14px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox className="main-wrapper" direction="column">
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className=" p-16px b-b"
        >
          <Typography text="General settings" fontSize="18px" />

          <IconButton
            appearance="secondary"
            icon={EditIcon}
            onClick={() => console.log('ssa')}
            height="32px"
            width="32px"
          />
        </FlexBox>
        <FlexBox className="p-16px" direction="column" gap="1rem">
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Measuring system" fontSize="14px" />
            <Typography text="Metric (kg, cm, etc.)" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Timezone" fontSize="14px" />
            <Typography text="(GMT +3:00) UTC" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Email notifications" fontSize="14px" />
            <Typography text="On" fontSize="14px" />
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className="w-100"
          >
            <Typography text="Invoices frequency" fontSize="14px" />
            <Typography text="Monthly (summary invoices)" fontSize="14px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </StyledWrapper>
  );
};

export default MyAccounts;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: -webkit-fill-available;

  .main-wrapper {
    background-color: ${colors.white};
  }
  .b-b {
    border-bottom: 1px solid ${colors.grey[300]};
  }
`;
