import { SampleOrderCart } from '../../interface/create-order-interface';

export const generateSampleOrderShippingCostPayload = (
  selectedProducts: SampleOrderCart[],
) => {
  const productsMap: Record<
    string,
    { quantity: number; printProviderId: string; variantId: string }
  > = {};

  selectedProducts.forEach((item) => {
    const { productId, quantity, variantId, printProviderId } = item;

    if (productsMap[productId]) {
      productsMap[productId].quantity += quantity;
    } else {
      productsMap[productId] = { printProviderId, quantity, variantId };
    }
  });

  return Object.keys(productsMap).map((productId) => ({
    id: productId,
    quantity: productsMap[productId].quantity,
    printProviderId: productsMap[productId].printProviderId,
    variantId: productsMap[productId].variantId,
  }));
};
