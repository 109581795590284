/**
 * @param value : the value for which you want to apply debounce
 * @param delay : delay time in ms
 *
 * @returns : debouncedValue
 */

import { useEffect, useState } from 'react';
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
