import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.css';
import ProductCardStyleOne from './cards/product-card-style-one';
import { CatalogFiltersState } from '../../interface/catalog-interfaces';
import Layout from '../layout';
import { useGetCatalogQuery } from '../../store/services/catalog';
import Loader from '../common/loader';
import { useDebounce } from '../../hooks/useDebounce';
import {
  setCatalogueId,
  setIsDetailsVisited,
  setIsMockupsVisited,
  setModelNumber,
  setPrintProviderId,
  setProductId,
} from '../../store/slices/utilitySlice';
import { useAppDispatch } from '../../store/hooks';

const Catalog: React.FC = () => {
  const dispatch = useAppDispatch();
  const topProductRef = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<CatalogFiltersState>({
    brands: [],
    categories: [],
    printProviders: [],
    sizes: [],
    searchText: '',
    priceRange: [1, 99],
    sortDir: '',
    currentPage: 1,
  });
  // const [filterToggle, setToggle] = useState<boolean>(false);
  // const [totalPageCount, setTotalPageCount] = useState<number>(1);

  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const debouncedSearchText = useDebounce(filters.searchText, 500);
  const [flag, setFlag] = useState(false);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFlag(true);
  };

  const handleDragEnd = () => {
    setFlag(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (flag) {
      event.preventDefault();
    }
  };
  const filtersParams = useMemo(() => {
    setFilters({ ...filters, currentPage: 1 });
    return {
      search: debouncedSearchText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);
  const {
    data: catalog,
    isFetching,
    isSuccess,
  } = useGetCatalogQuery({
    page: filters.currentPage,
    search: filtersParams.search,
    sizes: filters.sizes,
    printProviders: filters.printProviders,
    brands: filters.brands,
    sortDir: filters.sortDir,
    maxPrice: filters.priceRange[1],
    minPrice: filters.priceRange[0],
  });
  // const { data: brands } = useGetAllBrandsQuery();

  const productData = useMemo(() => {
    if (isSuccess) {
      setInitialLoading(true);
    }
    // setTotalPageCount(catalog?.totalPages ?? 1);
    return catalog?.results ?? [];
  }, [catalog, isSuccess]);

  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   value: number,
  // ) => {
  //   topProductRef.current?.scrollIntoView();
  //   setFilters({ ...filters, currentPage: value });
  // };

  // const searchHandler = (value: string) => {
  //   setFilters({ ...filters, searchText: value });
  // };

  useEffect(() => {
    dispatch(setCatalogueId(''));
    dispatch(setProductId(''));
    dispatch(setPrintProviderId(''));
    dispatch(setModelNumber(''));
    dispatch(setIsDetailsVisited(false));
    dispatch(setIsMockupsVisited(false));
  }, [dispatch]);

  return (
    <Layout>
      {isFetching && <Loader />}
      <div style={{ backgroundColor: 'white', paddingTop: '2rem' }}>
        {initialLoading && (
          <>
            {/* <div className="w-full h-[36px] container-x mx-auto mb-[60px]">
              <SearchBox onChange={searchHandler} value={filters.searchText} />
            </div> */}
            <div className="products-page-wrapper w-full" ref={topProductRef}>
              <div className="container-x mx-auto">
                {/* <Breadcrumb /> */}
                <div className="w-full lg:flex lg:space-x-[30px]">
                  {/* <div className="lg:w-[270px]">
                    <ProductsFilter
                      filterToggle={filterToggle}
                      filterToggleHandler={() => setToggle(!filterToggle)}
                      filters={filters}
                      className="mb-[30px]"
                      setFilters={setFilters}
                      brands={brands?.data ?? []}
                    />
                  </div> */}

                  <div className="flex-1">
                    {/* <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                      <div className="flex space-x-3 items-center ml-auto">
                        <StyledWrapper>
                          <SelectList
                            items={sortItems}
                            onChange={(value) => {
                              setFilters({
                                ...filters,
                                sortDir: value,
                                currentPage: 1,
                              });
                            }}
                            selectedValue={filters.sortDir}
                            label="Sort by"
                            width="100%"
                            gapBetweenInputAndItems={0}
                            className="sort-by-dropdown"
                          />
                        </StyledWrapper>
                        <button
                          onClick={() => setToggle(!filterToggle)}
                          type="button"
                          className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                        >
                          <FilterIcon />
                        </button>
                      </div>
                    </div> */}
                    {productData.length > 0 ? (
                      <div className="grid xl:grid-cols-3 sm:grid-cols-3 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px] ">
                        {productData.map((product) => (
                          <div
                            key={product._id}
                            onDragEnter={handleDragStart}
                            onDragEnd={handleDragEnd}
                            onClick={handleClick}
                          >
                            <ProductCardStyleOne
                              productId={product._id}
                              productTitle={product.title}
                              productImage={product.image}
                              properties={product.properties}
                              fromPrice={product.minPrice}
                              modalNumber={product.model}
                              brand={product.brand}
                              flag={flag}
                              growthPrice={product.minPriceSubscription}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center text-2xl min-w-fit min-h-screen m-auto">
                        Oh Oh no products found!!
                      </div>
                    )}

                    {/* <div className="w-full flex flex-wrap content-end flex-col">
                      <Pagination
                        shape="circular"
                        color="primary"
                        size="medium"
                        page={filters.currentPage}
                        count={totalPageCount}
                        sx={{
                          color: colors.blue[900],
                          marginLeft: 'auto',
                        }}
                        onChange={handlePageChange}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Catalog;

/* const StyledWrapper = styled.div``;

const sortItems: Option[] = [
  {
    label: 'Lowest price',
    value: 'asc',
  },
  {
    label: 'Highest price',
    value: 'desc',
  },
];
*/
export enum CatalogProductCategory {
  APPAREL = 'Apparel',
  HOUSEWARE = 'Houseware',
  DRINKWARE = 'Drinkware',
}
