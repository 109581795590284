import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../../assets/colors';
import { StepDone } from '../../../../assets/icons';
import { Link } from 'react-router-dom';

interface Props {
  linkCondition: boolean;
  linkPath?: string;
  displayText: string;
  stepNumber: string | number;
  isStepDone: boolean;
  isStepNumberActive: boolean;
  fromModal?: boolean;
}

const StepSystem: React.FC<Props> = ({
  linkCondition,
  linkPath = '',
  displayText,
  stepNumber,
  isStepDone,
  isStepNumberActive,
  fromModal,
}) => {
  return (
    <>
      {linkCondition ? (
        <Link to={linkPath} reloadDocument={true}>
          <Step>
            {isStepDone ? (
              <img src={StepDone} alt="Done" />
            ) : (
              <StepNumber status={isStepNumberActive ? 'active' : 'next'}>
                {stepNumber}
              </StepNumber>
            )}

            <span>{displayText}</span>
          </Step>
        </Link>
      ) : (
        <Step fromModal={fromModal} isStepNumberActive={isStepNumberActive}>
          {isStepDone ? (
            <img src={StepDone} alt="Done" />
          ) : (
            <StepNumber status={isStepNumberActive ? 'active' : 'next'}>
              {stepNumber}
            </StepNumber>
          )}

          <span className="title">{displayText}</span>
        </Step>
      )}
    </>
  );
};

export default StepSystem;

const Step = styled.div<{ fromModal?: boolean; isStepNumberActive?: boolean }>`
  display: flex;
  margin: 5px !important;
  > :first-child {
    margin-right: 5px;
  }
  > * {
    margin: auto;
  }
  .title {
    color: ${(props) =>
      !props.isStepNumberActive ? colors.grey[700] : 'inherit'};
  }
  ${({ fromModal }) =>
    fromModal &&
    css`
      font-size: 12px;
    `};
`;

const StepNumber = styled.span<{ status?: 'active' | 'next' | 'done' }>`
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background: ${(props) =>
    props.status === 'active' ? colors.grey[900] : 'transparent'};
  color: ${(props) =>
    props.status === 'done' || props.status === 'next'
      ? colors.grey[500]
      : '#fff'};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  border: 1px solid
    ${(props) =>
      props.status === 'done' || props.status === 'next'
        ? colors.grey[500]
        : 'transparent'};
`;
