import { useMemo } from 'react';

export const useGetPerPage = () => {
  const perPageLimit = useMemo(() => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth <= 1500) {
      return 10;
    } else if (viewportWidth > 1500 && viewportWidth <= 1900) {
      return 14;
    } else if (viewportWidth > 1900 && viewportWidth <= 2400) {
      return 18;
    } else {
      return 20;
    }
  }, []);

  return perPageLimit;
};
