import { createApi } from '@reduxjs/toolkit/query/react';
import {
  MyMockupsResponse,
  MyMockupsResponseQueryParams,
  UploadMyMockupResponse,
} from '../../interface';
import { baseQueryWithAuth } from '../baseQuery';
import { appendQueryParams } from '../../helper/appenedQueryParams';

export const myMockupsAPI = createApi({
  reducerPath: 'myMockups',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['MyMockups'],
  endpoints: (builder) => ({
    getAllMyMockups: builder.query<
      MyMockupsResponse,
      MyMockupsResponseQueryParams
    >({
      query: (params) => {
        let url = `/users/my-mockups`;
        return appendQueryParams(url, params);
      },
      providesTags: ['MyMockups'],
    }),

    addImageToMyMockups: builder.mutation<
      UploadMyMockupResponse,
      { formData: FormData; folderName?: string }
    >({
      
      query: (params) => ({
        url: appendQueryParams(`/users/my-mockups`, params),
        method: 'POST',
        body: params.formData,
      }),
    }),

    deleteMyMockupById: builder.mutation<
      { message: string },
      { mockupId?: string ,folderName?: string }
      
    >({
      query: (params) => ({ 
      url: appendQueryParams(`/users/my-mockups`, params),
      method: 'DELETE',
      }),
      async onQueryStarted(params, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          for (const {
            endpointName,
            originalArgs,
          } of myMockupsAPI.util.selectInvalidatedBy(getState(), [
            { type: 'MyMockups' },
          ])) {
            if (endpointName !== 'getAllMyMockups') continue;
            dispatch(
              myMockupsAPI.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  const data = draft;
                  if (params.folderName && params.mockupId) {
                    data.data.results = data.data.results.filter(
                      (image) =>
                        image.id !== params.mockupId &&
                        image.folderName.includes(params.folderName as string),
                    );
                  } else if (params.folderName) {
                    data.data.allFolders = data.data.allFolders.filter(
                      (folder) => folder !== params.folderName,
                    );
                  } else if (params.mockupId) {
                    data.data.results = data.data.results.filter(
                      (image) => image.id !== params.mockupId,
                    );
                  }
                  return data;
                },
              ),
            );
          }
        } catch {}
      },
    }),
  }),
});

export const {
  useGetAllMyMockupsQuery,
  useLazyGetAllMyMockupsQuery,
  useAddImageToMyMockupsMutation,
  useDeleteMyMockupByIdMutation,
} = myMockupsAPI;
