import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import Typography from '../typography';

const Title = (title: string) => {
  return <Typography text={title} fontSize="0.8rem" classname="box p-10px" />;
};

const CustomToolTip = styled(({ className, title, ...props }: TooltipProps) => (
  <Tooltip
    title={typeof title === 'string' ? Title(title) : title}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0px',
    backgroundColor: `${colors.white}`,
  },
}));

export default CustomToolTip;
