import React from 'react';
import MyProducts from '../../components/my-products';
import { TabletProps } from '../../interface';
import BaseLayout from '../../components/layout/base-layout';

const MyProductsPage: React.FC<TabletProps> = ({ isTablet }) => {
  return (
    <BaseLayout>
      <MyProducts isTablet={isTablet} />
    </BaseLayout>
  );
};

export default MyProductsPage;
