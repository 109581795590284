import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { FlexBox } from '../../../common/wrappers';
import RangeSlider from '../../../catalog/common/range-slider';
import Typography from '../../../typography';
import { ContactShadows, Environment, OrbitControls } from '@react-three/drei';
import { colors } from '../../../../assets/colors';
import { SnapIcon, TheeDIcon } from '../../../../assets/icons';
import { CustomBtnWithIcon } from '../../../common/elements';
import Mug3d from './Mug3d';
interface MugViewerProps {
  image: Blob | string;
  selectedColor: any;
  isColoredMug: boolean;
  setSnapImages?: any;
  frontDesign?: string;
  snapImages?: any;
  setLoading?: (loading: boolean) => void;
}
const MugViewer: React.FC<MugViewerProps> = ({
  image,
  selectedColor,
  isColoredMug,
  setSnapImages,
  frontDesign,
  snapImages,
  setLoading,
}) => {
  const [rotationY, setRotationY] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const mugRef = useRef<{ takeScreenshot: () => void }>(null);
  const handleSliderChange = (e: any) => {
    setRotationY(parseInt(e.target.value, 10));
    const newPercentage = Math.round(
      ((parseInt(e.target.value, 10) - 40) / (360 - 40)) * 100,
    );
    setPercentage(newPercentage);
  };
  const [snapClicked, setSnapClicked] = useState(false);
  useEffect(() => {
    if (snapClicked) {
      setSnapClicked(false);
    }
  }, [snapClicked]);
  const handleScreenshot = () => {
    if (mugRef.current) {
      mugRef.current.takeScreenshot();
    }
  };
  return (
    <Container isModal={setSnapImages ? true : false}>
      <Canvas
        camera={{ position: [14, -2, 20], fov: 0.5 }}
        className="mugs-canvas"
      >
        <OrbitControls enableZoom={false} />
        <Mug3d
          rotationY={rotationY}
          image={image}
          selectedColor={selectedColor.label}
          isColoredMug={isColoredMug}
          snapClicked={snapClicked}
          setSnapImages={setSnapImages}
          frontDesign={frontDesign}
          ref={mugRef}
          {...(setLoading && { setLoading })}
        />
        <ContactShadows position-y={-0.5} opacity={0.1} blur={2} />
        <Environment preset="sunset" background={false} />
      </Canvas>
      <FlexBox
        gap="1rem"
        justifyContent={setSnapImages ? 'space-between' : 'center'}
        alignItems="center"
      >
        {setSnapImages && (
          <>
            <FlexBox justifyContent="center" alignItems="center" gap="10px">
              <img src={TheeDIcon} alt="3d Icon" height={25} width={25} />
              <p>3D</p>
            </FlexBox>
          </>
        )}
        <div style={{ width: setSnapImages ? '40%' : '75%' }}>
          <RangeSlider
            value={rotationY}
            min={40}
            max={360}
            onChange={handleSliderChange}
            aria-label="Rotation Slider"
          />
        </div>
        {!setSnapImages ? (
          <Typography text={`${percentage}%`} fontSize="12px" />
        ) : (
          <CustomBtnWithIcon
            onClick={handleScreenshot}
            style={{
              padding: '3px',
              margin: '0',
            }}
          >
            <img src={SnapIcon} alt="Text" />
            <p style={{ fontSize: '14px', margin: '0 0 0 3px' }}>Take a Snap</p>
          </CustomBtnWithIcon>
        )}
      </FlexBox>
      {snapImages && (
        <FlexBox className="count">
          <span className="fs-12">{snapImages.length} of 10</span>{' '}
        </FlexBox>
      )}
    </Container>
  );
};

export default MugViewer;
const Container = styled.div<{ isModal: boolean }>`
  height: 206px;
  width: ${(props) => (props.isModal ? '100%' : '250px')};
  margin: ${(props) => (props.isModal ? '0 auto 70px' : '0 auto 90px')};
  .mugs-canvas {
    canvas {
      height: 300px !important;
    }
  }
  .mugs-canvas div:first-child {
    height: unset !important;
    width: unset !important;
  }
  .MuiSlider-rail {
    border-radius: var(--Spacing-1, 4px);
    background: var(--grey-400, #ccc);
    color: ${colors.grey[400]};
  }
  .MuiSlider-thumb {
    width: 28px;
    border-radius: var(--Spacing-1, 4px);
    background: ${colors.grey[600]};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px,
      rgba(0, 0, 0, 0.15) 0px 1px 1px 0px !important;
    height: 10px;
  }
  .MuiSlider-track {
    border-radius: var(--Spacing-1, 4px);
    background: var(--grey-400, #ccc);
    color: ${colors.grey[400]};
  }
  .count {
    padding-left: 40%;
  }
`;
