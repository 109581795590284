import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface InitialState {
  catalogueId: string | undefined;
  printProviderId: string | undefined;
  productId: string | undefined;
  modelNumber: string | number | undefined;
  isDetailsVisited: boolean;
  isMockupsVisited: boolean;
  extraMockupCount: number;
  isInvalidGrantFromEtsy: boolean;
  showConnetModalForAddNewShop: boolean;
}

const initialState: InitialState = {
  catalogueId: '',
  printProviderId: '',
  productId: '',
  modelNumber: '',
  isDetailsVisited: false,
  isMockupsVisited: false,
  extraMockupCount: 0,
  isInvalidGrantFromEtsy: false,
  showConnetModalForAddNewShop: false,
};

export const utilitySlice = createSlice({
  name: 'utilities',
  initialState,
  reducers: {
    setCatalogueId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.catalogueId = payload;
    },
    setProductId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.productId = payload;
    },
    setModelNumber: (
      state,
      { payload }: PayloadAction<string | number | undefined>,
    ) => {
      state.modelNumber = payload;
    },
    setPrintProviderId: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.printProviderId = payload;
    },
    setIsDetailsVisited: (state, { payload }: PayloadAction<boolean>) => {
      state.isDetailsVisited = payload;
    },
    setIsMockupsVisited: (state, { payload }: PayloadAction<boolean>) => {
      state.isMockupsVisited = payload;
    },
    increaseExtraMockupCount: (state) => {
      state.extraMockupCount = state.extraMockupCount + 1;
    },
    setConnectShopModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvalidGrantFromEtsy = payload;
    },
    setShowConnetModalForAddNewShop: (state, { payload }: PayloadAction<boolean>) => {
      state.showConnetModalForAddNewShop = payload;
    }
  },
});

export const {
  setCatalogueId,
  setPrintProviderId,
  setProductId,
  setModelNumber,
  setIsDetailsVisited,
  setIsMockupsVisited,
  increaseExtraMockupCount,
  setConnectShopModal,
  setShowConnetModalForAddNewShop
} = utilitySlice.actions;

export const getUtilityData = (state: RootState) => state.utilities;

export default utilitySlice.reducer;
