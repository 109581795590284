import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { ReactComponent as QuestionMarkIcon } from '../../assets/images/question.svg';
import AlertIconComponent from './alert-icon-component';
interface Props {
  text: string | ReactNode;
  type: 'info' | 'warning' | 'error';
  className?: string;
}
const Banner: React.FC<Props> = ({ text, className, type }) => {
  const backgroundColor =
    type === 'info'
      ? colors.blue[100]
      : type === 'warning'
      ? colors.orange[100]
      : colors.red[100];

  const borderColor =
    type === 'info'
      ? colors.blue[400]
      : type === 'warning'
      ? colors.orange[200]
      : colors.red[300];

  return (
    <StyledWrapper
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      className={className}
    >
      {type === 'info' ? (
        <QuestionMarkIcon width={20} />
      ) : (
        <AlertIconComponent
          color={type === 'warning' ? colors.orange[500] : colors.red[900]}
          width="25px"
          height="25px"
        />
      )}

      {text}
    </StyledWrapper>
  );
};

export default Banner;

const StyledWrapper = styled.div<{
  backgroundColor: string;
  borderColor?: string;
}>`
  width: -webkit-fill-available;
  max-height: fit-content;
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;
