import React, { Dispatch, SetStateAction, useState, useRef } from 'react';
import Modal from '../../common/modal';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import Button from '../../common/button';
import Input from '../../inputs';
import { colors } from '../../../assets/colors';
import { BrowseIcon, DeleteIcon } from '../../../assets/icons';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { useAddImageToMyMockupsMutation } from '../../../store/services/my-mockups';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAddImageToGalleryMutation } from '../../../store/services/gallery';
import Loader from '../../common/loader';
import { onDropRejectedHandler } from '../../editor/utils';
import { MAX_DESIGN_FILE_SIZE } from '../../../constants/designTabs';

const AddFolderModal: React.FC<{
  setOpen: Dispatch<SetStateAction<boolean>>;
  allFolders: string[];
  refetch?: any;
  isMyLibrary?: boolean;
}> = ({ setOpen, allFolders, refetch, isMyLibrary }) => {
  const [addToMyMockups, { isLoading: isUpdating }] =
    useAddImageToMyMockupsMutation();
  const [addToGallery, { isLoading }] = useAddImageToGalleryMutation();
  const [folder, setFolderName] = useState<string>('');
  const [uploadImage, setUploadImages] = useState<File[]>([]);
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const debouncedFolder = useDebounce<string>(folder, 300);
  const handleOnChange = (e: any) => {
    const newValue = e.target.value;
    if (newValue.length < 15) {
      setFolderName(e.target.value);
    }
  };

  const isFolderExists = allFolders
    .map((existingFolder) => existingFolder.toLowerCase())
    .includes(debouncedFolder.toLowerCase());
  const handleSave = async () => {
    const formData = new FormData();
    uploadImage.forEach((image) => {
      formData.append('images', image);
    });

    const res = isMyLibrary
      ? await addToGallery({ folderName: debouncedFolder, formData })
      : await addToMyMockups({ folderName: debouncedFolder, formData });

    if (res) {
      refetch();
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const uploadMyMockups = (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const updatedImages = [...uploadImage];
      acceptedFiles.forEach((file: File) => {
        updatedImages.push(file);
      });

      setUploadImages(updatedImages);
    }
  };

  const handleDelete = (index: number) => {
    const updatedUploadImage = [...uploadImage];
    updatedUploadImage.splice(index, 1);
    setUploadImages(updatedUploadImage);
  };
  return (
    <StyledWrapper>
      {(isUpdating || isLoading) && <Loader />}
      <Modal
        title="Add Folder"
        onClose={handleCancel}
        size="small"
        className="edit-modal"
      >
        <FlexBox direction="column" gap="8px" className="input-wrapper">
          <label htmlFor="folderName">Folder Name</label>
          <Input
            onChange={(e) => handleOnChange(e)}
            value={folder}
            name="Folder Name"
            type="text"
          />
          {isFolderExists && (
            <p className="warning-text">
              Folder with this name already exists.
            </p>
          )}
        </FlexBox>

        <Dropzone
          ref={dropzoneRef}
          onDropRejected={onDropRejectedHandler}
          onDrop={(acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) return;
            uploadMyMockups(acceptedFiles);
          }}
          maxSize={isMyLibrary ? MAX_DESIGN_FILE_SIZE : undefined}
          accept={{
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            ...(isMyLibrary && { 'image/svg': ['.svg'] }),
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <FlexBox
                alignItems="center"
                className="uploadArea"
                justifyContent="center"
                gap="2"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <img src={BrowseIcon} alt="Dropzone" />
                <p className="fs-16" style={{ margin: '2px' }}>
                  Drag and drop files here or
                </p>

                <Button
                  label="Browse files"
                  onClick={() => {
                    if (dropzoneRef.current) {
                      dropzoneRef.current.open();
                    }
                  }}
                  appearance="primary"
                  size="medium"
                  height="36px"
                />
              </FlexBox>
            </section>
          )}
        </Dropzone>
        <FlexBox gap="1rem" className="model-footer">
          <Button
            label="Cancel"
            onClick={handleCancel}
            appearance="secondary"
            size="fullWidth"
            height="36px"
          />

          <Button
            label="Save folder"
            disabled={
              folder.length < 1 ||
              !uploadImage ||
              uploadImage?.length < 1 ||
              isFolderExists
            }
            onClick={handleSave}
            appearance="primary"
            size="fullWidth"
            height="36px"
          />
        </FlexBox>
        {uploadImage &&
          uploadImage.map((image: File, index: number) => (
            <Wrapper key={index}>
              <FlexBox gap="10px" style={{ width: '400px' }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt="Uploaded File"
                  style={{
                    maxWidth: '20px',
                    maxHeight: '20px',
                    borderRadius: '10%',
                  }}
                />
                <p className="file-name">{image.name ? image.name : ''}</p>
              </FlexBox>
              <img
                src={DeleteIcon}
                alt="Add"
                onClick={() => handleDelete(index)}
              />
            </Wrapper>
          ))}
      </Modal>
    </StyledWrapper>
  );
};

export default AddFolderModal;

const StyledWrapper = styled.div`
  .edit-modal {
    padding: 24px;
    border-radius: 16px;
    height: max-content;
    .modal-header {
      font-size: 18px;
    }
    p {
      margin: 0;
    }
    .sub-text {
      color: ${colors.grey[900]};
      font-size: 14px;
    }
    .input-wrapper {
      width: 100%;
      padding-block: 24px;
      /* to hide arrows in input field */
    }
    .edit-input input {
      padding: 10px 12px;
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .model-footer {
      width: 100%;
    }
  }
  .uploadArea {
    padding: 20px;
    height: 128px;
    background: ${colors.grey[100]};
    cursor: pointer;
  }
  .warning-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 12px;
  padding: 9px;
  background: #fafafa;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
  margin-top: 10px;
  cursor: pointer;

  .file-name {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // max-width: calc(100% - 40px);
    width: 80%;
  }
`;
