import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { FlexBox } from '../common/wrappers';
import { ColoredCircle } from '../common/elements';
import { Product } from '../../interface/orders-interface';
import PreviewModal from '../order-details/details/modals/preview-modal';
import ImageBox from '../common/image-box';
import { CatalogProductCategory } from '../catalog';

interface Props {
  quantity?: number;
  productTitle?: string;
  productColor?: string;
  productSize?: string;
  className?: string;
  modelNumber?: string;
  brandName?: string;
  product?: Product;
  forHover?: boolean;
  forModal?: boolean;
  imageUrls?: any;
  category?: string;
  printArea?: string[];
  colorName?: string;
}
const MiniImageInfoSection: React.FC<Props> = ({
  quantity,
  productColor,
  productSize,
  className,
  modelNumber,
  brandName,
  product,
  forHover,
  forModal,
  imageUrls,
  category,
  printArea,
  colorName,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const frontDesign = product?.variants?.[0]?.frontDesign ? 'Front Side' : '';
  const backDesign = product?.variants?.[0]?.backDesign ? 'Back Side' : '';
  const result =
    frontDesign && backDesign
      ? `${frontDesign}, ${backDesign}`
      : frontDesign || backDesign;
  const noOfPreviews = frontDesign && backDesign ? 2 : 1;
  const handleImageClick = () => {
    if (forModal) setShowPreviewModal(true);
  };
  const getMockupUrls = () => {
    const mockupURL = product?.personalization?.mockupURL;
    const mockupURLBack = product?.personalization?.mockupURLBack;
    const defaultMockupURL = product?.mockupURL;
    const defaultMockupURLBack = product?.mockupURLBack;
    const ImageArray: any = [];
    if (mockupURL) {
      ImageArray.push(mockupURL);
    }
    if (mockupURLBack) {
      ImageArray.push(mockupURLBack);
    }
    if (!mockupURL && defaultMockupURL) {
      ImageArray.push(defaultMockupURL);
    }
    if (!mockupURLBack && defaultMockupURLBack) {
      ImageArray.push(defaultMockupURLBack);
    }
    return ImageArray;
  };

  return (
    <StyledWrapper className={className} forHover={forHover ?? false}>
      {forHover ? (
        <>
          <FlexBox
            direction="column"
            gap="1rem"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '2px' }}
          >
            <FlexBox gap="10px">
              {imageUrls?.slice(0, 2)?.map((item: any, index: number) => (
                <ImageBox
                  isClickable
                  className="item-header"
                  key={index}
                  width="92px"
                  boxHeight="92px"
                  image={item}
                ></ImageBox>
              ))}
            </FlexBox>
            {imageUrls && imageUrls.length > 2 && (
              <>
                <FlexBox
                  className="grey700"
                  style={{
                    fontSize: '16px',
                  }}
                >
                  <span>+ {`${imageUrls.length - 2}`} more Items</span>
                </FlexBox>
              </>
            )}
          </FlexBox>
        </>
      ) : (
        <>
          <div className="image-wrapper" onClick={handleImageClick}>
            <img
              src={`${getMockupUrls()[0]}?${Date.now()}`}
              alt="product_image"
              className={category === CatalogProductCategory.DRINKWARE ? 'set-height' : ''}
            />

            <div className="quantity">{noOfPreviews}</div>
          </div>
          <FlexBox
            direction="column"
            gap="12px"
            className="product-info-wrapper"
          >
            <FlexBox gap="8px" className="product-details" alignItems="center">
              <ColoredCircle
                colorCode={
                  colorName?.includes('/')
                    ? colorName
                    : `#${productColor}` ?? '#000000'
                }
                width={18}
                height={18}
              />
              <span className="small-dot"></span>
              <div className="product-size">{productSize ?? '--'}</div>
              <span className="small-dot"></span>
              <div>{quantity ?? '--'} items</div>
            </FlexBox>
            <>
              <FlexBox justifyContent="space-between">
                <span>Brand</span>
                <span>{brandName}</span>
              </FlexBox>
              <FlexBox justifyContent="space-between">
                <span>Model Number</span>
                <span>{modelNumber}</span>
              </FlexBox>
              <FlexBox justifyContent="space-between">
                <span>Print Locations</span>
                <span>
                  {category === CatalogProductCategory.DRINKWARE && printArea
                    ? printArea[0]
                    : result}
                </span>
              </FlexBox>
            </>
          </FlexBox>
        </>
      )}

      {showPreviewModal && product && (
        <PreviewModal
          onClose={() => setShowPreviewModal(false)}
          ImageArray={getMockupUrls()}
        />
      )}
    </StyledWrapper>
  );
};

export default MiniImageInfoSection;
export const StyledWrapper = styled.div<{
  forHover: boolean;
}>`
  box-sizing: border-box;
  width: ${(props) => (props.forHover ? 'auto' : '384px')};
  height: ${(props) => (props.forHover ? 'fit-content' : '116px')};
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey['border']};
  padding: 12px;
  .image-wrapper {
    height: 92px;
    width: 92px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid ${colors.grey[100]};
    img {
      height: 100%;
      border-radius: 6px;
      &.set-height {
        width: inherit;
        object-fit: contain;
      }
    }
    .quantity {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      position: absolute;
      background-color: ${colors.black};
      color: ${colors.white};
      bottom: 0;
      right: 0px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .product-info-wrapper {
    width: 100%;
    .product-title {
      font-weight: 700;
      font-size: 14px;
      color: ${colors.grey[900]};
    }
    .product-details {
      font-size: 14px;
      color: ${colors.grey['700']};
    }
  }
  .preview-modal {
    height: fit-content;
    .image-box-container {
      max-height: 70vh;
    }
  }
  .activeBox {
    border: 2px solid ${colors.blue['800']};
  }
  .imagebox {
    margin-bottom: 15px;
  }
  .bottom-button-wrapper {
    transform: translateX(-50%);
    margin-top: 20px;
    user-select: none;
  }
`;
