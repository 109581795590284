import { Status, StatusColorCodes } from '../interface';
import { OrderStatus } from '../interface/orders-interface';

export const getColorCodeByStatus = (status: string) => {
  switch (status) {
    case Status.Draft:
    case OrderStatus.PENDING_PERSONALIZATION:
      return StatusColorCodes.Ash;
    case Status.UnpublishedChanges:
    case Status.UploadingImages:
    case Status.Unpublished:
    case OrderStatus.READY:
      return StatusColorCodes.Yellow;
    case Status.Published:
    case OrderStatus.IN_PRODUCTION:
    case OrderStatus.READY_TO_SHIP:
    case OrderStatus.SHIPPED:
      return StatusColorCodes.Green;
    case Status.Error:
    case Status.LISTING_ERROR:
    case OrderStatus.CANCELLED:
    case OrderStatus.EXCEPTION:
      return StatusColorCodes.Red;
    default:
      return StatusColorCodes.Ash;
  }
};
