import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';
import { FlexBox } from '../common/wrappers';
import Typography from '../typography';
import { GalleryIcon, NewFolderIcon, SearchIcon } from '../../assets/icons';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg';
import SearchBar from '../common/search-bar';
import {
  useAddImageToGalleryMutation,
  useDeleteImageByIdMutation,
  useGetGalleryByUserIDQuery,
  useLazyGetGalleryByUserIDQuery,
} from '../../store/services/gallery';
import { useEffect, useMemo, useRef, useState } from 'react';
import Loader from '../common/loader';
import { useDebounce } from '../../hooks/useDebounce';
import DeleteConfirmationModal from '../common/confirmation-modal';
import { LoaderGif } from '../../assets/icons';
import AddFolderModal from '../mockups/right-sidebar/add-folder-modal';
import { ImagePlaceholder } from '../common/image-placeholder';
import Button from '../common/button';
import CustomToolTip from '../custom-tooltip';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import InfiniteScroll from '../common/infinite-scroll';
import { onDropRejectedHandler } from '../editor/utils';
import { MAX_DESIGN_FILE_SIZE } from '../../constants/designTabs';

interface Popup {
  imageName: string;
  openModal: boolean;
  imageId: string;
  isFolder?: boolean;
}

interface MyFilesProps {
  isInLibrary: boolean;
  addtoBoard?(src: string, name: string): void;
  isTablet: boolean;
  onClose?: () => void;
}

const MyFiles: React.FC<MyFilesProps> = ({
  isInLibrary,
  addtoBoard,
  isTablet,
  onClose,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const [showPopup, setShowPopup] = useState<Popup>({
    imageName: '',
    imageId: '',
    openModal: false,
  });
  const [open, setOpen] = useState(false);
  const [allFolders, setAllFolders] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput);

  const [getFolderArray, { isLoading: isGettingFolderImages }] =
    useLazyGetGalleryByUserIDQuery();
  const [folderData, setFolderData] = useState<any>({
    name: '',
    images: [],
  });
  const [deleteImage, { isLoading: isDeleting }] = useDeleteImageByIdMutation();
  const [imageArray, setImageArray] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [myLibraryQueryParams, setMyLibraryQueryParams] = useState<{
    currentPage: number;
    limit: number;
  }>({
    currentPage: 1,
    limit: isTablet ? 20 : 10,
  });

  const {
    data: imagesData,
    isLoading,
    refetch,
    isFetching,
  } = useGetGalleryByUserIDQuery({
    search: debouncedSearchInput,
    page: myLibraryQueryParams.currentPage,
    limit: myLibraryQueryParams.limit,
    folderName: folderData.name,
  });

  const [addToGallery, { isLoading: isUpdating }] =
    useAddImageToGalleryMutation();

  const uploadToGallery = async (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const filesArray = Array.from(acceptedFiles);

      const updatedImage = filesArray.map((file: any) => file);
      const formData = new FormData();
      updatedImage.forEach((image) => formData.append('images', image));

      if (folderData.name !== '') {
        const res = await addToGallery({
          folderName: folderData.name,
          formData: formData,
        });
        if (res && 'data' in res) {
          const newImages = Array.isArray(res?.data.data)
            ? res.data.data
            : [res.data.data];

          setFolderData({
            ...folderData,
            images: [...newImages, ...folderData.images],
          });
        }
      } else {
        const formData = new FormData();
        updatedImage.forEach((image) => formData.append('images', image));
        const res = await addToGallery({
          formData,
        }).unwrap();
        if (res) {
          setImageArray([...res.data, ...imageArray]);
        }
      }
    }
  };

  useEffect(() => {
    if (
      imagesData &&
      folderData.name === '' &&
      imagesData.data.currentFolder === ''
    ) {
      if (myLibraryQueryParams.currentPage !== 1) {
        const newData = imagesData.data.results.filter((obj: { id: any }) =>
          imageArray.every((existingObj) => existingObj.id !== obj.id),
        );

        if (newData.length > 0) {
          setImageArray([...imageArray, ...newData]);
        }
      } else {
        setImageArray(imagesData.data.results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData.name, imagesData, myLibraryQueryParams]);

  useEffect(() => {
    if (imagesData && imagesData.data.currentFolder !== '') {
      if (myLibraryQueryParams.currentPage !== 1) {
        const newData = imagesData.data.results.filter((obj: any) =>
          folderData.images.every(
            (existingObj: any) => existingObj.id !== obj.id,
          ),
        );
        if (newData.length > 0) {
          setFolderData({
            ...folderData,
            images: [...folderData.images, ...newData],
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLibraryQueryParams.currentPage, imagesData]);

  useEffect(() => {
    if (imagesData) {
      setTotalPages(imagesData.data.totalPages);
      setAllFolders(imagesData.data.allFolders);
    }
  }, [imagesData]);

  const handleFolder: any = async (folderName: any) => {
    setMyLibraryQueryParams((prev) => ({
      ...prev,
      currentPage: 1,
    }));

    const res = await getFolderArray({
      search: debouncedSearchInput,
      page: 1,
      limit: myLibraryQueryParams.limit,
      folderName: folderName,
    });
    if (res.isSuccess) {
      setFolderData({ name: folderName, images: res.data.data.results });
    }
  };

  const filteredGalleryData = useMemo(() => {
    const { name, images } = folderData;
    if (name !== '') {
      return images;
    }
  }, [folderData]);

  useEffect(() => {
    if (folderData.name === '') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData]);

  const handleDelete = async (imageId: any) => {
    if (folderData.name !== '') {
      const res = await deleteImage({
        imageId,
        folderName: folderData.name,
      });
      const filteredData = filteredGalleryData.filter(
        (data: any) => data.id !== imageId,
      );
      setFolderData({
        name: folderData.name,
        images: filteredData,
      });
      if (filteredData.length === 0 && res) {
        setFolderData({ name: '', images: [] });
      }
    } else if (folderData.name === '') {
      if (showPopup.isFolder) {
        deleteImage({
          folderName: imageId,
        });
        const updatedFolders = allFolders.filter(
          (folder) => folder !== imageId,
        );
        setAllFolders(updatedFolders);
      } else {
        const res = await deleteImage({
          imageId,
          folderName: folderData.name,
        });
        if (res)
          setImageArray((prevImageArray) =>
            prevImageArray.filter((data: any) => data.id !== imageId),
          );
      }
    }
  };
  const mergedArray = [...allFolders, ...imageArray];

  return (
    <StyledWrapper isInLibrary={isInLibrary}>
      {open && (
        <AddFolderModal
          setOpen={setOpen}
          allFolders={allFolders}
          isMyLibrary={true}
          refetch={refetch}
        />
      )}
      {!isInLibrary &&
        (isLoading ||
          isUpdating ||
          isDeleting ||
          isGettingFolderImages ||
          isDeleting) && <Loader />}
      {!isInLibrary && (
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className=" p-16px b-b"
        >
          <Typography text="My Library" fontSize="18px" />
          <div style={{ display: 'flex', gap: '5px' }}>
            {folderData.name === '' && (
              <Button
                appearance="primary"
                label="Add Folder"
                onClick={() => setOpen(true)}
                size="small"
              />
            )}
          </div>
        </FlexBox>
      )}
      <div style={{ margin: '16px' }}>
        <SearchBar
          icon={SearchIcon}
          onChange={(value) => setSearchInput(value)}
          value={searchInput}
        />
      </div>
      <Dropzone
        ref={dropzoneRef}
        onDropRejected={onDropRejectedHandler}
        noClick={true}
        maxSize={MAX_DESIGN_FILE_SIZE}
        disabled={isUpdating}
        onDrop={(acceptedFiles, fileRejections) => {
          if (fileRejections.length > 0) return;
          uploadToGallery(acceptedFiles);
        }}
        accept={{
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
          'image/svg+xml': ['.svg'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {folderData.name && folderData.name?.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 17px',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  text="All Folders "
                  fontSize="16px"
                  primaryColorValue={800}
                  className="pointer-cursor"
                  onClick={() => {
                    setFolderData({ name: '', images: [] });
                  }}
                />

                <span style={{ padding: '0 5px' }}>/</span>
                {folderData.name}
              </div>
            )}
            {folderData.name === '' && isInLibrary && (
              <div style={{ width: '100px', margin: '0 15px' }}>
                <Button
                  appearance="primary"
                  label="Add Folder"
                  onClick={() => setOpen(true)}
                  size="small"
                />
              </div>
            )}

            <InfiniteScroll
              hasMore={
                myLibraryQueryParams.currentPage < totalPages && !isFetching
              }
              fetchData={() => {
                if (
                  myLibraryQueryParams.currentPage <
                    imagesData?.data.totalPages &&
                  !isFetching
                ) {
                  setMyLibraryQueryParams((prev) => ({
                    ...prev,
                    currentPage: prev.currentPage + 1,
                  }));
                }
              }}
              loading={isFetching}
              loaderText="Loading images..."
              showScrollBar={isInLibrary}
              height={`${
                isInLibrary ? 'calc(100vh - 300px)' : 'calc(100vh - 400px)'
              }`}
            >
              <FlexBox className="p-16px w-100" gap="1rem">
                {isInLibrary && (isUpdating || isDeleting) ? (
                  <div className="loader-right">
                    <img height={100} src={LoaderGif} alt="loader" />
                  </div>
                ) : (
                  <FlexBox gap="18px" className="w-100 image-wrapper">
                    <CustomToolTip placement="top" title="Upload Images">
                      <ImagePlaceholder
                        width={isInLibrary ? '150px !important' : '206px'}
                        height={isInLibrary ? '150px !important' : '206px'}
                        style={{
                          background: `${colors.grey[100]}`,
                        }}
                        className="content"
                        onClick={() => {
                          if (dropzoneRef.current) {
                            dropzoneRef.current.open();
                          }
                        }}
                      >
                        <FlexBox
                          alignItems="center"
                          className="uploadArea"
                          justifyContent="center"
                          gap="2"
                        >
                          <img
                            src={GalleryIcon}
                            alt="Dropzone"
                            style={{ height: '80px', width: '80px' }}
                          />
                        </FlexBox>
                      </ImagePlaceholder>
                    </CustomToolTip>
                    {(folderData.name !== ''
                      ? filteredGalleryData
                      : mergedArray
                    ).map((item: any) => (
                      <FlexBox
                        justifyContent="center"
                        alignItems="center"
                        className="image-container folderWrapper"
                        key={item.id || item}
                      >
                        {typeof item === 'string' ? (
                          <FlexBox
                            className="content"
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            style={{ position: 'relative' }}
                            onClick={(e) => handleFolder(item)}
                          >
                            <img
                              style={{
                                cursor: 'pointer',
                                height: '90px',
                              }}
                              className="folderImage"
                              src={NewFolderIcon}
                              alt={item}
                              height="100px"
                              onClick={() => handleFolder(item)}
                            />
                            <p className="folder-name">{item}</p>
                            <DeleteIcon
                              className="delete-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowPopup({
                                  imageName: item,
                                  openModal: true,
                                  imageId: item,
                                  isFolder: true,
                                });
                              }}
                            />
                          </FlexBox>
                        ) : (
                          <img
                            style={{ cursor: 'pointer' }}
                            src={item.src}
                            alt={item.name}
                            className="content"
                            onClick={() => {
                              addtoBoard && addtoBoard(item.src, item.name);
                              onClose && onClose();
                            }}
                          />
                        )}
                        {typeof item !== 'string' && (
                          <DeleteIcon
                            className="delete-icon"
                            onClick={() =>
                              setShowPopup({
                                imageName: item.name,
                                openModal: true,
                                imageId: item.id,
                              })
                            }
                          />
                        )}
                      </FlexBox>
                    ))}
                  </FlexBox>
                )}
                {imagesData?.length === 0 && <p>No images added.</p>}
              </FlexBox>
            </InfiniteScroll>
          </div>
        )}
      </Dropzone>

      {showPopup.openModal && (
        <DeleteConfirmationModal
          buttonColor="red"
          headingText="Delete"
          buttonLabel="Delete"
          onClose={() =>
            setShowPopup({
              imageName: '',
              openModal: false,
              imageId: '',
              isFolder: false,
            })
          }
          text={`Are you sure you want to delete ${showPopup.imageName} ?`}
          onClick={() => {
            handleDelete(showPopup.imageId);
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default MyFiles;

const StyledWrapper = styled.div<{ isInLibrary: boolean }>`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 6px;
  .b-b {
    border-bottom: 1px solid ${colors.grey[300]};
  }
  .image-wrapper {
    flex-wrap: wrap;
    height: 100%;
    ${({ isInLibrary }) =>
      isInLibrary &&
      css`
        align-content: flex-start;
      `}
    .folderWrapper {
      background: ${colors.grey[100]};
    }
    .image-container {
      position: relative;
      .delete-icon {
        height: 28px;
        width: 28px;
        background-color: ${colors.white};
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }

      &:hover .delete-icon {
        opacity: 1;
      }
    }
    img {
      background: ${colors.grey[100]};
      border-radius: 6px;
      object-fit: contain;
      height: ${({ isInLibrary }) => (isInLibrary ? '150px' : '206px')};
      width: ${({ isInLibrary }) => (isInLibrary ? '150px' : '206px')};
      flex-grow: 1;
      max-width: 220px;
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${colors.grey[100]};
        border-radius: 6px;
        height: ${({ isInLibrary }) => (isInLibrary ? '150px' : '206px')};
        width: ${({ isInLibrary }) => (isInLibrary ? '150px' : '206px')};
        flex-grow: 1;
        max-width: 220px;
        .delete-icon {
          height: 28px;
          width: 28px;
          background-color: ${colors.white};
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }

        &:hover .delete-icon {
          opacity: 1;
        }
      }
      .folder-name {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
        padding: 5px;
      }
      .all-folders:hover .delete-icon {
        opacity: 1;
      }
    }
  }
  .loader-right {
    width: 100%;
    text-align: center;
    margin-top: 48px;
  }
`;
