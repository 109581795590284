import React, { FC } from 'react';
import { FlexBox } from './wrappers';
import { DownloadIcon } from '../../assets/icons';
import Typography from '../typography';
import { CatalogProductCategory } from '../catalog';

interface ImageSideProps {
  index: number;
  sideType: string;
  imageUrl: string;
  onHover: (index: number, side: string) => void;
  onLeave: (index: number) => void;
  downloadName: string;
  hoveredSide: string | null;
  hoveredIndex: number | null;
  showPending?: boolean;
  category?: string;
}

const ImageSide: FC<ImageSideProps> = ({
  index,
  sideType,
  imageUrl,
  onHover,
  onLeave,
  downloadName,
  hoveredSide,
  hoveredIndex,
  showPending,
  category,
}) => {
  async function handleDownloadClick() {
    const response = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin,
      },
    });

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = `${downloadName}-image-design`;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }
  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      className="image-side-box"
      onMouseEnter={() => {
        onHover(index, sideType);
      }}
      onMouseLeave={() => onLeave(index)}
    >
      <Typography
        fontSize="14px"
        text={
          category === CatalogProductCategory.DRINKWARE
            ? 'Design'
            : sideType === 'front'
            ? 'Front Side'
            : 'Back Side'
        }
      />
      <div className="image-container" style={{ position: 'relative' }}>
        {imageUrl ? (
          <img
            alt={`${sideType} Img`}
            src={imageUrl}
            height={40}
            width={40}
            className="transform-image image-box"
          />
        ) : (
          showPending && (
            <Typography
              fontSize={'12px'}
              text="Pending"
              secondaryColorValue={500}
            />
          )
        )}

        {hoveredSide === sideType && hoveredIndex === index && imageUrl && (
          <div
            className="image-popup image-box"
            style={{
              position: 'absolute',
              display: 'inline-block',
              top: '50%',
              right: '100%',
            }}
          >
            <img
              src={imageUrl}
              alt={`${sideType}-Img`}
              height={100}
              width={100}
            />
            <a
              href={imageUrl}
              download={`${downloadName}-${sideType}-image`}
              onClick={handleDownloadClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={DownloadIcon} alt="Icon" className="icon-box " />
            </a>
          </div>
        )}
      </div>
    </FlexBox>
  );
};

export default ImageSide;
