import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import { Divider } from '../../order-details/details/billing';
import { colors } from '../../../assets/colors';
import Input from '../../inputs';
import Button from '../../common/button';
import { getSelectedProductData } from '../../../store/slices/createOrderSlice';
import { useAppSelector } from '../../../store/hooks';
import { getUserData } from '../../../store/slices/authSlice';
import {
  extraUnitGrowthShippingPrice,
  extraUnitShipping,
  growthShippingPrice,
  shippingPrice,
} from '../../../constants/publishSettings';

const BillingSection: React.FC = () => {
  const { productCost, quantity } = useAppSelector(getSelectedProductData);
  const { isGrowth } = useAppSelector(getUserData);
  const shippingCost = isGrowth ? growthShippingPrice : shippingPrice;
  const additionalShippingCost = isGrowth
    ? extraUnitGrowthShippingPrice * (quantity - 1)
    : extraUnitShipping * (quantity - 1);
  return (
    <StyledWrapper>
      <div className="heading">Billing</div>
      <Divider />
      <FlexBox direction="column" gap="8px" className="billing-info">
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Production" fontSize="14px" />
          <Typography text={`USD ${productCost}`} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Standard shipping" fontSize="14px" />
          <Typography text={`USD ${shippingCost}`} fontSize="14px" />
        </FlexBox>
        {quantity > 1 && (
          <FlexBox justifyContent="space-between" alignItems="center">
            <Typography text="Additional shipping" fontSize="14px" />
            <Typography
              text={`USD ${additionalShippingCost}`}
              fontSize="14px"
            />
          </FlexBox>
        )}
      </FlexBox>
      <Divider />
      <FlexBox className="coupon-section" direction="column" gap="6px">
        <Typography
          text="Have a coupon?"
          fontSize="14px"
          secondaryColorValue={800}
        />
        <FlexBox gap="10px" alignItems="center">
          <Input
            onChange={(value) => console.log(value)}
            value=""
            placeholder="Enter coupon"
          />
          <Button
            label="Apply"
            appearance="secondary"
            onClick={() => console.log('clicked')}
          />
        </FlexBox>
      </FlexBox>
      <Divider />
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="total-bill"
      >
        <Typography text="Total" fontSize="14px" fontWeight="700" />
        <Typography
          text={`USD ${(
            productCost +
            shippingCost +
            additionalShippingCost
          ).toFixed(2)}`}
          fontSize="14px"
          fontWeight="700"
        />
      </FlexBox>
    </StyledWrapper>
  );
};

export default BillingSection;

const StyledWrapper = styled.div`
  border: 1px solid ${colors.grey.border};
  border-radius: 6px;
  background-color: ${colors.white};
  .heading {
    padding: 18px 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.grey[900]};
  }
  .billing-info {
    padding: 16px;
  }
  .shipping-option {
    padding: 16px;
  }
  .coupon-section {
    padding: 12px 16px 16px;
  }
  .total-bill {
    padding: 12px 16px;
  }
`;
