import { FC } from 'react';
import { FlexBox } from '../../common/wrappers';
import { USAFlagIcon } from './../../../assets/icons';
import { useAppSelector } from '../../../store/hooks';
import { getUserData } from '../../../store/slices/authSlice';
import PriceSection from '../common/price-section';
import { CatalogItemProperties } from '../../../interface/catalog-interfaces';

interface Props {
  productImage: string;
  productId: string;
  productTitle: string;
  properties: CatalogItemProperties[];
  fromPrice: number;
  modalNumber?: string;
  brand: string;
  flag?: boolean;
  growthPrice: number;
}

const ProductCardStyleOne: FC<Props> = ({
  productImage,
  productId,
  productTitle,
  properties,
  fromPrice,
  modalNumber,
  brand,
  growthPrice,
  flag,
}) => {
  const { isGrowth } = useAppSelector(getUserData);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    window.location.href = `/catalog/${productId}`;
  };

  return (
    <div
      className="product-card-one w-full h-full relative group overflow-hidden"
      onClick={handleClick}
    >
      <div
        className="product-card-img w-full h-[300px] bg-contain"
        style={{
          background: `url(${productImage}) no-repeat`,
          backgroundSize: 'contain',
          backgroundColor: '#f7f7f7',
          backgroundPosition: 'center',
          padding: '10px',
          backgroundOrigin: 'content-box',
        }}
      ></div>
      <div className="product-card-details px-[10px] pb-[10px] relative mt-[20px]">
        <FlexBox direction="column">
          <p className="title  text-[15px] font-500 text-qblack leading-[24px] line-clamp-2">
            {productTitle}
          </p>
          <p
            className="title mb-2 text-[14px] font-500 text-qblack leading-[24px] line-clamp-2"
            style={{ color: '#757c7e' }}
          >
            by {brand} {modalNumber && <span>&#903; {modalNumber}</span>}
          </p>
        </FlexBox>
        <p className="flex items-center gap-[8px] mb-[8px]">
          <img
            className="w-[20px] h-[18px] "
            src={USAFlagIcon}
            alt="USA Flag"
          />
          <span className="text-sm">Fulfilled in 1 country</span>
        </p>
        <div className="price flex flex-col mb-2">
          <PriceSection
            isGrowth={isGrowth}
            price={fromPrice}
            growthPrice={growthPrice}
          />
        </div>
        <div
          className="offer-price font-500 text-[12px] flex gap-3 flex-wrap"
          style={{ color: '#757c7e' }}
        >
          {properties.map((property, index) => (
            <span key={index}>
              {property.totalCount} {property.propertyName}
              {index % 2 === 0 && <span> &#903;</span>}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProductCardStyleOne;
