import AWS from "aws-sdk";
import { VariantEditorDataV2 } from "../interface/catalog-interface-v2";
AWS.config.update({
  secretAccessKey: process.env.REACT_APP_AWS_S3_ACCESS_SECRET,
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
  region: 'us-west-1',
});

const s3 = new AWS.S3();

export const uploadEditorDataToS3 = async (fileName: string, data: { defaultEditorData: any; variantEditorData: VariantEditorDataV2[]; }) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME_FOR_EDITOR_DATA ?? 'editor-data-staging',
    Key: fileName,
    Body: JSON.stringify(data),
  };

  try {
    const result = await s3.upload(params).promise();
    return { success: true, url: result.Location };
  } catch (error: any) {
    console.error("Error uploading to S3:", error);
    return { success: false, error: error.message };
  }
};
