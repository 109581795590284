import styled from 'styled-components';
import OrdersTable from './orders-table';
import ActionButtonsAndFilters from './action-buttons-and-filters';
import { useGetAllOrdersQuery } from '../../store/services/orders';
import { useEffect, useMemo, useState } from 'react';
import { getOrdersRowData } from '../../helper/getOrdersRowData';
import Loader from '../common/loader';
import { SortFilter, OrdersFilter } from '../../interface/orders-interface';
import { useDebounce } from '../../hooks/useDebounce';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { getUserData } from '../../store/slices/authSlice';
import { useAppSelector } from '../../store/hooks';
import { TabletProps } from '../../interface';

const Orders: React.FC<TabletProps> = ({ isTablet }) => {
  const { userInfo } = useAppSelector(getUserData);
  const [filters, setFilters] = useState<OrdersFilter>({
    status: '',
    searchText: '',
  });

  const apiRef = useGridApiRef();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [sortModel, setSortModel] = useState<SortFilter>({
    field: 'order',
    sort: 'desc',
  });
  const debouncedSearchText = useDebounce(filters.searchText);
  const [showSuccessSampleOrder, setShowSuccessSampleOrder] =
    useState<boolean>(false);

  useEffect(() => {
    let timeoutId: number;

    if (showSuccessSampleOrder) {
      timeoutId = window.setTimeout(() => {
        setShowSuccessSampleOrder(false);
      }, 4000);
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [showSuccessSampleOrder]);
  const {
    data: ordersData,
    isSuccess,
    isFetching,
  } = useGetAllOrdersQuery({
    storeId: userInfo.storeId,
    page: currentPage,
    search: debouncedSearchText?.replace('#', ''),
    status: filters.status,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    sortBy:
      sortModel.field === 'order'
        ? { ...sortModel, field: 'createdAt' }
        : sortModel,
  });
  const rowData = useMemo(() => {
    if (isSuccess) {
      setTotalPages(ordersData.totalPages);
      return getOrdersRowData(ordersData.results);
    }
  }, [isSuccess, ordersData]);

  return (
    <StyleWrapper className="show-no-scrollbar" isTablet={isTablet}>
      {isFetching && <Loader />}
      <ActionButtonsAndFilters
        filters={filters}
        setFilters={setFilters}
        setCurrentPage={setCurrentPage}
        apiRef={apiRef}
        statusTotals={ordersData?.statusTotals ?? []}
      />
      <OrdersTable
        rowData={rowData ?? []}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        apiRef={apiRef}
        sortModel={sortModel}
        setSortModel={setSortModel}
        isTablet={isTablet}
      />
    </StyleWrapper>
  );
};

export default Orders;

const StyleWrapper = styled.div<{ isTablet: boolean }>`
  padding: 10px 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
