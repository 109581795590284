import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClickedImage,
  MockupApiData,
  MockupData,
  SelectedImage,
} from '../../interface/mockups-interface';
import { RootState } from '..';
import { myDataMockupResponse, MyMockupResponseObject } from '../../interface';
export interface MyMockupsInitialState {
  mockupData: MockupData[];
  myMockupsData: MyMockupResponseObject[];
  selectedImages: SelectedImage[];
  clickedImage: ClickedImage | null;
  currentPage: number;
  mokupApiResponse: MockupApiData[];
  idsToExclude: string[];
  currentFolder: string;
  activeFolder: string;
  myImagesData: myDataMockupResponse[];
}

const initialState: MyMockupsInitialState = {
  mockupData: [],
  myMockupsData: [],
  mokupApiResponse: [],
  selectedImages: [],
  clickedImage: null,
  currentPage: 1,
  idsToExclude: [],
  currentFolder: '',
  activeFolder: '',
  myImagesData: [],
};

export const mockUpSlice = createSlice({
  name: 'mockup',
  initialState,
  reducers: {
    updateMockups: (state, { payload }: PayloadAction<MockupData[]>) => {
      if (state.mockupData.length > 0) {
        const uniqueArray = payload.filter(
          (obj) => !state.mockupData.find((x) => x.id === obj.id),
        );
        state.mockupData = [...state.mockupData, ...uniqueArray];
      } else {
        state.mockupData = [...state.mockupData, ...payload];
      }
    },
    updateMockupsInBeginning: (
      state,
      { payload }: PayloadAction<MockupData[]>,
    ) => {
      state.mockupData = [...payload, ...state.mockupData];
    },
    setMyMockupsData: (
      state,
      { payload }: PayloadAction<MyMockupResponseObject[]>,
    ) => {
      state.myMockupsData = payload;
    },
    setMockups: (state, { payload }: PayloadAction<MockupData[]>) => {
      state.mockupData = payload;
    },
    setIdsToExclude: (state, { payload }: PayloadAction<any>) => {
      state.idsToExclude.push(payload);
    },
    setActiveFolder: (state, { payload }: PayloadAction<string>) => {
      state.activeFolder = payload;
    },
    setSelectedImages: (state, { payload }: PayloadAction<SelectedImage>) => {
      state.selectedImages.push(payload);
    },

    replaceSelectdImage: (state, { payload }: PayloadAction<SelectedImage>) => {
      const selectedImage = state.selectedImages.findIndex(
        (img) => img.mockupId === payload.mockupId,
      );
      if (selectedImage !== -1) {
        state.selectedImages[selectedImage].mockupImage = payload.mockupImage;
      }
      if (state.clickedImage && state.clickedImage.imgId === payload.mockupId) {
        state.clickedImage.imageUrl = payload.mockupImage;
      }
    },

    setSelectedImageArray: (
      state,
      { payload }: PayloadAction<SelectedImage[]>,
    ) => {
      state.selectedImages = payload;
    },
    deleteImageOnDeselect: (
      state,
      { payload }: PayloadAction<{ mockupId: number | string | undefined }>,
    ) => {
      state.selectedImages = state.selectedImages.filter((item, index) => {
        return item.mockupId !== payload.mockupId;
      });
      const lastSelectedImageInfo =
        state.selectedImages[state.selectedImages.length - 1];
      if (lastSelectedImageInfo) {
        state.clickedImage = {
          imageUrl: lastSelectedImageInfo.mockupImage,
          imgId: lastSelectedImageInfo.mockupId,
        };
      }
    },

    setClickedImage: (
      state,
      { payload }: PayloadAction<ClickedImage | null>,
    ) => {
      state.clickedImage = payload;
    },

    incrementCurrentPage: (state) => {
      state.currentPage = state.currentPage + 1;
    },
    resetCurrentPage: (state) => {
      state.currentPage = 1;
    },
    storeMockupApiResponse: (
      state,
      { payload }: PayloadAction<MockupApiData[]>,
    ) => {
      if (state.mokupApiResponse.length > 0) {
        const uniqueArray = payload.filter(
          (obj) => !state.mokupApiResponse.find((x) => x.id === obj.id),
        );
        state.mokupApiResponse = [...state.mokupApiResponse, ...uniqueArray];
      } else {
        state.mokupApiResponse = [...state.mokupApiResponse, ...payload];
      }
    },
  },
});

export const {
  setMockups,
  updateMockups,
  updateMockupsInBeginning,
  setSelectedImages,
  setIdsToExclude,
  setSelectedImageArray,
  deleteImageOnDeselect,
  setClickedImage,
  setActiveFolder,
  setMyMockupsData,
  incrementCurrentPage,
  storeMockupApiResponse,
  resetCurrentPage,
  replaceSelectdImage,
} = mockUpSlice.actions;

export default mockUpSlice.reducer;

export const getMockups = (state: RootState): MyMockupsInitialState =>
  state.mockup;
export const getActiveFolder = (state: RootState) => state.mockup.activeFolder;
export const getMyImagesData = (state: RootState) => state.mockup.myImagesData;
