import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { FlexBox } from '../../../common/wrappers';
import { Canvas } from 'fabric/fabric-impl';
import { IObject, ObjectTypes } from '../../../../interface';
import { deleteObj, duplicateObj, getImageDPI } from '../../utils';
import {
  DeleteIcon,
  DragIcon,
  HorizontalCenter,
  Locked,
  MoveDown,
  MoveLeft,
  MoveRight,
  MoveUp,
  PersonalizeImage,
  TextIcon,
  Unlocked,
  VerticalCenter,
} from '../../../../assets/icons';
import PersonalizedImageOptions from './personalized-image-options';
import PersonalizedTextOptions from './personalized-text-options';
import { StyledInput } from '../../../common/elements';
import {
  heightInInches,
  pixelInInch,
  widthInInches,
} from '../../../../store/config';
import { useAppSelector } from '../../../../store/hooks';
import { getObjects, getUpdatingJson } from '../../../../store/slices/editor';
import Typography from '../../../typography';
import { Side } from '../../../../interface/catalog-interface-v2';
interface ExtendedFabricObject extends fabric.Object {
  isEditing?: boolean;
}
interface Props {
  isExpanded: boolean;
  onExpandClick: (index: number) => void;
  index: number;
  element: IObject;
  drawingBoard: Canvas | undefined;
  onChange(data: any, field: string, elementId: string): void;
  hideOptions?: boolean;
  hideWidthAndHeightControls?: boolean;
  showTextBox?: boolean;
  updateText?: (value: string) => void;
  undoAndRedoLoadingRef: React.MutableRefObject<boolean>;
  printAreaDimensions: Side[] | undefined;
  selectedAngle: string;
}
type ResolutionInfo = {
  text: string;
  className: string;
};
const MOVEMENT_VALUE = 10;
const LARGER_MOVEMENT_VALUE = 30;

const DesignElement: React.FC<Props> = ({
  element,
  isExpanded,
  onExpandClick,
  index,
  drawingBoard,
  onChange,
  hideOptions,
  hideWidthAndHeightControls,
  showTextBox,
  updateText,
  undoAndRedoLoadingRef,
  printAreaDimensions,
  selectedAngle,
}) => {
  const files = useAppSelector(getObjects);
  const updatingJSON = useAppSelector(getUpdatingJson);
  const [topAndLeft, setTopAndLeft] = useState<{ top: number; left: number }>({
    left: 0,
    top: 0,
  });
  const [loadingDPI, setLoadingDPI] = useState(false);
  const [resolutionInfo, setResolutionInfo] = useState<ResolutionInfo | null>(
    null,
  );

  const [tempWidth, setTempWidth] = useState<any>();
  const [tempHeight, setTempHeight] = useState<any>();
  const currentHeight = drawingBoard?.getActiveObject()?.getScaledHeight();
  const areaWidth = drawingBoard?.clipPath
    ? drawingBoard?.clipPath?.width
    : drawingBoard?.width;
  const areaHeight = drawingBoard?.clipPath
    ? drawingBoard?.clipPath?.height
    : drawingBoard?.height;
  const heightRatio = (heightInInches * pixelInInch) / (areaHeight ?? 0);
  const widthRatio = (widthInInches * pixelInInch) / (areaWidth ?? 0);
  const currentWidth = drawingBoard?.getActiveObject()?.getScaledWidth();
  const currentScale = element.scaleX && element.scaleX * 1000;
  const initialHeight = currentHeight
    ? ((currentHeight / pixelInInch) * heightRatio).toFixed(2)
    : 0;
  const initialWidth = currentWidth
    ? ((currentWidth / pixelInInch) * widthRatio).toFixed(2)
    : 0;

  const [scaleValue, setScaleValue] = useState(currentScale);
  const type = element.type;
  const leftAreaOutsideClipPath = drawingBoard?.clipPath?.left;
  const topAreaOutsideClipPath = drawingBoard?.clipPath?.top;

  /** render initial top and left values */
  useEffect(() => {
    if (element) {
      calculateLeftAndTopValues(element.left as number, element.top as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    if (drawingBoard) {
      const newValue = drawingBoard?.getActiveObject()?.scaleX;
      if (newValue) {
        const roundedValue = Math.round(newValue * 1000);
        setScaleValue(roundedValue);
      }
    }
  }, [element, drawingBoard]);

  const convertPercentageToValue = (value: number, field: string) => {
    let area = 1;
    if (field === 'left') {
      area = drawingBoard?.clipPath?.width
        ? drawingBoard?.clipPath?.width
        : drawingBoard?.width ?? 1;
    } else {
      area = drawingBoard?.clipPath?.height
        ? drawingBoard?.clipPath?.height
        : drawingBoard?.height ?? 1;
    }
    let val = parseFloat(((area * value) / 100).toFixed(2));
    val =
      field === 'left'
        ? val + (currentWidth ?? 0) / 2
        : val + (currentHeight ?? 0) / 2;
    onChange(
      val +
        ((field === 'left'
          ? leftAreaOutsideClipPath
          : topAreaOutsideClipPath) ?? 0),
      field,
      element.id,
    );
    drawingBoard?.fire('object:modified', {
      target: drawingBoard.getActiveObject(),
    });
  };
  /** Alignment */
  const alignDesignElement = (position: string) => {
    const element: any = drawingBoard?.getActiveObject();
    const boundingBox = element?.getBoundingRect();

    if (drawingBoard?.clipPath) {
      const element: any = drawingBoard?.getActiveObject();
      const leftAreaOutsideClipPath = drawingBoard?.clipPath?.left;
      const topAreaOutsideClipPath = drawingBoard?.clipPath?.top;
      const areaWidth = drawingBoard?.clipPath?.width;
      const areaHeight = drawingBoard?.clipPath?.height;
      const boundingBox = element?.getBoundingRect();
      if (
        leftAreaOutsideClipPath &&
        topAreaOutsideClipPath &&
        areaWidth &&
        areaHeight &&
        currentWidth &&
        currentHeight &&
        drawingBoard?.clipPath?.top &&
        drawingBoard?.clipPath?.left
      ) {
        if (position === 'left') {
          element.set({
            left: leftAreaOutsideClipPath + currentWidth / 2,
          });
        } else if (position === 'centerX') {
          const left =
            leftAreaOutsideClipPath +
            areaWidth / 2 -
            boundingBox.width / 2 +
            currentWidth / 2;
          element.set({ left });
        } else if (position === 'right') {
          element.set({
            left:
              (leftAreaOutsideClipPath ?? 0) +
              areaWidth -
              boundingBox.width +
              currentWidth / 2,
          });
        } else if (position === 'top') {
          element.set({
            top: topAreaOutsideClipPath + currentHeight / 2,
          });
        } else if (position === 'centerY') {
          const top =
            topAreaOutsideClipPath +
            areaHeight / 2 -
            boundingBox.height / 2 +
            currentHeight / 2;
          element.set({ top });
        } else if (position === 'bottom') {
          element.set({
            top:
              (topAreaOutsideClipPath ?? 0) +
              areaHeight -
              boundingBox.height +
              currentHeight / 2,
          });
        }
      }
    } else {
      if (position === 'left') {
        element.set({
          left: boundingBox.width / 2,
        });
      } else if (position === 'centerX') {
        element.set({
          left: element?.canvas.width / 2,
        });
      } else if (position === 'right') {
        element.set({
          left: element?.canvas.width - boundingBox.width / 2,
        });
      } else if (position === 'top') {
        element.set({
          top: boundingBox.height / 2,
        });
      } else if (position === 'centerY') {
        element.set({
          top: element?.canvas.height / 2,
        });
      } else if (position === 'bottom') {
        element.set({
          top: element?.canvas.height - boundingBox.height / 2,
        });
      }
    }

    drawingBoard?.renderAll();
    drawingBoard?.fire('object:modified', { target: element });
  };

  const handleHeightChange = (e: any) => {
    if (e.target.value === '0' || e.target.value === 0) {
      setTempHeight(e.target.value === 0 ? 0 : e.target.value);
      onChange(0.01, 'scale', element.id);
      return;
    } else {
      setTempHeight('');
    }
    if (e.target.value === '') {
      setTempHeight(-1);
      onChange(0.01, 'scale', element.id);
      return;
    } else {
      setTempHeight('');
    }
    const inchesValue = parseFloat(e.target.value);
    const nonNegativeValue = Math.max(0, inchesValue);
    const roundedValue = Math.max(1, nonNegativeValue);
    const pixelsValue = (roundedValue * pixelInInch) / heightRatio;
    if (inchesValue === 0 || isNaN(nonNegativeValue)) {
      onChange(0.01, 'scale', element.id);
    } else {
      onChange(pixelsValue, 'height', element.id);
    }
  };

  const handleWidthChange = (e: any) => {
    if (e.target.value === '0' || e.target.value === 0) {
      setTempWidth(e.target.value === 0 ? 0 : e.target.value);
      onChange(0.01, 'scale', element.id);
      return;
    } else {
      setTempWidth('');
    }
    if (e.target.value === '') {
      setTempWidth(-1);
      onChange(0.01, 'scale', element.id);
      return;
    } else {
      setTempWidth('');
    }
    const inchesValue = parseFloat(e.target.value);
    const nonNegativeValue = Math.max(0, inchesValue);
    const roundedValue = Math.max(1, nonNegativeValue);
    const pixelsValue = (roundedValue * pixelInInch) / widthRatio;
    if (inchesValue === 0 || isNaN(nonNegativeValue)) {
      onChange(0.01, 'scale', element.id);
    } else {
      onChange(pixelsValue, 'width', element.id);
    }
  };

  const handleScaleChange = (e: { target: { value: string } }) => {
    const newScale = parseFloat(e.target.value);
    setScaleValue(newScale);
    onChange(newScale / 1000, 'scale', element.id);
  };

  function trimLeadingZero(input: string): string {
    const parsedInput = parseFloat(input);
    if (!isNaN(parsedInput) && parsedInput > 0) {
      return parsedInput.toString();
    } else {
      return input;
    }
  }

  const onBlur = (e: any, field: string) => {
    const inputValue = e.target.value.trim();
    const parsedInput = parseFloat(inputValue);

    if (parsedInput === 0 || isNaN(parsedInput)) {
      onChange(0.01, 'scale', element.id);
      if (field === 'width') {
        setTempWidth(undefined);
      }
      if (field === 'height') {
        setTempHeight(undefined);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!drawingBoard || e.repeat) {
        return;
      }
      if (document.activeElement?.tagName === 'INPUT') {
        return;
      }
      const key = e.keyCode || e.which;
      let charCode = String.fromCharCode(key).toLowerCase();
      let activeGroupElements = drawingBoard.getActiveObjects();
      const isEditing =
        (drawingBoard.getActiveObject() as ExtendedFabricObject)?.isEditing ??
        false;

      switch (key) {
        case 37: // left
        case 38: // up
        case 39: // right
        case 40: // down:
          handleMovement(key, activeGroupElements, e.shiftKey);
          break;

        case 86: // V key
          handleDuplicate(charCode, e.ctrlKey || e.metaKey);
          break;

        case 46: // Delete key
          handleDelete(e);
          break;

        case 27: // Esc key to deselect the  design elements
          drawingBoard?.discardActiveObject();
          break;
        case 8: // Backspace key (delete)
          if (!isEditing) handleDelete(e);
          break;
        default:
          break;
      }
    };

    const handleMovement = (
      key: number,
      activeGroupElements: any[],
      isShiftPressed: boolean,
    ) => {
      /** input field is active stop the keyboard arrow movements */
      if (document.activeElement?.tagName === 'INPUT') {
        return;
      }
      const value = isShiftPressed ? LARGER_MOVEMENT_VALUE : MOVEMENT_VALUE;
      activeGroupElements.forEach((obj: any, index) => {
        let isElementUpdated = false;

        switch (key) {
          case 37: // left
            obj.left = obj.left - value;
            isElementUpdated = true;
            break;
          case 38: // up
            obj.top = obj.top - value;
            isElementUpdated = true;
            break;
          case 39: // right
            obj.left = obj.left + value;
            isElementUpdated = true;
            break;
          case 40: // down
            obj.top = obj.top + value;
            isElementUpdated = true;
            break;
          default:
            isElementUpdated = false;
            break;
        }

        if (isElementUpdated) {
          obj.setCoords();

          if (index === activeGroupElements.length - 1) {
            drawingBoard?.fire('object:modified', {
              target: obj,
            });
          }

          calculateLeftAndTopValues(obj.left, obj.top);
        }
      });
      drawingBoard?.renderAll();
    };

    const handleDuplicate = (charCode: string, isCtrlPressed: boolean) => {
      if (isCtrlPressed && charCode === 'v') {
        const disableLoader = () => {
          undoAndRedoLoadingRef.current = false;
        };
        undoAndRedoLoadingRef.current = true;
        duplicateObj(drawingBoard, files, true, disableLoader);
      }
    };

    const handleDelete = (e: KeyboardEvent) => {
      deleteObj(
        e,
        drawingBoard,
        drawingBoard?.getActiveObjects().map((obj: any) => obj.id),
      );
    };

    document.addEventListener('keydown', handleKeyDown as any);

    return () => {
      document.removeEventListener('keydown', handleKeyDown as any);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingBoard, files, duplicateObj]);

  const calculateLeftAndTopValues = (leftValue: number, topValue: number) => {
    let updatedLeftValue = 0;
    let updatedTopValue = 0;
    const element: any = drawingBoard?.getActiveObject();
    const boundingBox = element?.getBoundingRect();
    if (leftValue) {
      if (drawingBoard?.clipPath && leftAreaOutsideClipPath) {
        updatedLeftValue =
          leftValue - leftAreaOutsideClipPath - (currentWidth ?? 0) / 2;
        updatedLeftValue = parseFloat(
          (
            (updatedLeftValue / (drawingBoard?.clipPath?.width ?? 1)) *
            100
          ).toFixed(2),
        );
      } else {
        if (drawingBoard?.width) {
          updatedLeftValue = leftValue - boundingBox?.width / 2;
          updatedLeftValue = parseFloat(
            ((updatedLeftValue / drawingBoard?.width) * 100).toFixed(2),
          );
        }
      }
    }
    if (drawingBoard?.clipPath && topValue && topAreaOutsideClipPath) {
      updatedTopValue =
        topValue - topAreaOutsideClipPath - (currentHeight ?? 0) / 2;
      updatedTopValue = parseFloat(
        (
          (updatedTopValue / (drawingBoard?.clipPath?.height ?? 1)) *
          100
        ).toFixed(2),
      );
    } else {
      if (drawingBoard?.height) {
        updatedTopValue = topValue - boundingBox?.height / 2;
        updatedTopValue = parseFloat(
          ((updatedTopValue / drawingBoard?.height) * 100).toFixed(2),
        );
      }
    }
    setTopAndLeft({ left: updatedLeftValue, top: updatedTopValue });
  };

  const imageResolution = useCallback(
    (elementId: string) => {
      setLoadingDPI(true);
      getImageDPI(elementId, drawingBoard, selectedAngle, printAreaDimensions)
        .then((dpi) => {
          setLoadingDPI(false);
          if (dpi === 0) {
            setResolutionInfo({ text: `Loading...`, className: 'green-color' });
          } else if (dpi <= 150) {
            setResolutionInfo({
              text: `Poor quality! Please use a higher resolution image (${dpi} DPI)`,
              className: 'warning-color',
            });
          } else if (dpi < 300) {
            setResolutionInfo({
              text: `Fair quality! Consider a higher resolution for optimal print (${dpi} DPI)`,
              className: 'amber-color',
            });
          } else {
            setResolutionInfo({
              text: `Great quality! Ready for print (${dpi} DPI)`,
              className: 'green-color',
            });
          }
        })
        .catch((error) => {
          setLoadingDPI(false);
          return { text: `Failed to get image DPI`, className: 'error-color' };
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawingBoard, element.scaleX, element.scaleY, files],
  );

  useEffect(() => {
    if (element.id) {
      imageResolution(element.id);
    }
  }, [element.id, imageResolution]);

  return (
    <Wrapper className="item-header">
      <FlexBox
        onClick={() => onExpandClick(index)}
        justifyContent="space-between"
        className="pointer-cursor section responsive-drag-and-drop-items"
      >
        {/* element icon and name */}
        <FlexBox
          className="elements-margin file-name"
          direction="row"
          alignItems="center"
        >
          <img
            src={
              type === 'custom-image'
                ? element.personalizationData
                  ? PersonalizeImage
                  : element.src
                : TextIcon
            }
            style={{ objectFit: 'contain' }}
            alt={element.name}
            width={20}
            height={20}
          />
          <FlexBox direction="column">
            <p style={{ fontSize: '14px', margin: 0 }}>
              {element.type === 'custom-text'
                ? element.text
                : element.personalizationData
                ? 'Personalized image'
                : element.name}
            </p>
            {element.type === ObjectTypes.image && (
              <>
                {!loadingDPI ? (
                  <>
                    <Typography
                      text={
                        updatingJSON
                          ? 'Loading...'
                          : resolutionInfo
                          ? resolutionInfo?.text
                          : 'Loading...'
                      }
                      fontSize="0.8rem"
                      className={
                        updatingJSON
                          ? 'green-color'
                          : resolutionInfo
                          ? resolutionInfo?.className
                          : 'green-color'
                      }
                    />
                  </>
                ) : (
                  <Typography
                    text={'Loading...'}
                    fontSize="0.8rem"
                    className={'green-color'}
                  />
                )}
              </>
            )}
          </FlexBox>
        </FlexBox>

        {/* Delete and drag icon */}
        <FlexBox className="elements-margin" alignItems="center">
          <img
            src={DeleteIcon}
            alt="Delete"
            onClick={(e) => deleteObj(e, drawingBoard, element.id)}
            className="icon"
          />
          <img src={DragIcon} alt="Drag" className="icon draggable" />
          {element.personalizationData && (
            <img
              onClick={(event) => {
                event.stopPropagation();
                onChange(
                  !element.personalizationData.isLocked,
                  'isLocked',
                  element.id,
                );
              }}
              src={element.personalizationData.isLocked ? Locked : Unlocked}
              alt="Drag"
              className="Locked icon"
            />
          )}
        </FlexBox>
      </FlexBox>
      {isExpanded && (
        <div>
          {!hideOptions && element.personalizationData && (
            <>
              {type === 'custom-image' ? (
                <PersonalizedImageOptions
                  element={element}
                  onChange={onChange}
                />
              ) : (
                <PersonalizedTextOptions
                  element={element}
                  onChange={onChange}
                  drawingBoard={drawingBoard}
                />
              )}
            </>
          )}
          <FlexBox
            className="separator info-section section"
            style={{ height: 'auto' }}
          >
            {!hideWidthAndHeightControls && element.type !== 'custom-text' && (
              <>
                <div>
                  <p className="grey800 fs-14">Width</p>
                  <StyledInput>
                    <input
                      type="number"
                      value={
                        tempWidth === 0 || tempWidth === '0' || tempWidth === -1
                          ? 0
                          : trimLeadingZero(initialWidth.toString())
                      }
                      onChange={(e) => handleWidthChange(e)}
                      onBlur={(e) => onBlur(e, 'width')}
                    />
                    <span>in</span>
                  </StyledInput>
                </div>
                <div>
                  <p className="grey800 fs-14">Height</p>
                  <StyledInput>
                    <input
                      type="number"
                      value={
                        tempHeight === 0 ||
                        tempHeight === '0' ||
                        tempHeight === -1
                          ? 0
                          : trimLeadingZero(initialHeight.toString())
                      }
                      onBlur={(e) => onBlur(e, 'height')}
                      onChange={(e) => handleHeightChange(e)}
                    />
                    <span>in</span>
                  </StyledInput>
                </div>
              </>
            )}

            {showTextBox && (
              <div style={{ flex: 'none', width: '100%' }}>
                <p className="grey800 fs-14">Enter your text here</p>
                <StyledInput>
                  <input
                    type="text"
                    value={element.text}
                    onChange={(e) =>
                      updateText ? updateText(e.target.value) : ''
                    }
                  />
                </StyledInput>
              </div>
            )}
            <div
              style={{
                flex: element.type !== 'custom-text' ? ' ' : '1 0 53%',
                width: '100%',
              }}
            >
              <p className="grey800 fs-14">Rotate</p>
              <StyledInput>
                <input
                  type="number"
                  max={360}
                  value={trimLeadingZero(element.angle?.toString() ?? '0')}
                  onChange={(e) =>
                    onChange(e.target.value, 'angle', element.id)
                  }
                />
                <span>deg</span>
              </StyledInput>
            </div>

            {element.type !== 'custom-text' && (
              <div>
                <p className="grey800 fs-14">Scale</p>
                <StyledInput>
                  <input
                    type="number"
                    value={scaleValue}
                    onChange={handleScaleChange}
                  />
                  <span>%</span>
                </StyledInput>
              </div>
            )}

            <div>
              <p className="grey800 fs-14">Left Position</p>
              <StyledInput>
                <input
                  type="number"
                  value={trimLeadingZero(topAndLeft.left.toString())}
                  onChange={(data: any) =>
                    convertPercentageToValue(data.target.value, 'left')
                  }
                />
                <span>%</span>
              </StyledInput>
            </div>

            <div>
              <p className="grey800 fs-14">Top Position</p>
              <StyledInput>
                <input
                  type="number"
                  value={trimLeadingZero(topAndLeft.top.toString())}
                  onChange={(data: any) =>
                    convertPercentageToValue(data.target.value, 'top')
                  }
                />
                <span>%</span>
              </StyledInput>
            </div>

            <div className="alignment-tools btn-inside">
              <img
                src={MoveLeft}
                alt="Move Left"
                onClick={() => alignDesignElement('left')}
              />
              <img
                src={VerticalCenter}
                alt="Center Vertically"
                onClick={() => alignDesignElement('centerX')}
              />
              <img
                src={MoveRight}
                alt="Move Right"
                onClick={() => alignDesignElement('right')}
              />
            </div>

            <div className="alignment-tools btn-inside">
              <img
                src={MoveUp}
                alt="Move Up"
                onClick={() => alignDesignElement('top')}
              />
              <img
                src={HorizontalCenter}
                alt="Center Horizontally"
                onClick={() => alignDesignElement('centerY')}
              />
              <img
                src={MoveDown}
                alt="Move Right"
                onClick={() => alignDesignElement('bottom')}
              />
            </div>
          </FlexBox>

          {/* <FlexBox className="separator section" direction="column">
            <FlexBox justifyContent="space-between">
              <p className="fs-14 m-0">Create Pattern</p>
              <ToggleSwitch checked={true} onChange={(e) => console.log(e)} />
            </FlexBox>
            <div className="pattern-tools">
              <div className="btn-inside">
                <img src={PatternA} alt="Grid" />
              </div>
              <div className="btn-inside">
                <img src={PatternB} alt="Horizontal" />
              </div>
              <div className="btn-inside">
                <img src={PatternC} alt="Vertical" />
              </div>
            </div>
          </FlexBox> */}
        </div>
      )}
    </Wrapper>
  );
};

export default DesignElement;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
  margin-top: 10px;
  .section {
    padding: 14px;
    .select-list {
      width: 100%;
      .dropdown-body {
        width: -webkit-fill-available;
        z-index: 9;
      }
    }
  }
  .elements-margin {
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }
  .file-name {
    object-fit: contain;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .info-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      flex-basis: 50%;
      flex-grow: 1;
      flex: 0 48%;
    }
  }
  .alignment-tools {
    background: ${colors.white};
    border: 1px solid ${colors.grey[300]};
    border-radius: 6px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    width: 33%;
    > img {
      width: 24px;
      height: 24px;
      padding: 4px 14px;
      padding-right: 20px;
      &:not(:last-child) {
        border-right: 1px solid ${colors.grey[300]};
      }
    }
  }
  .pattern-tools {
    display: flex;
    background: ${colors.white};
    border: 1px solid ${colors.grey[300]};
    border-radius: 6px;
    align-items: center;
    > div {
      flex-basis: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        border-right: 1px solid ${colors.grey[300]};
      }
      img {
        padding: 6px 16px;
      }
    }
  }
`;
