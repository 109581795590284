import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import { colors } from '../../../../assets/colors';
import Chart from 'react-apexcharts';
import ChartDataItem from '../chart/chart-data-Item';
import { Fees, ProductCost } from '../../../../interface/orders-interface';
import { billingCalculations } from '../../../../helper/billingCalculations';
import { useAppSelector } from '../../../../store/hooks';
import {
  AuthInitialState,
  getUserData,
} from '../../../../store/slices/authSlice';
interface Props {
  customerPaid?: ProductCost;
  productionCost?: ProductCost;
  fees?: Fees;
  containsNonEverbeePrintsItems?: boolean;
  isSampleOrder?: boolean;
}
const Billing: React.FC<Props> = ({
  productionCost,
  customerPaid,
  fees,
  containsNonEverbeePrintsItems,
  isSampleOrder,
}) => {
  const {
    userInfo: { salesChannelCurrency },
  }: AuthInitialState = useAppSelector(getUserData);

  const billingData = useMemo(() => {
    return billingCalculations(customerPaid, productionCost, fees);
  }, [productionCost, customerPaid, fees]);

  const donutChartOptions: ApexCharts.ApexOptions = {
    colors: ['#2E8AE5', '#55A4F2', '#89C2FB', '#CCE6FF'],
    series: [
      Number(billingData.netProfit ?? 0),
      Number(productionCost?.shipping ?? 0),
      Number(billingData.totalProductionCost ?? 0),
      Number(billingData.totalFees ?? 0),
    ],
    labels: ['Net profit', 'Shipping cost', 'COGS', 'Fees'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <StyledWrapper>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading-wrapper"
      >
        <Typography fontSize="18px" text="Billing" />
        {/* <Button
          label="Download invoice"
          onClick={() => console.log('object')}
          appearance="secondary"
          size="medium"
        /> */}
      </FlexBox>
      <Divider />
      {isSampleOrder ? (
        <>
          <FlexBox
            direction="column"
            gap="8px"
            className="customer-paid-section"
          >
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Item total" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  productionCost?.total ?? '---'
                }`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Shipping" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  productionCost?.shipping ?? '---'
                }`}
              />
            </FlexBox>
          </FlexBox>
          <Divider />
          <FlexBox justifyContent="space-between" className="total-text">
            <Typography fontSize="14px" text="Total" fontWeight="700" />
            <Typography
              fontSize="14px"
              fontWeight="700"
              text={`${salesChannelCurrency} ${
                productionCost?.total && productionCost?.shipping
                  ? (productionCost?.total + productionCost?.shipping).toFixed(
                      2,
                    )
                  : '---'
              }`}
            />
          </FlexBox>
        </>
      ) : (
        <>
          {/*  Chart Sections*/}

          {!containsNonEverbeePrintsItems && (
            <>
              <FlexBox className="chart-data-container" alignItems="center">
                <Chart
                  options={donutChartOptions}
                  series={donutChartOptions.series}
                  type="donut"
                  height="120"
                  width="120"
                />
                <FlexBox direction="column" className="chart-data">
                  <FlexBox justifyContent="space-between">
                    <ChartDataItem
                      displayName="Net profit"
                      displayData={`${salesChannelCurrency} ${
                        billingData.netProfit
                      } (${(
                        (Number(billingData.netProfit) /
                          Number(billingData.totalChartValue)) *
                        100
                      ).toFixed(2)}%)`}
                    />
                    <ChartDataItem
                      displayName="Shipping cost"
                      displayData={`${salesChannelCurrency} ${
                        productionCost?.shipping
                      } (${(
                        (Number(productionCost?.shipping) /
                          Number(billingData.totalChartValue)) *
                        100
                      ).toFixed(2)}%)`}
                    />
                  </FlexBox>
                  <FlexBox justifyContent="space-between" className="pt-8">
                    <ChartDataItem
                      displayName="COGS"
                      displayData={`${salesChannelCurrency} ${
                        billingData.totalProductionCost
                      } (${(
                        (Number(billingData.totalProductionCost) /
                          Number(billingData.totalChartValue)) *
                        100
                      ).toFixed(2)}%)`}
                    />
                    <ChartDataItem
                      displayName="Fees"
                      displayData={`${salesChannelCurrency} ${
                        billingData.totalFees
                      } (${(
                        (Number(billingData.totalFees) /
                          Number(billingData.totalChartValue)) *
                        100
                      ).toFixed(2)}%)`}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <Divider />
            </>
          )}

          {/* Customer Paid Section */}
          <FlexBox
            direction="column"
            gap="8px"
            className="customer-paid-section"
          >
            <Typography
              fontSize="14px"
              text="Your customer paid"
              fontWeight="700"
            />
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Item total" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${customerPaid?.total ?? '---'}`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Shipping" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  customerPaid?.shipping ?? '---'
                }`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center" gap="8px">
                <Typography fontSize="14px" text="Tax" />
              </FlexBox>
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${customerPaid?.tax ?? '---'}`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Order total before tax" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  billingData.orderTotalBeforeTax ?? '---'
                }`}
              />
            </FlexBox>
          </FlexBox>

          <Divider />

          {/* Production Cost Section */}
          <FlexBox
            direction="column"
            gap="8px"
            className="customer-paid-section"
          >
            <Typography
              fontSize="14px"
              text="Production costs"
              fontWeight="700"
            />
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Item total" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  productionCost?.total ?? '---'
                }`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Shipping" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  productionCost?.shipping ?? '---'
                }`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Total production costs" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  billingData.totalProductionCost ?? '---'
                }`}
              />
            </FlexBox>
          </FlexBox>

          <Divider />

          {/* Etsy Fee */}
          <FlexBox
            direction="column"
            gap="8px"
            className="customer-paid-section"
          >
            <Typography fontSize="14px" text="Etsy Fees" fontWeight="700" />

            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Transaction fee" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  fees?.transactionFee?.toFixed(2) ?? '---'
                }`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between" alignItems="center">
              <Typography fontSize="14px" text="Payment processing fee" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${fees?.processingFee ?? '---'}`}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between">
              <Typography fontSize="14px" text="Total Etsy fees" />
              <Typography
                fontSize="14px"
                text={`${salesChannelCurrency} ${
                  billingData.totalFees ?? '---'
                }`}
              />
            </FlexBox>
          </FlexBox>
          {!containsNonEverbeePrintsItems && (
            <>
              <Divider />

              {/* Stats Section */}
              <FlexBox
                direction="column"
                gap="8px"
                className="customer-paid-section"
              >
                <FlexBox justifyContent="space-between">
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text="Total revenue"
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text={`${salesChannelCurrency} ${
                      billingData.orderTotalBeforeTax ?? '---'
                    }`}
                  />
                </FlexBox>
                <FlexBox justifyContent="space-between">
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text="Total costs"
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text={`${salesChannelCurrency} ${
                      billingData.totalProductionCost ?? '---'
                    }`}
                  />
                </FlexBox>
                <FlexBox justifyContent="space-between">
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text="Net profit"
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text={`${salesChannelCurrency} ${
                      billingData.netProfit ?? '---'
                    }`}
                  />
                </FlexBox>
                <FlexBox justifyContent="space-between">
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text="Net profit margin"
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    text={` ${billingData.netProfitMargin ?? '---'}%`}
                  />
                </FlexBox>
              </FlexBox>
            </>
          )}
          {salesChannelCurrency !== 'USD' && (
            <>
              <Divider />
              <Typography
                text="**Figures may vary due to currency fluctuations"
                fontSize="12px"
                className="info-text"
              />
            </>
          )}
        </>
      )}
    </StyledWrapper>
  );
};

export default Billing;

const StyledWrapper = styled.div`
  .heading-wrapper,
  .customer-paid-section,
  .chart-data-container,
  .info-text {
    padding: 12px 14px;
  }
  .info-text {
    display: block;
    padding-bottom: 10px;
  }
  .chart-data {
    width: 100%;
  }
  .pt-8 {
    padding-top: 8px;
  }

  .info-icon {
    cursor: pointer;
    width: 16px;
    aspect-ratio: 1;
  }
`;
export const Divider = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${colors.grey.border};
  margin: 0;
  padding: 0;
`;
