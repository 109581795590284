import React, { useState } from 'react';
import Modal from '../../../common/modal';
import styled from 'styled-components';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import Input from '../../../inputs';
import Button from '../../../common/button';
import { Customer } from '../../../../interface/create-order-interface';
interface Props extends Customer {
  onClose: () => void;
  onSave: (customerInfo: Customer) => void;
}

const CustomerDetailsModal: React.FC<Props> = ({
  onClose,
  email,
  fullName,
  phone,
  onSave,
}) => {
  const [customerInfo, setCustomerInfo] = useState<Customer>({
    email,
    fullName,
    phone,
  });
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerInfo({
      ...customerInfo,
      [name]: value,
    });
  };
  return (
    <StyledWrapper>
      <Modal
        title="Customer Details"
        className="customer-details-modal"
        onClose={onClose}
      >
        <FlexBox direction="column" gap="1rem">
          <FlexBox direction="column" gap="8px">
            <Typography
              text="Full Name"
              secondaryColorValue={800}
              fontSize="14px"
            />
            <Input
              onChange={changeHandler}
              value={customerInfo.fullName}
              name="fullName"
            />
          </FlexBox>
          <FlexBox direction="column" gap="8px">
            <Typography
              text="Email"
              secondaryColorValue={800}
              fontSize="14px"
            />
            <Input
              onChange={changeHandler}
              value={customerInfo.email}
              name="email"
            />
          </FlexBox>
          <FlexBox direction="column" gap="8px">
            <Typography
              text="phone"
              secondaryColorValue={800}
              fontSize="14px"
            />
            <Input
              onChange={changeHandler}
              value={customerInfo.phone}
              name="phone"
            />
          </FlexBox>
          <FlexBox gap="1rem">
            <Button
              label="Cancel"
              onClick={onClose}
              appearance="secondary"
              size="fullWidth"
            />
            <Button
              label="Save Changes"
              onClick={() => {
                onSave(customerInfo);
                onClose();
              }}
              appearance="primary"
              size="fullWidth"
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default CustomerDetailsModal;
const StyledWrapper = styled.div`
  .modal-header p {
    font-size: 18px;
  }
  .customer-details-modal {
    height: fit-content;
    border-radius: 1rem;
  }
`;
