import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import { colors } from '../../../assets/colors';
import Billing from './billing';
import Typography from '../../typography';
import CustomerDetailsModal from './modals/customer-details-modal';
import CustomerSection from './customer-section';
import MiniImageInfoSection from '../../mini-image-info-section';
import { useParams } from 'react-router-dom';
import {
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
} from '../../../store/services/orders';
import AddressLayout from '../../create-order/main-content/address-layout';
import { CreateorderModals } from '../../../interface/create-order-interface';
import AddressModal from '../../create-order/modals/adress-modal';
import Loader from '../../common/loader';
import dayjs from 'dayjs';
import { OrderStatus, ProductCost } from '../../../interface/orders-interface';
import { TabletProps } from '../../../interface';
import { useAppSelector } from '../../../store/hooks';
import { AuthInitialState, getUserData } from '../../../store/slices/authSlice';
import { fetchCurrencyRates } from '../../../utils/currency-converter';
import ImageSide from '../../common/image-wrapper';
import PersonalizationEditor from './personalization/personalization-editor';
import PersonalizationProgressAndFulfillmentButton from './personalization/progress-bar-and-fulfillment-button';
import { openLinkInNewTab } from '../../../helper/openLinkInNewTab';

const Details: React.FC<TabletProps> = ({ isTablet }) => {
  const { orderId } = useParams();

  const [showEditDetailsModal, setShowEditDetailsModal] = useState<{
    shippingAddressModal: boolean;
    customerDetailsModal: boolean;
  }>({ customerDetailsModal: false, shippingAddressModal: false });
  const [multiplier, setMultiplier] = useState<number>(1);
  const {
    userInfo: { salesChannelCurrency },
  }: AuthInitialState = useAppSelector(getUserData);
  const [hoveredSide, setHoveredSide] = useState<'front' | 'back' | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(0);
  const [productionCostMultiplier, setProductionCostMultiplier] =
    useState<number>(1);
  const [showPersonalizeEditorModal, setShowPersonalizeEditorModal] = useState({
    status: false,
    productId: '',
  });
  const getUpdatedPrices = (data: ProductCost, isProductionCost: boolean) => {
    const { shipping, tax, total, currencyCode } = data;
    const newMultiplier = isProductionCost
      ? productionCostMultiplier
      : multiplier;
    return {
      shipping: Number((shipping * newMultiplier).toFixed(2)),
      tax: Number((tax * newMultiplier).toFixed(2)),
      total: Number((total * newMultiplier).toFixed(2)),
      currencyCode: currencyCode,
    };
  };
  const { orderDetails, refetch } = useGetOrderByIdQuery(
    {
      orderId,
    },
    {
      skip: !orderId,
      selectFromResult: ({ data: { data } = {} }) => ({
        orderDetails: {
          title: data?.title ?? '',
          createdAt: dayjs(data?.createdAt).format('MMM D YYYY, h:m A') ?? '',
          updatedAt: dayjs(data?.updatedAt).format('MMM D YYYY, h:m A') ?? '',
          productionDate: data?.productionDate
            ? dayjs(data?.productionDate).format('MMM D YYYY, h:m A')
            : '',
          shippedOn: data?.shippedOn
            ? dayjs(data?.shippedOn).format('MMM D YYYY, h:m A')
            : '',
          mockupImage: data?.mockupImage ?? '',
          designFileUrl: data?.designFileUrl ?? '',
          colorHex: data?.colorHex ?? '',
          brand: data?.brand ?? '',
          model: data?.model ?? '',
          color: data?.color ?? '',
          quantity: data?.quantity ?? 1,
          catalogSKUId: data?.catalogSKUId ?? '',
          status: data?.status,
          size: data?.size,
          customerInfo: {
            email: data?.customer.email ?? '',
            fullName: data?.customer.fullName ?? '',
            phone: data?.customer.phone ?? '',
          },
          shippingAddress: {
            country: data?.shippingAddress.country ?? '',
            state: data?.shippingAddress.state ?? '',
            address: data?.shippingAddress.address ?? '',
            firstLine: data?.shippingAddress.firstLine ?? '',
            city: data?.shippingAddress.city ?? '',
            zipCode: data?.shippingAddress.zipCode ?? '',
          },
          customerPaid: data?.customerPaid
            ? getUpdatedPrices(data.customerPaid, false)
            : data?.customerPaid,
          ProductionCost: data?.productionCost
            ? getUpdatedPrices(data?.productionCost, true)
            : data?.productCost,
          fees: data?.fees
            ? {
                transactionFee: Number(
                  (data.fees.transactionFee * multiplier).toFixed(2),
                ),
                listingFee: Number(
                  (data.fees.listingFee * multiplier).toFixed(2),
                ),
                processingFee: data.fees.processingFee,
              }
            : data?.fees,
          trackingLink: data?.trackingLink,
          trackingNumber: data?.trackingNumber,
          products: data?.products,
          containsNonEverbeePrintsItems: data?.containsNonEverbeePrintsItems,
          externalOrderId: data?.externalOrderId,
          totalPersonalizableProducts:
            data?.products.filter(
              (product: any) =>
                product.personalization && product.personalization.required,
            ).length ?? 0,
          totalPersonalizedProducts:
            data?.products.filter(
              (product: any) =>
                product.personalization &&
                product.personalization.required &&
                product.personalization.done,
            ).length ?? 0,
          isSampleOrder: data?.isSampleOrder,
        },
      }),
    },
  );

  useEffect(() => {
    const fetchData = async (
      currencyCode: string,
      forProductionCost: boolean,
    ) => {
      try {
        const data = await fetchCurrencyRates(
          forProductionCost ? 'USD' : currencyCode,
          salesChannelCurrency,
        );
        if (forProductionCost) {
          setProductionCostMultiplier(data);
        } else {
          setMultiplier(data);
        }
      } catch {
        setMultiplier(1);
      }
    };
    const currencyCustomerPaidIn = orderDetails?.customerPaid?.currencyCode;

    if (currencyCustomerPaidIn) {
      if (currencyCustomerPaidIn !== 'USD') {
        fetchData(currencyCustomerPaidIn, true);
      }

      if (currencyCustomerPaidIn !== salesChannelCurrency) {
        fetchData(currencyCustomerPaidIn, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesChannelCurrency]);

  const { customerInfo, shippingAddress } = orderDetails;
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();
  const handleHover = (index: number, side: 'front' | 'back') => {
    setHoveredSide(side);
    setHoverIndex(index);
  };

  const handleLeave = (index: number) => {
    setHoveredSide(null);
    setHoverIndex(null);
  };

  return (
    <StyledWrapper isTablet={isTablet}>
      <div style={{ position: 'absolute' }}>{isLoading && <Loader />}</div>

      <FlexBox direction="column" gap="22px" className="left-container">
        <>
          {orderDetails.totalPersonalizableProducts &&
          (orderDetails.status === OrderStatus.PENDING_FULFILLMENT ||
            orderDetails.status === OrderStatus.PENDING_PERSONALIZATION) ? (
            <PersonalizationProgressAndFulfillmentButton
              totalProducts={orderDetails.totalPersonalizableProducts}
              personalizedProducts={orderDetails.totalPersonalizedProducts}
              orderId={orderId ?? ''}
              refetch={refetch}
            />
          ) : null}
          {orderDetails?.products?.map((item: any, index: number) => (
            <div className="wrapper bg-white" key={index}>
              {/* box-1 */}
              <FlexBox
                justifyContent="space-between"
                alignItems="center"
                className="heading-wrapper"
              >
                <Typography fontSize="18px" text={`${item.title}`} />
                {item.personalization && item.personalization.required && (
                  <PersonalizationEditor
                    orderId={orderId ?? ''}
                    setShowPersonalizeEditorModal={(status) =>
                      setShowPersonalizeEditorModal({
                        status,
                        productId: status ? item.productId : '',
                      })
                    }
                    showPersonalizeEditorModal={
                      showPersonalizeEditorModal.status
                    }
                    personalizationId={showPersonalizeEditorModal.productId}
                    item={item}
                    status={orderDetails.status}
                  />
                )}
              </FlexBox>
              {/* box-2 */}
              <FlexBox className="product-info-container">
                <div className="product-left-side-box">
                  <MiniImageInfoSection
                    product={item}
                    productColor={item?.colorHex}
                    quantity={item?.quantity}
                    className="product-image-section"
                    productTitle={item.title}
                    productSize={item.size}
                    modelNumber={item?.model}
                    brandName={item?.brand}
                    forModal={true}
                    category={item?.category}
                    printArea={item?.printAreasForCatalog}
                    colorName={item?.colorName}
                  />
                </div>
                <FlexBox
                  direction="column"
                  className="product-right-side-box"
                  gap="12px"
                >
                  <FlexBox direction="column">
                    {item.frontDesign && (
                      <ImageSide
                        key={index}
                        index={index}
                        sideType="front"
                        imageUrl={
                          item.personalization && item.personalization.required
                            ? item.personalization.frontDesign
                            : item.frontDesign
                        }
                        showPending={item?.personalization.required}
                        onHover={(index: number) => handleHover(index, 'front')}
                        onLeave={(index: number) => handleLeave(index)}
                        downloadName="front"
                        hoveredIndex={hoverIndex}
                        hoveredSide={hoveredSide}
                        category={item?.category}
                      />
                    )}
                    {item.backDesign && (
                      <ImageSide
                        key={item.productId}
                        index={index}
                        sideType="back"
                        imageUrl={
                          item.personalization && item.personalization.required
                            ? item.personalization.backDesign
                            : item.backDesign
                        }
                        showPending={item?.personalization.required}
                        onHover={(index: number) => handleHover(index, 'back')}
                        onLeave={(index: number) => handleLeave(index)}
                        downloadName="back"
                        hoveredSide={hoveredSide}
                        hoveredIndex={hoverIndex}
                      />
                    )}
                  </FlexBox>

                  <FlexBox
                    direction="column"
                    gap="8px"
                    justifyContent="space-evenly"
                  >
                    {orderDetails?.trackingLink && (
                      <FlexBox
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography fontSize="14px" text="Tracking Details" />
                        <Typography
                          fontSize="14px"
                          primaryColorValue={900}
                          text={orderDetails.trackingNumber}
                          className="pointer-cursor"
                          onClick={() => 
                            openLinkInNewTab(orderDetails.trackingLink)
                          }
                        />
                      </FlexBox>
                    )}
                    <FlexBox justifyContent="space-between" alignItems="center">
                      <Typography fontSize="14px" text="SKU" />
                      <Typography fontSize="14px" text={item?.printSKUId} />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </div>
          ))}

          <div className="wrapper bg-white">
            <CustomerSection
              openModal={() =>
                setShowEditDetailsModal({
                  customerDetailsModal: true,
                  shippingAddressModal: false,
                })
              }
              email={customerInfo?.email ?? '---'}
              fullName={customerInfo?.fullName ?? '---'}
              phone={customerInfo?.phone ?? '---'}
              designFileUrl={orderDetails.designFileUrl}
            />
          </div>
          <div className="wrapper bg-white">
            <AddressLayout
              className="shipping-address b-r-6px"
              address={orderDetails?.shippingAddress?.address ?? '---'}
              city={orderDetails?.shippingAddress?.city ?? '---'}
              country={orderDetails?.shippingAddress?.country ?? '---'}
              firstLine={orderDetails?.shippingAddress?.firstLine ?? '---'}
              state={orderDetails?.shippingAddress?.state ?? '---'}
              title="Shipping Address"
              zipCode={orderDetails?.shippingAddress?.zipCode ?? '---'}
              onClick={() =>
                setShowEditDetailsModal({
                  customerDetailsModal: false,
                  shippingAddressModal: true,
                })
              }
              width="100%"
              externalOrderId={orderDetails?.externalOrderId}
            />
          </div>

          {/* <div className="history-wrapper">
          <History />
        </div> */}
        </>
      </FlexBox>
      <div className="wrapper bg-white">
        <Billing
          productionCost={orderDetails.ProductionCost}
          customerPaid={orderDetails.customerPaid}
          fees={orderDetails.fees}
          containsNonEverbeePrintsItems={
            orderDetails.containsNonEverbeePrintsItems
          }
          isSampleOrder={orderDetails?.isSampleOrder}
        />
      </div>
      {showEditDetailsModal.customerDetailsModal && (
        <CustomerDetailsModal
          onClose={() =>
            setShowEditDetailsModal({
              customerDetailsModal: false,
              shippingAddressModal: false,
            })
          }
          email={customerInfo.email}
          fullName={customerInfo.fullName}
          phone={customerInfo.phone}
          onSave={(customerInfo) =>
            updateOrder({
              orderId: orderId ?? '',
              body: { customer: customerInfo },
            })
          }
        />
      )}
      {showEditDetailsModal.shippingAddressModal && (
        <AddressModal
          title={CreateorderModals.ShippingAddressModal}
          address={shippingAddress.address}
          city={shippingAddress.city}
          country={shippingAddress.country}
          state={shippingAddress.state}
          zipCode={shippingAddress.zipCode}
          firstLine={shippingAddress.firstLine}
          onSave={(addressInfo) =>
            updateOrder({
              orderId: orderId ?? '',
              body: { shippingAddress: addressInfo },
            })
          }
          onCloseModal={() =>
            setShowEditDetailsModal({
              customerDetailsModal: false,
              shippingAddressModal: false,
            })
          }
        />
      )}
    </StyledWrapper>
  );
};

export default Details;

const StyledWrapper = styled.div<{
  isTablet: boolean;
}>`
  position: relative;
  ${(props) =>
    !props.isTablet &&
    css`
      display: grid;
    `};
  gap: 22px;
  max-width: 100%;
  max-height: 100%;
  grid-template-columns: 1fr 40%;

  .wrapper {
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    border: 1px solid ${colors.grey.border};
  }
  .icon-box {
    position: absolute;
    top: 8px;
    right: 4px;
    width: 20px;
    height: 20px;
    background: ${colors.white};
    border-radius: 50%;
    border: 1px solid ${colors.grey.border};
  }

  .image-box {
    border: 1px solid ${colors.grey.border};
    border-radius: 5px;
  }
  .transform-image {
    object-fit: contain;
  }
  .image-popup {
    // display: none;
    position: absolute;
    right: 7%;
    overflow: hidden;
    transition: right 0.3s ease-in-out;
    background-color: white;
    img {
      object-fit: contain;
    }
  }

  .popup-image {
    width: 100%;
    height: 100%;
  }

  .heading-wrapper {
    border-bottom: 1px solid ${colors.grey.border};
    padding: 12px 14px;
  }
  .product-info-container {
    font-size: 14px;
    ${(props) =>
      props.isTablet &&
      css`
        flex-direction: column;
      `};
    border-bottom: 1px solid ${colors.grey.border};
    .product-left-side-box {
      ${(props) =>
        !props.isTablet &&
        css`
          width: 50%;
        `};
      border-right: 1px solid ${colors.grey.border};
      padding: 12px 14px;
      .product-image-section {
        width: 100%;
        border: none;
        height: fit-content;
        padding: 0;
        .image-wrapper {
          width: 116px;
          height: 116px;
        }
      }
    }
  }
  .product-right-side-box {
    > * {
      &:first-child {
        border-bottom: 1px solid ${colors.grey.border};
      }
      padding: 6px 14px;
    }

    ${(props) =>
      !props.isTablet &&
      css`
        width: 50%;
      `};

    ${(props) =>
      props.isTablet &&
      css`
        border-top: 1px solid ${colors.grey.border};
      `};
  }

  .product-tracking-info {
    padding: 12px 14px;
    border-bottom: 1px solid ${colors.grey.border};
  }
  .product-print-provider-info,
  .shipping-address .heading {
    padding: 12px 14px;
  }
  .w-100 {
    width: 100%;
  }
`;
