import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DecreaseIcon } from '../../assets/images/decrease.svg';
import { ReactComponent as IncreaseIcon } from '../../assets/images/increase.svg';
import { colors } from '../../assets/colors';

interface Props {
  quantity: number;
  onChange(quantity: number): void;
}

const QuantityButtons: React.FC<Props> = ({ onChange, quantity }) => {
  return (
    <QuantityButtonGroup>
      <div>
        <button
          disabled={quantity === 1}
          onClick={() => onChange(quantity === 1 ? 1 : quantity - 1)}
        >
          <DecreaseIcon width="12" />
        </button>
        <span>{quantity}</span>
        <button onClick={() => onChange(quantity + 1)}>
          <IncreaseIcon width="12" />
        </button>
      </div>
    </QuantityButtonGroup>
  );
};

export default QuantityButtons;

const QuantityButtonGroup = styled.span`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  button > svg {
    stroke: ${colors.grey[900]};
  }

  button:disabled > svg {
    stroke: ${colors.grey[400]};
  }

  & > div {
    display: flex;
    border: 1px solid ${colors.grey[300]};
    border-radius: 6px;
    overflow: hidden;
    font-size: 14px;
  }

  & > div > * {
    width: 28px;
    padding: 4px;
    display: flex;
    justify-content: center;
    background: ${colors.white};
    border-right: 1px solid ${colors.grey[300]};
    box-sizing: border-box;
  }
`;
