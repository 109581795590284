import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

interface Props {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disable?: boolean;
  name?: string;
  value?: string;
}
const Checkbox: React.FC<Props> = React.forwardRef(
  ({ checked, onChange, label, disable, name, value, ...props }, ref) => {
    return (
      <CheckboxContainer>
        <CheckboxInput
          value={value}
          disabled={disable}
          type="checkbox"
          onChange={(e) => {
            onChange(e);
          }}
          checked={checked}
          name={name}
          {...props}
        />
        <CheckboxCustom />
        {label && (
          <span className={disable ? 'grey500 fs-14' : 'grey900 fs-14'}>
            {label}
          </span>
        )}
      </CheckboxContainer>
    );
  },
);

export default Checkbox;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const CheckboxCustom = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 5px;
  border: 2px solid ${colors.grey['400']};
  transition: all 0.3s ease;

  ${CheckboxInput}:checked + & {
    background-color: white;
    border-color: ${colors.blue['800']};
  }
  ${CheckboxInput}:disabled + & {
    background-color: white;
    border-color: ${colors.grey['400']};
  }

  ${CheckboxInput}:checked + &::after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: 2px solid ${colors.blue['800']};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-left: 4px;
    margin-top: 1px;
  }
  ${CheckboxInput}:disabled + &::after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: 2px solid ${colors.grey['400']};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-left: 4px;
    margin-top: 1px;
  }
`;
