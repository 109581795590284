import Dropzone from 'react-dropzone';
import { FlexBox } from '../../../common/wrappers';
import styled from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import { Canvas } from 'fabric/fabric-impl';
import { uploadToGallery } from '../../utils';
import { ProductName } from '../..';
import { generatePreviewImages } from '../apparel/utils';
import {
  PrintArea,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';
import ProductAngles from '../product-angles';
import { DataToGenerateAngleImage } from '../../../../interface';
interface Props {
  isTablet: boolean;
  addToGallery: (files: any) => void;
  files: any[];
  ToolBar: ReactNode;
  UndoRedoComponent: ReactNode;
  drawingBoard: Canvas | undefined;
  title: string;

  selectedAngle: {
    angle: string;
    image: string;
    originalImage: any;
  };
  dataToGenerateAngleImage: DataToGenerateAngleImage[];
  selectedVariant: VariantsV2 | undefined;
  printArea: PrintArea | undefined;
  handleAngleChange: (angle: string, source: string) => void;
  preloadedImages: {
    [key: string]: string;
  };
}
const OrnamentEditor: React.FC<Props> = ({
  isTablet,
  addToGallery,
  files,
  ToolBar,
  UndoRedoComponent,
  drawingBoard,
  title,
  selectedVariant,
  dataToGenerateAngleImage,
  selectedAngle,
  printArea,
  handleAngleChange,
  preloadedImages,
}) => {
  const [angleImages, setAngleImages] = useState<{ [key: string]: string }>();

  useEffect(() => {
    async function startImageGeneration() {
      let copyAngles: any = { ...angleImages };
      for (const data of dataToGenerateAngleImage) {
        const { imageData, catalogData, variant, pixiApp, angle } = data;

        const img = await generatePreviewImages(
          imageData,
          catalogData,
          variant,
          pixiApp,
          angle,
          preloadedImages,
        );
        copyAngles = { ...copyAngles, [angle]: img };
      }
      setAngleImages(copyAngles);
    }
    startImageGeneration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToGenerateAngleImage]);
  const canvasWidth = getCanvasWidthFromTitle(title);
  return (
    <>
      <Wrapper isTablet={isTablet}>
        {selectedVariant && (
          <FlexBox style={{ height: '40%', width: '40%' }}>
            <ProductAngles
              isAnglesLoading={false}
              angle={selectedAngle.angle}
              selectedImage={selectedAngle.image}
              angleImages={angleImages}
              images={selectedVariant?.images}
              onClick={(angle, image) =>
                handleAngleChange(angle, image.target.src)
              }
              printArea={printArea}
            />
          </FlexBox>
        )}

        <DrawingWrapper>
          <FlexBox
            style={{
              width: '100%',
              height: 'auto',
            }}
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="2rem"
          >
            <FlexBox
              justifyContent="center"
              direction="column"
              alignItems="center"
              className="canvas"
              id="canvas-parent"
              style={{
                margin: '5%',
                // opacity: '0.5',
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadToGallery(
                    acceptedFiles,
                    addToGallery,
                    drawingBoard,
                    files,
                  )
                }
                accept={{
                  'image/png': ['.png'],
                  'image/jpg': ['.jpg'],
                  'image/jpeg': ['.jpeg'],
                  'image/svg': ['.svg'],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    style={{
                      display: files.length === 0 ? 'block' : 'none',
                      position: 'absolute',
                    }}
                  >
                    <div
                      {...getRootProps()}
                      style={{
                        background: 'white',
                        padding: '42px',
                      }}
                    >
                      <input {...getInputProps()} />
                    </div>
                  </section>
                )}
              </Dropzone>
              <CanvasWrapper>
                <canvas height={500} width={canvasWidth} id="drawingArea" />
              </CanvasWrapper>
              {ToolBar}
            </FlexBox>
          </FlexBox>
        </DrawingWrapper>
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            userSelect: 'none',
          }}
        >
          {UndoRedoComponent}
        </div>
      </Wrapper>
    </>
  );
};
export default OrnamentEditor;
const Wrapper = styled.div<{ isTablet: boolean }>`
  padding: 16px 32px;
  display: flex;
  overflow: hidden;
  height: 90vh;
  // justify-content: center;
  gap: ${(props) => props.isTablet && '5rem'};
`;
const CanvasWrapper = styled.div`
  // position: absolute;
  top: 0;
  left: 0;
  // z-index: 150000;
`;
const DrawingWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;

  .canvas {
    position: relative !important;
    background-color: #c2c2c2 !important;
  }
  .image-box {
    .mug-angle {
      margin-left: 128px;
    }
  }
`;

export const getCanvasWidthFromTitle = (title: string): number => {
  if (title.includes(ProductName.CircleOrnament)) {
    return 500;
  } else if (title.includes(ProductName.OvalOrnament)) {
    return 394;
  } else if (title.includes(ProductName.HeartOrnament)) {
    return 534;
  } else if (title.includes(ProductName.StarOrnament)) {
    return 515;
  } else {
    return 500;
  }
};
