import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GalleryImageObject,
  MyLibraryResponseQueryParams,
} from '../../interface';
import { baseQueryWithAuth } from '../baseQuery';
import { appendQueryParams } from '../../helper/appenedQueryParams';

export const galleryAPI = createApi({
  reducerPath: 'gallery',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Gallery'],
  endpoints: (builder) => ({
    getGalleryByUserID: builder.query<any, MyLibraryResponseQueryParams>({
      query: (params) => {
        var url = `users/gallery`;
        return appendQueryParams(url, params);
      },
      providesTags: ['Gallery'],
    }),

    addImageToGallery: builder.mutation<
      any,
      { formData: FormData; folderName?: string }
    >({
      query: (params) => {
        let url = '/users/gallery';
        if (params?.folderName) {
          url += `?folderName=${params.folderName}`;
        }
        return {
          url,
          method: 'POST',
          body: params.formData,
        };
      },
    }),
    tempUploadImageToGallery: builder.mutation<
      GalleryImageObject,
      { formData: FormData }
    >({
      query: (params) => ({
        url: '/users/temp-gallery',
        method: 'POST',
        body: params.formData,
      }),
    }),

    deleteFolder: builder.mutation<{ message: string }, { folderName: string }>(
      {
        query: (params) => ({
          url: `/my-mockup?folderName=${params.folderName}`,
          method: 'DELETE',
        }),
      },
    ),
    deleteImageById: builder.mutation<
      { message: string },
      { imageId?: string; folderName?: string }
    >({
      query: (params) => ({
        url: appendQueryParams(`/users/gallery`, params),
        method: 'DELETE',
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            galleryAPI.util.updateQueryData(
              'getGalleryByUserID',
              {
                search: '',
              },
              (draft) => {
                return draft.filter((data: any) => data.id !== params.imageId);
              },
            ),
          );
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetGalleryByUserIDQuery,
  useLazyGetGalleryByUserIDQuery,
  useAddImageToGalleryMutation,
  useDeleteImageByIdMutation,
  useTempUploadImageToGalleryMutation,
} = galleryAPI;
