import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import Button from '../../common/button';
import { colors } from '../../../assets/colors';
import SearchBar from '../../common/search-bar';
import { CalendarIcon, SearchIcon } from '../../../assets/icons';
import IconButton from '../../icon-button';
import DatePicker from '../date-range-picker';
import { ClickAwayListener } from '@mui/material';
import MyProductsModal from '../modals';
import {
  OrdersFilter,
  StatusTotals,
} from '../../../interface/orders-interface';
import { Option } from '../../../interface';
import Typography from '../../typography';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<OrdersFilter>>;
  filters: OrdersFilter;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  apiRef?: React.MutableRefObject<GridApiPro>;
  statusTotals: StatusTotals[];
}
const ActionButtonsAndFilters: React.FC<Props> = ({
  setFilters,
  filters,
  setCurrentPage,
  apiRef,
  statusTotals,
}) => {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    null,
    null,
  ]);
  // const { selectedRows } = useAppSelector(getDetails);
  const [showCalender, setShowCalender] = useState<boolean>(false);
  const [showCreateProductModal, setShowCreateProductModal] =
    useState<boolean>(false);

  const handleFilterClick = (status: string) => {
    setFilters({ ...filters, status });
    setCurrentPage(1);
  };

  return (
    <StyledWrapper>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading"
      >
        <FlexBox gap="1rem">
          {/* <Button
            label="Order in bulk"
            onClick={() => console.log('object')}
            appearance="secondary"
            size="large"
          />
          <Button
            label="Get samples"
            onClick={() => console.log('object')}
            appearance="secondary"
            size="large"
          /> */}
          {/* <Button
            label="CSV Import/Export"
            onClick={() => {
              if (selectedRows.length > 0) {
                apiRef?.current.exportDataAsCsv({
                  getRowsToExport: () => selectedRows.map((id: any) => id),
                });
              } else {
                apiRef?.current.exportDataAsCsv({
                  getRowsToExport: (params) =>
                    params.apiRef.current.getAllRowIds(),
                });
              }
            }}
            appearance="secondary"
            size="large"
          /> */}
          {/* {(userInfo.email.includes('everbee.io') ||
            userInfo.email.includes('abhi18december11@gmail.com')) && (
            <Button
              label="Create order"
              onClick={() => setShowCreateProductModal(true)}
              appearance="primary"
              size="large"
            />
          )} */}
        </FlexBox>
      </FlexBox>
      <FlexBox direction="column" gap="1rem">
        <FlexBox gap="1rem">
          <SearchBar
            className="searchbar-filter"
            onChange={(value) => setFilters({ ...filters, searchText: value })}
            value={filters.searchText}
            icon={SearchIcon}
          />
          <ClickAwayListener onClickAway={() => setShowCalender(false)}>
            <div className="icon-btn-wrapper">
              <IconButton
                icon={CalendarIcon}
                onClick={() => setShowCalender((prev) => !prev)}
                appearance="secondary"
              />
              {showCalender && (
                <DatePicker
                  onClear={(fromDate, toDate) => {
                    setFilters({ ...filters, fromDate, toDate });
                  }}
                  onClose={() => {
                    setShowCalender(false);
                  }}
                  onApply={(fromDate, toDate) => {
                    setFilters({ ...filters, fromDate, toDate });
                    setCurrentPage(1);
                  }}
                  setValue={setDateRange}
                  value={dateRange}
                />
              )}
            </div>
          </ClickAwayListener>
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <FlexBox gap="10px" alignItems="center">
            {ordersFilters.map((filterText) => {
              const statusObj = statusTotals.find(
                (status) => status.status === filterText.value,
              );
              const count = statusObj?.count || 0;
              const label = `${filterText.label} ${
                count > 0 ? `· ${count}` : ''
              }`;
              const isFilterActive =
                filters.status === filterText.value ||
                (filters.status === '' && filterText.label === 'All');

              const classNames = `${
                filterText.label === 'All' ? 'filter-btn-all' : ''
              } ${isFilterActive ? 'active-filter' : ''}`;

              return (
                <Button
                  key={filterText.value}
                  label={label}
                  onClick={() => handleFilterClick(filterText.value)}
                  appearance="secondary"
                  size="medium"
                  className={classNames}
                />
              );
            })}
          </FlexBox>
          {/*  <div className="icon-btn-wrapper">
            <IconButton
              icon={VerticalDotsIcon}
              onClick={() => setShowPopup((prev) => !prev)}
              appearance="secondary"
              width="2rem"
              height="2rem"
              className="more-btn"
            />
            {showPopup && (
              <MenuList
                items={moreMenu}
                onClick={(value) => console.log(value)}
                width="max-content"
                height="max-content"
              />
            )}
          </div>
            */}

          <Typography
            text=" *Orders may take up to 5 minutes to appear here."
            fontSize="16px"
            secondaryColorValue={600}
            className="message-box"
          />
        </FlexBox>
      </FlexBox>
      {showCreateProductModal && (
        <MyProductsModal closeModal={() => setShowCreateProductModal(false)} />
      )}
    </StyledWrapper>
  );
};

export default ActionButtonsAndFilters;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .filters-wrapper {
    width: 100%;
  }
  .searchbar-filter {
    width: 100%;
    input {
      height: 100% !important;
      padding: 0;
    }
  }
  .more-btn img {
    transform: rotate(90deg);
  }
  .filter-btn-all {
    width: 88px;
  }
  .active-filter {
    background-color: ${colors.blue[100]};
    border: 1px solid ${colors.blue[500]};
  }
  .icon-btn-wrapper {
    position: relative;
  }
  .message-box {
    font-style: italic;
  }
  .success-sample-order-modal {
    max-height: 270px;
    overflow: hidden;
  }
`;

const ordersFilters: Option[] = [
  { label: 'All', value: 'all' },
  // { label: 'Draft', value: 'Draft' },
  // { label: 'Pending Personalization', value: 'Pending Personalization' },
  { label: 'Ready for production', value: 'Ready for production' },
  { label: 'In production', value: 'In Production' },
  { label: 'Ready to ship', value: 'Ready to Ship' },
  { label: 'Shipped', value: 'Shipped' },
  { label: 'On Hold', value: 'On Hold' },
  { label: 'Cancelled', value: 'Cancelled' },
];
