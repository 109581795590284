import React from 'react';
import styled from 'styled-components';
import Title from './title';
import MainContent from './main-content';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getCreateOrderDetails,
  setModalStatus,
  updateAddressInfo,
} from '../../store/slices/createOrderSlice';
import { CreateorderModals } from '../../interface/create-order-interface';
import RecipientModal from './modals/recipient-modal';
import AddressModal from './modals/adress-modal';
import CreateOrderFooter from './create-order-footer';
import { colors } from '../../assets/colors';

const CreateOrder: React.FC = () => {
  const { createOrderModal, shippingAddress, billingAddress } = useAppSelector(
    getCreateOrderDetails,
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <StyledWrapper>
        <Title />
        <MainContent />
        {createOrderModal === CreateorderModals.RecipientModal && (
          <RecipientModal />
        )}
        {createOrderModal === CreateorderModals.ShippingAddressModal && (
          <AddressModal
            title={CreateorderModals.ShippingAddressModal}
            address={shippingAddress.address}
            city={shippingAddress.city}
            country={shippingAddress.country}
            firstLine={shippingAddress.firstLine}
            state={shippingAddress.state}
            zipCode={shippingAddress.zipCode}
            onCloseModal={() =>
              dispatch(setModalStatus(CreateorderModals.None))
            }
            onSave={(addressInfo) => {
              dispatch(
                updateAddressInfo({
                  address: `${addressInfo.firstLine},${addressInfo.city},${addressInfo.state} ${addressInfo.zipCode} ${addressInfo.country}`,
                  city: addressInfo.city,
                  country: addressInfo.country,
                  state: addressInfo.state,
                  zipCode: addressInfo.zipCode,
                  firstLine: addressInfo.firstLine,
                  sectionName: 'shippingAddress',
                }),
              );
            }}
          />
        )}
        {createOrderModal === CreateorderModals.BillingAddressModal && (
          <AddressModal
            title={CreateorderModals.BillingAddressModal}
            address={billingAddress.address}
            city={billingAddress.city}
            firstLine={billingAddress.firstLine}
            country={billingAddress.country}
            state={billingAddress.state}
            zipCode={billingAddress.zipCode}
            onCloseModal={() =>
              dispatch(setModalStatus(CreateorderModals.None))
            }
            onSave={(addressInfo) => {
              dispatch(
                updateAddressInfo({
                  address: `${addressInfo.firstLine},${addressInfo.city},${addressInfo.state} ${addressInfo.zipCode} ${addressInfo.country}`,
                  city: addressInfo.city,
                  country: addressInfo.country,
                  state: addressInfo.state,
                  zipCode: addressInfo.zipCode,
                  firstLine: addressInfo.firstLine,
                  sectionName: 'billingAddress',
                }),
              );
            }}
          />
        )}
      </StyledWrapper>
      <CreateOrderFooter />
    </>
  );
};

export default CreateOrder;

const StyledWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  background-color: ${colors.background};
`;
