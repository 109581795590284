import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import SearchBar from '../../common/search-bar';
import { DeleteIcon, SearchIcon, SortIcon } from '../../../assets/icons';
import { SelectList } from '../../common/elements';
import { Option, ProductsFilters, Status } from '../../../interface';
import { colors } from '../../../assets/colors';
import FilterLabel from '../../filter-label';
import MultiSelectDropDown from '../../multi-select-dropdown';
import MenuList from '../../menu';
import IconButton from '../../icon-button';
import Button from '../../common/button';
import ConfirmationModal from '../../common/confirmation-modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getDetails,
  updateSeletedRows,
} from '../../../store/slices/detailsSlice';
import {
  useDeleteProductByIdMutation,
  useDuplicateProductMutation,
  usePublishProductMutation,
} from '../../../store/services/products';
import Loader from '../../common/loader';

interface Props {
  selectBrands: Option[];
  setSelectBrands: React.Dispatch<React.SetStateAction<Option[]>>;
  filters: ProductsFilters;
  setFilters: React.Dispatch<React.SetStateAction<ProductsFilters>>;
  brandsList: Option[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  refetchProduct: () => void;
  rowData: any[];
}

const Header: React.FC<Props> = ({
  selectBrands,
  setSelectBrands,
  filters,
  setFilters,
  brandsList,
  setCurrentPage,
  currentPage,
  refetchProduct,
  rowData,
}) => {
  const dispatch = useAppDispatch();
  const { selectedRows } = useAppSelector(getDetails);
  const [isFailedToPublish, setIsFailedToPublish] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [unpublishedProducts, setUnpublishedProducts] = useState<string[]>([]);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [deleteProduct, { isLoading }] = useDeleteProductByIdMutation();
  const [duplicateProduct, { isLoading: isDuplicating }] =
    useDuplicateProductMutation();
  const [publishProducts, { isLoading: isPublishing, error: publishingError }] =
    usePublishProductMutation();

  useEffect(() => {
    if (selectedRows.length > 0) {
      const unpublishedIds = selectedRows.filter((id: string) => {
        const row = rowData.find((row) => row.id === id);
        return (
          row &&
          (row.status === Status.Unpublished ||
            row.status === Status.UnpublishedChanges)
        );
      });

      setUnpublishedProducts(unpublishedIds);
    }
  }, [rowData, selectedRows]);

  useEffect(() => {
    const error = publishingError as any;
    if (error?.data?.productIdsFailedToPublish) {
      const { productIdsFailedToPublish } = error.data;
      const productIds = productIdsFailedToPublish.map(
        (productObj: any) => productObj.productId,
      );
      setRetryCount((error.data?.retryCount ?? retryCount) + 1);
      dispatch(updateSeletedRows(productIds));
      setUnpublishedProducts(productIds);
      setIsFailedToPublish(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, publishingError]);

  return (
    <StyledWrapper>
      {(isLoading || isDuplicating || isPublishing) && <Loader />}
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading"
      >
        {/* <FlexBox gap="1rem">
          <Button
            label="Order in bulk"
            onClick={() => console.log('object')}
            appearance="secondary"
            size="medium"
          />
          <Button
            label="Get samples"
            onClick={() => console.log('object')}
            appearance="secondary"
            size="medium"
          />
        </FlexBox> */}
      </FlexBox>
      <FlexBox direction="column" gap="1rem">
        <FlexBox gap="1.5rem" className="w-100">
          <SearchBar
            className="searchbar-filter"
            onChange={(value: string) =>
              setFilters({ ...filters, search: value })
            }
            value={filters.search}
            icon={SearchIcon}
          />
          <FlexBox gap="1rem" className="drop-down-filters">
            <MultiSelectDropDown
              width="100%"
              items={brandsList}
              label="Brand"
              selectedValues={selectBrands}
              onChange={(array) => {
                setSelectBrands(array);
                setCurrentPage(1);
              }}
            />
            <SelectList
              width="100%"
              selectedValue={filters.status}
              onChange={(value: string) => {
                setFilters({ ...filters, status: value });
                setCurrentPage(1);
              }}
              items={statusOptions}
              label="Status"
              listType="color"
              gapBetweenInputAndItems={0}
              className="status-filter"
            />
            <div className="icon-btn-wrapper">
              <IconButton
                appearance="secondary"
                icon={SortIcon}
                width="39px"
                height="42px"
                onClick={() => setShowPopup((prev) => !prev)}
              />

              {showPopup && (
                <MenuList
                  right="0px"
                  items={sortByOptions}
                  onClick={(value) => {
                    setFilters({
                      ...filters,
                      sortBy: {
                        field: value,
                        sort: 'desc',
                      },
                    });
                    setCurrentPage(1);
                    setShowPopup(false);
                  }}
                />
              )}
            </div>
          </FlexBox>
        </FlexBox>
        <FlexBox gap="12px" className="applied-filters">
          {selectBrands.map((value) => (
            <FilterLabel
              key={value.value}
              text={value.label}
              onClick={(text: string) =>
                setSelectBrands((prev) =>
                  prev.filter((item) => item.label !== text),
                )
              }
            />
          ))}

          {filters.status && (
            <FilterLabel
              text={filters.status}
              onClick={() => setFilters({ ...filters, status: '' })}
            />
          )}

          {(selectBrands.length > 0 || filters.status) && (
            <button
              className="clear-btn"
              onClick={() => {
                //resetting filters to initial state
                setSelectBrands([]);
                setFilters({
                  ...filters,
                  status: '',
                  sortBy: {
                    field: 'createdAt',
                    sort: 'desc',
                  },
                });
              }}
            >
              <img src={DeleteIcon} alt="delete" />
              <span>Clear all</span>
            </button>
          )}

          {selectedRows.length > 0 && (
            <FlexBox gap="1rem" className="action-btns-wrapper">
              {unpublishedProducts.length > 0 && (
                <Button
                  label="Publish"
                  appearance="primary"
                  onClick={() => setIsPublishModalOpen(true)}
                />
              )}

              <Button
                label="Duplicate"
                appearance="primary"
                onClick={async () => {
                  await duplicateProduct(selectedRows);
                  dispatch(updateSeletedRows([]));
                  if (currentPage !== 1) {
                    setCurrentPage(1);
                  } else {
                    refetchProduct();
                  }
                }}
              />
              <Button
                label="Delete"
                appearance="primary"
                className="delete-btn"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              />
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
      {isDeleteModalOpen && (
        <ConfirmationModal
          onClose={() => setIsDeleteModalOpen(false)}
          buttonColor="red"
          headingText="Delete"
          buttonLabel="Delete"
          text={
            selectedRows.length > 1
              ? `Are you sure you want to delete these ${selectedRows.length} products?`
              : 'Are you sure you want to delete this product?'
          }
          onClick={async () => {
            await deleteProduct(selectedRows);
          }}
        />
      )}
      {(isPublishModalOpen || isFailedToPublish) && (
        <ConfirmationModal
          onClose={() => {
            setIsPublishModalOpen(false);
            setIsFailedToPublish(false);
          }}
          buttonColor="primary"
          headingText="Confirmation"
          buttonLabel="Publish"
          text={
            retryCount === 2
              ? 'Please contact the customer support for assistance.'
              : isFailedToPublish
              ? 'Some listings failed to publish. Please review the following items and try again, If the problem persists, contact the custome support for assistance.'
              : 'Are you sure you want to publish all selected unpublished / unpublished changes listings?'
          }
          hideButtons={retryCount === 2}
          onClick={async () => {
            await publishProducts({
              productIds: unpublishedProducts,
              retryCount,
            });
          }}
        >
          {isFailedToPublish && (
            <ul className="products-info-wrapper">
              {rowData
                .filter((data) => unpublishedProducts.includes(data.id))
                .map((data) => (
                  <li key={data.id}>{data.productTitle}</li>
                ))}
            </ul>
          )}
        </ConfirmationModal>
      )}
    </StyledWrapper>
  );
};

export default Header;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .icon-btn-wrapper {
    position: relative;
  }

  .searchbar-filter {
    width: 30%;
    input {
      height: 100% !important;
      padding: 0;
    }
  }
  .drop-down-filters {
    width: 70%;
  }
  .status-filter {
    .colored-item {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }
  .clear-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${colors.grey['600']};
    background-color: ${colors.white};
    outline: none;
    border: none;
    gap: 6px;
    cursor: pointer;
  }
  .action-btns-wrapper {
    margin-left: auto;
  }
  .delete-btn {
    background-color: ${colors.red[900]};
    margin-left: auto;
  }
  .products-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 13px;
    margin: 0;
    padding-left: 12px;
  }
`;

const statusOptions: Array<Option> = [
  {
    label: 'Published',
    value: 'Published',
  },
  {
    label: 'Hidden',
    value: 'Hidden',
  },
  {
    label: 'Unpublished changes',
    value: 'Unpublished changes',
  },
  {
    label: 'Draft',
    value: 'Draft',
  },
];

const sortByOptions: Option[] = [
  { label: 'Recently added', value: 'createdAt' },
  { label: 'Recently updated', value: 'updatedAt' },
];
