import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithOutAuth } from '../baseQuery';
import {
  CatalogQueryParams,
  CatalogResponse,
  SingleCatalogProductResponse,
} from '../../interface/catalog-interfaces';
import { appendQueryParams } from '../../helper/appenedQueryParams';
import { CatalogProductForEditor } from '../../interface/catalog-interface-v2';

export const catalogAPI = createApi({
  reducerPath: 'catalog',
  baseQuery: baseQueryWithOutAuth,
  endpoints: (builder) => ({
    getCatalog: builder.query<CatalogResponse, CatalogQueryParams>({
      query: (params) => {
        var url = `/catalog`;
        return appendQueryParams(url, params);
      },
    }),
    getSingleCatalogProduct: builder.query<
      SingleCatalogProductResponse | any,
      {
        productId: string | undefined;
        printProviderId?: string;
        fromEditor?: string;
      }
    >({
      query: (params) => {
        let queryString = `/catalog/${params.productId}`;
        queryString += `?printProvider=${params.printProviderId}`;
        if (params.fromEditor) {
          queryString += `&fromEditor=${params.fromEditor}`;
        }
        return queryString;
      },
    }),
    getSingleCatalogProductForEditor: builder.query<
      CatalogProductForEditor,
      {
        catalogProductId: string | undefined;
        printProviderId?: string;
        fromEditor?: string;
      }
    >({
      query: (params) => {
        let queryString = `/catalog/${params.catalogProductId}`;
        queryString += `?printProvider=${params.printProviderId}`;
        if (params.fromEditor) {
          queryString += `&fromEditor=${params.fromEditor}`;
        }
        return queryString;
      },
    }),
    getAllBrands: builder.query<{ data: string[] }, void>({
      query: () => `/catalog/brands`,
    }),
  }),
});

export const {
  useGetCatalogQuery,
  useGetSingleCatalogProductQuery,
  useGetSingleCatalogProductForEditorQuery,
  useGetAllBrandsQuery,
} = catalogAPI;
