const sortedSizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];

const sizeIndexMap: Record<string, number> = sortedSizes.reduce((acc: Record<string, number>, size: string, index: number) => {
  acc[size.toLowerCase()] = index;
  return acc;
}, {});

export const sortSizes = (unsortedSizes: any) => {
  const sortedUnsortedSizes = unsortedSizes.sort((a: string, b: string) => {
    const indexA = sizeIndexMap[a.toLowerCase()];
    const indexB = sizeIndexMap[b.toLowerCase()];
    return indexA - indexB;
  });
  return sortedUnsortedSizes;
}
