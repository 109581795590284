import {
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';
import { colors } from '../../assets/colors';
import styled from 'styled-components';
import { FlexBox } from '../common/wrappers';

import { FC, useCallback, useEffect, useState } from 'react';
import Typography from '../typography';
import { toast } from 'react-toastify';
import Button from '../common/button';
import {
  getSampleOrderData,
  setAttachPaymentMethod,
  setSampleOrderPaymentId,
} from '../../store/slices/sampleOrderSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';

interface Props {
  onCloseModal: () => void;
  onMakePayment?: (paymentMethodId: string) => void;
  forSampleOrder?: boolean;
  setIsFulfillmentButtonDisabled?: (status: boolean) => void;
  generatePayentMethodId?: boolean;
  setPaymentError?: (value: any) => void;
}

const CheckoutForm: FC<Props> = ({
  onCloseModal,
  onMakePayment,
  forSampleOrder,
  setIsFulfillmentButtonDisabled,
  generatePayentMethodId,
  setPaymentError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });
  const { billingAddress, recipient } = useAppSelector(getSampleOrderData);
  useEffect(() => {
    if (forSampleOrder) handleClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePayentMethodId]);

  const handleClick = useCallback(async () => {
    const card = elements?.getElement(CardNumberElement);
    if (card) {
      const cardDetails: any = {
        type: 'card',
        card,
      };
      if (!forSampleOrder) {
        const address = await elements?.getElement(AddressElement)?.getValue();
        cardDetails.billing_details = {
          address: {
            ...address?.value.address,
            line2: address?.value.address.line2 ?? undefined,
          },
          name: address?.value.name,
        };
      } else {
        cardDetails.billing_details = {
          address: {
            line1: billingAddress.address,
            city: billingAddress.city,
            country: billingAddress.country,
            postal_code: billingAddress.zipCode,
            state: billingAddress.state,
          },
          name: recipient.fullName,
        };
      }
      let paymentMethod = await stripe?.createPaymentMethod(cardDetails);
      if (paymentMethod?.paymentMethod?.id) {
        if (onMakePayment) {
          onMakePayment(paymentMethod?.paymentMethod?.id);
        } else {
          dispatch(setSampleOrderPaymentId(paymentMethod?.paymentMethod?.id));
          dispatch(setAttachPaymentMethod(true));
        }
      }
      if (paymentMethod?.error) {
        toast.error(paymentMethod.error.message);
        if (setPaymentError) setPaymentError(paymentMethod.error.message);
      }
    }
  }, [
    elements,
    forSampleOrder,
    stripe,
    billingAddress,
    recipient.fullName,
    onMakePayment,
    dispatch,
    setPaymentError,
  ]);

  const handleCardFieldChange = (e: any) => {
    const { elementType, complete, error } = e;
    if (error) {
      console.error(error.message);
      return;
    }
    const cardInfo = { ...cardData, [elementType]: complete };
    setCardData(cardInfo);
    const allElementsComplete = Object.values(cardInfo).every(
      (element) => element,
    );
    if (forSampleOrder && setIsFulfillmentButtonDisabled) {
      setIsFulfillmentButtonDisabled(!allElementsComplete);
    }
  };

  return (
    <StyleWrapper>
      <div>
        <ElementWrapper>
          <Typography
            text="Card number"
            fontSize="0.93rem"
            secondaryColorValue={800}
            fontWeight="400"
          />

          <div>
            <CardNumberElement
              options={options}
              onChange={handleCardFieldChange}
            />
          </div>
        </ElementWrapper>

        <FlexBox justifyContent="space-between">
          <ElementWrapper style={{ width: '48%' }}>
            <Typography
              fontWeight="400"
              text="Expiration date"
              fontSize="0.93rem"
              secondaryColorValue={800}
            />

            <div>
              <CardExpiryElement
                options={options}
                onChange={handleCardFieldChange}
              />
            </div>
          </ElementWrapper>

          <ElementWrapper style={{ width: '48%' }}>
            <Typography
              fontWeight="400"
              text="CVC"
              fontSize="0.93rem"
              secondaryColorValue={800}
            />

            <div>
              <CardCvcElement
                options={options}
                onChange={handleCardFieldChange}
              />
            </div>
          </ElementWrapper>
        </FlexBox>
      </div>

      {/* Address section */}

      {!forSampleOrder && (
        <>
          <div>
            <Typography
              text="Add your billing address"
              fontSize="0.8rem"
              fontWeight="800"
              secondaryColorValue={900}
              classname="sub-heading"
            />
            <AddressElement
              className="address"
              options={{
                mode: 'billing',
                fields: { phone: 'never' },
                display: { name: 'full' },
              }}
            />
          </div>
          <FlexBox gap="1rem">
            <Button
              label="Cancel"
              size="fullWidth"
              appearance="secondary"
              className="fs-14"
              onClick={onCloseModal}
            />
            <Button
              label="Confirm"
              size="fullWidth"
              className="fs-14"
              appearance="primary"
              onClick={handleClick}
            />
          </FlexBox>
        </>
      )}
    </StyleWrapper>
  );
};

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  .sub-heading {
    margin-bottom: 0.75rem;
  }
`;

export const ElementWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  > div {
    padding: 8px;
    background: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }
`;

export default CheckoutForm;

const options = {
  style: {
    base: {
      iconColor: '#8898AA',
      color: colors.grey[600],
      lineHeight: '24px',
      fontWeight: 300,
      fontSize: '13px',
      border: '1px solid',

      '::placeholder': {
        color: '#8898AA',
      },
    },
    invalid: {
      iconColor: '#e85746',
      color: '#e85746',
    },
  },
  showIcon: true,
  classes: {
    focus: 'is-focused',
    empty: 'is-empty',
  },
};
