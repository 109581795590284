import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../common/button';
import { colors } from '../../assets/colors';
import {
  CreateOrderAPIPayload,
  CreateOrderInitialState,
} from '../../interface/create-order-interface';
import { useAppSelector } from '../../store/hooks';
import { getCreateOrderDetails } from '../../store/slices/createOrderSlice';
import { useLazyCreateProductsQuery } from '../../store/services/orders';
import { useNavigate } from 'react-router-dom';
import Loader from '../common/loader';
import { getUserData } from '../../store/slices/authSlice';
import ConfirmationModal from '../common/confirmation-modal';

const CreateOrderFooter = () => {
  const { userInfo } = useAppSelector(getUserData);
  const [showModal, setShopModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createProduct, { isSuccess, isLoading }] =
    useLazyCreateProductsQuery();
  const createOrderData: CreateOrderInitialState = useAppSelector(
    getCreateOrderDetails,
  );
  const { quantity, skuId, selectedProductId, productColorID } =
    createOrderData.selectedProductDetails;

  const createOrder = (placeOrder: boolean) => {
    const payload: CreateOrderAPIPayload = {
      payload: {
        productId: selectedProductId,
        quantity,
        catalogSKUId: skuId as unknown as number,
        customer: createOrderData.recipient,
        shippingAddress: createOrderData.shippingAddress,
        billingAddress: createOrderData.billingAddress,
        productColorID,
        placeOrder,
      },
      storeId: userInfo.storeId,
    };
    createProduct(payload);
  };
  useEffect(() => {
    if (isSuccess) {
      navigate('/orders');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const isButtonDisabled = () => {
    const { billingAddress, shippingAddress } = createOrderData;
    if (
      quantity &&
      selectedProductId &&
      billingAddress.city &&
      billingAddress.country &&
      billingAddress.firstLine &&
      billingAddress.state &&
      billingAddress.zipCode &&
      shippingAddress.city &&
      shippingAddress.country &&
      shippingAddress.firstLine &&
      shippingAddress.state &&
      shippingAddress.zipCode
    ) {
      return false;
    }
    return true;
  };

  return (
    <StyledWrapper>
      {isLoading && <Loader />}
      {showModal && (
        <ConfirmationModal
          buttonColor="primary"
          buttonLabel="Place order"
          headingText="Order Confirmation"
          onClick={() => createOrder(true)}
          onClose={() => setShopModal(false)}
          text='Please ensure that the details provided are accurate, and then click on "Place Order."'
        />
      )}
      <Button
        label="Save for later"
        onClick={() => createOrder(false)}
        appearance="secondary"
        disabled={isButtonDisabled()}
        size="large"
      />
      <Button
        label="Place order"
        onClick={() => setShopModal(true)}
        appearance="primary"
        size="large"
        disabled={isButtonDisabled()}
      />
    </StyledWrapper>
  );
};

export default CreateOrderFooter;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  height: 68px;
  background-color: ${colors.white};
  button {
    width: 160px;
  }
  :last-child {
    padding: 0px 2rem;
  }
`;
