export const routeNames = {
  editor: '/editor',
  mockups: '/mockups',
  details: '/details',
  catalog: '/',
  myProducts: '/my-products',
  orders: '/orders',
  userSettings: '/user-settings',
  myAccount: '/user-settings/my-account',
  myLibrary: '/user-settings/my-library',
};
