import dayjs from 'dayjs';
import { GetOrdersResponseResults } from '../interface/orders-interface';
import { nanoid } from '@reduxjs/toolkit';
export const getOrdersRowData = (array: GetOrdersResponseResults[]) => {
  var rowData: any[] = array
    .filter((a) => a !== undefined)
    .map((orderObj) => {
      let productDetails: any = {};
      let imageUrls: string[] = [];
      if (orderObj.products.length > 0) {
        orderObj?.products?.forEach((product) => {
          if (product?.mockupURL) {
            imageUrls.push(product?.mockupURL);
          }
        });
      }
      return {
        id: nanoid(),
        order: `Order #${orderObj.orderId}  `,
        createdAt: dayjs(orderObj.createdAt).format('LLLL'),
        productionDate: orderObj.productionDate
          ? dayjs(orderObj.productionDate).format('LL')
          : 'Not sent',
        shippedOn: orderObj.shippedOn
          ? dayjs(orderObj.shippedOn).format('LLLL')
          : 'Not sent',
        customer: orderObj.customer.fullName,
        status: orderObj.status,
        orderId: orderObj.orderId,
        userId: orderObj.userId,
        storeId: orderObj.storeId,
        productionCost:
          orderObj?.productionCost?.total?.toFixed(2) ?? productDetails?.cost,
        productDetails: productDetails,
        imageUrls: imageUrls,
        isSampleOrder: orderObj.isSampleOrder,
      };
    });
  return rowData;
};
