import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Modal from './modal';
import { FlexBox } from './wrappers';
import Typography from '../typography';
import Button from './button';
import { colors } from '../../assets/colors';
interface Props {
  onClose: () => void;
  text: string;
  onClick: () => void;
  headingText: string;
  buttonLabel: string;
  buttonColor: 'red' | 'primary';
  children?: ReactNode;
  hideButtons?: boolean;
}
const ConfirmationModal: React.FC<Props> = ({
  onClose,
  text,
  onClick,
  headingText,
  buttonLabel,
  buttonColor,
  children,
  hideButtons,
}) => {
  return (
    <StyledWrapper>
      <Modal
        className="confirmation-modal"
        title={headingText}
        onClose={onClose}
        size="extraSmall"
      >
        <FlexBox direction="column" gap="1rem" className="w-100">
          <Typography
            className="confirmation-modal-heading"
            text={text}
            fontSize="14px"
          />
          {children && <>{children}</>}
          {!hideButtons && (
            <FlexBox
              gap="1rem"
              className="w-100 btn-wrapper"
              justifyContent="center"
            >
              <Button
                appearance="secondary"
                label="Cancel"
                onClick={onClose}
                size="fullWidth"
              />
              <Button
                appearance="primary"
                label={buttonLabel}
                onClick={() => {
                  onClick();
                  onClose();
                }}
                size="fullWidth"
                className={buttonColor === 'red' ? 'delete-btn' : ''}
              />
            </FlexBox>
          )}
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default ConfirmationModal;

const StyledWrapper = styled.div`
  .confirmation-modal {
    height: fit-content;
    padding-block: 5px;
  }
  .delete-btn {
    background-color: ${colors.red['900']};
  }
`;
