import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  childrenClasses?: string;
}
const Layout: React.FC<Props> = ({ children, childrenClasses }) => {
  return (
    <div className="w-full overflow-x-hidden px-4">
      <div
        style={{ overflow: 'hidden' }}
        className={`w-full  ${childrenClasses || 'pt-[30px] pb-[60px]'}`}
      >
        {children && children}
      </div>
    </div>
  );
};

export default Layout;
