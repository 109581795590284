interface DataProps {
  text: string;
  subText: string;
  value: string;
}
export const varientsInfo: Array<DataProps> = [
  {
    text: 'Show all variants as available',
    subText: 'Accept any order regardless of stock status',
    value: 'show_all',
  },
  {
    text: 'Show in stock variants as available',
    subText: 'Out of stock variants will show as “sold out”',
    value: 'show_in_stock',
  },
  {
    text: 'Only show in stock variants',
    subText: 'Out of stock variants will be hidden',
    value: 'only_show_in_stock',
  },
];

export const shippingPrice = 7;

export const extraUnitShipping = 2.5;

export const growthShippingPrice = 5.00;

export const extraUnitGrowthShippingPrice = 2;
