import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';
import {
  DropDownIcon,
  MockupImage1,
  MockupImage2,
  MockupImage3,
  MockupImage4,
} from '../../assets/icons';
import { Option } from '../../interface';
import Checkbox from '../checkbox/Checkbox';
import { FlexBox } from './wrappers';
import { generateDarkColor } from '../../helper/generateDarkColor';
import { ClickAwayListener } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useDebounce } from '../../hooks/useDebounce';
import { getFocus, setFocus } from '../../store/slices/editor';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const StyledTag = styled.span<any>`
  background-color: ${colors.grey[300]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 14px;
  color: ${colors.grey[800]};
  height: 24px;
  width: auto;
`;

export const CustomBtnWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #fafafa;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
  margin-top: 10px;
  cursor: pointer;
  p {
    margin: 0px 0px 0px 10px;
  }
`;

const SelectList: React.FC<{
  items: Option[];
  label?: string;
  onChange: (value: any, selectedOption: Option) => void;
  listType?: 'checkbox' | 'color' | 'color-item' | 'input';
  width?: string;
  selectedValue: any;
  className?: string;
  gapBetweenInputAndItems?: number;
  setSelectedFontSize?: (prevState: any) => void;
  addFontFamilyToLabel?: boolean;
}> = ({
  items,
  label,
  onChange,
  width,
  listType,
  selectedValue,
  className,
  gapBetweenInputAndItems = 5,
  setSelectedFontSize,
  addFontFamilyToLabel,
}) => {
  const isFocused = useAppSelector(getFocus);
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>(selectedValue);
  const debouncedValue = useDebounce(selectedValue, 2000);
  const handleItemClick = (value: string, selectedOption: Option) => {
    if (selectedItem === value && listType !== 'input') {
      setSelectedItem('');
      setIsOpen(true);
    } else {
      setSelectedItem(value);
      setIsOpen(false);
    }
    onChange(value, selectedOption);
  };

  useEffect(() => {
    if (selectedValue === '') {
      setSelectedItem('');
    } else {
      setSelectedItem(selectedValue);
    }
  }, [selectedValue]);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused, selectedValue, setSelectedFontSize]);
  const handleSizeChange = (e: any) => {
    const newSize = e.target.value;
    if (
      setSelectedFontSize !== undefined &&
      newSize !== '0' &&
      newSize !== '-' &&
      newSize !== '+'
    ) {
      setSelectedFontSize(newSize);
      setSelectedItem(newSize);
    }
    if (newSize.length === 0) {
      inputRef.current.focus();
    }
    dispatch(setFocus(true));
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Select
        className={`box select-list pointer-cursor ${className}`}
        width={width}
        onClick={(e) => {
          if (
            e.target instanceof HTMLElement &&
            e.target.tagName.toLowerCase() !== 'input'
          ) {
            setIsOpen(!isOpen);
          }
        }}
        isOpen={isOpen}
        onBlurCapture={() => setIsOpen(false)}
        gapBetweenInputAndItems={gapBetweenInputAndItems}
      >
        <div className="dropdown-header">
          <span className="text-wrap">
            {' '}
            {selectedItem ? (
              listType === 'color-item' ? (
                <FlexBox gap="6px">
                  <ColoredCircle colorCode={selectedItem} />
                  {selectedItem.includes('/')
                    ? items.find((item) => item.label === selectedItem)?.label
                    : items.find((item) => item.value === selectedItem)?.label}
                </FlexBox>
              ) : listType === 'input' ? (
                <input
                  type="number"
                  placeholder={label?.toString()}
                  onChange={(e) => handleSizeChange(e)}
                  className="inputtxt"
                  ref={inputRef}
                  value={debouncedValue}
                  autoFocus={false}
                  onBlur={() => {
                    dispatch(setFocus(false));
                    if (selectedItem.length === 0) {
                      inputRef.current.focus();
                    }
                  }}
                />
              ) : (
                items.find((item) => item.value === selectedItem)?.label
              )
            ) : listType === 'input' ? (
              <input
                type="number"
                placeholder={label?.toString()}
                onChange={(e) => handleSizeChange(e)}
                className="inputtxt"
                ref={inputRef}
                value={selectedItem}
                onBlur={() => {
                  if (selectedItem.length === 0) {
                    inputRef.current.focus();
                  }
                }}
              />
            ) : (
              label
            )}
          </span>
          <img src={DropDownIcon} alt="Drop Down" />
        </div>
        <div
          className={`dropdown-body  box
       show-no-scrollbar ${isOpen ? 'open' : ''}`}
        >
          {items.map((item) => (
            <div
              key={nanoid()}
              className={`dropdown-item pointer-cursor ${
                selectedItem === item.value ? 'active-item' : 'normal-item'
              } ${listType ?? ''}`}
              onClick={() =>
                listType !== 'checkbox'
                  ? handleItemClick(
                      item.label.includes('/') ? item.label : item.value,
                      item,
                    )
                  : ''
              }
            >
              {listType === 'checkbox' ? (
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleItemClick(
                      item.label.includes('/') ? item.label : item.value,
                      item,
                    )
                  }
                  checked={selectedItem === item.value}
                  label={item.label}
                  name={label}
                />
              ) : listType === 'color-item' ? (
                <FlexBox gap="6px" alignItems="center">
                  <ColoredCircle
                    colorCode={
                      item.label.includes('/') ? item.label : item.value
                    }
                    width={13}
                    height={13}
                  />
                  <span className="text-wrap">{item.label}</span>
                </FlexBox>
              ) : listType === 'color' ? (
                <FlexBox gap="8px" alignItems="center">
                  <ListColorMark colorCode={item.label} />
                  {item.label}
                </FlexBox>
              ) : (
                <span
                  style={{
                    fontFamily: addFontFamilyToLabel ? `${item.value}` : '',
                  }}
                >
                  {item.label}{' '}
                </span>
              )}
            </div>
          ))}
        </div>
      </Select>
    </ClickAwayListener>
  );
};

const Select = styled.div<{
  isOpen: boolean;
  width?: string;
  gapBetweenInputAndItems: number;
}>`
  width: ${(props) => (props.width ? props.width : 'max-content')};
  max-width: ${(props) => (props.width ? props.width : 'max-content')};
  padding: 11px;
  min-width: 40px;
  position: relative;
  span {
    font-size: 12px;
  }
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }

  .inputtxt {
    border: none;
  }

  .inputtxt:focus {
    border: none;
    outline: none;
  }

  .dropdown-body {
    border-top: 1px solid #e5e8ec;
    display: none;
    background: ${colors.white};
    &.open {
      display: block;
      position: absolute;
      z-index: 1;
      top: ${(props) =>
        props.isOpen
          ? document.getElementsByClassName('select-list')?.[0]?.clientHeight +
              props.gapBetweenInputAndItems ?? 5
          : 0}px;
      left: 0px;
      width: ${(props) => (props.width ? props.width : 'max-content')};
      max-width: ${(props) => (props.width ? props.width : 'max-content')};
      font-size: 12px;
      max-height: 200px;
    }
  }
  .active-item {
    background-color: ${colors.grey[300]};
  }

  .dropdown-item {
    padding: 10px;
    &.color-item {
      padding: 5px 6px 5px 8px;
      border-bottom: 1px solid ${colors.grey[300]};
      background-color: ${colors.white};
      :last-child {
        border-bottom: none;
      }
    }
  }
`;
const ListColorMark = styled.div<{
  colorCode: string;
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.colorCode === 'Published'
      ? colors.green['300']
      : props.colorCode === 'Hidden'
      ? colors.red['300']
      : props.colorCode === 'Unpublished changes'
      ? colors.yellow
      : colors.grey['300']};
`;

export const ColoredCircle = styled.div<{
  colorCode: string;
  height?: number;
  width?: number;
}>`
  height: ${(props) => (props.height ? props.height : 14)}px;
  width: ${(props) => (props.width ? props.width : 14)}px;
  border-radius: 50%;
  ${({ colorCode }) =>
    colorCode.includes('/')
      ? css`
          background: linear-gradient(
            to right,
            ${colorCode.split('/')[0].toLocaleLowerCase().replace(/\s/g, '')}
              50%,
            ${colorCode.split('/')[1].toLocaleLowerCase().replace(/\s/g, '')}
              50%
          );
        `
      : css`
          background-color: ${colorCode};
        `}
  border: ${(props) => `1px solid ${generateDarkColor(props.colorCode)}`};
  flex-basis: ${(props) => (props.width ? props.width : 14)}px;
  flex-shrink: 0;
  // margin-right: 4px;
`;
export { SelectList };

export const fonts: Option[] = [
  {
    label: 'Font a',
    value: 'a',
  },
  {
    label: 'Font a',
    value: 'a',
  },
  {
    label: 'Font a',
    value: 'a',
  },
  {
    label: 'Font a',
    value: 'a',
  },
];

export const IMAGE_OPTIONS: Option[] = [
  {
    label: 'Apple',
    value: 'apple',
  },
  {
    label: 'Banana',
    value: 'banana',
  },
  {
    label: 'Bee',
    value: 'bee',
  },
  {
    label: 'Butterfly',
    value: 'butterfly',
  },
];

export const GENERATED_MOCKUPS = [
  {
    id: 1,
    label: 'Mockup Image 1',
    image: MockupImage1,
    selected: false,
  },
  {
    id: 2,
    label: 'Mockup Image 2',
    image: MockupImage2,
    selected: false,
  },
  {
    id: 3,
    label: 'Mockup Image 3',
    image: MockupImage3,
    selected: false,
  },
  {
    id: 4,
    label: 'Mockup Image 4',
    image: MockupImage4,
    selected: false,
  },
  {
    id: 5,
    label: 'Mockup Image 4',
    image: MockupImage4,
    selected: false,
  },
  {
    id: 6,
    label: 'Mockup Image 4',
    image: MockupImage4,
    selected: false,
  },
];

export const COLOR_OPTIONS = [
  colors.grey[300],
  '#47A188',
  '#B675B9',
  '#3B9AA8',
  '#DFC98E',
  '#F8CDE0',
  '#F8CDE0',
  '#F077A5',
  '#8382B3',
  '#1F2311',
  '#44484B',
  '#F87368',
  '#C8102E',
  '#FFFFFF',
];

export const StyledInput = styled.div<{ isLeft?: boolean }>`
  position: relative;
  input {
    width: 100%;
    border-radius: 6px;
    margin: ${(props) => (props.isLeft ? '13px 5px 6px 0px' : '0 auto')};
    outline: none;
    padding: ${(props) =>
      props.isLeft ? '4px 4px 4px 45px !important' : '6px 12px;'};
    box-sizing: border-box;
    background: #ffffff !important;
    color: ${colors.grey[600]};
    font-size: 14px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.08);
    border: none;
  }
  span {
    position: absolute;
    left: ${(props) => (props.isLeft ? '0' : 'auto')};
    right: 0;
    top: ${(props) => (props.isLeft ? '13px' : '0')};
    bottom: ${(props) => (props.isLeft ? '6px' : '0')};
    background-color: ${colors.grey[200]};
    color: #fff;
    padding: ${(props) => (props.isLeft ? '4px' : '6px')};
    border-radius: ${(props) =>
      props.isLeft ? '6px 0px 0px 6px' : ' 0px 6px 6px 0'};
    border-right: ${(props) => props.isLeft && `1px solid ${colors.grey[300]}`};
    border-left: ${(props) => !props.isLeft && `1px solid ${colors.grey[300]}`};
    color: ${colors.grey[500]};
    font-size: 14px;
    width: 24px;
    text-align: center;
  }
`;
