import { Canvas } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
export const applyMugCanvas = (
  canvas: Canvas,
  data: any,
  boundaryDiv: HTMLDivElement,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  boundaryDiv.style.boxShadow = 'inset 0 0 0 20px #F3F9FF';
  boundaryDiv.style.top = '0';
  boundaryDiv.style.left = '0';
  boundaryDiv.style.width = `${canvasWidth}px`;
  boundaryDiv.style.height = `${canvasHeight}px`;
  boundaryDiv.className = 'editor-design-inactive-area';

  const marginPercentageHeight = 0.05;
  const marginPercentageWidth = 0.025;
  const centralBoxHeight = canvasHeight * (1 - 2 * marginPercentageHeight);
  const centralBoxWidth = canvasWidth * (1 - 2 * marginPercentageWidth);
  var centralBox = new fabric.Rect({
    left: canvasWidth * marginPercentageWidth,
    top: canvasHeight * marginPercentageHeight,
    width: centralBoxWidth,
    height: centralBoxHeight,
    fill: 'white',
    selectable: false,
    name: 'border-canvas',
    stroke: 'black',
    strokeWidth: 1,
    strokeDashArray: [10, 10],
    evented: false,
  });
  canvas.add(centralBox);
  canvas.sendToBack(centralBox);
  canvas.requestRenderAll();
};
