import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';

interface Props extends React.InputHTMLAttributes<HTMLParagraphElement> {
  fontSize: string;
  fontWeight?: string;
  secondaryColorValue?: keyof typeof colors.grey;
  primaryColorValue?: keyof typeof colors.blue;
  text?: string | number | ReactNode;
  texTransform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  classname?: string;
  ellipsisText?: boolean;
  id?: string;
}
const Typography: React.FC<Props> = ({
  text,
  fontSize,
  fontWeight,
  secondaryColorValue,
  primaryColorValue,
  texTransform,
  ellipsisText,
  classname,
  ...props
}) => {
  return (
    <StyledWrapper
      className={classname}
      fontSize={fontSize}
      fontWeight={fontWeight}
      secondaryColorValue={secondaryColorValue}
      primaryColorValue={primaryColorValue}
      texTransform={texTransform}
      ellipsisText={ellipsisText}
      {...props}
    >
      {text ?? '---'}
    </StyledWrapper>
  );
};

export default Typography;
const StyledWrapper = styled.span<{
  fontSize: string;
  fontWeight?: string;
  texTransform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  secondaryColorValue?: keyof typeof colors.grey;
  primaryColorValue?: keyof typeof colors.blue;
  ellipsisText?: boolean;
}>`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  color: ${(props) =>
    props.secondaryColorValue
      ? colors.grey[props.secondaryColorValue ?? 900]
      : colors.grey[900]};
  color: ${(props) =>
    props.primaryColorValue && colors.blue[props.primaryColorValue ?? 900]};
  ${(props) =>
    props.texTransform &&
    css`
      text-transform: ${props.texTransform};
    `}
  ${(props) =>
    props.ellipsisText &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
