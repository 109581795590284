import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { Divider } from '../../order-details/details/billing';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import Button from '../../common/button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getCreateOrderDetails,
  setModalStatus,
} from '../../../store/slices/createOrderSlice';
import { CreateorderModals } from '../../../interface/create-order-interface';

const RecipientSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    recipient: { fullName, email, phone },
  } = useAppSelector(getCreateOrderDetails);
  return (
    <StyledWrapper>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading"
      >
        <Typography text="Recipient" fontSize="18px" />
        <Button
          label="Edit"
          appearance="secondary"
          onClick={() =>
            dispatch(setModalStatus(CreateorderModals.RecipientModal))
          }
        />
      </FlexBox>
      <Divider />
      <FlexBox direction="column" gap="12px" className="recipient-info">
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Name" fontSize="14px" />
          <Typography text={fullName} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Email" fontSize="14px" />
          <Typography text={email} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Phone" fontSize="14px" />
          <Typography text={phone} fontSize="14px" />
        </FlexBox>
      </FlexBox>
    </StyledWrapper>
  );
};

export default RecipientSection;

const StyledWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.border};
  .heading,
  .recipient-info {
    padding: 16px;
  }
`;
