import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  darkColor?: string;
}

const TickMarkComponent: React.FC<Props> = ({ darkColor, ...props }) => {
  return (
    <svg
      {...props}
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58337 5.41667L5.33337 9.16667L13.25 1.25"
        stroke={darkColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickMarkComponent;
