import React from 'react';
import { FlexBox } from '../../common/wrappers';
import { Drawer } from '@mui/material';
import IconsCard from '../../common/icons-card';
import { ReactComponent as CloseIcon } from '../../../assets/images/cross.svg';
interface Props {
  keyboardModal: any;
  setKeyboardModal: any;
}
const KeyboardShortcutModal: React.FC<Props> = ({
  keyboardModal,
  setKeyboardModal,
}) => {
  const isWindows = navigator.userAgent.includes('Windows');
  return (
    <Drawer
      open={keyboardModal}
      anchor={'bottom'}
      PaperProps={{
        sx: {
          boxShadow: ' 0px -8px 32px -24px rgba(0, 0, 0, 0.44)',
          width: '45vw',
          borderRadius: '3px',
          padding: '10px',
          margin: '0 auto',
        },
      }}
      ModalProps={{
        disableEnforceFocus: true,
        disableAutoFocus: true,
        onBackdropClick: () => setKeyboardModal(false),
      }}
    >
      <FlexBox justifyContent="space-between" direction="column">
        <FlexBox justifyContent="space-between" alignItems="center">
          <span style={{ fontSize: '18px', padding: '8px' }}>
            Keyboard Shortcuts
          </span>
          <CloseIcon
            className="pointer-cursor"
            onClick={() => setKeyboardModal(false)}
          />
        </FlexBox>
        <span
          style={{ fontSize: '14px', color: '#646566', padding: '0 8px 8px' }}
        >
          List of shortcuts to speed up your work
        </span>
        <FlexBox
          className="buttons"
          gap="1rem"
          direction="column"
          style={{ padding: '0 8px' }}
        >
          <IconsCard title={'Move'} icons={['←', '→', '↑', '↓']} />
          <IconsCard
            title={'Larger Movements'}
            icons={['Shift', '←', '→', '↑', '↓']}
          />
          <IconsCard
            title={'Copy the selected element'}
            icons={[isWindows ? 'Ctrl' : 'Cmd', 'C']}
          />
          <IconsCard
            title={'Paste the selected element'}
            icons={[isWindows ? 'Ctrl' : 'Cmd', 'V']}
          />
          <IconsCard
            title={'Undo an action'}
            icons={[isWindows ? 'Ctrl' : 'Cmd', 'Z']}
          />
          <IconsCard
            title={'Redo an action'}
            icons={[!isWindows && 'Shift', isWindows ? 'Ctrl' : 'Cmd', 'Y']}
          />
          <IconsCard title={'Delete'} icons={['Delete']} />
          <IconsCard title={'Unselect the element'} icons={['Esc']} />
        </FlexBox>
      </FlexBox>
    </Drawer>
  );
};
export default KeyboardShortcutModal;
