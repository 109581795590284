import { SingleProductVariant } from '../interface/product-interface';

export const getMinimumAndMaximumMRSPFromVariants = (
  variantsData: { mrsp: number }[],
) => {
  const values = variantsData.map((variant) => variant.mrsp);

  return {
    min: Math.min(...values.flat()),
    max: Math.max(...values.flat()),
  };
};

export const getUniqueColorsOrColorHexFromVariants = (
  variants: SingleProductVariant[],
  isColorCodes?: boolean,
) => {
  return Array.from(
    new Set(
      variants.flatMap((variant) =>
        variant.properties
          .filter((prop) => prop.propertyName === 'color')
          .map((color) =>
            isColorCodes ? color.meta?.colorHex : color.propertyValue,
          ),
      ),
    ),
  );
};

export const getUniqueColorsAndHexFromVariants = (
  variants: SingleProductVariant[],
): { label: string; colorHex: string }[] => {
  const colorMap: { [key: string]: string } = {};
  const colorArray: { label: string; colorHex: string }[] = [];

  for (const variant of variants) {
    for (const prop of variant.properties) {
      if (prop.propertyName === 'color' && prop.meta?.colorHex) {
        if (!colorMap[prop.propertyValue]) {
          colorMap[prop.propertyValue] = prop.meta.colorHex;
          colorArray.push({
            label: prop.propertyValue,
            colorHex: prop.meta.colorHex,
          });
        }
      }
    }
  }

  return colorArray;
};

export const getUniqueSizesFromVariants = (
  variants: SingleProductVariant[],
) => {
  return Array.from(
    new Set(
      variants.flatMap((variant) =>
        variant.properties
          .filter((prop) => prop.propertyName === 'size')
          .map((color) => color.propertyValue),
      ),
    ),
  );
};
