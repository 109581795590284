import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

interface Props {
  appearance: 'primary' | 'secondary';
  onClick: () => void;
  icon: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  className?: string;
}

const IconButton: React.FC<Props> = ({
  onClick,
  appearance,
  disabled,
  icon,
  height,
  width,
  className,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      appearance={appearance}
      disabled={disabled}
      width={width}
      height={height}
      className={className}
    >
      <img width="20" src={icon} alt="icon" />
    </StyledButton>
  );
};

export default IconButton;

const StyledButton = styled.button<{
  appearance: string;
  disabled?: boolean;
  height?: string;
  width?: string;
}>`
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '36px')};
  height: ${(props) => (props.height ? props.height : '36px')};
  padding: 8px;
  gap: 0.5rem;
  color: ${(props) =>
    props.appearance === 'primary' ? 'white' : colors.grey[800]};
  background-color: ${(props) =>
    props.appearance === 'primary' ? colors.blue[800] : 'white'};
  border: 1px solid ${colors.grey['border']};
  box-shadow: ${(props) =>
    props.appearance === 'secondary' && colors.shadows.elevation1};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
