import { Link } from 'react-router-dom';

interface Object {
  name: string | undefined;
  path: string;
}
interface Props {
  paths?: Object[];
}
const Breadcrumb: React.FC<Props> = ({
  paths = [{ name: 'Catalog', path: '/' }],
}) => {
  return (
    <>
      {paths && paths.length > 0 && (
        <div className="breadcrumb-wrapper font-400 text-[13px] text-qblack lg:mb-[23px] md:mb-[13px]">
          {paths.map((path) => (
            <span key={path.name} className="fs-18">
              <Link to={path.path}>
                <span className="mx-1 capitalize">{path.name}</span>
              </Link>
              <span className="sperator">/</span>
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
