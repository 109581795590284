import React from 'react';
import styled from 'styled-components';
import Sidebar from '.';

const Layout: React.FC<{
  isExpanded: boolean;
  toggleSidebar: () => void;
  children: any;
}> = ({ children, isExpanded, toggleSidebar }) => {
  return (
    <>
      <Sidebar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />

      <StyledWrapper isExpanded={isExpanded}>{children}</StyledWrapper>
    </>
  );
};

export default Layout;

const StyledWrapper = styled.div<{ isExpanded: boolean }>`
  margin-left: ${({ isExpanded }) => (isExpanded ? '256px' : '76px')};
  transition: margin-left 0.3s ease;
  .sample-button {
    margin-left: ${({ isExpanded }) =>
      isExpanded ? 'calc(90vw - 263px)' : 'calc(90vw - 83px)'};
    position: absolute;
    margin-top: 20px;
  }
`;
