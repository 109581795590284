export const getMinimumAndMaximumCostFromVariants = (variants: any) => {
  if (variants) {
    const costs = variants.map((it: any) =>
      it.skus.map((sku: any) => parseFloat(sku.cost)),
    );
    const flattened = costs.flat();
    return {
      min: Math.min(...flattened.flat()),
      max: Math.max(...flattened.flat()),
    };
  } else {
    return {
      min: 0,
      max: 0,
    };
  }
};

export interface GroupedData {
  color: string;
  colorHex: string;
  imageUrl: string;
  size: number;
  inventory: string;
  price: string | number;
  everbeeGrowthPlanPrice: string;
  id: string;
}
