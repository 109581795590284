import React, { useEffect, useMemo, useState } from 'react';
import Header from './header';
import styled from 'styled-components';
import MyProductsTable from './my-product-table';
import { useGetMyProductsQuery } from '../../store/services/products';
import { getCutomizedMyProductsResponse } from '../../helper/getCutomizedMyProductsResponse';
import { useDebounce } from '../../hooks/useDebounce';
import { Option, ProductsFilters } from '../../interface';
import Loader from '../common/loader';
import { TabletProps } from '../../interface';

const MyProducts: React.FC<TabletProps> = ({ isTablet }) => {
  const [selectBrand, setSelectBrand] = useState<Option[]>([]);
  const [brandsList, setBrandsList] = useState<Option[]>([]);
  const [filters, setFilters] = useState<ProductsFilters>({
    search: '',
    sortBy: {
      field: 'updatedAt',
      sort: 'desc',
    },
    status: '',
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const debouncedValue = useDebounce(filters.search, 500);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedValue, selectBrand]);

  const {
    data: myProducts,
    isFetching,
    refetch,
  } = useGetMyProductsQuery({
    search: debouncedValue,
    brands: selectBrand.map((brand) => {
      if (brand.value.includes('+')) {
        return brand.value.replace('+', '%2B');
      } else return brand.value;
    }),
    status: filters.status,
    sortBy: filters.sortBy,
    page: currentPage,
  });

  const rowData = useMemo(() => {
    var brandInfo: Option[] = [];
    myProducts?.brands.forEach((brand) => {
      brandInfo.push({ label: brand, value: brand });
    });
    setTotalPages(myProducts?.totalPages ?? 1);
    setBrandsList(brandInfo);
    return getCutomizedMyProductsResponse(myProducts?.results || []);
  }, [myProducts]);

  return (
    <StyleWrapper className="show-no-scrollbar">
      {isFetching && <Loader />}
      <Header
        filters={filters}
        selectBrands={selectBrand}
        setSelectBrands={setSelectBrand}
        setFilters={setFilters}
        brandsList={brandsList}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        refetchProduct={refetch}
        rowData={rowData}
      />
      <MyProductsTable
        rowData={rowData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        refetchProduct={refetch}
        isTablet={isTablet}
      />
    </StyleWrapper>
  );
};

export default MyProducts;

const StyleWrapper = styled.div`
  padding: 33px 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
