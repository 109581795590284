import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import Typography from '../../../typography';
import { FlexBox } from '../../../common/wrappers';
interface Props {
  displayName: string;
  displayData: string;
}
const ChartDataItem: React.FC<Props> = ({ displayName, displayData }) => {
  return (
    <StyledWrapper displayName={displayName}>
      <div className="dot"></div>
      <FlexBox gap="2px" direction="column" className="data-display">
        <Typography text={displayName} fontSize="14px" />
        <Typography
          text={displayData.includes('undefined') ? '---' : displayData}
          fontSize="12px"
          secondaryColorValue={600}
        />
      </FlexBox>
    </StyledWrapper>
  );
};

export default ChartDataItem;
const StyledWrapper = styled.div<{ displayName: string }>`
  width: 9.5rem;
  display: flex;
  margin-left: 2rem;

  .dot {
    height: 8px;
    width: 8px;
    margin-top: 7px;
    margin-right: 6px;
    border-radius: 50%;

    background-color: ${(props) =>
      props.displayName === 'Net profit'
        ? colors.blue[900]
        : props.displayName === 'Shipping cost'
        ? colors.blue[300]
        : props.displayName === 'COGS'
        ? colors.blue[600]
        : colors.grey[300]};
  }

  .data-display span {
    display: block;
  }
`;
