import React from 'react';
import Modal from '../common/modal';
import { FlexBox } from '../common/wrappers';

import Typography from '../typography';
import { GreenTickIcon } from '../../assets/icons';

interface SuccessSampleOrderModalProps {
  onClose: () => void;
}

const SuccessSampleOrderModal: React.FC<SuccessSampleOrderModalProps> = ({
  onClose,
}) => {
  return (
    <div>
      <Modal
        title={''}
        size="small"
        onClose={onClose}
        className="success-sample-order-modal"
      >
        <FlexBox
          direction="column"
          gap="1rem"
          className="mainsection"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '18%' }}
        >
          <img src={GreenTickIcon} alt="success" />
          <Typography
            fontSize="20px"
            text="Thank You!"
            secondaryColorValue={900}
            fontWeight="900"
          />
          <Typography fontSize="14px" text="We have received your order" />
        </FlexBox>
      </Modal>
    </div>
  );
};

export default SuccessSampleOrderModal;
