import React from 'react';
import Orders from '../../components/orders';
import { TabletProps } from '../../interface';
import BaseLayout from '../../components/layout/base-layout';
const OrdersPage: React.FC<TabletProps> = ({ isTablet }) => {
  return (
    <BaseLayout>
      <Orders isTablet={isTablet} />
    </BaseLayout>
  );
};

export default OrdersPage;
