import axios from "axios";

export const convertB64toBlob = (
  b64Array: any[],
  contentType: string = 'image/png',
  sliceSize: number = 512,
) => {
  const blobArray: any[] = [];
  b64Array.forEach((b64Data: any) => {
    const byteCharacters = atob(b64Data.mockupImage.split('base64,')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });

    blobArray.push(blob);
  });

  return blobArray;
};

// Create a function to convert Blob to Base64
export const blobToBase64 = (blob: Blob | any, callBack: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => {
    callBack(reader.result);
  };
};

export const getImageBlobFromURL = async (imageUrl: string) => {
  const imgResponse = await axios.get(imageUrl, {
    responseType: 'blob',
  });
  return imgResponse.data;
}
