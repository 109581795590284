import React from 'react';
import styled from 'styled-components';
interface Props {
  onChange: (e: boolean) => void;
  checked: boolean;
  id: string;
  name?: string;
}
const ToggleSwitch: React.FC<Props> = ({ onChange, checked, id }) => {
  return (
    <ToggleSection>
      <label className="switch" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          name={id ? id : 'checkbox'}
          checked={checked}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <div className="slider round"></div>
      </label>
    </ToggleSection>
  );
};

export default ToggleSwitch;

const ToggleSection = styled.div`
  .switch {
    display: inline-block;
    height: 20px;
    position: relative;
    width: 36px;
    input {
      display: none;
    }
  }

  .slider {
    background-color: #ebebeb;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    &:before {
      background-color: #fff;
      bottom: 2px;
      content: '';
      height: 16px;
      left: 2px;
      position: absolute;
      transition: 0.4s;
      width: 16px;
    }
  }

  input:checked + .slider {
    background-color: #55a4f2;
  }

  input:checked + .slider:before {
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
