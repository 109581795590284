import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchBar from '../../common/search-bar';
import { FlexBox } from '../../common/wrappers';
import Button from '../../common/button';
import Modal from '../../common/modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getDetails,
  updateEditField,
  updateVariantsRowData,
} from '../../../store/slices/detailsSlice';
import { colors } from '../../../assets/colors';
interface Prices {
  cost: number;
  id: number;
}
interface UpdatedPrice {
  id: string;
  newValue: number;
}
const EditModel = () => {
  const [prices, setPrices] = useState<Prices[]>([]);
  const [updatedMrsp, setUpdatedMrsp] = useState<UpdatedPrice[]>([]);
  const [value, setValue] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { productDetails, editField, selectedRows } =
    useAppSelector(getDetails);

  useEffect(() => {
    const newPrices: Prices[] = [];
    selectedRows.forEach((id: any) => {
      productDetails.variants.forEach((variant) => {
        if (variant.id === id) {
          newPrices.push({
            id,
            cost: variant.cost,
          });
        }
      });

      setPrices(newPrices);
    });
  }, [selectedRows, productDetails.variants]);

  useEffect(() => {
    const newUpdatedValues: UpdatedPrice[] = [];
    selectedRows.forEach((id: any) => {
      prices.forEach((originalPriceInfo) => {
        if (id === originalPriceInfo.id) {
          let mrsp;
          if (editField === 'profit') {
            mrsp = originalPriceInfo.cost + value;
          } else if (editField === 'margin') {
            mrsp = originalPriceInfo.cost * (1 + value / 100);
          } else if (editField === 'mrsp') {
            mrsp = value;
          }
          if (mrsp) {
            newUpdatedValues.push({
              id,
              newValue: parseFloat(mrsp.toFixed(2)),
            });
          }
        }
      });
    });
    setUpdatedMrsp(newUpdatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, value]);

  return (
    <StyledWrapper>
      <Modal
        title={
          editField === 'profit'
            ? 'Edit profit'
            : editField === 'margin'
            ? 'Edit profit margin'
            : 'Edit price'
        }
        onClose={() => dispatch(updateEditField({ fieldName: 'none' }))}
        size="extraSmall"
        className="edit-modal"
      >
        <p className="sub-text">
          {editField === 'profit'
            ? `This will update profit for ${selectedRows.length} variants`
            : editField === 'margin'
            ? `This will update profit margin for ${selectedRows.length} variants`
            : `This will update price for ${selectedRows.length} variants`}
        </p>
        <FlexBox direction="column" gap="8px" className="input-wrapper">
          <p className="sub-text">
            {editField === 'profit'
              ? 'Profit'
              : editField === 'margin'
              ? 'Profit margin'
              : 'Price'}
          </p>
          <SearchBar
            value={value.toString()}
            className="edit-input"
            type="number"
            onChange={(newValue) => setValue(Number(newValue))}
            addStaticText={editField === 'margin' ? '%' : 'USD'}
          />
        </FlexBox>
        <FlexBox gap="1rem" className="model-footer">
          <Button
            label="Cancel"
            onClick={() => dispatch(updateEditField({ fieldName: 'none' }))}
            appearance="secondary"
            size="fullWidth"
            height="36px"
          />
          <Button
            label="Apply"
            disabled={editField === 'mrsp' && !value}
            onClick={() => {
              dispatch(updateVariantsRowData(updatedMrsp));
            }}
            appearance="primary"
            size="fullWidth"
            height="36px"
          />
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default EditModel;

const StyledWrapper = styled.div`
  .edit-modal {
    padding: 24px;
    border-radius: 16px;
    height: max-content;
    .modal-header {
      font-size: 18px;
    }
    p {
      margin: 0;
    }
    .sub-text {
      color: ${colors.grey[900]};
      font-size: 14px;
    }
    .input-wrapper {
      width: 100%;
      padding-block: 24px;
      /* to hide arrows in input field */
    }
    .edit-input input {
      padding: 10px 12px;
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .model-footer {
      width: 100%;
    }
  }
`;
