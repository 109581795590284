import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import Button from '../../common/button';
import { Divider } from '../../order-details/details/billing';
import { colors } from '../../../assets/colors';

interface Props {
  title: string;
  onClick: () => void;
  country: string;
  state: string;
  address: string;
  city: string;
  zipCode: string;
  width?: string;
  className?: string;
  firstLine: string;
  externalOrderId?: string;
}
const AddressLayout: React.FC<Props> = ({
  title,
  onClick,
  address,
  city,
  country,
  state,
  zipCode,
  width,
  className,
  firstLine,
  externalOrderId,
}) => {
  return (
    <StyledWrapper width={width} className={className}>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading"
      >
        <Typography text={title} fontSize="18px" />
        {!externalOrderId && (
          <Button
            label="Edit"
            appearance="secondary"
            onClick={onClick}
            size="medium"
            // disabled={true}
          />
        )}
      </FlexBox>
      <Divider />
      <FlexBox direction="column" gap="12px" className="address-info">
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Country" fontSize="14px" />
          <Typography text={country} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="State" fontSize="14px" />
          <Typography text={state} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Street Address" fontSize="14px" />
          <Typography text={firstLine} fontSize="14px" />
        </FlexBox>
        {/* <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="Address" fontSize="14px" />
          <Typography text={address} fontSize="14px" />
        </FlexBox> */}
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="City" fontSize="14px" />
          <Typography text={city} fontSize="14px" />
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography text="ZIP code" fontSize="14px" />
          <Typography text={zipCode} fontSize="14px" />
        </FlexBox>
      </FlexBox>
    </StyledWrapper>
  );
};

export default AddressLayout;

const StyledWrapper = styled.div<{ width?: string }>`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.border};
  width: ${(props) => (props.width ? props.width : '50%')};
  .heading,
  .address-info {
    padding: 16px;
  }
`;
