import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query';
import { RootState } from '.';

const mockupServerUrl = 'https://mockup-print-a5db5cb43e90.herokuapp.com/image_posts';

export const getAPIBaseURL = () => {
  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:8080/v1/';
  } else {
    return process.env.REACT_APP_BACKEND_URL;
  }
};

export const baseQueryWithAuth = retry(fetchBaseQuery({
  baseUrl: getAPIBaseURL(),
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
}), {maxRetries: 1});

export const baseQueryWithOutAuth = fetchBaseQuery({
  baseUrl: getAPIBaseURL(),
});

export const mockupServerBaseQuery = fetchBaseQuery({
  baseUrl: mockupServerUrl,
});
