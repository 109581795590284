import { useEffect } from 'react';
import SingleProduct from '../../components/catalog/single-product';
import BaseLayout from '../../components/layout/base-layout';

const SingleProductPage = () => {

  useEffect(() => {
    fetch('/tailwind-latest-details.css')
      .then(response => response.text())
      .then(cssContent => {
        const style = document.createElement('style');
        style.id = 'my-component-style';
        style.innerHTML = cssContent;
        document.head.appendChild(style);
      });

    return () => {
      const styleElement = document.getElementById('my-component-style');
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);

  return (
    <BaseLayout>
      <SingleProduct />
    </BaseLayout>
  );
};

export default SingleProductPage;
