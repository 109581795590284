import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import Input from '../../inputs';
import Button from '../../common/button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getCreateOrderDetails,
  updateSameAsShippingAddress,
} from '../../../store/slices/createOrderSlice';
import {
  Address,
  CreateorderModals,
} from '../../../interface/create-order-interface';
interface Props {
  title: string;
  country: string;
  state: string;
  address: string;
  firstLine: string;
  city: string;
  zipCode: string;
  onCloseModal: () => void;
  onSave: (addressInfo: Address) => void;
}
const AddressModal: React.FC<Props> = ({
  title,
  city,
  country,
  state,
  address,
  zipCode,
  firstLine,
  onCloseModal,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const { createOrderModal, sameAsShippingAddress, billingAddress } =
    useAppSelector(getCreateOrderDetails);
  const [addressInfo, setAdressInfo] = useState<Address>({
    country,
    state,
    address,
    city,
    zipCode,
    firstLine,
  });
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAdressInfo({ ...addressInfo, [name]: value });
  };
  useEffect(() => {
    if (createOrderModal === CreateorderModals.BillingAddressModal) {
      setAdressInfo({
        ...billingAddress,
      });
    }
  }, [createOrderModal, billingAddress]);
  return (
    <StyledWrapper>
      <Modal
        title={title}
        onClose={onCloseModal}
        className="address-modal"
        showCheckbox={
          createOrderModal === CreateorderModals.BillingAddressModal
        }
        checked={sameAsShippingAddress}
        checkboxHandler={(value) =>
          dispatch(
            updateSameAsShippingAddress({ sameAsShippingAddress: value }),
          )
        }
        checkboxLabel="Same as shipping address"
      >
        <FlexBox direction="column" gap="12px">
          <FlexBox direction="column" gap="6px">
            <Typography text="Street Address" fontSize="14px" />
            <Input
              onChange={changeHandler}
              value={addressInfo.firstLine}
              name="firstLine"
            />
          </FlexBox>
          {/* <FlexBox direction="column" gap="6px">
            <Typography text="Address" fontSize="14px" />
            <Input
              onChange={changeHandler}
              value={addressInfo.address}
              name="address"
            />
          </FlexBox> */}

          <FlexBox gap="1rem" className="w-100">
            <FlexBox direction="column" gap="6px" className="w-100">
              <Typography text="City" fontSize="14px" />
              <Input
                onChange={changeHandler}
                value={addressInfo.city}
                name="city"
              />
            </FlexBox>
            <FlexBox direction="column" gap="6px" className="w-100">
              <Typography text="State" fontSize="14px" />
              <Input
                onChange={changeHandler}
                value={addressInfo.state}
                name="state"
              />
            </FlexBox>
          </FlexBox>
          <FlexBox gap="1rem" className="w-100">
            <FlexBox direction="column" gap="6px" className="w-100">
              <Typography text="Zip Code" fontSize="14px" />
              <Input
                onChange={changeHandler}
                value={addressInfo.zipCode}
                name="zipCode"
              />
            </FlexBox>
            <FlexBox direction="column" gap="6px" className="w-100">
              <Typography text="Country" fontSize="14px" />
              <Input
                onChange={changeHandler}
                value={addressInfo.country}
                name="country"
              />
            </FlexBox>
          </FlexBox>
          <FlexBox gap="12px">
            <Button
              label="Cancel"
              size="fullWidth"
              appearance="secondary"
              onClick={onCloseModal}
            />
            <Button
              label="Save Changes"
              size="fullWidth"
              appearance="primary"
              disabled={
                !addressInfo.firstLine ||
                !addressInfo.city ||
                !addressInfo.country ||
                !addressInfo.state ||
                !addressInfo.zipCode
              }
              onClick={() => {
                onSave(addressInfo);
                onCloseModal();
              }}
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default AddressModal;

const StyledWrapper = styled.div`
  .address-modal {
    height: fit-content;
    border-radius: 16px;
  }
`;
