import { FC } from 'react';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
interface Props {
  price: number;
  isGrowth: boolean;
  growthPrice: number;
}
const PriceSection: FC<Props> = ({ isGrowth, price, growthPrice }) => {
  return (
    <>
      <FlexBox
        className="main-price text-qred  font-500 text-[15px]"
        gap="2px"
        alignItems="flex-end"
      >
        From USD{' '}
        {isGrowth ? (
          <FlexBox>
            <p className="discount-price">{price}</p>
            <Typography
              primaryColorValue={600}
              fontSize={'16px'}
              style={{ padding: '0 6px' }}
              text={parseFloat(growthPrice as unknown as string).toFixed(2)}
            />
          </FlexBox>
        ) : (
          price
        )}
      </FlexBox>
      {!isGrowth && (
        <span
          className="main-price text-qred  font-500 text-[14px]"
          style={{ color: colors.blue[600] }}
        >
          From USD {parseFloat(growthPrice as unknown as string).toFixed(2)}{' '}
          with EverBee Growth Plan
        </span>
      )}
    </>
  );
};

export default PriceSection;
