import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { Divider } from '../../order-details/details/billing';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import { SelectList } from '../../common/elements';
import QuantityButtons from '../../quantity-buttons';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getSelectedProductData,
  updateQuantity,
  updateSelectedProductInfo,
} from '../../../store/slices/createOrderSlice';
import { SelectedProduct } from '../../../interface/create-order-interface';
import { useNavigate } from 'react-router-dom';

const ProductSection = () => {
  const navigate = useNavigate();
  const selectedProductData: SelectedProduct = useAppSelector(
    getSelectedProductData,
  );
  const {
    selectedColor,
    selectedSize,
    quantity,
    skuId,
    brand,
    modelNumber,
    productCost,
    mockupImage,
    colorsOptions,
    title,
    variants,
  } = selectedProductData;
  const [color, setColor] = useState<string>(selectedColor);
  const dispatch = useAppDispatch();

  const sizeOptions = useMemo(() => {
    let options: any[] = [];
    variants.forEach((variant: any) => {
      if (`#${variant.colorHex}` === color) {
        options = variant.skus.map((sku: any) => ({
          label: sku.size,
          value: sku.catalogSKUId as any,
          cost: sku.cost,
          productColorID: variant.productColorID,
        }));
      }
    });
    return options;
  }, [color, variants]);

  useEffect(() => {
    // navigate user to orders page on refreshing a page
    if (!title) {
      navigate('/orders');
    }
  }, [navigate, title]);

  return (
    <StyledWrapper>
      <div className="heading">Product</div>
      <Divider />
      <FlexBox
        className="container"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FlexBox gap="1rem">
          <div className="image-wrapper">
            <img src={mockupImage} alt="shirt" />
            <div className="quantity">{quantity}</div>
          </div>
          <FlexBox direction="column" gap="8px">
            <Typography text={title} fontSize="18px" fontWeight="700" />
            <FlexBox direction="column" gap="4px">
              <Typography
                text={`SKU: ${skuId}`}
                secondaryColorValue={600}
                fontSize="12px"
              />
              <Typography
                text={`Brand: ${brand}`}
                secondaryColorValue={600}
                fontSize="12px"
              />
              <Typography
                text={`Model number: ${modelNumber}`}
                secondaryColorValue={600}
                fontSize="12px"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox gap="1.5rem" alignItems="center">
          <FlexBox gap="1rem">
            <SelectList
              items={colorsOptions}
              selectedValue={selectedColor}
              onChange={(value) => setColor(value)}
              listType="color-item"
              width="100%"
              className="order-select"
              label="Color"
              gapBetweenInputAndItems={0}
            />
            <SelectList
              items={sizeOptions}
              selectedValue={selectedSize}
              onChange={(value) => {
                const obj = sizeOptions.find((size) => size.value === value);
                dispatch(
                  updateSelectedProductInfo({
                    field: 'skuId',
                    updatedValue: {
                      skuId: value,
                      productColorID: obj?.productColorID,
                      updatedCost: obj?.cost,
                    },
                  }),
                );
              }}
              label="Size"
              className="order-select select-size"
              width="100%"
              gapBetweenInputAndItems={0}
            />
          </FlexBox>
          <QuantityButtons
            quantity={quantity}
            onChange={(qty) => {
              dispatch(
                updateQuantity({
                  quantity: qty,
                  originalProductCost: sizeOptions.find(
                    (size) => size.value === skuId,
                  )?.cost,
                }),
              );
            }}
          />
          <Typography text={`USD ${productCost}`} fontSize="14px" />
          {/* <FlexBox gap="8px">
            <IconButton
              appearance="secondary"
              icon={DuplicateIcon}
              onClick={() => console.log("s")}
              width="28px"
              height="28px"
              className="duplicate-icon-btn"
            />
            <IconButton
              appearance="secondary"
              icon={DeleteIcon}
              onClick={() => console.log("s")}
              width="28px"
              height="28px"
              className="delete-icon-btn"
            />
          </FlexBox> */}
        </FlexBox>
      </FlexBox>
    </StyledWrapper>
  );
};

export default ProductSection;
const StyledWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.border};
  .heading {
    padding: 18px 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.grey[900]};
  }
  .container {
    padding: 16px;
  }
  .image-wrapper {
    height: 88px;
    width: 88px;
    position: relative;
    border-radius: 6px;
    border: 1px solid ${colors.grey[100]};
    img {
      height: 100%;
      border-radius: 6px;
    }
    .quantity {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      position: absolute;
      background-color: ${colors.black};
      color: ${colors.white};
      bottom: 0;
      right: 0px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .order-select {
    max-height: 12px;
    min-width: 100px;
    padding: 6px 9px 9px 8px;
    .dropdown-body {
      border-radius: 0px 0px 6px 6px;
    }
  }
  .select-size {
    .dropdown-item {
      padding: 5px 6px 5px 8px;
      border-bottom: 1px solid ${colors.grey[300]};
      background-color: ${colors.white};
      :last-child {
        border-bottom: none;
      }
    }
  }
  .duplicate-icon-btn,
  .delete-icon-btn {
    border-radius: 4px;
  }
  .duplicate-icon-btn img {
    width: 13.33px;
    height: 13.33px;
  }
`;
