import React, { useEffect } from 'react';
import Typography from '../typography';
import { FlexBox } from '../common/wrappers';
import {
  getSampleOrderData,
  setSelectedProduct,
  setTotalCost,
} from '../../store/slices/sampleOrderSlice';
import { useAppSelector } from '../../store/hooks';
import { ColoredCircle } from '../common/elements';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { DeleteIcon } from '../../assets/icons';
import { useDispatch } from 'react-redux';
import Input from '../inputs';
import Button from '../common/button';
import { useGetShippingCostMutation } from '../../store/services/orders';
import SmallLoader from '../common/small-loader';
import { generateSampleOrderShippingCostPayload } from './utils';

interface Props {
  setRecalculateShipping: (value: boolean) => void;
  recalculateShipping: boolean;
}
const Cart: React.FC<Props> = ({
  setRecalculateShipping,
  recalculateShipping,
}) => {
  const [getShippingCost, { isLoading }] = useGetShippingCostMutation();
  const { currentStep, selectedProducts, totalCost } =
    useAppSelector(getSampleOrderData);
  const dispatch = useDispatch();

  const handleInputChange = (
    productId: string,
    productColorID: number,
    variantId: string,
    newValue: number,
  ) => {
    setRecalculateShipping(true);
    const updatedProducts = selectedProducts.map((item) => {
      if (
        item.productId === productId &&
        item.productColorID === productColorID &&
        item.variantId === variantId
      ) {
        return {
          ...item,
          quantity: isNaN(newValue) ? 0 : Math.min(newValue, 99),
        };
      }
      return item;
    });
    dispatch(setSelectedProduct(updatedProducts));
  };

  const handleDelete = (productId: string) => {
    const index = selectedProducts.findIndex(
      (item) => item.productId === productId,
    );

    if (index !== -1) {
      const updatedProducts = [...selectedProducts];
      updatedProducts.splice(index, 1);
      dispatch(setSelectedProduct(updatedProducts));
    }
  };
  useEffect(() => {
    const totalPrice = selectedProducts.reduce((total, product) => {
      return (total + product.cost) * product.quantity;
    }, 0);

    dispatch(
      setTotalCost({ shippingCost: 0, totalProductionCost: totalPrice }),
    );
  }, [dispatch, selectedProducts]);

  const calculateProductPrice = (variantId: string, quantity: number) => {
    const variant = selectedProducts.find(
      (product) => product.variantId === variantId,
    );
    if (variant) {
      const totalProductPrice = variant.cost * quantity;

      return totalProductPrice.toFixed(2);
    }
    return '---';
  };

  const updateProductQuantity = (
    productId: string,
    productColorID: number,
    variantId: string,
    increaseValue: boolean,
  ) => {
    setRecalculateShipping(true);
    const updatedProducts = selectedProducts.map((item) => {
      if (
        item.productId === productId &&
        item.productColorID === productColorID &&
        item.variantId === variantId
      ) {
        return {
          ...item,
          quantity: increaseValue
            ? Math.min(item.quantity + 1, 99)
            : Math.max(item.quantity - 1, 0),
        };
      }
      return item;
    });
    dispatch(setSelectedProduct(updatedProducts));
  };

  const calculateShipping = async () => {
    const products = generateSampleOrderShippingCostPayload(selectedProducts);
    try {
      const res = await getShippingCost({ products });
      setRecalculateShipping(false);
      if ('data' in res) {
        dispatch(
          setTotalCost({ ...totalCost, shippingCost: res.data.shippingCost }),
        );
      }
    } catch (error) {}
  };

  return (
    <StyledWrapper className="show-no-scrollbar b-r-6px">
      {isLoading && <SmallLoader />}
      <Typography
        text="Cart"
        fontSize="18px"
        secondaryColorValue={900}
        className="cart-heading"
      />
      <div className="show-no-scrollbar">
        {selectedProducts.map((product, index) => (
          <ProductWrapper key={index} className="product-container">
            <div className="product-image">
              <img src={product.image} alt="product_image" />
            </div>
            <FlexBox gap="8px" direction="column" className="product-details">
              <div className="title-container">
                <span>{product.title}</span>
                {currentStep !== 3 && (
                  <img
                    src={DeleteIcon}
                    className="delete-icon grey-border b-r-6px"
                    alt="Delete"
                    onClick={() => handleDelete(product.productId)}
                  />
                )}
              </div>

              <FlexBox
                alignItems="center"
                className="details-container"
                justifyContent="space-between"
              >
                <FlexBox
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <ColoredCircle
                    colorCode={
                      product?.colorName.includes('/')
                        ? product?.colorName
                        : `${product?.color}` ?? colors.black
                    }
                    width={18}
                    height={18}
                  />
                  <span className="small-dot"></span>
                  <div className="product-size">{product.size ?? '--'}</div>
                </FlexBox>
                <FlexBox
                  className="border-box"
                  style={{ width: 'fit-content' }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                      color: product.quantity === 1 ? colors.grey[300] : '',
                    }}
                    onClick={
                      product.quantity > 1
                        ? () =>
                            updateProductQuantity(
                              product.productId,
                              product.productColorID,
                              product.variantId,
                              false,
                            )
                        : undefined
                    }
                  >
                    -
                  </span>
                  <Input
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onKeyDown={(event) => {
                      const key: any = event.key;

                      const isNumeric = !isNaN(key) || key === 'Backspace';

                      if (!isNumeric) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) =>
                      handleInputChange(
                        product.productId,
                        product.productColorID,
                        product.variantId,
                        parseInt(event.target.value),
                      )
                    }
                    onBlur={(event) => {
                      const value = parseInt(event.target.value);
                      if (isNaN(value) || value < 1) {
                        handleInputChange(
                          product.productId,
                          product.productColorID,
                          product.variantId,
                          1,
                        );
                      }
                    }}
                    value={product.quantity.toString()}
                    style={{
                      width: '24px',
                      textAlign: 'center',
                      boxShadow: 'none',
                    }}
                  />
                  <span
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      updateProductQuantity(
                        product.productId,
                        product.productColorID,
                        product.variantId,
                        true,
                      )
                    }
                  >
                    +
                  </span>
                </FlexBox>
                <FlexBox>
                  <Typography
                    text={`USD ${calculateProductPrice(
                      product.variantId,
                      product.quantity,
                    )}`}
                    fontSize={'14px'}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </ProductWrapper>
        ))}
      </div>
      <FlexBox direction="column">
        {selectedProducts.length > 0 && (
          <>
            <FlexBox
              alignItems="center"
              justifyContent="space-between"
              className="totalCost-wrapper"
            >
              <Typography text="Production" fontSize={'14px'} />
              <Typography
                text={`USD ${totalCost.totalProductionCost.toFixed(2)}`}
                fontSize={'14px'}
              />
            </FlexBox>

            {
              <FlexBox
                alignItems="center"
                justifyContent="space-between"
                className="shipping-title"
              >
                <Typography text="Shipping cost" fontSize={'14px'} />

                <span color={colors.grey[900]} className="f-s-14">
                  {totalCost.shippingCost === 0
                    ? currentStep === 2
                      ? '---'
                      : currentStep !== 3 && 'Calculated at next step'
                    : `USD ${totalCost.shippingCost.toFixed(2)}`}
                </span>

                {recalculateShipping && currentStep === 3 && (
                  <Button
                    onClick={() => calculateShipping()}
                    label="Recalculate"
                    appearance="secondary"
                  />
                )}
              </FlexBox>
            }
          </>
        )}
      </FlexBox>
      <FlexBox
        alignItems="center"
        className=" heading b-t"
        justifyContent="space-between"
      >
        <Typography text="Total" fontSize={'14px'} fontWeight="700" />

        <span color={colors.grey[900]} className=" f-s-14 f-w-700">{`USD ${(
          totalCost.shippingCost + totalCost.totalProductionCost
        ).toFixed(2)}`}</span>
      </FlexBox>
    </StyledWrapper>
  );
};

export default Cart;

const StyledWrapper = styled.div`
  border: 1px solid rgb(235, 235, 235);
  height: fit-content;
  width: 47%;
  max-height: 544px;
  display: flex;
  flex-direction: column;
  .cart-heading {
    padding: 15px 16px;
  }
  .heading {
    padding: 10px 15px !important;
  }
  .shipping-title {
    padding: 0 15px 10px !important;
  }
  .totalCost-wrapper {
    padding: 10px 15px;
  }
`;

const ProductWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 8px 16px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey.border};
  margin-top: 5px;
  &:first-child {
    border-top: 1px solid ${colors.grey.border};
  }

  .product-image img {
    width: 64px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid ${colors.grey[150]};
    object-fit: contain;
  }

  .product-container {
    width: 100%;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
  .product-details {
    width: 80%;
  }
  .delete-icon {
    height: 20px;
    width: 20px;
  }

  .border-box {
    border: 1px solid ${colors.grey.border};
    border-radius: 6px;
  }
`;
