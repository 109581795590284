import React from 'react';
import StepSystem from '../editor/subComponents/top-bar/step-system';
import { Separator } from '../editor/subComponents/top-bar';
import { FlexBox } from '../common/wrappers';
import Typography from '../typography';
import styled from 'styled-components';

interface Props {
  currentStep: number;
}
const Topbar: React.FC<Props> = ({ currentStep }) => {
  return (
    <StyledWrapper>
      <Typography
        text="Create sample order"
        fontSize="18px"
        secondaryColorValue={900}
      />
      <FlexBox alignItems="center">
        {steps.map((obj) => {
          const isEvenStep = obj.step % 2 === 0;
          return (
            <div
              key={obj.step}
              className={isEvenStep ? 'align-items-center' : ''}
            >
              {isEvenStep && <Separator />}
              <StepSystem
                fromModal={true}
                displayText={obj.name}
                isStepDone={currentStep > obj.step}
                linkCondition={false}
                stepNumber={obj.step}
                isStepNumberActive={obj.step === currentStep}
              />
              {isEvenStep && <Separator />}
            </div>
          );
        })}
      </FlexBox>
    </StyledWrapper>
  );
};

export default Topbar;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  .align-items-center {
    display: flex;
    align-items: center;
  }
  hr {
    margin: 0 5px;
  }
`;

const steps = [
  { name: 'Select products', step: 1 },
  { name: 'Shipment details', step: 2 },
  { name: 'Payment', step: 3 },
];
