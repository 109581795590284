// ProductSelectionStep.js

import React from 'react';
import { FlexBox } from '../common/wrappers';
import { ProductInfo } from '../orders/modals';
import { SearchIcon } from '../../assets/icons';
import SearchBar from '../common/search-bar';
import Typography from '../typography';
import { SampleOrderProduct } from '../../interface/sample-order-interface-v2';

interface ProductSelectionStepProps {
  productsData: SampleOrderProduct[];
  searchText: string | undefined;
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
  page: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const ProductSelectionStep: React.FC<ProductSelectionStepProps> = ({
  productsData,
  searchText,
  setSearchText,
  page,
  totalPages,
  setPage,
}) => {
  const handleSearchChange = (value: string) => {
    setSearchText(value);
    setPage(1);
  };

  return (
    <FlexBox gap="12px" direction="column" className="main-container">
      <div style={{ margin: '1px 1px 0' }}>
        <SearchBar
          icon={SearchIcon}
          onChange={handleSearchChange}
          value={searchText as string}
        />
      </div>
      {productsData.length > 0 ? (
        <FlexBox gap="12px" direction="column" className="products-list ">
          {productsData?.map((product, index) => (
            <ProductInfo key={index} product={product} forSampleOrder={true} />
          ))}
          {page < (totalPages ?? 1) && (
            <div className="more-products">
              <span
                onClick={() => setPage((prev) => prev + 1)}
                className="pointer-cursor"
              >
                See more
              </span>
            </div>
          )}
        </FlexBox>
      ) : (
        <>
          {searchText !== undefined && (
            <Typography
              text="Searched product are not present!!"
              fontSize="16px"
              className="text-align-center"
            />
          )}
        </>
      )}
    </FlexBox>
  );
};

export default ProductSelectionStep;
