import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes/Routes';
import './App.css';
import './assets/fonts/satoshi/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from './helper/socket';
import amplitude from 'amplitude-js';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getUserData } from './store/slices/authSlice';
import {
  getUtilityData,
  setConnectShopModal,
  setShowConnetModalForAddNewShop,
} from './store/slices/utilitySlice';
import EtsyConnectModal from './components/common/etsy-connect-modal';
import { useGetUserInfoQuery } from './store/services/user';
import Layout from './components/Sidebar/layout';
import Loader from './components/common/loader';
import CryptoJS from 'crypto-js';

declare const gist: {
  identify: (params: { email: string; user_hash: string }) => void;
};

function App() {
  const { isAuth, userInfo } = useAppSelector(getUserData);
  const [searchParams] = useSearchParams();
  const { isInvalidGrantFromEtsy, showConnetModalForAddNewShop } = useAppSelector(getUtilityData);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetUserInfoQuery(undefined, {
    skip: !isAuth,
  });

  const isErrorMessageFromBackend = searchParams.get('message');
  function gistIdentify(email: string) {
    const secretKey =
      'lUdkl+wa9Mw9xMKQZPsIcH37gF9uh+/MKYZeFuNkx+I4T/zNIGjDMg==';

    const gistHash = CryptoJS.HmacSHA256(email, secretKey).toString(
      CryptoJS.enc.Hex,
    );

    if (
      typeof gist !== 'undefined' &&
      gist &&
      typeof gist.identify === 'function'
    ) {
      gist.identify({
        email: email,
        user_hash: gistHash,
      });
    }
  }
  useEffect(() => {
    if (data) {
      /** amplitude  */
      amplitude
        .getInstance()
        .init(
          process.env.REACT_APP_AMPLITUDE_KEY as string,
          data.everbeeProdUserId,
        );
      gistIdentify(data.email);
    }
  }, [data, dispatch]);

  /** socket  */
  useEffect(() => {
    const onConnect = () => {
      console.log('connected');
    };

    const onDisconnect = () => {
      console.log('disconnected');
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Layout isExpanded={isExpanded} toggleSidebar={toggleSidebar}>
        {isLoading && <Loader />}
        {isInvalidGrantFromEtsy && (
          <EtsyConnectModal
            errorMessage="Please reconnect your shop"
            onClose={() => dispatch(setConnectShopModal(false))}
          />
        )}
        {showConnetModalForAddNewShop && (
          <EtsyConnectModal
            onClose={() => dispatch(setShowConnetModalForAddNewShop(false))}
          />
        )}
        {((userInfo.id && userInfo.salesChannels.length === 0) ||
          isErrorMessageFromBackend) && (
          <EtsyConnectModal errorMessage={isErrorMessageFromBackend} />
        )}
        <ToastContainer autoClose={2000} position="top-right" />
        <Routes />
      </Layout>
    </>
  );
}

export default App;
