const personalizationTexts = {
  allowBuyersToUpload: 'Allow buyers to upload their own image',
  giveBuyersOptions: 'Give buyers options of images to choose from',
  allowBuyersToSelectFont: 'Allow buyers to select any font and color',
  giveBuyersFontOptions: 'Give buyers options of fonts and colors',
};

const fabricFieldsToKeep = [
  'id',
  'name',
  'personalizationData',
  'originalWidth',
  'originalHeight',
];

export { personalizationTexts, fabricFieldsToKeep };
