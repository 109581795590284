import React from 'react';
import styled from 'styled-components';
import Modal from '../../../common/modal';
import { FlexBox } from '../../../common/wrappers';
import Button from '../../../common/button';
import { openLinkInNewTab } from '../../../../helper/openLinkInNewTab';

interface Props {
  closeModal: () => void;
}

const EtsyCancelOrderModal: React.FC<Props> = ({ closeModal }) => {
  return (
    <StyledWrapper>
      <Modal title="Order Cancellation Update" className="cancel-modal">
        <FlexBox
          gap="1rem"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <ul>
            <li>
              Your EverBee Print order has been cancelled, and a refund is on
              its way to your account within 7 days.
            </li>
            <li>
              Please remember to also cancel this order on your Etsy Shop
              Dashboard, as we're unable to do this for you.
            </li>
          </ul>
          <span
            onClick={() => openLinkInNewTab('https://www.etsy.com/your/orders/sold/new')}
            style={{ textDecoration: 'underline' }}
            className="pointer-cursor"
          >
            Etsy Order Page
          </span>
          <Button
            label="Okay"
            onClick={closeModal}
            appearance="primary"
            size="large"
          />
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default EtsyCancelOrderModal;

const StyledWrapper = styled.div`
  .cancel-modal {
    height: fit-content;
    border-radius: 8px;
    padding: 16px;
    ul {
      margin: 0;
      padding-inline: 1.1rem;
      font-size: 0.9rem;
      li {
        padding-top: 6px;
      }
    }
  }
`;
