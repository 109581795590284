import React from 'react';
import { FlexBox } from './wrappers';

interface FeatureCardProps {
  title: string;
  icons: any[];
}
const IconsCard: React.FC<FeatureCardProps> = ({ title, icons }) => {
  return (
    <FlexBox
      className="feature-card"
      justifyContent="space-between"
      alignItems="center"
      style={{ fontSize: '14px' }}
    >
      <span>{title}</span>
      <FlexBox className="icons" gap="5px">
        {icons
          .filter((icon) => icon && icon.trim() !== '')
          .map((icon, index) => (
            <FlexBox
              key={index}
              gap="4px"
              alignItems="center"
              justifyContent="center"
              className="icon-wrapper"
            >
              {icon}
            </FlexBox>
          ))}
      </FlexBox>
    </FlexBox>
  );
};

export default IconsCard;
