import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { colors } from '../../assets/colors';
import styled, { css } from 'styled-components';
import { DeleteIcon, DropzonePlaceholder } from '../../assets/icons';

import {
  setMockups,
  deleteImageOnDeselect,
  setMyMockupsData,
  MyMockupsInitialState,
  getMockups,
  setClickedImage,
} from '../../store/slices/mockupSlice';
import { useDispatch } from 'react-redux';
import { MockupData } from '../../interface/mockups-interface';
import { useDeleteMyMockupByIdMutation } from '../../store/services/my-mockups';
import { useAppSelector } from '../../store/hooks';
import Loader from './loader';

interface FilterMockupItem {
  userId: string;
  imageUrl: string;
  name: string;
  folderName: string[];
  id: string;
}

const ImageBox: React.FC<{
  className?: string;
  width?: string;
  height?: string;
  image: string;
  deleteIcon?: boolean;
  children?: ReactNode;
  itemId?: string | number;
  leftScrollItem?: boolean;
  mainImage?: boolean;
  isMockAlreadyAdded?: boolean;
  isClickable?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  defaultCursor?: boolean;
  index?: number;
  myMockup?: boolean;
  activeFolderData?: { name: string; images: any[] };
  setActiveFolderData?: Dispatch<
    SetStateAction<{ name: string; images: any[] }>
  >;
  filteredMockupData?: FilterMockupItem[];
  isFolder?: boolean;
  boxHeight?: string;
  imgHeight?: string;
  imgWidth?: string;
}> = ({
  className,
  image,
  width,
  height,
  children,
  deleteIcon,
  itemId,
  leftScrollItem,
  mainImage,
  isMockAlreadyAdded,
  isClickable,
  onClick,
  disabled,
  defaultCursor,
  index,
  myMockup,
  activeFolderData,
  setActiveFolderData,
  filteredMockupData,
  isFolder,
  boxHeight,
  imgHeight,
  imgWidth,
}) => {
  const [showDelete, setShowDelete] = useState(false);

  const {
    myMockupsData,
    mockupData: mockups,
    selectedImages,
  }: MyMockupsInitialState = useAppSelector(getMockups);
  const [deleteMyMockup, { isLoading }] = useDeleteMyMockupByIdMutation();
  const dispatch = useDispatch();
  const currentFolderName = activeFolderData?.name;

  const deleteSelectedMockups = () => {
    dispatch(
      deleteImageOnDeselect({
        mockupId: itemId,
      }),
    );
    if (selectedImages.length === 1) {
      dispatch(setClickedImage(null));
    }
    const updatedMockups = mockups.map((mockup: MockupData) => {
      if (mockup.id === itemId) {
        return {
          ...mockup,
          selected: false,
        };
      }
      return mockup;
    });

    dispatch(setMockups(updatedMockups));
  };

  const handleDelete = async () => {
    const params: any = { mockupId: itemId };
    if (activeFolderData !== undefined) {
      if (currentFolderName) {
        deleteSelectedMockups();
        params['folderName'] = currentFolderName;
        const filteredData = (filteredMockupData || []).filter(
          (data: FilterMockupItem) => data.id !== itemId,
        );
        setActiveFolderData?.({ ...activeFolderData, images: filteredData });
      } else {
        deleteSelectedMockups();
        const updatedMockupsData = myMockupsData.filter(
          (data: FilterMockupItem) => data.id !== itemId,
        );
        dispatch(setMyMockupsData(updatedMockupsData));
      }
    } else {
      delete params.mockupId;
      params['folderName'] = itemId;
    }
    await deleteMyMockup(params);
  };

  const handleDeleteImage = async (e: any) => {
    e.stopPropagation();
    if (!isFolder) {
      deleteSelectedMockups();
    } else {
      handleDelete();
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <ImageContainer
        disabled={disabled}
        className={className}
        width={width}
        height={height}
        leftScrollItem={leftScrollItem}
        mainImage={mainImage}
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
        isClickable={isClickable}
        isMockAlreadyAdded={isMockAlreadyAdded}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !disabled && onClick && onClick();
        }}
        defaultCursor={defaultCursor}
        index={index}
        myMockup={myMockup}
        boxHeight={boxHeight}
      >
        {image ? (
          <img
            className="image-item-main draggable"
            width={imgWidth ?? width ?? '100%'}
            height={imgHeight ?? height ?? '100%'}
            alt="Generated pic"
            src={image}
          />
        ) : (
          <img
            width={width ?? '80px'}
            height={height ?? '80px'}
            alt="Placeholder"
            src={DropzonePlaceholder}
          />
        )}
        {showDelete && deleteIcon && (
          <img
            className="image-item delete"
            width="24"
            src={DeleteIcon}
            alt="Delete"
            onClick={(e) => handleDeleteImage(e)}
          />
        )}
        {children}
      </ImageContainer>
    </>
  );
};

const ImageContainer = styled.div<{
  width?: string;
  height?: string;
  leftScrollItem?: boolean;
  mainImage?: boolean;
  isClickable?: boolean;
  isMockAlreadyAdded?: boolean;
  disabled?: boolean;
  defaultCursor?: boolean;
  index?: number;
  myMockup?: boolean;
  boxHeight?: string;
}>`
  position: relative;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.boxHeight ?? props.height ?? '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  ${({ mainImage }) =>
    !mainImage &&
    css`
      border: 1px solid ${colors.grey[300]};
    `}
  cursor:${(props) =>
    props.isClickable
      ? 'pointer'
      : props.defaultCursor
      ? 'default'
      : 'not-allowed'};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  img {
    object-fit: contain;
    border-radius: 6px;
  }

  .image-item {
    position: absolute;
    right: 10px;
    top: 10px;
    background: ${colors.white};
    border-radius: 50%;
    z-index: 2;
    &:not(.crown) {
      top: ${(props) => (props.index === 0 ? '45px ' : '10px')};
    }
  }
  .delete {
    top: ${(props) => (props.myMockup ? '45px !important' : '10px')};
  }
  .image-item-main {
    cursor: ${({ leftScrollItem }) => leftScrollItem && 'pointer'};
  }
`;

export default ImageBox;
