import { createApi } from '@reduxjs/toolkit/query/react';
import { mockupServerBaseQuery } from '../baseQuery';
import {
  MockupsQueryParams,
  MockupsResponse,
} from '../../interface/mockups-interface';

export const mockupsApi = createApi({
  reducerPath: 'mockups',
  baseQuery: mockupServerBaseQuery,
  endpoints: (builder) => ({
    getMockups: builder.query<MockupsResponse, MockupsQueryParams>({
      query: (params) =>
        `/mockup_templates?is_default=${params.isDefault}&type_of_shirt=${params?.modelNumber}&page=${params.pageNo}&side_of_shirt=${params.mockupAngle}&per_page=${params.perPage}&texture_type=${params.textureType}
        `,
    }),
  }),
});
export const { useGetMockupsQuery: useGetMockupsQueryFromMockupServer } = mockupsApi;
