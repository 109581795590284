import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setConnectShopModal } from './slices/utilitySlice';

export const errorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const redirectUrl = action?.meta?.baseQueryMeta?.response?.url;
      if (action?.payload?.status === 'PARSING_ERROR' && redirectUrl)
        window.location.href = redirectUrl;
      if (action?.payload?.data?.message === 'invalid_grant') {
        api.dispatch(setConnectShopModal(true));
      } else {
        toast.error(action?.payload?.data?.message);
      }
    }
    if (isFulfilled(action)) {
      if (action?.payload?.message) {
        toast.success(action?.payload?.message);
      }
    }
    return next(action);
  };
