import React from 'react';
import styled from 'styled-components';
import Button from '../../components/common/button';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <h2>404: The page you are looking for isn’t here</h2>
      <Button
        appearance="primary"
        label="Go back "
        onClick={() => navigate('/')}
      />
    </StyledWrapper>
  );
};

export default PageNotFound;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;
