import React, { ReactNode } from 'react';
import { colors } from '../../assets/colors';
import styled from 'styled-components';
import PaymentBanner from './payment-banner';
import HeaderWithSampleButton from '../Header-with-button';
import { useLocation } from 'react-router-dom';
import { routeNames } from '../../constants/routesPath';

interface Props {
  children: ReactNode;
}
const BaseLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const title = (() => {
    switch (location.pathname) {
      case routeNames.myProducts:
        return 'My Products';
      case routeNames.orders:
        return 'Orders';
      case routeNames.catalog:
        return 'Catalog';
      default:
        return '';
    }
  })();

  return (
    <StyledWrapper>
      {/* Payment Banner */}

      <PaymentBanner />
      <HeaderWithSampleButton title={title} />

      {children}
    </StyledWrapper>
  );
};

export default BaseLayout;

const StyledWrapper = styled.div`
  .underline-text {
    border-bottom: 1px solid ${colors.black};
    cursor: pointer;
  }
`;
