/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { colors } from '../../../../assets/colors';
import AddDesignTabs from './add-design-tabs';
import ElementList from './element-listing';
import { Canvas } from 'fabric/fabric-impl';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getObjects,
  getSelectedColors,
  getPersonalizationElementId,
  updatePersonalizationElementId,
  setCurrentTab,
  clearBase64Cache,
} from '../../../../store/slices/editor';
import Modal from '../../../common/modal';
import { FlexBox } from '../../../common/wrappers';
import Button from '../../../common/button';
import ImageContentForPersonalizationModal from '../personalization-modal-content/image';
import {} from '../../../../interface/catalog-interfaces';
import {
  useCreateProductMutation,
  useUpdateProductDesignMutation,
  useUpdateProductDesignFilesMutation,
} from '../../../../store/services/products';
import { fabricFieldsToKeep } from '../../../common/contants';
import { getUserData } from '../../../../store/slices/authSlice';
import Loader from '../../../common/loader';
import { routeNames } from '../../../../constants/routesPath';
import {
  setMockups,
  setSelectedImageArray,
} from '../../../../store/slices/mockupSlice';
import { Drawer } from '@mui/material';
import {
  BrushIcon,
  DetailsIcon,
  LayersIcon,
  RightArrowIcon,
} from '../../../../assets/icons';
import amplitude from 'amplitude-js';
import { getDesignElements } from '../../../../helper/getDesignElements';
import { getMinimumAndMaximumMRSPFromVariants } from '../../../../helper/getMinimumAndMaximumMRSPFromVariants';
import { uploadEditorDataToS3 } from '../../../../helper/s3';
import { updateSrcInEditorData } from '../../utils';
import {
  Side,
  VariantEditorDataV2,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';
import { DropzoneRef } from 'react-dropzone';

interface Props {
  selectedElementId: string;
  drawingBoard: Canvas | undefined;
  selectedVariant: VariantsV2 | undefined;
  catalogVariants: VariantsV2[] | undefined;
  setSelectedVariant(variant: VariantsV2): void;
  catalogProductId: string | undefined;
  selectedAngle: string;
  anglesData: VariantEditorDataV2[];
  manageSpecificationDesign(status: boolean, colorCode: string): void;
  defaultEditorData: any;
  modelNumber?: string;
  isTablet: boolean;
  productName?: string;
  setAddImageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  undoAndRedoLoadingRef: React.MutableRefObject<boolean>;
  isSellerEditor: boolean;
  instructions?: string;
  setShowPersonalizeEditorModal?: (value: any) => void;
  productIdFromPersonalization?: string;
  editorDataFromProps?: string;
  printProviderId?: string;
  currentProductType: string | undefined;

  printSides: string[];
  printAreaDimensions: Side[] | undefined;
  setLoading: (loading: boolean) => void;
  dropzoneRef: React.MutableRefObject<DropzoneRef | null>;
}

const RightSideBar: React.FC<Props> = ({
  selectedElementId,
  drawingBoard,
  catalogVariants,
  selectedVariant,
  setSelectedVariant,
  catalogProductId,
  selectedAngle,
  anglesData,
  manageSpecificationDesign,
  defaultEditorData,
  modelNumber,
  isTablet,
  productName,
  setAddImageLoader,
  undoAndRedoLoadingRef,
  isSellerEditor,
  instructions,
  setShowPersonalizeEditorModal,
  productIdFromPersonalization,
  editorDataFromProps,
  printProviderId,
  currentProductType,
  printSides,
  printAreaDimensions,
  setLoading,
  dropzoneRef,
}) => {
  const elements = useAppSelector(getObjects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [openDesignDrwaer, setOpenDesignDrawer] = useState<boolean>(false);
  const [showColorsModal, setShowColorsModal] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [activeToolbar, setActiveToolbar] = useState<'list' | 'add'>(
    elements.length > 0 ? 'list' : 'add',
  );
  const [activeDrawer, setActiveDrawer] = useState<'layers' | 'details' | ''>(
    '',
  );

  const selectedColors = useAppSelector(getSelectedColors);
  const personalizationData = useAppSelector(getPersonalizationElementId);
  const { userInfo } = useAppSelector(getUserData);
  const [updateProductDesign, { isLoading: isProductUpdating }] =
    useUpdateProductDesignMutation();
  const [updateProductDesignFiles] = useUpdateProductDesignFilesMutation();
  const [createProduct, { isLoading }] = useCreateProductMutation();
  const [loadingForEditorDataSaving, setLoadingForEditorDataSaving] =
    useState(false);
  const changeToolBar = () => {
    if (activeToolbar === 'add') {
      setActiveToolbar('list');
    } else {
      setActiveToolbar('add');
    }
    if (activeDrawer === 'layers') {
      // setActiveDrawer('layers');
      setOpenDesignDrawer(true);
    } else {
      setActiveDrawer('');
    }
  };

  const isBackSidePrintAdded = () => {
    let isAdded = selectedAngle === 'back' && elements.length > 0;
    const backAdded = anglesData.filter((variant) => variant.back !== null);
    if (backAdded.length > 0) {
      isAdded = true;
    }
    if (defaultEditorData.back !== null) {
      isAdded = true;
    }
    return isAdded;
  };
  const isFrontSidePrintAdded = () => {
    let isAdded = selectedAngle === 'front' && elements.length > 0;
    const frontAdded = anglesData.filter((variant) => variant.front !== null);
    if (frontAdded.length > 0) {
      isAdded = true;
    }
    if (defaultEditorData.front !== null) {
      isAdded = true;
    }
    return isAdded;
  };

  const isEmptyProductAdded = () => {
    let currentVariantEditorData = anglesData.find(
      (variant) => variant.id === selectedVariant?.id,
    );
    let isEmpty = elements.length === 0;
    if (!currentVariantEditorData?.variantSpecificDesign) {
      const emptySpecificVariant = anglesData.filter(
        (variant) =>
          variant.variantSpecificDesign === true &&
          variant.back === null &&
          variant.front === null,
      );
      if (emptySpecificVariant.length > 0) {
        isEmpty = true;
      }
    }
    return isEmpty;
  };

  const buildImages = async (navigateToDetailsPage: boolean) => {
    const copyDrawingBoard = drawingBoard;
    const objects = copyDrawingBoard
      ?.toJSON(fabricFieldsToKeep)
      .objects.filter(
        (obj) =>
          obj.type !== 'line' &&
          obj.type !== 'rect' &&
          obj.type !== 'circle' &&
          obj.type !== 'path',
      );
    const dataToExtract: any = copyDrawingBoard?.toJSON(fabricFieldsToKeep);
    if (objects && dataToExtract) {
      dataToExtract.objects = objects;
    }
    delete dataToExtract?.clipPath;
    delete dataToExtract?.backgroundImage;
    const jsonData =
      dataToExtract.objects.length === 0 ? null : JSON.stringify(dataToExtract);

    let variantData = anglesData;
    let copyDefaultData = defaultEditorData;
    let currentVariantEditorData = variantData.find(
      (variant) => variant.id === selectedVariant?.id,
    );
    if (currentVariantEditorData?.variantSpecificDesign) {
      variantData = await Promise.all(
        variantData.map(async (variant) => {
          if (variant.id === selectedVariant?.id) {
            return {
              ...variant,
              [selectedAngle]: jsonData,
            };
          }
          return variant;
        }),
      );
    } else {
      copyDefaultData = {
        ...defaultEditorData,
        [selectedAngle]: jsonData,
      };
    }
    const coloursWithDefaultDesign = variantData.filter(
      (angle) => angle.variantSpecificDesign === false,
    );
    const coloursWithSpecificDesign = variantData.filter(
      (angle) => angle.variantSpecificDesign === true,
    );
    let selectedVariants = [...selectedColors];
    const formDataContainingImagesOnly = new FormData();

    // Code start to create image for each specific design
    for (const color of coloursWithSpecificDesign) {
      const index = selectedVariants.findIndex(
        (variant) => variant.id === color.id,
      );
      const element = { ...selectedVariants[index] };
      let variantInfo = variantData.find((angle) => angle.id === color.id);
      for (const printSide of printSides) {
        if (variantInfo) {
          if (
            variantInfo[printSide + 'image'] &&
            variantInfo[printSide] !== null
          ) {
            formDataContainingImagesOnly.append(
              `${variantInfo?.id}-${printSide}Design`,
              (variantInfo as any)[printSide + 'image'],
              `${printSide}Design.png`,
            );
            element['specificDesignRequired'] = true;
          }
        }
      }
      selectedVariants[index] = element;
    }

    const sidesHavingDesign = [];
    for (const printSide of printSides || []) {
      if (
        copyDefaultData[printSide + 'image'] &&
        copyDefaultData[printSide] !== null
      ) {
        formDataContainingImagesOnly.append(
          `${printSide}Design`,
          copyDefaultData[printSide + 'image'],
          `${printSide}Design.png`,
        );
        sidesHavingDesign.push(printSide);
      }
    }
    for (const side of sidesHavingDesign) {
      for (const color of coloursWithDefaultDesign) {
        const index = selectedVariants.findIndex(
          (variant) => variant.id === color.id,
        );
        const element = { ...selectedVariants[index] };
        selectedVariants[index] = element;
      }
    }
    setLoadingForEditorDataSaving(true);
    delete copyDefaultData.frontimage;
    delete copyDefaultData.backimage;
    // Function to remove images from variant editor data
    const removeImagesFromVariantEditorData = async (variants: any[]) => {
      return await Promise.all(
        variants.map(({ frontimage, backimage, ...rest }) => rest),
      );
    };

    // Await the removal of images from variant editor data
    variantData = await removeImagesFromVariantEditorData(variantData);
    variantData = variantData.filter((variant) =>
      selectedColors.some((color: { id: string }) => color.id === variant.id),
    );
    const updatedVariantEditorData = await Promise.all(
      variantData.map((variant) => updateSrcInEditorData(variant)),
    );
    const updatedDefaultEditorData = await updateSrcInEditorData(
      copyDefaultData,
    );

    const editorData = {
      defaultEditorData: updatedDefaultEditorData,
      variantEditorData: updatedVariantEditorData,
    };
    const editorDataFileName = `${userInfo.id + Date.now()}-editorData`;
    const editorDataResponse = await uploadEditorDataToS3(
      editorDataFileName,
      editorData,
    );

    const productInfo = {
      catalogId: catalogProductId,
      printProviderId,
      storeId: userInfo.storeId,
      variants: await Promise.all(
        selectedVariants.map((color) => {
          const requiredKeys = Object.keys(color).filter((key) =>
            key.includes('Required'),
          );
          const mainVariant = requiredKeys.reduce((obj, key) => {
            (obj as any)[key] = color[key];
            return obj;
          }, {});

          return {
            ...mainVariant,
            id: color.id,
            mrsp: color.mrsp,
            connectedVariants: color.connectedVariants.map(
              (varian: { id: any; mrsp: any }) => ({
                id: varian.id,
                mrsp: varian.mrsp,
              }),
            ),
          };
        }),
      ),
      editorData: editorDataResponse.url,
    };
    const mrsp = getMinimumAndMaximumMRSPFromVariants(selectedVariants);

    const printedAreas = sidesHavingDesign;
    const amplitudeData = {
      product_name: productName,
      color: selectedVariants.map((color) => color.color).join(','),
      design_elements: getDesignElements(defaultEditorData),
      price: `USD ${mrsp.min} - ${mrsp.max}`,
      print_areas: printedAreas,
    };

    const formDataObject: any = {};
    formDataContainingImagesOnly.forEach((value, key) => {
      formDataObject[key] = value;
    });

    if (productId) {
      const { printProviderId, catalogId, storeId, ...rest } = productInfo;
      updateProductDesign({
        body: {
          variants: productInfo.variants,
          editorData: productInfo.editorData,
        },
        productId,
      })
        .unwrap()
        .then(() => {
          amplitude.getInstance().logEvent('Editor', amplitudeData);
          dispatch(setMockups([]));
          dispatch(setSelectedImageArray([]));

          updateProductDesignFiles({
            body: formDataContainingImagesOnly,
            productId,
          });
          /** clearing base64 state */
          dispatch(clearBase64Cache());
          navigate(
            `/mockups/${productId}/${
              modelNumber === '3001C' ? '3001' : modelNumber
            }`,
            {
              state: {
                imagesData: formDataObject,
                catalogProductId,
                printProviderId,
              },
            },
          );
        })
        .catch(() => {});
    } else {
      const { data } = await createProduct({
        body: productInfo,
      }).unwrap();
      updateProductDesignFiles({
        body: formDataContainingImagesOnly,
        productId: data.id,
      });
      if (navigateToDetailsPage) {
        if (data.id) {
          amplitude.getInstance().logEvent('Editor', amplitudeData);
          /** clearing base64 state */
          dispatch(clearBase64Cache());
        }
        navigate(
          `/mockups/${data.id ?? productId}/${
            modelNumber === '3001C' ? '3001' : modelNumber
          }`,
          {
            state: {
              imagesData: formDataObject,
              catalogProductId,
              printProviderId,
            },
          },
        );
      } else {
        amplitude.getInstance().logEvent('Save - Editor', amplitudeData);
        navigate(routeNames.myProducts);
      }
    }
  };

  useEffect(() => {
    if (elements.length > 0 || !selectedElementId) {
      setActiveToolbar('list');
    } else {
      setActiveToolbar('add');
    }
  }, [elements, selectedElementId]);

  return (
    <>
      {isTablet ? (
        <>
          <div style={{ display: 'flex', gap: '12rem' }}>
            <div style={{ display: 'flex', gap: '2rem', marginLeft: '2rem' }}>
              <Button
                label="Details"
                onClick={() => setActiveDrawer('details')}
                appearance="secondary"
                icon={DetailsIcon}
                size="medium"
              />
              <Button
                label="Layers"
                onClick={() => setActiveDrawer('layers')}
                appearance="secondary"
                size="medium"
                icon={LayersIcon}
              />
              <Button
                label="Design"
                onClick={() => setOpenDesignDrawer(true)}
                appearance="secondary"
                size="medium"
                icon={BrushIcon}
              />
            </div>
            <div style={{ width: '20%' }}>
              <Button
                label=""
                icon={RightArrowIcon}
                onClick={() => setOpenSaveModal(true)}
                appearance="primary"
                size="fullWidth"
              />
            </div>
          </div>
          <Drawer
            open={openSaveModal}
            anchor={'bottom'}
            PaperProps={{
              sx: {
                width: '100vw',
                padding: '10px',
              },
            }}
            ModalProps={{
              disableEnforceFocus: true,
              disableAutoFocus: true,
              onBackdropClick: () => setOpenSaveModal(false),
            }}
          >
            <FlexBox
              style={{ margin: '7px 27px' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <span> What would you like to do now?</span>
              <FlexBox className="buttons" gap="1rem">
                {!productId && (
                  <Button
                    appearance="secondary"
                    onClick={() => buildImages(false)}
                    label="Save as Draft"
                    disabled={isEmptyProductAdded()}
                    size="large"
                    className="responsive-btn"
                  />
                )}

                <Button
                  appearance="primary"
                  disabled={isEmptyProductAdded()}
                  onClick={() => buildImages(true)}
                  label="Save & Continue"
                  size="large"
                  className="responsive-btn"
                />
              </FlexBox>
            </FlexBox>
          </Drawer>
          <Drawer
            open={openDesignDrwaer}
            anchor={'bottom'}
            PaperProps={{
              sx: {
                width: '100vw',
                height: '100%',
              },
            }}
          >
            <AddDesignTabs
              onClose={() => {
                setOpenDesignDrawer(false);
                /** after closing resetting the tab */
                dispatch(setCurrentTab('newDesign'));
              }}
              drawingBoard={drawingBoard}
              isTablet={isTablet}
              setAddImageLoader={setAddImageLoader}
              currentProductType={currentProductType}
              printAreaDimensions={printAreaDimensions}
              selectedAngle={selectedAngle}
              setLoading={setLoading}
              dropzoneRef={dropzoneRef}
            />
          </Drawer>
          <Drawer
            open={activeDrawer === 'details' || activeDrawer === 'layers'}
            anchor={'bottom'}
            ModalProps={{
              disableEnforceFocus: true,
              disableAutoFocus: true,
              onBackdropClick: () => setActiveDrawer(''),
            }}
            PaperProps={{
              sx: {
                width: '100vw',
                height: activeDrawer === 'layers' ? '100%' : '30%',
              },
            }}
          >
            <ElementList
              isSellerEditor={isSellerEditor}
              onClickAddButton={changeToolBar}
              selectedElementId={selectedElementId}
              drawingBoard={drawingBoard}
              catalogVariants={catalogVariants}
              setSelectedVariant={setSelectedVariant}
              selectedVariant={selectedVariant}
              selectedAngle={selectedAngle}
              anglesData={anglesData}
              manageSpecificationDesign={manageSpecificationDesign}
              defaultEditorData={defaultEditorData}
              isBackSidePrintAdded={isBackSidePrintAdded}
              isFrontSidePrintAdded={isFrontSidePrintAdded}
              pricesMin={selectedVariant?.minCost}
              pricesMax={selectedVariant?.maxCost}
              setShowColorsModal={setShowColorsModal}
              showColorsModal={showColorsModal}
              onClose={() => setActiveDrawer('')}
              type={activeDrawer}
              isTablet={isTablet}
              undoAndRedoLoadingRef={undoAndRedoLoadingRef}
              instructions={instructions}
              setShowPersonalizeEditorModal={setShowPersonalizeEditorModal}
              productIdFromPersonalization={productIdFromPersonalization}
              editorDataFromProps={editorDataFromProps}
              printAreaDimensions={printAreaDimensions}
              printSides={printSides}
            />
          </Drawer>
        </>
      ) : (
        <Section style={{ height: '100%' }}>
          {(isLoading || isProductUpdating || loadingForEditorDataSaving) && (
            <Loader />
          )}
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {activeToolbar === 'add' ? (
              <AddDesignTabs
                onClose={() => {
                  changeToolBar();
                  /** after closing resetting the tab */
                  dispatch(setCurrentTab('newDesign'));
                }}
                drawingBoard={drawingBoard}
                isTablet={isTablet}
                setAddImageLoader={setAddImageLoader}
                currentProductType={currentProductType}
                printAreaDimensions={printAreaDimensions}
                selectedAngle={selectedAngle}
                setLoading={setLoading}
                dropzoneRef={dropzoneRef}
              />
            ) : (
              <ElementList
                isSellerEditor={isSellerEditor}
                onClickAddButton={changeToolBar}
                selectedElementId={selectedElementId}
                drawingBoard={drawingBoard}
                catalogVariants={catalogVariants}
                setSelectedVariant={setSelectedVariant}
                selectedVariant={selectedVariant}
                selectedAngle={selectedAngle}
                anglesData={anglesData}
                manageSpecificationDesign={manageSpecificationDesign}
                defaultEditorData={defaultEditorData}
                setShowColorsModal={setShowColorsModal}
                showColorsModal={showColorsModal}
                undoAndRedoLoadingRef={undoAndRedoLoadingRef}
                instructions={instructions}
                setShowPersonalizeEditorModal={setShowPersonalizeEditorModal}
                productIdFromPersonalization={productIdFromPersonalization}
                editorDataFromProps={editorDataFromProps}
                printAreaDimensions={printAreaDimensions}
                printSides={printSides}
              />
            )}

            {!isSellerEditor && (
              <FlexBox
                style={{ height: '20%' }}
                direction="column"
                justifyContent="flex-end"
                gap="5px"
              >
                <FlexBox
                  justifyContent="space-between"
                  className="responsive-text"
                >
                  <span className="fs-14 ">
                    Base Cost (includes front print)
                  </span>
                  <span className="fs-14">
                    USD {selectedVariant?.minCost.toFixed(2)} -{' '}
                    {selectedVariant?.maxCost.toFixed(2)}
                  </span>
                </FlexBox>
                {isBackSidePrintAdded() && isFrontSidePrintAdded() && (
                  <FlexBox
                    justifyContent="space-between"
                    className="responsive-text"
                  >
                    <span className="fs-14">Print back side</span>
                    <span className="fs-14">USD 5.00</span>
                  </FlexBox>
                )}
                <FlexBox
                  justifyContent="space-between"
                  className="responsive-text"
                >
                  <strong>
                    <span className="fs-14">Subtotal</span>
                  </strong>
                  <strong>
                    <span className="fs-14">
                      USD{' '}
                      {isBackSidePrintAdded() && isFrontSidePrintAdded()
                        ? ((selectedVariant?.minCost ?? 0) + 5).toFixed(2)
                        : selectedVariant?.minCost.toFixed(2)}{' '}
                      -{' '}
                      {isBackSidePrintAdded() && isFrontSidePrintAdded()
                        ? ((selectedVariant?.maxCost ?? 0) + 5).toFixed(2)
                        : selectedVariant?.maxCost.toFixed(2)}
                    </span>
                  </strong>
                </FlexBox>
                <FlexBox className="buttons">
                  {!productId && (
                    <Button
                      appearance="secondary"
                      onClick={() => buildImages(false)}
                      label="Save as Draft"
                      disabled={isEmptyProductAdded()}
                      className="responsive-btn"
                    />
                  )}
                  <Button
                    appearance="primary"
                    disabled={isEmptyProductAdded()}
                    onClick={() => buildImages(true)}
                    label="Save & Continue"
                    size="large"
                    className="responsive-btn"
                  />
                </FlexBox>
              </FlexBox>
            )}
          </div>

          {personalizationData && (
            <Modal
              size="large"
              onClose={() => dispatch(updatePersonalizationElementId(''))}
              title="Add images to your listing"
            >
              <ImageContentForPersonalizationModal
                drawingBoard={drawingBoard}
                selectedElementId={selectedElementId}
              />
            </Modal>
          )}
        </Section>
      )}
    </>
  );
};

export default RightSideBar;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  .blue-tag {
    padding: 4px 10px;
    background: ${colors.blue[100]};
    border: 1px solid ${colors.blue[700]};
    border-radius: 6px;
    span {
      font-size: 12px;
      color: ${colors.grey[900]};
    }
  }
  .buttons {
    button {
      flex-grow: 1;
      margin-right: 3%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .grey-wrapper {
    border: 1px solid ${colors.grey[300]};
    background: ${colors.grey[100]};
    border-radius: 6px;
    padding: 10px;
    color: ${colors.grey[600]};
  }
`;
