import { configureStore, ThunkAction, Action, Store } from '@reduxjs/toolkit';
import editorState from './slices/editor';
import { galleryAPI } from './services/gallery';
import authSlice from './slices/authSlice';
import detailsSlice from './slices/detailsSlice';
import mockupReducer from './slices/mockupSlice';
import utilityReducer from './slices/utilitySlice';
import { productsAPI } from './services/products';
import { catalogAPI } from './services/catalog';
import createOrderSlice from './slices/createOrderSlice';
import { ordersAPI } from './services/orders';
import { errorHandlingMiddleware } from './error-handling-middleware';
import { mockupsApi } from './services/mockups';
import { myMockupsAPI } from './services/my-mockups';
import { userAPI } from './services/user';
import sampleOrderSlice from './slices/sampleOrderSlice';

export const store: Store = configureStore({
  reducer: {
    editor: editorState,
    [galleryAPI.reducerPath]: galleryAPI.reducer,
    [productsAPI.reducerPath]: productsAPI.reducer,
    [catalogAPI.reducerPath]: catalogAPI.reducer,
    [ordersAPI.reducerPath]: ordersAPI.reducer,
    [mockupsApi.reducerPath]: mockupsApi.reducer,
    [myMockupsAPI.reducerPath]: myMockupsAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    auth: authSlice,
    details: detailsSlice,
    createOrder: createOrderSlice,
    mockup: mockupReducer,
    utilities: utilityReducer,
    sampleOrder: sampleOrderSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      galleryAPI.middleware,
      productsAPI.middleware,
      catalogAPI.middleware,
      ordersAPI.middleware,
      mockupsApi.middleware,
      myMockupsAPI.middleware,
      userAPI.middleware,
      errorHandlingMiddleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
