import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import SmallLoader from './small-loader';
import styled from 'styled-components';

interface Props {
  height?: string;
  fetchData: () => void;
  hasMore: boolean;
  children: ReactNode;
  loading: boolean;
  loaderText?: string;
  showScrollBar?: boolean;
}

const InfiniteScroll: React.FC<Props> = ({
  fetchData,
  hasMore,
  children,
  loading,
  loaderText,
  height,
  showScrollBar,
}) => {
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const loadMore = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !loading) {
        fetchData();
      }
    },
    [loading, hasMore, fetchData],
  );

  useEffect(() => {
    const options = {
      threshold: 1.0,
    };

    // Observer API
    const observer = new IntersectionObserver(loadMore, options);

    const currentLoader = loaderRef.current;
    if (currentLoader) {
      observer.observe(currentLoader);
    }
    return () => observer.disconnect();
  }, [loadMore]);

  return (
    <StyledWrapper
      className={`${showScrollBar ? 'show-no-scrollbar-auto' : ''}`}
      height={height}
    >
      <div> {children}</div>
      <div ref={loaderRef}>{loading && <SmallLoader text={loaderText} />}</div>
    </StyledWrapper>
  );
};

export default InfiniteScroll;

const StyledWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? 'calc(100vh - 300px)'};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;
