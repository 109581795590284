import React from 'react';
import { FlexBox } from '../../../common/wrappers';
import Radio from '../../../common/radio-button';
import { IObject, Option, PersonalizationType } from '../../../../interface';
import { personalizationTexts } from '../../../common/contants';
import { fontFamilies } from '../../../common/fontFamilies';
import MultiSelectDropDown from '../../../multi-select-dropdown';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getObjects, setObjects } from '../../../../store/slices/editor';
import { Canvas } from 'fabric/fabric-impl';

interface Props {
  element: IObject;
  onChange: (value: any, field: string, elementId: string) => void;
  drawingBoard: Canvas | undefined;
}

const PersonalizedTextOptions: React.FC<Props> = ({ element, onChange, drawingBoard }) => {
  const files = useAppSelector(getObjects);
  const dispatch = useAppDispatch();

  const updatePersonalizationData = (selectedOptions: Option[], field: string) => {
    const filesCopy = [...files];
    const element: any = drawingBoard?.getActiveObject();
    const index = files.findIndex((obj: any) => obj.id === element.id);
    if (element) {
      if (field === 'fonts') {
        element?.set({
          personalizationData: {
            ...element.personalizationData,
            textOptions: { ...element.personalizationData.textOptions, fonts: selectedOptions}
          },
        });
        filesCopy[index] = {
          ...files[index],
          personalizationData: {
            ...files[index].personalizationData,
            textOptions: { ...files[index].personalizationData.textOptions, fonts: selectedOptions}
          },
        };
      } else {
        element?.set({
          personalizationData: {
            ...element.personalizationData,
            textOptions: { ...element.personalizationData.textOptions, colors: selectedOptions}
          },
        });
        filesCopy[index] = {
          ...files[index],
          personalizationData: {
            ...files[index].personalizationData,
            textOptions: { ...files[index].personalizationData.textOptions, colors: selectedOptions}
          },
        };
      }
      dispatch(setObjects({ objects: filesCopy }));
      drawingBoard?.renderAll();
    }
  };

  return (
    <>
      <FlexBox className="separator section" direction="column">
        <Radio
          checked={
            element.personalizationData.personalizationType ===
            PersonalizationType.ALLOW_BUYERS_TO_UPLOAD
          }
          onChange={(e) =>
            onChange(e.target.value, 'personalizationType', element.id)
          }
          id={PersonalizationType.ALLOW_BUYERS_TO_UPLOAD}
          label={personalizationTexts.allowBuyersToSelectFont}
          value={PersonalizationType.ALLOW_BUYERS_TO_UPLOAD}
          name="peronalization-type"
        />
        <Radio
          checked={
            element.personalizationData.personalizationType ===
            PersonalizationType.GIVE_BUYERS_OPTION
          }
          onChange={(e) =>
            onChange(e.target.value, 'personalizationType', element.id)
          }
          id={PersonalizationType.GIVE_BUYERS_OPTION}
          label={personalizationTexts.giveBuyersFontOptions}
          value={PersonalizationType.GIVE_BUYERS_OPTION}
          name="peronalization-type"
        />
      </FlexBox>
      {element.personalizationData.personalizationType ===
        PersonalizationType.GIVE_BUYERS_OPTION && (
        <>
          <FlexBox className="separator section">
            <MultiSelectDropDown
              selectedValues={element.personalizationData.textOptions?.fonts ?? []}
              label="Select available fonts"
              items={fontFamilies.map((font) => ({
                label: font.name,
                value: font.value,
              }))}
              onChange={(selectedFonts) => updatePersonalizationData(selectedFonts, 'fonts')}
            />
          </FlexBox>
          <FlexBox className="separator section">
            <MultiSelectDropDown
              selectedValues={element.personalizationData.textOptions?.colors ?? []}
              label="Select available colors"
              items={[{ label: 'Red', value: 'red' }, { label: 'White', value: 'white' }, { label: 'Black', value: 'black' }]}
              onChange={(selectedColors) => updatePersonalizationData(selectedColors, 'colors')}
            />
          </FlexBox>
        </>
      )}
    </>
  );
};

export default PersonalizedTextOptions;
