import React from 'react';
import { FlexBox } from './wrappers';
import { LoaderGif } from '../../assets/icons';
import Typography from '../typography';
interface Props {
  text?: string;
}
const SmallLoader: React.FC<Props> = ({ text }) => {
  return (
    <FlexBox
      className="mockup-loader"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <img height={80} src={LoaderGif} alt="loader" />
      {text && <Typography text={text} fontSize="0.8rem" />}
    </FlexBox>
  );
};

export default SmallLoader;
