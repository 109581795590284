import React from 'react';
import Button from '../../../common/button';
import styled from 'styled-components';
import { FlexBox } from '../../../common/wrappers';
import { colors } from '../../../../assets/colors';
import { useLazySendPersonalizedOrderToFulfillmentQuery } from '../../../../store/services/orders';
import Loader from '../../../common/loader';

interface Props {
  totalProducts: number;
  personalizedProducts: number;
  orderId: string;
  refetch: () => void;
}

const PersonalizationProgressAndFulfillmentButton: React.FC<Props> = ({
  personalizedProducts,
  totalProducts,
  orderId,
  refetch,
}) => {
  const width = (personalizedProducts * 100) / totalProducts;
  const [fulfillPersonalizeOrder, { isLoading }] =
    useLazySendPersonalizedOrderToFulfillmentQuery();

  const fulfillOrder = async () => {
    const res = await fulfillPersonalizeOrder({ orderId });
    if (res) {
      refetch();
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Section>
        <FlexBox alignItems="center" justifyContent="space-between">
          <div className="Progressbar">
            <div
              style={{ width: `${width}%` }}
              className="Progressbar__value"
            ></div>
            <progress value={width} max="100">
              {width}%
            </progress>
          </div>
          <p>
            {personalizedProducts} / {totalProducts} products personalised
          </p>
        </FlexBox>
        <Button
          appearance={'primary'}
          onClick={fulfillOrder}
          label="Send order for fulfillment"
          disabled={totalProducts !== personalizedProducts}
          size="large"
          className="m-0 p-10px"
        />
      </Section>
    </>
  );
};

export default PersonalizationProgressAndFulfillmentButton;

const Section = styled.div`
  padding: 8px 18px;
  background-color: white;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  .Progressbar {
    position: relative;
    width: 350px;
    height: 10px;
    border-radius: 1000px;
    margin-right: 24px;
    background-color: ${colors.grey[300]};
    clip-path: inset(0 0 0 0 round 1000px);
    .Progressbar__value {
      height: 10px;
      border-radius: 1000px 0 0 1000px;
      background-color: #3d98f2;
      will-change: width;
    }
    progress {
      opacity: 0;
      width: 1px;
      height: 1px;
      position: absolute;
      pointer-events: none;
    }
  }
`;
