import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { FlexBox } from './wrappers';
import { Cross } from '../../assets/icons';
import Checkbox from '../checkbox/Checkbox';
import { ColoredCircle } from './elements';
import { Product } from '../../interface/orders-interface';

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  onClose?: () => void;
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'auto';
  className?: string;
  checkboxHandler?: (e: boolean) => void;
  showCheckbox?: boolean;
  checked?: boolean;
  checkboxLabel?: string;
  colorBoxItem?: Product;
  showColorBox?: boolean;
}

const Modal: React.FC<Props> = ({
  children,
  title,
  onClose,
  size,
  className,
  checkboxHandler,
  showCheckbox,
  checked,
  checkboxLabel,
  colorBoxItem,
  showColorBox,
}) => {
  useEffect(() => {
    document.getElementsByTagName('body')?.[0].classList.add('modal-open');
    return () => {
      document.getElementsByTagName('body')?.[0].classList.remove('modal-open');
    };
  }, []);

  return (
    <Popup>
      <div className="overlay" />

      <Content
        size={size ?? 'small'}
        className={`show-no-scrollbar ${className}`}
      >
        <FlexBox className="modal-header">
          <FlexBox
            gap="12px"
            alignItems="center"
            justifyContent="space-between"
            className="modal-title"
          >
            <FlexBox>
              {typeof title === 'string' ? (
                <p className="fs-16">{title}</p>
              ) : (
                title
              )}
              {showCheckbox && (
                <Checkbox
                  label={checkboxLabel}
                  checked={checked}
                  onChange={(e) => {
                    if (checkboxHandler) {
                      checkboxHandler(e.target.checked);
                    }
                  }}
                />
              )}
            </FlexBox>
            {showColorBox && (
              <FlexBox
                gap="8px"
                className="product-details "
                alignItems="center"
                justifyContent="center"
              >
                <ColoredCircle
                  colorCode={`#${colorBoxItem?.colorHex}` ?? '#000000'}
                  width={18}
                  height={18}
                />
                <span className="small-dot"></span>
                <div className="product-size">{colorBoxItem?.size ?? '--'}</div>
                <span className="small-dot"></span>
                <div>{colorBoxItem?.quantity ?? '--'} items</div>
              </FlexBox>
            )}
          </FlexBox>

          {onClose && (
            <div onClick={onClose} className="pointer-cursor">
              <img src={Cross} alt="CROSS" />
            </div>
          )}
        </FlexBox>
        <div className="content">{children}</div>
      </Content>
    </Popup>
  );
};

export default Modal;

const Popup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1120;
  }
  .modal-title {
    width: 73%;
  }
`;

const Content = styled.div<{ size: string }>`
  z-index: 1200;
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 95%;
  max-height: 95%;
  position: fixed;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  width: ${(props) =>
    props.size === 'extraSmall'
      ? '328px'
      : props.size === 'small'
      ? '450px'
      : props.size === 'medium'
      ? '650px'
      : props.size === 'extraLarge'
      ? '95%'
      : '80%'};
  height: ${(props) =>
    props.size === 'extraSmall'
      ? '248px'
      : props.size === 'small'
      ? '400px'
      : props.size === 'medium'
      ? '600px'
      : props.size === 'extraLarge'
      ? '93%'
      : '80%'};

  .modal-header {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    width: 100%;
    height: inherit;
  }
`;
