interface WorkerResponse {
  [key: string]: Blob;
}

export const loadImagesWithWorker = (
  urls: string[],
  callback?: (data: WorkerResponse, err: any) => void,
): Promise<WorkerResponse> => {
  return new Promise((reslove, reject) => {
    const worker = new Worker(new URL('./image-worker.ts', import.meta.url));

    worker.onmessage = (event) => {
      if (callback) {
        callback(event.data, null);
      }
      reslove(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      if (callback) {
        callback({}, error);
      }
      reject(error);
      worker.terminate();
    };
    worker.postMessage(urls);
  });
};
