import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import ToggleSwitch from '../../common/toggle-switch';
import Checkbox from '../../checkbox';
import { SelectList } from '../../common/elements';
import { useDispatch } from 'react-redux';
import {
  productDetails,
  updateSyncingOptions,
  updatePublishingSettings,
  getDetails,
  updateIsPersonalization,
  updatePersonalizationInstructions,
} from '../../../store/slices/detailsSlice';
import { useAppSelector } from '../../../store/hooks';
import { Status, SyncingOptions } from '../../../interface';
import Typography from '../../typography';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-icon.svg';
import { fetchCurrencyRates } from '../../../utils/currency-converter';
import { nanoid } from '@reduxjs/toolkit';
import { replaceHtmlLineBreaks } from '../../../helper/commonUtils';
import { ShippingProfile } from '../../../interface/product-interface';

interface Props {
  shopCurrency?: string;
}
const PublishingSettings = React.forwardRef<HTMLDivElement, Props>(
  ({ shopCurrency }, ref) => {
    const dispatch = useDispatch();
    const {
      publishingSettings: { syncingOptions, showInStore, shippingProfile },
    } = useAppSelector(productDetails);
    const {
      shippingProfiles,
      isPersonalizable,
      personalizationInstructions,
      status,
    } = useAppSelector(getDetails);
    const [multiplier, setMultiplier] = useState(1);
    const [count, setCount] = useState(
      replaceHtmlLineBreaks(personalizationInstructions as string).length,
    );

    const selectedShippingProfile: ShippingProfile | undefined =
      shippingProfiles.find(
        (e: ShippingProfile) => e.shippingProfileId === shippingProfile,
      );

    const handleChange = (event: any) => {
      let inputValue = event.target.value;
      const valueWithLineBreaks = inputValue.replace(
        /(\r\n|\r|\n)+/g,
        (match: any) => {
          const numLineBreaks = match.length;
          return '<br>'.repeat(numLineBreaks);
        },
      );
      setCount(inputValue.length);
      dispatch(
        updatePersonalizationInstructions({ value: valueWithLineBreaks }),
      );
    };
    useEffect(() => {
      const fetchData = async () => {
        if (
          selectedShippingProfile &&
          shopCurrency !== selectedShippingProfile.primaryCost.currencyCode
        ) {
          try {
            const data = await fetchCurrencyRates(
              selectedShippingProfile.primaryCost.currencyCode,
              shopCurrency,
            );
            setMultiplier(data);
          } catch {
            setMultiplier(1);
          }
        }
      };
      fetchData();
    }, [shopCurrency, shippingProfiles, selectedShippingProfile]);

    return (
      <StyledWrapper ref={ref} className="show-no-scrollbar">
        <Heading>
          <div className="heading">Publishing settings</div>
        </Heading>
        <MainWrapper>
          {/* <div className="variant-wrapper">
            <Typography
              fontSize="14px"
              fontWeight="500"
              text="Variant visibility"
            />

            <FlexBox
              direction="column"
              gap="16px"
              className="radio-buttons-wrapper"
            >
              {varientsInfo.map((info, index) => (
                <FlexBox direction="column" gap="1px" key={index}>
                  <FlexBox gap="12px">
                    <Radio
                      id={info.value}
                      value={info.value}
                      label={info.text}
                      name={'variantsVisibility'}
                      checked={variantsVisibility === info.value ? true : false}
                      onChange={(e) =>
                        dispatch(
                          updatePublishingSettings({
                            fieldName: 'variantsVisibility',
                            value: e.target.value,
                          }),
                        )
                      }
                    />
                  </FlexBox>
                  <Typography
                    fontSize="12px"
                    fontWeight="500"
                    className="sub-text"
                    text={info.subText}
                  />
                </FlexBox>
              ))}
            </FlexBox>
          </div> */}
          {(status === Status.Published ||
            status === Status.UnpublishedChanges) && (
            <div className="sync-products-details">
              <Typography
                fontSize="14px"
                fontWeight="500"
                text="Sync products details"
              />
              <FlexBox
                direction="column"
                gap="12px"
                className="checkbox-wrapper"
              >
                {Object.keys(syncingOptions).map((option) => (
                  <Checkbox
                    key={option}
                    label={syncProductInfo(option)?.text}
                    value={option}
                    onChange={() =>
                      dispatch(
                        updateSyncingOptions({
                          key: option as keyof SyncingOptions,
                          value:
                            !syncingOptions[option as keyof SyncingOptions],
                        }),
                      )
                    }
                    checked={syncingOptions[option as keyof SyncingOptions]}
                  />
                ))}
              </FlexBox>
            </div>
          )}
          <FlexBox className="show-in-store" direction="column">
            <FlexBox
              gap="0.8rem"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography
                fontSize="14px"
                fontWeight="500"
                text="Show in store"
              />
              <ToggleSwitch
                id={nanoid()}
                checked={showInStore}
                onChange={(value) =>
                  dispatch(
                    updatePublishingSettings({
                      fieldName: 'showInStore',
                      value,
                    }),
                  )
                }
              />
            </FlexBox>

            <Typography
              text={`When 'Show in Store' is OFF, your listing will be saved as ${
                status === Status.Unpublished ? ' draft' : ' inactive'
              } on Etsy and will not be visible to the public`}
              fontSize="12px"
            />
          </FlexBox>

          <div className="shipping-profile">
            <Typography
              fontSize="14px"
              fontWeight="500"
              text="Shipping profile"
            />
            <div className="select-wrapper">
              <SelectList
                selectedValue={shippingProfile}
                width="100%"
                onChange={(value) => {
                  dispatch(
                    updatePublishingSettings({
                      fieldName: 'shippingProfile',
                      value,
                    }),
                  );
                }}
                items={[
                  ...shippingProfiles.map(
                    (shippingProfile: ShippingProfile) => ({
                      label: shippingProfile.title,
                      value: shippingProfile.shippingProfileId,
                    }),
                  ),
                  {
                    label: 'Automatically assign a new shipping profile',
                    value: 'automatically_assign',
                  },
                ]}
              />
            </div>
            {typeof shippingProfile === 'number' &&
              selectedShippingProfile?.shippingProfileId && (
                <FlexBox style={{ marginTop: 5 }} gap="5px" direction="column">
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    text="Type of shipping service: USPS"
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    text={`Time of Delivery: ${selectedShippingProfile?.minProcessingDays} - ${selectedShippingProfile?.maxProcessingDays} days`}
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    text={`Price: ${shopCurrency} ${(
                      (selectedShippingProfile.primaryCost.amount /
                        selectedShippingProfile.primaryCost.divisor) *
                      multiplier
                    ).toFixed(2)} first item,  ${shopCurrency} ${(
                      (selectedShippingProfile.secondaryCost.amount /
                        selectedShippingProfile.secondaryCost.divisor) *
                      multiplier
                    ).toFixed(2)} each additional item`}
                  />
                </FlexBox>
              )}
            {shippingProfile === 'automatically_assign' && (
              <FlexBox alignItems="center" gap="2px">
                <InfoIcon width="14px" />
                <Typography
                  text="The assigned shipping profile will be shown here after publishing."
                  fontSize="12px"
                  secondaryColorValue={600}
                />
              </FlexBox>
            )}
          </div>
          {process.env.REACT_APP_SHOW_PERSONALIZATION_FIELDS === 'true' && (
            <FlexBox
              gap="0.8rem"
              alignItems="flex-start"
              justifyContent="space-between"
              className="personalized-title personalized-product"
            >
              <Typography
                fontSize="18px"
                fontWeight="500"
                text="Personalized Product"
              />
              <ToggleSwitch
                checked={isPersonalizable}
                onChange={(value) => {
                  dispatch(updateIsPersonalization({ value: value }));
                }}
                id={nanoid(5)}
                name="personalized"
              />
            </FlexBox>
          )}
          {isPersonalizable && (
            <FlexBox
              direction="column"
              className="isPersonalizable-wrapper"
              gap="0.6rem"
            >
              <Typography
                fontSize="14px"
                fontWeight="500"
                text={`Personalization Instructions`}
              />
              <textarea
                value={
                  replaceHtmlLineBreaks(personalizationInstructions as string)
                    .length < 257
                    ? replaceHtmlLineBreaks(
                        personalizationInstructions as string,
                      )
                    : ''
                }
                onChange={handleChange}
                maxLength={256}
                placeholder="Type here"
                style={{ height: '100px', padding: '8px' }}
              />

              <Typography
                classname="count"
                text={`${count}/256`}
                fontSize="0.8rem"
                secondaryColorValue={400}
              />
            </FlexBox>
          )}
        </MainWrapper>
      </StyledWrapper>
    );
  },
);

export default PublishingSettings;

const StyledWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
  margin-bottom: 4rem;
  .personalized-title {
    border-bottom: 1px solid ${colors.grey[300]};
  }
`;
const Heading = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.grey[300]};
  .heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    font-style: normal;
  }
`;

const MainWrapper = styled.div`
  .variant-wrapper,
  .show-in-store,
  .personalized-product,
  .sync-products-details {
    padding-inline: 1rem;
    padding-block: 1.1rem;
    border-bottom: 1px solid ${colors.grey[300]};
  }
  .personalized-product {
    padding-inline: 1rem;
    padding-block: 1.1rem;
    border-top: 1px solid ${colors.grey[300]};
  }

  .sub-text {
    padding-left: 1.75rem;
  }
  .radio-buttons-wrapper,
  .checkbox-wrapper {
    padding-top: 1rem;
  }
  .shipping-profile,
  .isPersonalizable-wrapper {
    padding-inline: 1rem;
    padding-block: 1.1rem;
  }
  .select-wrapper {
    padding-top: 8px;
    padding-right: 1.2rem;
  }
  .count {
    text-align: end;
  }
`;

const syncProductInfo = (option: string) => {
  const array = [
    {
      text: 'Product title',
      value: 'syncProductTitle',
    },
    {
      text: 'Description',
      value: 'syncDescription',
    },
    {
      text: 'Images',
      value: 'syncMockups',
    },
    {
      text: 'Colors, sizes, prices and SKUs',
      value: 'syncVariants',
    },
    {
      text: 'Shipping profile',
      value: 'syncShippingProfiles',
    },
  ];
  return array.find((obj) => obj.value === option);
};
