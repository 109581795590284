import styled from 'styled-components';
import { colors } from '../../assets/colors';

interface Props {
  width?: string;
  height?: string;
  borderColor?: string;
  isThereImage?: Boolean;
  mainImage?: boolean;
}

const ImagePlaceholder = styled.div<Props>`
  width: ${(props) => props.width ?? '120px'};
  height: ${(props) => props.height ?? '120px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  /* border: 2px dashed ${(props) => props.borderColor ?? colors.grey[400]}; */
  /* border: ${(props) =>
    props.isThereImage &&
    `2px dashed ${props.borderColor ?? colors.grey[300]}`}; */
  border: ${({ isThereImage, mainImage }) =>
    isThereImage
      ? mainImage
        ? `2px solid ${colors.grey[300]}`
        : `1px solid ${colors.grey[300]}`
      : mainImage
      ? `2px dashed ${colors.grey[500]}`
      : `1px dashed ${colors.grey[500]}`};
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  & > img {
    width: 55%;
    height: 55%;
  }
`;

export { ImagePlaceholder };
