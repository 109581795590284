import styled, { css } from 'styled-components';
import { Checked } from '../../assets/icons';
import { colors } from '../../assets/colors';

interface CheckboxProps {
  className?: string;
  checked: boolean;
  id?: string;
  label?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  id,
  label,
  checked,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <CheckboxContainer className={className}>
      <label>
        <HiddenCheckbox
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onChange(e.target.checked);
          }}
          {...props}
        />
        <StyledCheckbox
          id={id}
          size="20px"
          checked={checked}
          disabled={disabled}
        >
          {checked && <img src={Checked} alt="checked-icon" />}
        </StyledCheckbox>
        {label && <span>{label}</span>}
      </label>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  label {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
  }
`;

// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  size: string;
  disabled?: boolean;
}>`
  min-width: 18px;
  min-height: 18px;
  width: ${(props) => props.size ?? '18px'};
  height: ${(props) => props.size ?? '18px'};
  padding: calc(${(props) => props.size} * 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? 'blue' : 'black')};
  border-radius: 3px;
  transition: all 50ms ease-out;
  background: ${colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
  box-shadow: ${(props) =>
    props.checked
      ? '0px 0px 0px 2px rgba(85, 164, 242, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2E8AE5'
      : '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)'};
  & img {
    width: 100%;
    height: 100%;
    // padding: calc(${(props) => props.size} * 0.2);
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0px 0px 0px 2px rgba(85, 164, 242, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }
`;

export default Checkbox;
