import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../common/wrappers';
import { Option } from '../../interface';
import { colors } from '../../assets/colors';
import { nanoid } from '@reduxjs/toolkit';
interface Props {
  width?: string;
  height?: string;
  fontSize?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  className?: string;
}
interface ComponentProps extends Props {
  items: Option[];
  gapBetweenEachItem?: string;
  onClick: (value: string) => void;
}
const MenuList: React.FC<ComponentProps> = ({
  width,
  height,
  fontSize,
  top,
  left,
  right,
  bottom,
  className,
  items,
  gapBetweenEachItem,
  onClick,
}) => {
  return (
    <StyledWrapper
      width={width}
      height={height}
      fontSize={fontSize}
      left={left}
      top={top}
      bottom={bottom}
      right={right}
      className={className}
    >
      <FlexBox
        gap={gapBetweenEachItem ? gapBetweenEachItem : ''}
        direction="column"
      >
        {items.map((option) => (
          <div
            key={nanoid()}
            className="menu-item"
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </div>
        ))}
      </FlexBox>
    </StyledWrapper>
  );
};

export default MenuList;

const StyledWrapper = styled.div<Props>`
  width: ${(props) => (props.width ? props.width : 'max-content')};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  height: ${(props) => (props.height ? props.height : 'max-content')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  color: ${colors.grey[900]};
  position: absolute;
  z-index: 2;
  border: 1px solid ${colors.grey[300]};
  background-color: ${colors.white};
  border-radius: 6px;
  .menu-item {
    padding: 9px;
    cursor: pointer;
  }
`;
