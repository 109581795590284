import React from 'react';
import styled from 'styled-components';
import ProductSection from './product-section';
import RecipientSection from './recipient-section';
import { FlexBox } from '../../common/wrappers';
import AddressLayout from './address-layout';
import ShippingSection from './shipping-section';
import BillingSection from './billing-section';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getCreateOrderDetails,
  setModalStatus,
} from '../../../store/slices/createOrderSlice';
import { CreateorderModals } from '../../../interface/create-order-interface';

const MainContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { billingAddress, shippingAddress } = useAppSelector(
    getCreateOrderDetails,
  );
  return (
    <StyledWrapper>
      <FlexBox direction="column" gap="22px" className="left-side-content">
        <ProductSection />
        <RecipientSection />
        <FlexBox gap="24px" className="address-wrapper">
          <AddressLayout
            title="Shipping address"
            firstLine={shippingAddress.firstLine}
            address={shippingAddress.address}
            city={shippingAddress.city}
            country={shippingAddress.country}
            state={shippingAddress.state}
            zipCode={shippingAddress.zipCode}
            onClick={() =>
              dispatch(setModalStatus(CreateorderModals.ShippingAddressModal))
            }
          />
          <AddressLayout
            title="Billing address"
            address={billingAddress.address}
            firstLine={billingAddress.firstLine}
            city={billingAddress.city}
            country={billingAddress.country}
            state={billingAddress.state}
            zipCode={billingAddress.zipCode}
            onClick={() =>
              dispatch(setModalStatus(CreateorderModals.BillingAddressModal))
            }
          />
        </FlexBox>
      </FlexBox>
      <FlexBox gap="1.5rem" direction="column" className="right-side-content">
        <ShippingSection />
        <BillingSection />
      </FlexBox>
    </StyledWrapper>
  );
};

export default MainContent;
const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 1.5rem;

  .address-wrapper {
    width: 100%;
  }
`;
