import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../constants/routesPath';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      {sidebarItems.map((item) => (
        <div
          key={item.name}
          onClick={() => navigate(item.pathName)}
          className={`sidebar-item ${
            location.pathname === item.pathName ||
            (item.name === 'My account' &&
              location.pathname === routeNames.userSettings)
              ? 'active'
              : ''
          }`}
        >
          {item.name}
        </div>
      ))}
    </StyledWrapper>
  );
};

export default Sidebar;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  .sidebar-item {
    border-radius: 6px;
    padding: 14px;
    background-color: ${colors.white};
    cursor: pointer;
  }
  .active {
    border-left: 6px solid ${colors.grey[900]};
  }
`;
interface Items {
  name: string;
  pathName: string;
}
const sidebarItems: Items[] = [
  { name: 'My Library', pathName: routeNames.myLibrary },
];
