/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MuiDataTable from '../../table/MuiDataTable';
import { myProductColumns } from './my-product-columns';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteProductByIdMutation,
  useDuplicateProductMutation,
  usePublishProductMutation,
} from '../../../store/services/products';
import Loader from '../../common/loader';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { socket } from '../../../helper/socket';
import { useAppSelector } from '../../../store/hooks';
import { getUserData } from '../../../store/slices/authSlice';
import ConfirmationModal from '../../common/confirmation-modal';
import Typography from '../../typography';
interface UpdateProductStatus {
  productId: string;
  status: string;
}
interface Props {
  rowData: any[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  currentPage: number;
  refetchProduct: () => void;
  isTablet: boolean;
}
const MyProductsTable: React.FC<Props> = ({
  rowData,
  setCurrentPage,
  currentPage,
  totalPages,
  refetchProduct,
  isTablet,
}) => {
  const [productInfo, setProductInfo] = useState<UpdateProductStatus>({
    productId: '',
    status: '',
  });
  const [productId, setProductId] = useState<string | null>(null);
  const { userInfo } = useAppSelector(getUserData);
  const [duplicateProduct, { isLoading: isDuplicating }] =
    useDuplicateProductMutation();
  const apiRef = useGridApiRef();

  const [deleteProduct, { isLoading }] = useDeleteProductByIdMutation();
  const [publishProduct, { isLoading: publishingLoader }] =
    usePublishProductMutation();

  const navigate = useNavigate();
  const publishProductHandler = async (productId: string) => {
    await publishProduct({ productIds: [productId] });
  };

  useEffect(() => {
    socket.emit('join', userInfo.id);

    const onMockupsUploaded = (data: UpdateProductStatus) => {
      setProductInfo(data);
    };
    socket.on('imagesUploadedToEtsy', onMockupsUploaded);
    return () => {
      socket.off('imagesUploadedToEtsy', onMockupsUploaded);
    };
  }, []);

  useEffect(() => {
    if (productInfo.productId && rowData.length > 0) {
      const foundProduct = rowData.find(
        (product) => product.id === productInfo.productId,
      );
      if (foundProduct) {
        apiRef.current.updateRows([
          ...rowData,
          { ...foundProduct, status: productInfo.status },
        ]);
      }
    }
  }, [productInfo, rowData]);

  useEffect(() => {
    if (refetchProduct && totalPages !== 1 && rowData.length === 0) {
      refetchProduct();
    }
  }, [rowData, refetchProduct]);

  const duplicateHandler = async (productId: string) => {
    await duplicateProduct([productId as string]);
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      refetchProduct();
    }
  };

  const deleteProductHandler = (productId: string) => {
    setProductId(productId);
  };
  return (
    <StyledWrapper isTablet={isTablet}>
      {(isLoading || publishingLoader || isDuplicating) && <Loader />}
      <Typography
        className="info-text"
        text="**Note: * indicates listing was published as Draft to Etsy."
        fontSize="13px"
      />
      <MuiDataTable
        columns={myProductColumns(
          deleteProductHandler,
          publishProductHandler,
          duplicateHandler,
          isTablet,
        )}
        rowData={rowData}
        rowHeight={100}
        pagination={true}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowClickHandler={(params) => navigate(`/details/${params.id}`)}
        apiRef={apiRef}
      />
      {productId && (
        <ConfirmationModal
          onClose={() => setProductId(null)}
          text={'Are you sure you want to delete this product?'}
          buttonColor="red"
          headingText="Delete"
          buttonLabel="Delete"
          onClick={async () => {
            await deleteProduct([productId as string]);
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default MyProductsTable;

const StyledWrapper = styled.div<{ isTablet: boolean }>`
  max-height: 810px;
  width: 100%;
  height: ${(props) => (props.isTablet ? '67vh' : '62vh')};
  .info-text {
    display: inline-block;
    width: 100%;
    text-align: end;
    padding-bottom: 10px;
  }
`;
