import React from 'react';
import { FlexBox } from '../../../common/wrappers';
import Radio from '../../../common/radio-button';
import { IObject, PersonalizationType } from '../../../../interface';
import { personalizationTexts } from '../../../common/contants';
import { useAppDispatch } from '../../../../store/hooks';
import { updatePersonalizationElementId } from '../../../../store/slices/editor';
import { FolderIcon } from '../../../../assets/icons';

interface Props {
  element: IObject;
  onChange: (value: any, field: string, elementId: string) => void;
}

const PersonalizedImageOptions: React.FC<Props> = ({ element, onChange }) => {
  const dispatch = useAppDispatch();
  const type = element.type;
  return (
    <>
      <FlexBox className="separator section" direction="column">
        <Radio
          checked={
            element.personalizationData.personalizationType ===
            PersonalizationType.ALLOW_BUYERS_TO_UPLOAD
          }
          onChange={(e) =>
            onChange(e.target.value, 'personalizationType', element.id)
          }
          id={PersonalizationType.ALLOW_BUYERS_TO_UPLOAD}
          label={personalizationTexts.allowBuyersToUpload}
          value={PersonalizationType.ALLOW_BUYERS_TO_UPLOAD}
          name="peronalization-type"
        />
        <Radio
          checked={
            element.personalizationData.personalizationType ===
            PersonalizationType.GIVE_BUYERS_OPTION
          }
          onChange={(e) =>
            onChange(e.target.value, 'personalizationType', element.id)
          }
          id={PersonalizationType.GIVE_BUYERS_OPTION}
          label={personalizationTexts.giveBuyersOptions}
          value={PersonalizationType.GIVE_BUYERS_OPTION}
          name="peronalization-type"
        />
      </FlexBox>
      {element.personalizationData.personalizationType ===
        PersonalizationType.GIVE_BUYERS_OPTION && (
        <FlexBox
          onClick={() => dispatch(updatePersonalizationElementId(element.id))}
          className="separator pointer-cursor"
          style={{
            margin: '0px 14px 0px 14px',
            padding: '14px 14px 14px 0px',
          }}
          alignItems="center"
        >
          <img
            style={{ marginRight: '8px' }}
            src={FolderIcon}
            alt="Select Images"
            className="icon"
            width={20}
            height={20}
          />
          {type === 'custom-image' ? (
            <span className="fs-14">
              {element.personalizationData.imageOptions &&
              element.personalizationData.imageOptions.length > 0
                ? element.personalizationData.imageOptions?.length + ' images'
                : 'Select image'}
            </span>
          ) : (
            <span>Image options</span>
          )}
        </FlexBox>
      )}
    </>
  );
};

export default PersonalizedImageOptions;
