import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import { colors } from '../../../../assets/colors';
import { Customer } from '../../../../interface/create-order-interface';
import CustomToolTip from '../../../custom-tooltip';
interface Props extends Customer {
  openModal: () => void;
}
const CustomerSection: React.FC<Props> = ({
  openModal,
  email,
  fullName,
  phone,
  designFileUrl,
}) => {
  return (
    <StyledWrapper>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        className="heading-wrapper"
      >
        <Typography fontSize="18px" text="Customer" />
        {/* <Button
          label="Edit"
          onClick={() => openModal()}
          appearance="secondary"
          size="medium"
        /> */}
      </FlexBox>
      <FlexBox direction="column" gap="12px" className="customer-info">
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          className="w-100"
        >
          <Typography fontSize="14px" text="Full name" />
          <Typography fontSize="14px" text={fullName} />
        </FlexBox>
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          className="w-100"
        >
          <Typography fontSize="14px" text="Email" />
          <Typography fontSize="14px" text={email} />
        </FlexBox>
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          className="w-100"
        >
          <Typography fontSize="14px" text="Phone" />
          <Typography fontSize="14px" text={phone} />
        </FlexBox>
        {designFileUrl && (
          <FlexBox
            justifyContent="space-between"
            alignItems="center"
            className="w-100"
          >
            <Typography fontSize="14px" text="File" />
            <CustomToolTip
              placement="top"
              title={
                <img
                  src={designFileUrl}
                  height={150}
                  width={150}
                  alt="Customer design"
                  style={{
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: 5,
                  }}
                />
              }
            >
              <img
                src={designFileUrl}
                height={30}
                width={30}
                alt="Customer design"
                style={{
                  border: `1px solid ${colors.grey[300]}`,
                  borderRadius: 5,
                }}
              />
            </CustomToolTip>
          </FlexBox>
        )}
      </FlexBox>
      {/* 
      <FlexBox justifyContent="space-between" className="file-info">
        <Typography fontSize="14px" text="File" />
        <FlexBox gap="8px">
          <Typography fontSize="14px" text="Bee.png" />
        </FlexBox>
      </FlexBox>
      <FlexBox justifyContent="space-between" className="notes-info">
        <Typography fontSize="14px" text="Notes" />

        <FlexBox gap="8px">
          <Typography
            fontSize="14px"
            text="Custom image of the bee should be used"
          />
        </FlexBox>
      </FlexBox> */}
    </StyledWrapper>
  );
};

export default CustomerSection;

const StyledWrapper = styled.div`
  .heading-wrapper {
    border-bottom: 1px solid ${colors.grey.border};
    padding: 12px 14px;
  }
  .customer-info,
  .file-info,
  .notes-info {
    padding: 12px 14px;
    border-bottom: 1px solid ${colors.grey.border};
  }
`;
