import React, { useState } from 'react';
import Typography from '../typography';
import Banner from '../common/banner';
import ConfirmationModal from '../common/confirmation-modal';
import PaymentModal from '../payment-modal';
import { useUpdatePaymentMethodMutation } from '../../store/services/orders';
import { StripeErrorCodes } from '../../interface/orders-interface';
import Loader from '../common/loader';
import { useAppSelector } from '../../store/hooks';
import { AuthInitialState, getUserData } from '../../store/slices/authSlice';

const PaymentBanner: React.FC = () => {
  const {
    userInfo: {
      paymentMethodId,
      paymentFailedForOrders,
      orderFailureReason,
      orderCount,
    },
  }: AuthInitialState = useAppSelector(getUserData);

  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');

  const [updatePaymentMethod, { isLoading, isSuccess }] =
    useUpdatePaymentMethodMutation();

  let textMessage;

  if (paymentFailedForOrders) {
    if (orderFailureReason === StripeErrorCodes.EXPIRED_CARD) {
      textMessage = (
        <>
          Oops! Your payment card has expired.{' '}
          <span
            className="underline-text"
            onClick={() => setOpenPaymentModal(true)}
          >
            Update your payment info
          </span>{' '}
          to ensure uninterrupted order processing.
        </>
      );
    } else if (orderFailureReason === StripeErrorCodes.INSUFFICIENT_FUNDS) {
      textMessage = (
        <>
          Payment unsuccessful due to insufficient funds.{' '}
          <span
            className="underline-text"
            onClick={() => setOpenPaymentModal(true)}
          >
            Update your payment method{' '}
          </span>{' '}
          or{' '}
          <span
            className="underline-text"
            onClick={() => setShowRetryModal(true)}
          >
            try again
          </span>{' '}
          to keep your orders moving.
        </>
      );
    } else {
      textMessage = (
        <>
          Oops! There was an issue processing your payment. Please{' '}
          <span
            className="underline-text"
            onClick={() => setOpenPaymentModal(true)}
          >
            Update your payment method{' '}
          </span>{' '}
          or{' '}
          <span
            className="underline-text"
            onClick={() => setShowRetryModal(true)}
          >
            try again
          </span>{' '}
        </>
      );
    }
  } else if (!paymentMethodId && orderCount > 0) {
    textMessage = (
      <>
       Congratulations on your first sale! 🎉 To proceed with order fulfillment, kindly{' '}
        <span
          className="underline-text"
          onClick={() => setOpenPaymentModal(true)}
        >
          update your payment details
        </span>{' '}
        now.
      </>
    );
  }

  const processPayment = async (retry: boolean, paymentMethodId?: string) => {
    try {
      await updatePaymentMethod({ retry, paymentMethodId }).unwrap();
      setOpenPaymentModal(false);
      setPaymentError('');
      textMessage = null;
    } catch (error: any) {
      setPaymentError(error.data.message);
      setOpenPaymentModal(true);
    }
  };

  return (
    <>
      {textMessage && !isSuccess && (
        <>
          {isLoading && <Loader />}
          <Banner
            text={
              <Typography
                text={textMessage}
                fontSize="0.9rem"
                secondaryColorValue={900}
              />
            }
            type={paymentFailedForOrders && textMessage ? 'error' : 'warning'}
          />

          {/* Payment modal */}

          {openPaymentModal && (
            <PaymentModal
              onCloseModal={() => setOpenPaymentModal(false)}
              onMakePayment={async (paymentMethodId) => {
                await processPayment(false, paymentMethodId);
              }}
              errorMessage={paymentError}
              setPaymentError={setPaymentError}
              selectedPaymentMethodId={paymentMethodId}
            />
          )}

          {/* Retry Payment Modal */}

          {showRetryModal && (
            <ConfirmationModal
              buttonColor="primary"
              buttonLabel="Proceed"
              headingText="Try again"
              text="Are you sure you want to retry the payment? This action will attempt to process payments for all subsequent orders currently on hold"
              onClick={async () => {
                await processPayment(true);
              }}
              onClose={() => setShowRetryModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default PaymentBanner;
