import React from 'react';

import UserSettings from '../../components/user-settings';
import { TabletProps } from '../../interface';
import BaseLayout from '../../components/layout/base-layout';

const UserSettingsPage: React.FC<TabletProps> = ({ isTablet }) => {
  return (
    <BaseLayout>
      <UserSettings isTablet={isTablet} />
    </BaseLayout>
  );
};

export default UserSettingsPage;
