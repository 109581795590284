import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { FlexBox } from '../../../common/wrappers';
import { CustomBtnWithIcon } from '../../../common/elements';
import { Canvas } from 'fabric/fabric-impl';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getEditorData,
  getObjects,
  setCurrentTab,
  setBase64Cache,
} from '../../../../store/slices/editor';
import { addCustomImage } from '../../../../classes/image';
import { TextIcon, UploadIcon } from '../../../../assets/icons';
import { NewPersonalizedImagePlaceHolder } from '../../../../assets/icons';
import { addText } from '../../../../classes/text';
import { ReactComponent as CloseIcon } from '../../../../assets/images/cross.svg';
import { designTabs } from '../../../../constants/designTabs';
import MyFiles from '../../../user-settings/my-files';
import {
  blobToBase64,
  getImageBlobFromURL,
} from '../../../../helper/convertB64toBlob';
import { Side } from '../../../../interface/catalog-interface-v2';
import { DropzoneRef } from 'react-dropzone';

interface Props {
  drawingBoard: Canvas | undefined;
  onClose: () => void;
  isTablet: boolean;
  setAddImageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  currentProductType: string | undefined;
  printAreaDimensions: Side[] | undefined;
  selectedAngle: string;
  setLoading: (loading: boolean) => void;
  dropzoneRef: React.MutableRefObject<DropzoneRef | null>;
}

const AddDesignTabs: React.FC<Props> = ({
  drawingBoard,
  onClose,
  isTablet,
  setAddImageLoader,
  currentProductType,
  dropzoneRef,
  printAreaDimensions,
  selectedAngle,
  setLoading,
}) => {
  const files = useAppSelector(getObjects);
  const { currentTab } = useAppSelector(getEditorData);
  const dispatch = useAppDispatch();

  const onClickUploadButton = async (
    personalizing: boolean,
    imgUrl?: string,
    name?: string,
  ) => {
    if (personalizing) {
      setAddImageLoader(true);
      if (imgUrl) {
        setLoading(true);
        getImageBlobFromURL(imgUrl + '?not-from-cache-please').then((blob) => {
          const blobUrl = URL.createObjectURL(blob);

          blobToBase64(blob, (base64: string) => {
            setAddImageLoader(true);
            setLoading(true);
            dispatch(setBase64Cache({ blobUrl, base64 }));

            addCustomImage(
              blobUrl,
              drawingBoard,
              files,
              true,
              true,
              name,
              currentProductType,
              imgUrl,
            );
          });
        });
      } else {
        setLoading(true);
        fetch(imgUrl ?? NewPersonalizedImagePlaceHolder).then((response) => {
          response.blob().then((response) => {
            blobToBase64(response, (base64: string) => {
              addCustomImage(
                base64,
                drawingBoard,
                files,
                true,
                false,
                '',
                currentProductType,
              );
            });
          });
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentTab('newDesign'));
    };
  }, [dispatch]);

  /** get the printarea dimensions based on current angle */
  const printAreaDimension = useMemo(() => {
    if (selectedAngle && printAreaDimensions) {
      return printAreaDimensions.find((p) => p.side === selectedAngle)
        ?.designFileDimensionsInPX;
    }
  }, [printAreaDimensions, selectedAngle]);

  return (
    <FlexBox direction="column" style={{ margin: isTablet ? '15px' : '0' }}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <p className="grey900 fs-18">Add Design</p>
        <CloseIcon className="pointer-cursor" onClick={onClose} />
      </FlexBox>
      <Tabs className="Tabs" isTablet={isTablet}>
        {designTabs.map((tabItem, index) => {
          return (
            <span
              key={tabItem.name}
              className={`${currentTab === tabItem.name ? 'active' : ''}`}
              onClick={() =>
                dispatch(
                  setCurrentTab(tabItem.name as 'myLibrary' | 'newDesign'),
                )
              }
            >
              {tabItem.displayName}
            </span>
          );
        })}
      </Tabs>

      {currentTab === designTabs[0].name && (
        <FlexBox direction="column">
          <RequirementWrapper
            onClick={() => {
              if (dropzoneRef.current) {
                dropzoneRef.current.open();
              }
            }}
          >
            <FlexBox
              alignItems="center"
              className="upload-btn pointer-cursor"
              gap="12px"
            >
              <img src={UploadIcon} alt="add icon" />
              <span className="fs-14">Upload Image</span>
            </FlexBox>
            <InstructionsWrapper>
              <p>Print file requirements</p>
              <ul>
                {printAreaDimension && (
                  <li>
                    Print area size {printAreaDimension.width} ×{' '}
                    {printAreaDimension.height} px (300 DPI)
                  </li>
                )}
                <li>JPG, PNG, and SVG file types supported</li>
                <li>Maximum 100 MB (JPG, PNG) or 20 MB (SVG)</li>
                <li>Maximum resolution 25000 x 25000 px</li>
              </ul>
            </InstructionsWrapper>
          </RequirementWrapper>

          <CustomBtnWithIcon
            onClick={() => {
              addText(drawingBoard, files, false, currentProductType);
              onClose();
            }}
          >
            <img src={TextIcon} alt="Text" />
            <p style={{ fontSize: '14px' }}>Custom Text</p>
          </CustomBtnWithIcon>

          {/* <CustomBtnWithIcon onClick={() => onClickUploadButton(true)}>
                <img src={PersonalizeImage} alt="personalize" />
                <p style={{ fontSize: '14px' }}>Personalized image</p>
                <img src={InfoIcon} alt="Info" />
              </CustomBtnWithIcon>

              <CustomBtnWithIcon
                onClick={() => addText(drawingBoard, files, true)}
              >
                <img src={PersonalizedTextIcon} alt="Peronalized Text" />
                <p style={{ fontSize: '14px' }}>Personalized text</p>
                <img src={InfoIcon} alt="Info" />
              </CustomBtnWithIcon> */}
        </FlexBox>
      )}

      {currentTab === designTabs[1].name && (
        <MyFiles
          isInLibrary={true}
          addtoBoard={(src: string, name: string) =>
            onClickUploadButton(true, src, name)
          }
          isTablet={isTablet}
          onClose={onClose}
        />
      )}
    </FlexBox>
  );
};

export default AddDesignTabs;

export const Tabs = styled.div<{ isTablet?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isTablet ? '10px 24px 0px 24px' : '0')};
  span {
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.grey[600]};
    padding: 7px;
    border-bottom: 1px solid ${colors.grey[300]};
    width: 0;
    flex: 1 1 0px;
    flex-grow: 1;
    text-align: center;
    &.active {
      border-bottom: 1px solid ${colors.grey[900]};
      color: ${colors.grey[900]};
    }
  }
`;

export const RequirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  background: #fafafa;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 14px;
`;

export const InstructionsWrapper = styled.div`
  padding: 0px;
  margin: 0px;

  li {
    font-size: 14px;
    margin: 5px;
    color: ${colors.grey[700]};
    font-weight: 100;
  }
  ul {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  p {
    margin: 15px 0 10px;
  }
`;
