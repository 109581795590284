import React, { useEffect } from 'react';
import amplitude from 'amplitude-js';
import styled from 'styled-components';
import Modal from '../../components/common/modal';
import Button from '../../components/common/button';
import { colors } from '../../assets/colors';
import { FlexBox } from './wrappers';
import { ReactComponent as CreativityIcon } from '../../assets/images/creativity.svg';
import { ReactComponent as EtsyIcon } from '../../assets/images/email.svg';
import { ReactComponent as TShirtIcon } from '../../assets/images/tshirt.svg';
import Typography from '../typography';
import { Divider } from '../order-details/details/billing';
import { useAppSelector } from '../../store/hooks';
import { AuthInitialState, getUserData } from '../../store/slices/authSlice';
const scopes =
  'address_r address_w billing_r cart_r cart_w email_r favorites_r favorites_w feedback_r listings_d listings_r listings_w profile_r profile_w recommend_r recommend_w shops_r shops_w transactions_r transactions_w';

interface Props {
  errorMessage?: string | null;
  onClose?: () => void;
}
const EtsyConnectModal: React.FC<Props> = ({ errorMessage, onClose }) => {
  const { userInfo }: AuthInitialState = useAppSelector(getUserData);

  const connectToEtsy = async () => {
    amplitude.getInstance().logEvent('Print - Clicked Connect Store');
    window.open(
      `https://www.etsy.com/oauth/connect?response_type=code&client_id=${process.env.REACT_APP_ETSY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BACKEND_URL}auth/etsy-oauth&scope=${scopes}&state=${userInfo.email}_${userInfo.id}&code_challenge=${process.env.REACT_APP_ETSY_CODE_CHALLENGE}&code_challenge_method=${process.env.REACT_APP_ETSY_CODE_CHALLENGE_METHOD}`,
      '_self',
    );
  };

  useEffect(() => {
    if (userInfo.everbeeProdUserId) {
      amplitude.getInstance().logEvent('Print - View Connect Store Modal');
    }
  }, [userInfo]);
  return (
    <MainWrapper>
      <Modal
        title="Connect your Etsy Store to EverBee Print"
        onClose={onClose ? () => onClose() : undefined}
        className="etsy-modal"
      >
        {errorMessage && (
          <Typography
            text={errorMessage}
            fontSize="1rem"
            className="error-message"
          />
        )}
        <FlexBox direction="column" gap="1rem">
          <FlexBox gap="1rem">
            <Tiles width="50%" color="blue">
              <FlexBox className="icon">
                <CreativityIcon width="100%" height="50%" />
              </FlexBox>
              <FlexBox direction="column" gap="10px">
                <Typography
                  text="Create your design"
                  fontSize="1.2rem"
                  fontWeight="700"
                />
                <Typography
                  text="Design your products using our intuitive tools"
                  fontSize="0.8rem"
                  fontWeight="500"
                />
              </FlexBox>
            </Tiles>
            <Tiles width="50%" color="orange">
              <FlexBox className="icon">
                <EtsyIcon width="100%" height="50%" />
              </FlexBox>

              <FlexBox direction="column" gap="10px">
                <Typography
                  text="Publish on Etsy"
                  fontSize="1.2rem"
                  fontWeight="700"
                />
                <Typography
                  text="Easily publish your products on Etsy store"
                  fontSize="0.8rem"
                  fontWeight="500"
                />
              </FlexBox>
            </Tiles>
          </FlexBox>
          <Tiles width="-webkit-fill-available" color="green">
            <FlexBox className="icon">
              <TShirtIcon width="100%" height="50%" />
            </FlexBox>

            <FlexBox direction="column" gap="10px">
              <Typography
                text="EverBee Print will handle the rest"
                fontSize="1.2rem"
                fontWeight="700"
              />
              <Typography
                text="Sit back and let EverBee Print manage production and delivery"
                fontSize="0.8rem"
                fontWeight="500"
              />
            </FlexBox>
          </Tiles>
        </FlexBox>
        {/* instructions */}

        <FlexBox gap="1.5rem" direction="column">
          <Typography
            text="Here are your instructions:"
            fontSize="1.12rem"
            fontWeight="700"
          />
          <FlexBox direction="column">
            <FlexBox gap="1rem" alignItems="center">
              <div className="colored-circle">1</div>
              <Typography
                text="Log in to your Etsy Store"
                fontSize="1.1rem"
                fontWeight="400"
              />
            </FlexBox>
            <Divider className="divider" />
            <FlexBox gap="1rem" alignItems="center">
              <div className="colored-circle">2</div>
              <Typography
                text="Confirm store details"
                fontSize="1.1rem"
                fontWeight="400"
              />
            </FlexBox>
            <Divider className="divider" />
            <FlexBox gap="1rem" alignItems="center">
              <div className="colored-circle">3</div>
              <Typography
                text='Click "Grant Access"'
                fontSize="1.1rem"
                fontWeight="400"
              />
            </FlexBox>
            <Divider className="divider" />
            <FlexBox gap="1rem" alignItems="center">
              <div className="colored-circle">4</div>
              <Typography
                text="Wait for confirmation"
                fontSize="1.1rem"
                fontWeight="400"
              />
            </FlexBox>
          </FlexBox>
          <Typography
            text="**Please make sure you are logged into the correct Etsy account
              before connecting your shop."
            fontSize="15px"
            primaryColorValue={900}
          />
        </FlexBox>

        <Button
          className="connect-btn"
          appearance="primary"
          label="Connect"
          size="large"
          onClick={connectToEtsy}
        ></Button>
      </Modal>
    </MainWrapper>
  );
};

export default EtsyConnectModal;

const MainWrapper = styled.div`
  .etsy-modal {
    width: 900px;
    height: fit-content;
    max-height: 100%;
    padding: 9px 15px;
    .modal-header {
      p {
        font-size: 1.12rem;
        font-weight: 700;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .connect-btn {
        align-self: flex-end;
        width: 7.5rem;
      }
      .error-message {
        color: ${colors.red[600]};
      }
    }
  }
  .colored-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${colors.blue[100]};
    text-align: center;
    line-height: 2rem;
  }
  .divider {
    border-color: ${colors.grey[400]};
    transform: rotate(90deg);
    width: 10px;
    height: 20px;
  }
`;

const Tiles = styled.div<{
  width?: string;
  color?: 'blue' | 'orange' | 'green';
}>`
  width: ${({ width }) => width};
  padding: 1rem;
  height: auto;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${({ color }) =>
    color === 'blue'
      ? colors.blue[100]
      : color === 'orange'
      ? '#FFE8D3'
      : '#CFF9DB'};
  .icon {
    background-color: ${colors.white};
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    & :first-child {
      margin: auto;
    }
  }
`;
