import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import { ArrowIcon, EtsyIcon } from '../../../assets/icons';
import Button from '../../common/button';
import { useState } from 'react';
import CancelOrderModal from '../details/modals/cancel-order-modal';
import Typography from '../../typography';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetOrderByIdResponse,
  OrderStatus,
} from '../../../interface/orders-interface';
import { routeNames } from '../../../constants/routesPath';
import EtsyCancelOrderModal from '../details/modals/etsy-cancel-modal';
import { useCancelOrderMutation } from '../../../store/services/orders';

interface Props {
  orderDetails?: GetOrderByIdResponse;
}
const Title: React.FC<Props> = ({ orderDetails }) => {
  const { orderId } = useParams();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [etsyCancelModal, setEtsyCancelModal] = useState<boolean>(false);
  const [, { isSuccess }] = useCancelOrderMutation({
    fixedCacheKey: orderId,
  });
  const navigate = useNavigate();

  return (
    <StyledWrapper>
      <FlexBox
        alignItems="center"
        gap="1rem"
        className="navigate-back"
        justifyContent="space-between"
      >
        <FlexBox gap="10px" onClick={() => navigate(routeNames.orders)}>
          <img src={ArrowIcon} alt="go-back" />
          <Typography fontSize="1rem" text="Back to Orders" />
        </FlexBox>
        {/* <SampleOrderButton /> */}
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center">
        <FlexBox gap="12px" className="heading">
          <img src={EtsyIcon} alt="shop" />
          <Typography fontSize="1.5rem" text={`#${orderId} `} />
        </FlexBox>
        <FlexBox gap="1rem" alignItems="center">
          {!orderDetails?.data.externalOrderId &&
            orderDetails?.data.status !== OrderStatus.CANCELLED &&
            !isSuccess && (
              <>
                {/* <SelectList
                items={submitIssueList}
                label="Submit issue"
                onChange={(value) => console.log(value)}
                selectedValue=""
                className="select-box"
              />
              <Button
                label="Upgrade shipping"
                onClick={() => console.log('object')}
                appearance="secondary"
                size="large"
              /> */}
                <Button
                  label="Cancel order"
                  onClick={() => setShowCancelModal(true)}
                  appearance="secondary"
                  size="large"
                />
              </>
            )}

          {/* <Button
            label="Duplicate order"
            onClick={() => console.log('object')}
            appearance="secondary"
            size="large"
          /> */}
        </FlexBox>
      </FlexBox>
      {showCancelModal && (
        <CancelOrderModal
          closeModal={() => setShowCancelModal(false)}
          openEtsyCancelModal={() => setEtsyCancelModal(true)}
          orderId={orderId ?? ''}
        />
      )}
      {etsyCancelModal && (
        <EtsyCancelOrderModal
          closeModal={() => {
            setEtsyCancelModal(false);
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default Title;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .navigate-back {
    * {
      cursor: pointer;
    }
  }
  .heading {
    img {
      height: 32px;
      width: 32px;
    }
  }
  .select-box {
    min-width: auto;
    padding: 8px 12px;
  }
  .w-100 {
    width: 100%;
  }
`;

// const submitIssueList: Option[] = [
//   {
//     label: 'Tracking status',
//     value: 'tracking_status',
//   },
//   {
//     label: 'Request refund',
//     value: 'request_refund',
//   },
//   {
//     label: 'Request reprint',
//     value: 'request_reprint',
//   },
// ];
