export function generateContrastingColor(originalColor: string): string {
  // Convert the base color to RGB values
  const hex = originalColor.replace(/#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate the perceived brightness
  const brightness = Math.sqrt(r * r * 0.241 + g * g * 0.691 + b * b * 0.068);

  // Adjust the generated color based on the brightness of the base color
  const adjust = brightness < 130 ? 120 : -120;
  const newR = Math.max(0, Math.min(255, r + adjust));
  const newG = Math.max(0, Math.min(255, g + adjust));
  const newB = Math.max(0, Math.min(255, b + adjust));

  // Convert the new RGB values back to hex
  const newHex = ((newR << 16) + (newG << 8) + newB).toString(16);

  const paddedHex = '#' + newHex.padStart(6, '0');

  return paddedHex;
}
