import { GridColDef } from '@mui/x-data-grid-pro';
import {
  RenderActionButtons,
  RenderInventory,
  RenderProduct,
  RenderText,
  RenderTextHeader,
  RenderTextWithColorMark,
} from '../../../utils/render-cells';

export const myProductColumns = (
  deleteProduct: (productId: string) => void,
  publishProduct: (
    productId: string,
    taxonomyId: number,
    publishChange: boolean,
  ) => void,
  duplicateProduct: (productId: string) => void,
  isTablet: boolean,
) => {
  const columns: GridColDef[] = [
    {
      field: 'productTitle',
      headerName: 'Product',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.2,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderProduct,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: (props) => RenderText(props, '14px'),
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderInventory,
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderTextWithColorMark,
    },
    {
      field: 'actions',
      headerName: ' ',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: !isTablet ? 200 : 100,
      renderCell: (props) => (
        <RenderActionButtons
          prop={props}
          deleteProduct={deleteProduct}
          publishProduct={publishProduct}
          duplicateProduct={duplicateProduct}
          isTablet={isTablet}
        />
      ),
      cellClassName: 'actions',
    },
  ];
  return columns;
};
