import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import Button from '../../common/button';
interface Props {
  onClose: () => void;
  onClear: (fromData: string, toData: string) => void;
  setValue: React.Dispatch<React.SetStateAction<DateRange<Dayjs>>>;
  value: DateRange<Dayjs>;
  onApply: (fromData: string, toData: string) => void;
}
const DatePicker: React.FC<Props> = ({
  onApply,
  onClose,
  setValue,
  value,
  onClear,
}) => {
  const [from, to] = value;

  return (
    <StyledWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateRangePicker
          disableHighlightToday
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          disableFuture
        />
        <FlexBox
          gap="1rem"
          justifyContent="space-between"
          className="action-buttons-wrapper"
        >
          <div className="action-buttons">
            <Button
              label="Cancel"
              onClick={() => {
                onClose();
                setValue([null, null]);
                onClear('', '');
              }}
              appearance="secondary"
              size="large"
            />
            <Button
              label="Apply"
              onClick={() => {
                onApply(
                  dayjs(from).format('DD/MM/YYYY'),
                  dayjs(to).format('DD/MM/YYYY'),
                );
                onClose();
              }}
              appearance="primary"
              size="large"
            />
          </div>
        </FlexBox>
      </LocalizationProvider>
    </StyledWrapper>
  );
};

export default DatePicker;
const StyledWrapper = styled.div`
  position: absolute;
  z-index: 9;
  right: 0;
  max-width: 639px;
  border-radius: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.border};
  /** overriding default border */
  .MuiDateRangeCalendar-monthContainer:not(:last-of-type) {
    border-right: 1px solid ${colors.grey.border} !important;
  }
  .MuiPickersArrowSwitcher-root {
    padding: 0;
  }
  .MuiPickersSlideTransition-root {
    height: fit-content;
  }
  /** selected date color */
  .MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.Mui-selected {
    background-color: ${colors.blue[800]};
  }
  .action-buttons {
    display: flex;
    gap: 1rem;
    padding-right: 1rem;
    button {
      width: 100px;
    }
  }
  .action-buttons-wrapper {
    width: 100%;
    border-top: 1px solid ${colors.grey.border};
    ::before {
      content: '';
    }
  }
`;
