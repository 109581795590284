/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Canvas } from 'fabric/fabric-impl';
import { VariantSpecificData } from '../../../interface/catalog-interfaces';
import { initializeFabricWithNewCanvas } from '../utils';
import { useAppSelector } from '../../../store/hooks';
import { getObjects } from '../../../store/slices/editor';
import { UndoRedoConfig } from '../../../interface';
import { ReactComponent as UndoIcon } from '../../../assets/images/undo.svg';
import { ReactComponent as RedoIcon } from '../../../assets/images/redo.svg';
import styled from 'styled-components';
import { colors } from '@mui/material';
import CustomToolTip from '../../custom-tooltip';
import {
  VariantEditorDataV2,
  VariantsV2,
} from '../../../interface/catalog-interface-v2';
import { SingleProductResponse } from '../../../interface/product-interface';

interface Props {
  drawingBoard: Canvas | undefined;
  initializeCanvasWithJson: (
    json: any,
    canvas: Canvas,
    loadMockupFromApi: boolean,
    updatedVariant?: VariantsV2,
    updateUndoRedoState?: () => void,
  ) => void;
  setConfigForUndoRedo: React.Dispatch<React.SetStateAction<UndoRedoConfig>>;
  configForUndoRedo: UndoRedoConfig;
  undoAndRedoLoadingRef: React.MutableRefObject<boolean>;
  angle: string;
  productInfo: SingleProductResponse | undefined;
  variantSpecificData: VariantEditorDataV2 | undefined;
  disableUndoRedo: boolean;
  catalogData: any;
  isKeyLongPressed: React.MutableRefObject<boolean>;
  selectedColor: any;
}

const UndoAndRedoComponent: React.FC<Props> = ({
  drawingBoard,
  initializeCanvasWithJson,
  setConfigForUndoRedo,
  configForUndoRedo,
  undoAndRedoLoadingRef,
  angle,
  productInfo,
  variantSpecificData,
  disableUndoRedo,
  catalogData,
  isKeyLongPressed,
  selectedColor,
}) => {
  const files = useAppSelector(getObjects);

  const updateToInitialState = (callback: () => void) => {
    if (productInfo) {
      // if it is old product  set to its original state
      const editorData = JSON.parse(productInfo.data.editorData);
      const product: any = productInfo.data.variants[0];

      let editorDataJson: any = editorData.variantEditorData.find(
        (variant: VariantSpecificData) =>
          variant.colorCode.toLowerCase() === product?.colorHex.toLowerCase() ||
          variant.color.toLowerCase() === product?.color.toLowerCase(),
      );
      if (editorDataJson.variantSpecificDesign) {
        editorDataJson = editorDataJson[angle];
      } else {
        editorDataJson = editorData.defaultEditorData[angle];
      }
      initializeFabricWithNewCanvas(
        catalogData,
        initializeCanvasWithJson,
        editorDataJson,
        true,
        productInfo.data.variants[0] as any,
        callback,
        '',
        false,
        selectedColor,
      );
    } else {
      // if it is new product  delete the last element
      initializeFabricWithNewCanvas(
        catalogData,
        initializeCanvasWithJson,
        null,
        false,
        undefined,
        callback,
        '',
        false,
        selectedColor,
      );
    }
  };

  /** Update Config State */
  const updateConfigState = (operation: 'undo' | 'redo') => {
    const indexOperation = operation === 'undo' ? -1 : 1;

    const updatedConfig = variantSpecificData?.variantSpecificDesign
      ? {
          ...configForUndoRedo,
          specificDesign: {
            ...configForUndoRedo.specificDesign,
            [variantSpecificData.id]: {
              ...configForUndoRedo.specificDesign[
                variantSpecificData.id
              ],
              [`${angle}CurrentStateIndex`]:
                configForUndoRedo.specificDesign[variantSpecificData.id][
                  `${angle}CurrentStateIndex`
                ] + indexOperation,
            },
          },
        }
      : {
          ...configForUndoRedo,
          [`${angle}CurrentStateIndex`]:
            configForUndoRedo[`${angle}CurrentStateIndex`] + indexOperation,
        };

    const stateUpdate =
      operation === 'undo'
        ? { undoStatus: false, undoFinishedStatus: 1 }
        : { redoStatus: false, redoFinishedStatus: 1 };

    setConfigForUndoRedo((prevConfig) => ({
      ...prevConfig,
      ...updatedConfig,
      ...stateUpdate,
    }));
  };

  const handleUndo = useCallback(() => {
    const currentCanvasConfig = variantSpecificData?.variantSpecificDesign
      ? configForUndoRedo.specificDesign[variantSpecificData.id]
      : configForUndoRedo;

    if (configForUndoRedo.undoFinishedStatus) {
      undoAndRedoLoadingRef.current = true;
      handleUndoForCanvas(currentCanvasConfig[angle]);
    }

    function handleUndoForCanvas(canvas: any[]) {
      const currentStateIndex = canvas
        ? currentCanvasConfig[angle + 'CurrentStateIndex']
        : -1;

      if (currentStateIndex === -1) {
        setConfigForUndoRedo((prevConfig) => ({
          ...prevConfig,
          undoStatus: false,
        }));
      } else if (canvas.length >= 1) {
        setConfigForUndoRedo((prevConfig) => ({
          ...prevConfig,
          undoFinishedStatus: 0,
        }));

        if (currentStateIndex !== 0) {
          setConfigForUndoRedo((prevConfig) => ({
            ...prevConfig,
            undoStatus: true,
          }));

          initializeFabricWithNewCanvas(
            catalogData,
            initializeCanvasWithJson,
            canvas[currentStateIndex - 1],
            false,
            undefined,
            () => updateConfigState('undo'),
            '',
            false,
            selectedColor,
          );
        } else {
          updateToInitialState(() => updateConfigState('undo'));
        }
      }
    }
  }, [
    angle,
    configForUndoRedo,
    drawingBoard,
    files,
    initializeCanvasWithJson,
    setConfigForUndoRedo,
    undoAndRedoLoadingRef,
    updateConfigState,
    updateToInitialState,
    variantSpecificData?.id,
    variantSpecificData?.variantSpecificDesign,
  ]);

  const handleRedo = useCallback(() => {
    const handleRedoForCanvas = (
      currentStateIndex: number,
      canvasState: any[],
    ) => {
      undoAndRedoLoadingRef.current = true;
      setConfigForUndoRedo((prevConfig) => ({
        ...prevConfig,
        redoFinishedStatus: 0,
        redoStatus: true,
      }));

      initializeFabricWithNewCanvas(
        catalogData,
        initializeCanvasWithJson,
        canvasState[currentStateIndex + 1],
        false,
        undefined,
        () => updateConfigState('redo'),
        '',
        false,
        selectedColor,
      );
    };

    const currentCanvasState: any = variantSpecificData?.variantSpecificDesign
      ? configForUndoRedo.specificDesign[variantSpecificData.id]
      : configForUndoRedo;

    if (
      currentCanvasState[angle].length >
      currentCanvasState[`${angle}CurrentStateIndex`]
    ) {
      handleRedoForCanvas(
        currentCanvasState[`${angle}CurrentStateIndex`],
        currentCanvasState[angle],
      );
    }
  }, [
    angle,
    configForUndoRedo,
    drawingBoard,
    files,
    initializeCanvasWithJson,
    setConfigForUndoRedo,
    updateConfigState,
    undoAndRedoLoadingRef,
    variantSpecificData?.id,
    variantSpecificData?.variantSpecificDesign,
  ]);

  const getCurrentCanvaStateIndex = () => {
    if (variantSpecificData?.variantSpecificDesign) {
      const specificDesignConfig =
        configForUndoRedo.specificDesign[variantSpecificData.id];
      return specificDesignConfig
        ? specificDesignConfig[angle + 'CurrentStateIndex']
        : -1;
    } else {
      return configForUndoRedo[angle + 'CurrentStateIndex'] ?? -1;
    }
  };

  const getCurrentCanvasStateLength = () => {
    if (variantSpecificData?.variantSpecificDesign) {
      const specificDesignConfig =
        configForUndoRedo.specificDesign[variantSpecificData.id];
      return specificDesignConfig ? specificDesignConfig[angle]?.length : 0;
    } else {
      return configForUndoRedo[angle]?.length ?? 0;
    }
  };

  const disableUndoButton = getCurrentCanvaStateIndex() === -1;

  const disableRedoButton =
    getCurrentCanvasStateLength() === getCurrentCanvaStateIndex() + 1;

  /** Undo and Redo keyboard shortcuts */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.repeat) {
        isKeyLongPressed.current = true;
      }
      const isCtrlKey = event.ctrlKey || event.metaKey;

      // Redo
      if (
        !disableUndoRedo &&
        isCtrlKey &&
        (event.key === 'y' || (event.shiftKey && event.key === 'z'))
      ) {
        event.preventDefault();
        if (!disableRedoButton) {
          handleRedo();
        }
      } else if (!disableUndoRedo && isCtrlKey && event.key === 'z') {
        // Undo
        event.preventDefault();
        if (!disableUndoButton) {
          handleUndo();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    disableRedoButton,
    disableUndoButton,
    handleRedo,
    handleUndo,
    disableUndoRedo,
  ]);

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      if (isKeyLongPressed.current) {
        isKeyLongPressed.current = false;
      }
    };
    document.addEventListener('keyup', keyUpHandler);
    return () => {
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, []);

  return (
    <StyledWrapper>
      <CustomToolTip title="Undo">
        <UndoIcon
          className={`icon-btn undo-btn ${
            disableUndoButton ? 'disable-btn' : ''
          }`}
          onClick={() => {
            if (!disableUndoButton && !disableUndoRedo) {
              handleUndo();
            }
          }}
        />
      </CustomToolTip>
      <CustomToolTip title="Redo">
        <RedoIcon
          className={`icon-btn ${disableRedoButton ? 'disable-btn' : ''}`}
          onClick={() => {
            if (!disableRedoButton && !disableUndoRedo) {
              handleRedo();
            }
          }}
        />
      </CustomToolTip>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  border: 1px solid ${colors.grey[300]};
  display: flex;
  width: 3rem;
  border-radius: 3px;
  z-index: 99;
  align-self: flex-start;

  .icon-btn {
    background: ${colors.grey[100]};
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  .undo-btn {
    border-right: 1px solid ${colors.grey[300]};
  }
  .disable-btn {
    opacity: 0.5;
    cursor: default;
  }
`;
export default UndoAndRedoComponent;
