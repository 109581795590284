import React from 'react';
import Button from '../../../common/button';
import Editor from '../../../editor';
import Modal from '../../../common/modal';
import { OpenEditorIcon } from '../../../../assets/icons';
import { OrderStatus, Product } from '../../../../interface/orders-interface';
import { FlexBox } from '../../../common/wrappers';
import CustomToolTip from '../../../custom-tooltip';
import { ReactComponent as InfoIcon } from '../../../../assets/images/info-icon.svg';

interface Props {
  item: Product;
  orderId: string;
  setShowPersonalizeEditorModal: (value: boolean) => void;
  showPersonalizeEditorModal: boolean;
  personalizationId?: string;
  status: string | undefined;
}

const PersonalizationEditor: React.FC<Props> = ({
  item,
  personalizationId,
  setShowPersonalizeEditorModal,
  showPersonalizeEditorModal,
  orderId,
  status,
}) => {
  return (
    <>
      {item.personalization.required && (
        <FlexBox justifyContent="center" alignItems="center" gap="5px">
          <CustomToolTip
            title={
              <p className="box p-10px fs-12">
                <b>Instructions:</b> {item.personalization.instructions}
              </p>
            }
          >
            <InfoIcon width="34px" />
          </CustomToolTip>
          {(status === OrderStatus.PENDING_FULFILLMENT ||
            status === OrderStatus.PENDING_PERSONALIZATION) && (
            <Button
              appearance={item.personalization.done ? 'secondary' : 'primary'}
              onClick={() => setShowPersonalizeEditorModal(true)}
              label={item.personalization.done ? 'Open Editor' : 'Personalize'}
              icon={item.personalization.done ? OpenEditorIcon : ''}
            />
          )}
        </FlexBox>
      )}
      {showPersonalizeEditorModal && personalizationId === item.productId && (
        <Modal
          title={item.title}
          size="extraLarge"
          onClose={() => setShowPersonalizeEditorModal(false)}
          colorBoxItem={item}
          showColorBox={true}
        >
          <Editor
            isTablet={false}
            isSellerEditor={true}
            productIdFromPersonalization={item.productId}
            ordersPrintProviderId={item.printProviderId}
            ordersCatalogId={item.catalogId}
            variantIdFromPeronalization={item.variantId}
            orderId={orderId}
            instructions={item.personalization?.instructions}
            editorDataFromProps={
              item.personalization?.editorData
                ? JSON.parse(item.personalization?.editorData)
                : undefined
            }
            setShowPersonalizeEditorModal={setShowPersonalizeEditorModal}
            productOrderData={item}
          />
        </Modal>
      )}
    </>
  );
};

export default PersonalizationEditor;
