import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { BeeLogo, StepDone } from '../../../../assets/icons';
import { FlexBox } from '../../../common/wrappers';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../../../constants/routesPath';
import { useAppSelector } from '../../../../store/hooks';
import { getUtilityData } from '../../../../store/slices/utilitySlice';
import StepSystem from './step-system';

interface Props {
  title?: string | undefined;
}

const TopBar: React.FC<Props> = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    printProviderId,
    catalogueId,
    productId,
    modelNumber,
    isDetailsVisited,
    isMockupsVisited,
  } = useAppSelector(getUtilityData);

  return (
    <Section className="responsive-topbar">
      <FlexBox alignItems="center" gap="0.7rem">
        <img
          className="bee-logo"
          src={BeeLogo}
          alt="logo"
          onClick={() => navigate('/')}
        />
        <span>{title}</span>
      </FlexBox>

      <FlexBox>
        <div
          onClick={() => (window.location.href = routeNames.catalog)}
          className="pointer-cursor"
        >
          <Step>
            <img src={StepDone} alt="Done" />
            <span>Products</span>
          </Step>
        </div>

        <Separator />
        <StepSystem
          linkCondition={catalogueId && productId}
          linkPath={`${routeNames.editor}/${catalogueId}/${printProviderId}/${productId}`}
          displayText="Editor"
          stepNumber="2"
          isStepDone={
            location.pathname.includes(routeNames.details) ||
            location.pathname.includes(routeNames.mockups)
          }
          isStepNumberActive={location.pathname.includes(routeNames.editor)}
        />
        <Separator />
        <StepSystem
          linkCondition={(productId && modelNumber) || isMockupsVisited}
          linkPath={`${routeNames.mockups}/${productId}/${modelNumber}`}
          displayText="Images"
          stepNumber="3"
          isStepDone={location.pathname.includes(routeNames.details)}
          isStepNumberActive={location.pathname.includes(routeNames.mockups)}
        />
        <Separator />
        <StepSystem
          linkCondition={isDetailsVisited}
          linkPath={`${routeNames.details}/${productId}`}
          displayText="Details"
          stepNumber="4"
          isStepDone={false}
          isStepNumberActive={location.pathname.includes(routeNames.details)}
        />
      </FlexBox>
    </Section>
  );
};

export default TopBar;

const Section = styled.div`
  display: flex;
  padding: 16px 32px;
  border-bottom: 1px solid ${colors.grey[300]};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    float: left;
  }
  > div > span:first-child {
    margin-right: 10px;
  }
  > div > * {
    margin: auto;
  }

  .bee-logo {
    cursor: pointer;
  }
`;

const Step = styled.div`
  display: flex;
  margin: 5px !important;
  > :first-child {
    margin-right: 5px;
  }
  > * {
    margin: auto;
  }
`;

export const Separator = styled.hr`
  width: 12px;
  border: 0.5px solid ${colors.grey[400]};
  height: 0px;
`;
