import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../common/modal';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import { SelectList } from '../../../common/elements';
import { Option } from '../../../../interface';
import Button from '../../../common/button';
import { colors } from '../../../../assets/colors';
import { useCancelOrderMutation } from '../../../../store/services/orders';
import Input from '../../../inputs';
import Loader from '../../../common/loader';

interface Props {
  closeModal: () => void;
  orderId: string;
  openEtsyCancelModal: () => void;
}

const CancelOrderModal: React.FC<Props> = ({
  closeModal,
  orderId,
  openEtsyCancelModal,
}) => {
const [cancelOrder, { isLoading }] = useCancelOrderMutation({
  fixedCacheKey: orderId,
});
  const [cancelModalError, setCancelModalError] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');
  const handleDeleteClick = () => {
    cancelOrder({
      orderId,
      reason: otherReason ? otherReason : selectedReason,
    })
      .unwrap()
      .then(() => {
        closeModal();
        openEtsyCancelModal();
      })

      .catch(() => {
        setCancelModalError(true);
      });
  };

  return (
    <StyledWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal
          title={'Cancel this order?'}
          onClose={closeModal}
          className="cancel-modal"
        >
          <FlexBox direction="column" gap="1rem">
            <FlexBox direction="column" gap="1rem">
              <Typography
                text={`Are you sure you want to cancel order #${orderId}?`}
                fontSize="14px"
              />
              <FlexBox direction="column" gap="8px">
                <Typography
                  text="Please select the reason"
                  secondaryColorValue={800}
                  fontSize="14px"
                />
                <SelectList
                  label="Reason"
                  items={cancelReasons}
                  selectedValue={selectedReason}
                  onChange={(value) => setSelectedReason(value)}
                  className="cancel-dropdown"
                  width="100%"
                  gapBetweenInputAndItems={0}
                />
                {selectedReason === 'Other' && (
                  <Input
                    type="text"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder="Enter reason..."
                  />
                )}
              </FlexBox>
            </FlexBox>

            <FlexBox gap="1rem">
              {
                <Button
                  label={'Go back'}
                  onClick={closeModal}
                  appearance="secondary"
                  size="fullWidth"
                />
              }
              <Button
                disabled={
                  !selectedReason ||
                  (selectedReason === 'Other' && !otherReason)
                }
                label={'Proceed'}
                onClick={handleDeleteClick}
                appearance="primary"
                size="fullWidth"
              />
            </FlexBox>
            {cancelModalError && (
              <FlexBox
                className="error-wrapper"
                justifyContent="center"
                alignItems="center"
              >
                Something went wrong, Try Again Later!
              </FlexBox>
            )}
          </FlexBox>
        </Modal>
      )}
    </StyledWrapper>
  );
};

export default CancelOrderModal;

const StyledWrapper = styled.div`
  .cancel-modal {
    height: fit-content;
    border-radius: 16px;
    padding: 16px;
  }
  .cancel-dropdown {
    max-width: -webkit-fill-available;
  }
  .main-content {
    width: 100%;
    border: 1px solid ${colors.grey.border};
    border-radius: 6px;
    .header,
    .product-sku {
      padding: 8px 16px;
    }
    .cancel-modal-image {
      border: none;
      padding: 1rem;
    }
  }
  .error-wrapper {
    color: red;
    font-size: 16px;
    margin-top: 4px;
  }
`;

const cancelReasons: Option[] = [
  {
    label: 'Order placed by mistake',
    value: 'Order placed by mistake',
  },
  {
    label: 'Customer changed their mind',
    value: 'Customer changed their mind',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
