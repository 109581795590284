import axios from 'axios';


export async function fetchCurrencyRates(fromCurrencyCode: any, toCurrencyCode: any) {
  const exchangeRates = await fetchRates(fromCurrencyCode, toCurrencyCode);
  return exchangeRates;
}

async function fetchRates(currentCurrency: string | number, shopCurrencyCode: string) {
    if (shopCurrencyCode !== currentCurrency) {
const EXCHANGE_RATE_BASE_URL = `https://v6.exchangerate-api.com/v6/92d3537e6da6c56687697fc1/latest/${currentCurrency}`;

      const url = `${EXCHANGE_RATE_BASE_URL}`
      try {
        const response = await axios.get(url);
        const rates = response.data.conversion_rates;
        return rates[shopCurrencyCode];
      } catch (error: any) {
        console.error(`Error fetching exchange rate for ${currentCurrency}:`, error.message);
        return null;
      }
    } else {
      return 1.0;
    }
}
