import React, { useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Button from '../../common/button';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import {
  useUpdateSingleProductMutation,
  useGetProductDetailsForDetailsPageQuery,
} from '../../../store/services/products';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { getDetails, productDetails } from '../../../store/slices/detailsSlice';
import Loader from '../../common/loader';
import { Status } from '../../../interface';
import { isArrayEqual } from '../../../helper/compareArrays';
import {
  getMinimumAndMaximumMRSPFromVariants,
  getUniqueColorsOrColorHexFromVariants,
} from '../../../helper/getMinimumAndMaximumMRSPFromVariants';
import Modal from '../../common/modal';
import VariantTable from '../variant-component/VariantTable';
import { replaceHtmlLineBreaks } from '../../../helper/commonUtils';
import { UpdateProductDetailsBody } from '../../../interface/product-interface';
// commented code in this file is for future uses
// import { getUserData } from '../../../store/slices/authSlice';
interface Props {
  isMockupsUploading: boolean;
}
const DetailsFooter: React.FC<Props> = ({ isMockupsUploading }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  // const { userInfo } = useAppSelector(getUserData);
  const [currency, setCurrency] = useState({
    current: 'USD',
    shopCurrency: 'USD',
  });
  // const [modalVisited, setModalVisited] = useState<boolean>(false);

  const details = useAppSelector(productDetails);
  const { status, images, isPersonalizable, personalizationInstructions } =
    useAppSelector(getDetails);
  const detailsCopy = { ...details };
  delete detailsCopy.descriptionResponse;

  const { productId } = useParams();
  const [updateProduct, { isLoading }] = useUpdateSingleProductMutation();
  const navigate = useNavigate();
  const isDescriptionAdded = (description: any) => {
    if (description.blocks) {
      return (
        description.blocks.map((block: any) => block.text).join().length > 0
      );
    } else {
      return description.length > 0;
    }
  };
  const productInfo = useGetProductDetailsForDetailsPageQuery(
    {
      productId: productId ?? '',
    },
    {
      skip: productId ? false : true,
      selectFromResult: ({ data }) => ({
        variants: data?.data.variants,
      }),
    },
  );
  const getEmptyFields = () => {
    const { variantsVisibility, syncingOptions, shippingProfile } =
      detailsCopy.publishingSettings;
    const missingFields = [];
    if (!variantsVisibility || !syncingOptions || !shippingProfile) {
      missingFields.push('Publishing Settings');
    }
    if (!detailsCopy.title) {
      missingFields.push('Title');
    }

    if (detailsCopy.tags.length === 0) {
      missingFields.push('Tags');
    }

    if (!isDescriptionAdded(detailsCopy.description)) {
      missingFields.push('Description');
    }
    return missingFields;
  };

  const isButtonDisabled = () => {
    if (
      detailsCopy.title &&
      detailsCopy.tags.length > 0 &&
      isDescriptionAdded(detailsCopy.description) &&
      detailsCopy.publishingSettings.variantsVisibility &&
      detailsCopy.publishingSettings.syncingOptions &&
      detailsCopy.publishingSettings.shippingProfile &&
      !isMockupsUploading &&
      ((isPersonalizable && personalizationInstructions) || !isPersonalizable)
    ) {
      return false;
    }
    return true;
  };

  const handleUpdateProduct = async (sendToMyProducts: boolean) => {
    const formattedPersonalizedText = isPersonalizable
      ? replaceHtmlLineBreaks(personalizationInstructions ?? '')
      : '';
    let mrsp = getMinimumAndMaximumMRSPFromVariants(details.variants);
    const amplitudeData = {
      product_name: details.title,
      color: getUniqueColorsOrColorHexFromVariants(details.variants).join(','),
      custom_price: isArrayEqual(productInfo.variants as any, details.variants),
      mockups: images.length,
      price: `USD ${mrsp.min.toFixed(2)} - ${mrsp.max.toFixed(2)}`,
      personalised: isPersonalizable,
    };
    const isDraft =
      status === Status.Draft ||
      status === Status.Unpublished ||
      status === Status.Error;
    const isUnpublishedChanges =
      status === Status.UnpublishedChanges ||
      status === Status.Published ||
      status === Status.LISTING_ERROR;
    const body: UpdateProductDetailsBody = {
      ...detailsCopy,
      description: JSON.stringify(detailsCopy.description),
      publishProduct: sendToMyProducts ? isDraft : false,
      isPersonalizable: isPersonalizable,
      ...(isPersonalizable && {
        personalizationInstructions: formattedPersonalizedText,
      }),
      variants: detailsCopy.variants.map((variant) => ({
        id: variant.id,
        mrsp: variant.mrsp,
      })),
    };
    if (sendToMyProducts) {
      body.publishChanges = isUnpublishedChanges ? true : false;
    }
    await updateProduct({
      productId,
      body,
    });
    if (sendToMyProducts) {
      amplitude.getInstance().logEvent('Publish', amplitudeData);
      navigate('/my-products');
    } else {
      amplitude.getInstance().logEvent('Save - Details', amplitudeData);
    }
  };

  const closeVariantModal = () => {
    setCurrency({ ...currency, shopCurrency: 'USD' });
    // setModalVisited(true);
  };

  return (
    <StyledWrapper>
      {getEmptyFields().length > 0 && showMessage && (
        <p
          className="fs-14 missing-details-error"
          style={{ color: colors.red[600] }}
        >{`${getEmptyFields().join(
          ', ',
        )} missing, please check the details again.`}</p>
      )}
      {isLoading && <Loader />}
      <FlexBox gap="1rem" className="buttons-wrapper">
        <Button
          label="Save Changes"
          disabled={isButtonDisabled()}
          size="medium"
          onClick={() => handleUpdateProduct(false)}
          appearance="secondary"
          setShowMessage={(value) => setShowMessage(value)}
        />

        <Button
          label={
            status === Status.Draft ||
            status === Status.Unpublished ||
            status === Status.Error
              ? 'Publish'
              : 'Publish changes'
          }
          size="medium"
          disabled={isButtonDisabled()}
          onClick={() => {
            handleUpdateProduct(true);
          }}
          appearance="primary"
          setShowMessage={(value) => setShowMessage(value)}
        />
      </FlexBox>
      {currency.shopCurrency !== currency.current && (
        <Modal
          size="auto"
          onClose={closeVariantModal}
          title="It seems that your store currency is different from USD. Please review prices in your Store Currency"
        >
          <VariantTable
            height="100%"
            shopCurrency={currency.shopCurrency}
            currentCurrency={currency.current}
          />
          <Button
            label={
              status === Status.Draft ||
              status === Status.Unpublished ||
              status === Status.Error
                ? 'Publish'
                : 'Publish changes'
            }
            size="medium"
            onClick={() => {
              handleUpdateProduct(true);
              closeVariantModal();
            }}
            appearance="primary"
            setShowMessage={(value) => setShowMessage(value)}
            className="float-right"
          />
        </Modal>
      )}
    </StyledWrapper>
  );
};

export default DetailsFooter;

const StyledWrapper = styled.div`
  max-height: 4.25rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 12;
  ::before {
    content: '';
  }
  .buttons-wrapper {
    margin-right: 1rem;
    margin-bottom: 9px;
  }
  .buttons-wrapper > button {
    width: 160px;
    height: 36px;
  }
`;
