import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ImageBox from '../../common/image-box';
import { FlexBox } from '../../common/wrappers';
import { colors } from '../../../assets/colors';
import { ColoredCircle } from '../../common/elements';
import { useAppSelector } from '../../../store/hooks';
import { getDetails, productDetails } from '../../../store/slices/detailsSlice';
import draftToHtml from 'draftjs-to-html';
import { ReactComponent as Cross } from '../../../assets/images/cross.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../../constants/routesPath';
import { getMinimumPriceFromVariants } from '../../editor/utils';

interface PreviewStoreProps {
  setOpenPreviewStore?: React.Dispatch<React.SetStateAction<boolean>>;
}
const PreviewStore: React.FC<PreviewStoreProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    sizeVariants,
    productColors,
    images,
    isPersonalizable,
    personalizationInstructions,
  } = useAppSelector(getDetails);
  const { title, tags, description, variants } = useAppSelector(productDetails);
  const [selectedImage, setSelectedImage] = useState<string>('');
  useEffect(() => {
    if (location.state) {
      if (location.state.selectedImageToShow) {
        setSelectedImage(location.state.selectedImageToShow[0].imageUrl);
      }
    } else {
      setSelectedImage(images[0]?.imageUrl);
    }
  }, [images, location.state]);

  const descriptionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (descriptionRef.current && description) {
      descriptionRef.current.innerHTML = draftToHtml(description);
    }
  }, [description]);
  return (
    <Section className="show-no-scrollbar">
      <FlexBox justifyContent="space-between" alignItems="center">
        <Heading>Preview in Store</Heading>
        <Cross
          className="close-btn"
          onClick={() =>
            !props.setOpenPreviewStore
              ? navigate(routeNames.myProducts)
              : props.setOpenPreviewStore(false)
          }
        />
      </FlexBox>
      <FlexBox gap="1.5rem" style={{ height: 'auto' }}>
        <MockupPreview>
          <SideMockupsGroup
            className="show-no-scrollbar"
            style={{ height: 'auto' }}
          >
            {(location.state ? location.state.selectedImageToShow : images).map(
              (mockup: any, index: number) => (
                <ImageBox
                  isClickable
                  key={index}
                  width="100%"
                  height="100%"
                  image={mockup.imageUrl}
                  onClick={() => setSelectedImage(mockup.imageUrl)}
                />
              ),
            )}
          </SideMockupsGroup>
          {selectedImage !== '' && (
            <ImageBox isClickable image={selectedImage} width="100%" />
          )}
        </MockupPreview>
        <DescriptionPreview>
          {variants.length > 0 && (
            <Price>{`USD ${getMinimumPriceFromVariants(
              variants,
              'mrsp',
            )}`}</Price>
          )}
          <Title>
            <span>{title}</span>
          </Title>
          <Tags>
            {tags.map((tag: string, index: number) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </Tags>

          <SizeWrapper>
            <label>Size</label>
            <SizeTags>
              {sizeVariants.map((size: string, index: number) => (
                <SizeTag key={index}>{size}</SizeTag>
              ))}
            </SizeTags>
          </SizeWrapper>
          <ColorWrapper>
            <label>Color</label>
            <FlexBox gap="6px" wrap="wrap">
              <FlexBox gap="6px" wrap="wrap">
                {productColors.map((obj) => (
                  <ColoredCircle
                    key={obj.label}
                    colorCode={
                      obj.label.includes('/') ? obj.label : `#${obj.colorHex}`
                    }
                    height={25}
                    width={25}
                  />
                ))}
              </FlexBox>
            </FlexBox>
          </ColorWrapper>
        </DescriptionPreview>
      </FlexBox>
      {isPersonalizable && (
        <FlexBox direction="column" gap="1rem">
          <label>Personalization Instructions</label>
          <div
            dangerouslySetInnerHTML={{
              __html: personalizationInstructions as string,
            }}
          />
          <textarea
            placeholder="Type here.."
            readOnly
            style={{ minHeight: '80px' }}
          />
        </FlexBox>
      )}

      <SellerDescription ref={descriptionRef} />
    </Section>
  );
};

export default PreviewStore;

const Section = styled.div`
  height: 100vh;
  display: flex;
  margin-bottom: 4rem;
  overflow: auto;
  flex-direction: column;
  padding: 0px 30px;
  font-size: 14px;
  .close-btn {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const Heading = styled.div`
  font-size: 18px;
  margin: 15px 0;
`;

const MockupPreview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0px;
  max-height: 360px;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: 1600px) {
    max-height: 290px;
  }
  .div:first-child {
    position: relative;
  }
`;

const SideMockupsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  & > div {
    min-height: 70px;
    max-width: 80px;
    border-radius: 6px;
    max-height: 80px;
  }
`;

const DescriptionPreview = styled.div`
  flex: 1;
  padding: 10px 0px;
  overflow: hidden;
  margin: 0px;
  min-height: 380px;
`;

const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  word-wrap: break-word;

  p {
    font-size: 12px;
    font-weight: 400;
    margin: 2px 0;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  padding: 4px 8px;
  background: ${colors.grey[500]};
  border-radius: 6px;
  color: ${colors.white};
`;

const SizeWrapper = styled.div`
  margin: 20px 0;
`;

const SizeTags = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  flex-wrap: wrap;
`;

const SizeTag = styled.div`
  padding: 4px 12px 6px;
  font-size: 14px;
  border: 1px solid ${colors.grey[300]};
  background: ${colors.white};
  border-radius: 5px;
`;

const ColorWrapper = styled.div`
  margin: 15px 0;

  & > div {
    display: flex;
    gap: 0.4rem;
    margin: 0.5rem 0;
  }
`;

const SellerDescription = styled.div`
  li {
    font-size: 15px;
  }
`;
