import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const RadioBox = styled.label`
  display: flex;
  align-items: center;
  /* margin: 4px; */
`;

const RadioButton = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  border: 1px solid ${colors.grey[300]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.4rem;
  transition:
    background 0.15s,
    border-color 0.15s;
  padding: 2px;

  &::after {
    content: '';
    width: 80%;
    height: 80%;
    display: block;
    background: ${colors.blue[800]};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
  }
`;

const Input = styled.input`
  display: none;
  &:checked + ${RadioButton} {
    border: 1px solid ${colors.blue[800]};
    &::after {
      transform: scale(1);
    }
  }
`;

interface Props {
  id: string;
  value: any;
  label?: string;
  name: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<Props> = ({
  id,
  label,
  value,
  name,
  checked,
  onChange,
}) => {
  return (
    <RadioBox id={id}>
      <Input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <RadioButton />
      <span className="grey900 fs-14">{label}</span>
    </RadioBox>
  );
};

export default Radio;
