import { SingleProductVariants } from '.';
import { Address, Customer } from './create-order-interface';
export interface ProductCost {
  shipping: number;
  tax: number;
  total: number;
  currencyCode?: string;
}
export interface Fees {
  listingFee: number;
  transactionFee: number;
  processingFee: number;
}

export interface GetOrdersResponseResults {
  productId: string;
  status: string;
  storeId: number;
  orderId: number;
  customer: Customer;
  catalogSKUId: number;
  mockupImage: string;
  colorHex: string;
  designImage: string;
  brand: string;
  model: string;
  color: string;
  productionDate: Date | null;
  userId: string;
  shippingAddress: Address;
  billingAddress: Address;
  quantity: number;
  externalOrderId: string | undefined;
  _id: string;
  title: string;
  productCost: ProductCost;
  createdAt: Date;
  updatedAt: Date;
  size: string;
  productionCost: ProductCost;
  customerPaid: ProductCost;
  fees: Fees;
  products: Product[];
  catalogProductId: string;
  designFileUrl?: string;
  cost?: number;
  shippedOn?: string;
  containsNonEverbeePrintsItems: boolean;
  isSampleOrder: boolean;
}

export interface GetOrdersResponse {
  results: GetOrdersResponseResults[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
  statusTotals: StatusTotals[];
}

export interface GetOrdersQueryParams {
  storeId: string;
  search?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  page: number;
  sortBy?: any;
  sortDirection?: any;
}
export interface OrdersFilter {
  searchText: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
}

export interface SortFilter {
  field: string;
  sort: 'asc' | 'desc';
}
export interface OrderIdQueryParam {
  orderId: string | undefined;
}

export interface GetOrderByIdResponse {
  data: GetOrdersResponseResults & {
    trackingNumber: string;
    trackingLink: string;
  };
}

export interface UpdateOrderPayload {
  body: {
    shippingAddress?: Address;
    customer?: Customer;
  };
  orderId: string;
}
export enum OrderStatus {
  DRAFT = 'Draft',
  PENDING_PERSONALIZATION = 'Pending Personalization',
  READY = 'Ready for production',
  IN_PRODUCTION = 'In Production',
  READY_TO_SHIP = 'Ready to Ship',
  SHIPPED = 'Shipped',
  CANCELLED = 'Cancelled',
  EXCEPTION = 'Exception',
  PENDING_FULFILLMENT = 'Pending Fulfillment',
}

export interface CancelOrderPayload extends OrderIdQueryParam {
  reason: string;
}

export interface StatusTotals {
  status: string;
  count: number;
}

export interface ProductInfoFromOrder {
  productId: string;
  catalogSKUId: number;
  quantity: number;
  productColorID: number;
}

export interface Product extends ProductInfoFromOrder {
  catalogId: string;
  printProviderId: string;
  storeId: number;
  title: string;
  variants: SingleProductVariants[];
  status: string;
  description: string;
  userId: string;
  isSizeTableAdded: boolean;
  editorData: string;
  tags: string[];
  isActive: boolean;
  isPersonalizable: boolean;
  images: Image[];
  unpublishedImages: any[];
  unpublishedVariants: any[];
  publishProduct: boolean;
  taxonomyId: number;
  listingId: number;
  productType: string;
  id: string;
  _id: string;
  cost: number;
  size: string;
  color: string;
  colorHex: string;
  catalogSKUId: any;
  variantId: string;
  printSKUId: string;
  quantity: number;
  mockupURL: string;
  mockupURLBack: string;
  personalization: PersonalizationInfoForProduct;
}
interface Image {
  imageUrl: string;
  etsyListingImageId: number;
}

export enum StripeErrorCodes {
  EXPIRED_CARD = 'expired_card',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
}
export interface PersonalizationInfoForProduct {
  instructions: string;
  required: boolean;
  done: boolean;
  frontDesign?: string;
  editorData?: string;
  backDesign?: string;
  mockupURL?: string;
  mockupURLBack?: string;
}
