import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Cross } from './../../assets/icons';
import { openLinkInNewTab } from '../../helper/openLinkInNewTab';

interface Props {
  handleBanner: (value: boolean) => void;
}

const Banner: React.FC<Props> = ({ handleBanner }) => {
  const handleBannerClose = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 2);
    document.cookie = `showBanner=no; expires=${expirationDate.toUTCString()}; path=/`;
    handleBanner(false);
  };

  return (
    <StyledWrapper>
      <div className="text-wrapper">
        <div>
          Get up to 20% discount on all products with EverBee Growth plan.
        </div>
        <button
          className="btn-upgrade"
          onClick={() =>
            openLinkInNewTab(`${process.env.REACT_APP_RESEARCH_APP}/pricing`)
          }
        >
          Upgrade now
        </button>
      </div>
      <button className="btn-close" onClick={handleBannerClose}>
        <img src={Cross} height={24} width={24} alt="Cross Icon" />
      </button>
    </StyledWrapper>
  );
};

export default Banner;

const StyledWrapper = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 36px 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.yellow};

  .text-wrapper {
    display: flex;
    gap: 8px;
  }

  .btn-upgrade {
    color: ${colors.blue[900]};
  }

  .btn-close {
    justify-self: end;
  }
`;
