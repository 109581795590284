import { FC, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout-form';
import Modal from '../common/modal';
import styled from 'styled-components';
import Typography from '../typography';
import { colors } from '../../assets/colors';
import { ReactComponent as ErrorIcon } from '../../assets/images/danger.svg';
import { FlexBox } from '../common/wrappers';
import { CardDetailsResponse } from '../../interface/user-interface';
import {
  DefaultCardIcon,
  MasterCardIcon,
  VisaCardIcon,
} from '../../assets/icons';
import { ReactComponent as PlusCircleIcon } from '../../assets/images/plus-circle.svg';
import Button from '../common/button';
import { useGetCardDetailsQuery } from '../../store/services/user';
import SmallLoader from '../common/small-loader';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
    'pk_test_51Ij5LLAprxaDl4vmQnejLIWCB5k03PlXbsAUdvzmO58OzBRXHBJXK5JPNL2AKgMZxUTNV6e6Uy1fjGorMz21bqxe001jgLdSd6',
);
interface Props {
  onCloseModal: () => void;
  onMakePayment: (paymentMethodId: string) => void;
  errorMessage?: string;
  selectedPaymentMethodId: string;
  setPaymentError: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentModal: FC<Props> = ({
  onCloseModal,
  onMakePayment,
  errorMessage,
  selectedPaymentMethodId,
  setPaymentError,
}) => {
  const { data: cardsList, isFetching } = useGetCardDetailsQuery();
  const [openAddNewPaymentModal, setOpenAddNewPaymentModal] =
    useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string>(
    selectedPaymentMethodId,
  );

  /** Error message clean up */
  useEffect(() => {
    return () => {
      setPaymentError('');
    };
  }, [setPaymentError]);

  /** Updating the selected value from the response received  */
  useEffect(() => {
    setPaymentMethodId(selectedPaymentMethodId);
  }, [selectedPaymentMethodId]);

  return (
    <StyledWrapper>
      <Modal
        title={
          (cardsList ?? []).length > 0 &&
          !openAddNewPaymentModal &&
          !errorMessage
            ? 'Update your payment details'
            : 'Add payment method'
        }
        onClose={onCloseModal}
        className="payment-modal"
      >
        {isFetching ? (
          <SmallLoader />
        ) : (
          <>
            {errorMessage && (
              <FlexBox gap="0.5rem" alignItems="center">
                <ErrorIcon height="1.25rem" width="1.25rem" />
                <span className="error-message">{errorMessage}</span>
              </FlexBox>
            )}
            {(cardsList ?? []).length > 0 && !openAddNewPaymentModal ? (
              <FlexBox direction="column" gap="0.5rem">
                <Typography
                  text="Select payment details"
                  fontSize="0.9rem"
                  className="sub-heading"
                />

                {cardsList?.map((card) => (
                  <CardTile
                    cardInfo={card}
                    isActive={paymentMethodId === card.id}
                    key={card.id}
                    onClick={() => setPaymentMethodId(card.id)}
                  />
                ))}

                <FlexBox
                  alignItems="center"
                  gap="0.5rem"
                  className="add-payment-btn"
                  onClick={() => setOpenAddNewPaymentModal(true)}
                >
                  <PlusCircleIcon width="20px" height="20px" />
                  <Typography text="Add payment method" fontSize="0.8rem" />
                </FlexBox>

                <FlexBox gap="1rem" className="btn-wrapper">
                  <Button
                    label="Cancel"
                    size="fullWidth"
                    appearance="secondary"
                    className="fs-14"
                    onClick={onCloseModal}
                  />
                  <Button
                    label="Update"
                    size="fullWidth"
                    className="fs-14"
                    appearance="primary"
                    disabled={!paymentMethodId}
                    onClick={() => onMakePayment(paymentMethodId)}
                  />
                </FlexBox>
              </FlexBox>
            ) : (
              <>
                <Typography
                  text="Add credit/debit card to your account"
                  fontSize="0.8rem"
                  fontWeight="800"
                  secondaryColorValue={900}
                  classname="sub-heading"
                />
                {stripePromise && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      onCloseModal={onCloseModal}
                      onMakePayment={onMakePayment}
                      forSampleOrder={false}
                    />
                  </Elements>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </StyledWrapper>
  );
};

export default PaymentModal;

const StyledWrapper = styled.div`
  .payment-modal {
    height: fit-content;
    border-radius: 16px;

    .modal-header {
      font-size: 1.2rem;
    }
    .btn-wrapper {
      margin-top: 10px;
    }
  }
  .error-message {
    color: ${colors.red[900]};
    font-size: 0.8rem;
    font-weight: 700;
  }
  .sub-heading {
    margin-top: 12px;
  }
  .add-payment-btn {
    padding: 8px 16px;
    border: 1px solid ${colors.grey.border};
    border-radius: 6px;
    cursor: pointer;
  }
`;

interface CardTileProps {
  cardInfo: CardDetailsResponse;
  isActive: boolean;
  onClick: () => void;
}
export const CardTile: React.FC<CardTileProps> = ({
  cardInfo,
  isActive,
  onClick,
}) => {
  const { brand, expMonth, expYear, last4 } = cardInfo;
  const getCardLogo = (brand: string) => {
    switch (brand) {
      case 'visa':
        return VisaCardIcon;
      case 'mastercard':
        return MasterCardIcon;
      default:
        return DefaultCardIcon;
    }
  };
  return (
    <PaymentCardTile isActive={isActive} onClick={onClick}>
      <FlexBox gap="8px" alignItems="center">
        <img src={getCardLogo(brand)} alt="logo" width="24px" height="24px" />
        <Typography
          text={`${brand}****${last4}`}
          fontSize="0.8rem"
          texTransform="capitalize"
        />
      </FlexBox>
      <Typography
        text={`Expires ${expMonth < 10 ? `0${expMonth}` : expMonth}/${expYear}`}
        fontSize="0.8rem"
      />
    </PaymentCardTile>
  );
};

const PaymentCardTile = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  padding: 8px 16px;
  background-color: ${(props) => (props.isActive ? colors.blue[100] : 'white')};
  border: ${(props) =>
    props.isActive
      ? `1px solid ${colors.blue[300]}`
      : `1px solid ${colors.grey.border}`};
  border-radius: 6px;
`;
