export const getDesignElements = (defaultEditorData: any) => {
  let designElements: string[] = [];
  let frontDesignElements: any;
  let backDesignElements: any;
  if (defaultEditorData?.front) {
    frontDesignElements = JSON.parse(defaultEditorData.front);
  }
  if (defaultEditorData?.back) {
    backDesignElements = JSON.parse(defaultEditorData.back);
  }
  if (frontDesignElements) {
    designElements.push(...getElements(frontDesignElements.objects));
  }

  if (backDesignElements) {
    designElements.push(...getElements(backDesignElements.objects));
  }
  return designElements
    .filter((ele, index) => designElements.indexOf(ele) === index)
    .join(', ');
};
const getElements = (designObjects: any[]) => {
  return designObjects.map((element: any) => {
    if (element.type === 'custom-image') {
      if (element.personalizationData) {
        return 'Personalized Image';
      }
      return 'Custom Image';
    } else {
      if (element.personalizationData) {
        return 'Personalized Text';
      }
      return 'Custom Text';
    }
  });
};
