import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import { Divider } from '../../order-details/details/billing';
import { colors } from '../../../assets/colors';
import { InfoIcon } from '../../../assets/icons';
import Radio from '../../common/radio-button';

const ShippingSection: React.FC = () => {
  return (
    <StyledWrapper>
      <div className="heading">Product</div>
      <Divider />
      <FlexBox
        justifyContent="space-between"
        gap="12px"
        className="shipping-notification"
      >
        <FlexBox gap="7px" alignItems="center">
          <Typography
            text="Send shipping notification"
            secondaryColorValue={800}
            fontSize="14px"
          />
          <img src={InfoIcon} alt="info" className="info-icon" />
        </FlexBox>
      </FlexBox>
      <Divider />
      <FlexBox gap="1rem" direction="column" className="shipping-option">
        <Radio
          id="standard_shipping"
          name="shipping-option"
          value="standard_shipping"
          label="Standard shipping"
        />
        <Radio
          id="priority_shipping"
          name="shipping-option"
          value="priority_shipping"
          label="Priority shipping"
        />
      </FlexBox>
    </StyledWrapper>
  );
};

export default ShippingSection;

const StyledWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.border};
  border-radius: 6px;

  .heading {
    padding: 18px 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.grey[900]};
  }
  .shipping-notification {
    padding: 16px;
    .info-icon {
      cursor: pointer;
      width: 16px;
      aspect-ratio: 1;
    }
  }
  .shipping-option {
    padding: 16px;
  }
`;
