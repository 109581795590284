import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { getUserData } from '../store/slices/authSlice';
import { useEffect } from 'react';
import { getCookie } from '../helper/cookies';

const ProtectedRoutes: React.FC = () => {
  const { isAuth, token, userInfo } = useAppSelector(getUserData);
  useEffect(() => {
    const checkCookie = () => {
      var everbeeCookie = getCookie(
        process.env.REACT_APP_AUTH_COOKIE_NAME as string,
      );
      if (!everbeeCookie) {
        // Token not found, redirect to login
        window.location.href = `${process.env.REACT_APP_SSO_FRONTEND_APP}/logout?redirect_to=${process.env.REACT_APP_PRINT_APP}`;
      }
    };

    // Set up polling to check the cookie every 5 seconds
    const intervalId = setInterval(checkCookie, 5000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (!isAuth || !token) {
      window.location.href = `${process.env.REACT_APP_SSO_FRONTEND_APP}/logout?redirect_to=${process.env.REACT_APP_PRINT_APP}`;
    }
  }, [isAuth, token]);

  return isAuth && token && userInfo.id ? <Outlet /> : null;
};

export { ProtectedRoutes };
