import React, { useState } from 'react';
import { FlexBox } from '../../../common/wrappers';
import Modal from '../../../common/modal';
import ImageBox from '../../../common/image-box';
import { ReactComponent as NextIcon } from '../../../../assets/images/next-icon.svg';
import { ReactComponent as PreviousIcon } from '../../../../assets/images/previous.svg';

interface PreviewModalProps {
  onClose?: any;
  ImageArray: string[];
}
const PreviewModal: React.FC<PreviewModalProps> = ({ onClose, ImageArray }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const filteredImageArray = ImageArray.filter((item) => item?.trim() !== '');

  const handleClickedImage = (index: number) => {
    setCurrentImageIndex(index);
  };
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex < filteredImageArray.length ? newIndex : 0;
    });
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? filteredImageArray.length - 1 : prevIndex - 1,
    );
  };
  return (
    <Modal
      title="Preview"
      className="preview-modal"
      onClose={() => onClose()}
      size="medium"
    >
      <FlexBox gap="3rem">
        <FlexBox
          direction="column"
          className="image-box-container show-no-scrollbar"
        >
          {filteredImageArray?.map((item: any, index: number) => (
            <ImageBox
              isClickable
              className={`item-header imagebox  ${
                index === currentImageIndex ? 'activeBox' : ''
              }`}
              imgWidth="110px"
              key={index}
              width="120px"
              boxHeight="120px"
              image={item}
              onClick={() => handleClickedImage(index)}
            ></ImageBox>
          ))}
        </FlexBox>

        <FlexBox
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ maxWidth: '70%' }}
        >
          <ImageBox
            isClickable
            boxHeight="450px"
            width="450px"
            imgWidth="440px"
            image={filteredImageArray[currentImageIndex]}
          />
          <FlexBox
            justifyContent="center"
            alignItems="center"
            gap="2px"
            className="bottom-button-wrapper"
          >
            <PreviousIcon
              onClick={handlePrevImage}
              className="pointer-cursor"
            />
            <NextIcon onClick={handleNextImage} className="pointer-cursor" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default PreviewModal;
