import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal';
import { FlexBox } from '../../common/wrappers';
import SearchBar from '../../common/search-bar';
import { SearchIcon } from '../../../assets/icons';
import { colors } from '../../../assets/colors';
import Typography from '../../typography';
import Button from '../../common/button';
import { SelectList } from '../../common/elements';
import { Option } from '../../../interface';
import QuantityButtons from '../../quantity-buttons';
import { useGetMyProductsQuery } from '../../../store/services/products';
import Loader from '../../common/loader';
import { SingleCatalogProductVariants } from '../../../interface/catalog-interfaces';
import { useDispatch } from 'react-redux';
import { setProductDataForCreateOrder } from '../../../store/slices/createOrderSlice';
import { useNavigate } from 'react-router-dom';
import { getMinimumAndMaximumCostFromVariants } from '../../../helper/getMoreDetailsRowData';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../store/hooks';
import {
  addSelectedProduct,
  getSampleOrderData,
  setSelectedProduct,
} from '../../../store/slices/sampleOrderSlice';
import { SampleOrderProduct } from '../../../interface/sample-order-interface-v2';
import { SampleOrderCart } from '../../../interface/create-order-interface';

interface Props {
  closeModal: () => void;
}
const MyProductsModal: React.FC<Props> = ({ closeModal }) => {
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [selectedSize, setSelectedSize] = useState<any>('');
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [quantity, setQuantity] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const debouncedText = useDebounce(searchText);
  const [productsData, setProductsData] = useState<any[]>([]); // need to change
  const { data: myProducts, isFetching } = useGetMyProductsQuery({
    page: page,
    search: debouncedText,
    status: 'Published',
  });
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [skuData, setSKUData] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (myProducts?.results && !isFetching) {
      setProductsData((prevProductsData) => {
        return page !== 1
          ? [...prevProductsData, ...myProducts.results]
          : myProducts.results;
      });
    }
  }, [isFetching, myProducts, page]);
  useEffect(() => {
    if (selectedProductId) {
      const product: any = myProducts?.results.find(
        (product) => product._id === selectedProductId,
      );
      setMinMax(getMinimumAndMaximumCostFromVariants(product?.variants));
    }
  }, [myProducts?.results, selectedProductId]);

  useEffect(() => {
    if (selectedSize) {
      let product: any = myProducts?.results.filter(
        (product) => product._id === selectedProductId,
      );
      product = product[0];
      setSelectedProduct(product);
      const variantsData = product.variants.find(
        (variant: any) => variant.colorHex === selectedColor.replace('#', ''),
      );
      const skuData = variantsData.skus.find(
        (sku: any) => sku.catalogSKUId === selectedSize,
      );
      setSKUData(skuData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize]);

  const changeRow = (id: string) => {
    setSelectedProductId(id);
    setSelectedColor('');
    setSelectedSize('');
  };

  const prepareDataAndSendToSlice = () => {
    const colorsOptions = selectedProduct.variants.map(
      (variant: SingleCatalogProductVariants) => ({
        label: variant.color,
        value: '#' + variant.colorHex,
      }),
    );
    let sizesOptions = [];
    let skus = selectedProduct.variants.filter(
      (variant: any) => variant.colorHex === selectedColor.replace('#', ''),
    );

    let productColorID: number = 0;
    if (skus.length > 0) {
      productColorID = skus[0].productColorID;
      skus = skus[0].skus;
      sizesOptions = (skus || []).map((sku: any) => ({
        label: sku.size,
        value: sku.catalogSKUId,
      }));
    }

    const data = {
      selectedProductId,
      selectedColor,
      selectedSize: skuData.catalogSKUId,
      quantity,
      skuId: selectedSize,
      brand: selectedProduct?.brand,
      modelNumber: selectedProduct.model,
      productCost: skuData.cost,
      mockupImage:
        selectedProduct.images && selectedProduct.images[0]
          ? selectedProduct.images[0].imageUrl
          : selectedProduct.variants[0]?.frontDesign,
      designImage: selectedProduct.designURL,
      sizesOptions,
      colorsOptions,
      title: selectedProduct.title,
      printProviderId: selectedProduct.printProvider,
      variants: selectedProduct.variants,
      productColorID: productColorID,
    };
    dispatch(setProductDataForCreateOrder(data));
    navigate('/create-order');
  };

  return (
    <StyledWrapper>
      <Modal
        title="My products"
        size="medium"
        onClose={closeModal}
        className="create-order-modal"
      >
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <FlexBox gap="12px" direction="column" className="main-container">
              <SearchBar
                icon={SearchIcon}
                onChange={(value) => {
                  setSearchText(value);
                  setPage(1);
                }}
                value={searchText}
              />
              {productsData.length > 0 ? (
                <FlexBox
                  gap="12px"
                  direction="column"
                  className="products-list show-no-scrollbar"
                >
                  {productsData.map((product) => (
                    <ProductInfo
                      selectedColor={selectedColor}
                      selectedSize={selectedSize}
                      setSelectedColor={(color) => setSelectedColor(color)}
                      setSelectedSize={(size) => setSelectedSize(size)}
                      key={product._id}
                      product={product}
                      isActive={product._id === selectedProductId}
                      onSelect={(id) => changeRow(id)}
                      forSampleOrder={false}
                    />
                  ))}
                  {page < (myProducts?.totalPages ?? 1) && (
                    <div className="more-products">
                      <span
                        onClick={() => setPage((prev) => prev + 1)}
                        className="pointer-cursor "
                      >
                        {' '}
                        See more
                      </span>
                    </div>
                  )}
                </FlexBox>
              ) : (
                <Typography
                  text="No products found!!"
                  fontSize="16px"
                  className="text-align-center"
                />
              )}
            </FlexBox>
            <FlexBox justifyContent="space-between" className="footer">
              <FlexBox direction="column" justifyContent="center">
                <Typography
                  text={selectedProduct?.title ?? 'Please Select product'}
                  fontSize="14px"
                  fontWeight="700"
                  secondaryColorValue={700}
                />
                <Typography
                  text={`From USD ${minMax.min}`}
                  fontSize="12px"
                  secondaryColorValue={500}
                />
              </FlexBox>
              <FlexBox gap="1rem" alignItems="center">
                <QuantityButtons
                  onChange={(qty) => setQuantity(qty)}
                  quantity={quantity}
                />
                <Button
                  appearance="primary"
                  label="Confirm"
                  size="large"
                  className="confirm-btn"
                  onClick={prepareDataAndSendToSlice}
                  disabled={!selectedColor || !selectedSize}
                />
              </FlexBox>
            </FlexBox>
          </>
        )}
      </Modal>
    </StyledWrapper>
  );
};

export default MyProductsModal;
interface ProductInfoProps {
  isActive?: boolean;
  product: SampleOrderProduct;
  onSelect?(productId?: any): void;
  selectedColor?: string;
  selectedSize?: any;
  setSelectedColor?(color: any): void;
  setSelectedSize?(size: any): void;
  forSampleOrder: boolean;
}
interface SelectedVariant {
  color: string;
  colorHex: string;
  size: string;
  variantId: string;
  cost: number;
  printSKUId: string;
  catalogSKUId: number;
}

interface SelectOption extends Option {
  variantId: string;
  cost: number;
  printSKUId: string;
  catalogSKUId: number;
}
export const ProductInfo: React.FC<ProductInfoProps> = ({
  onSelect,
  product,
  isActive,
  selectedColor,
  selectedSize,
  setSelectedColor,
  setSelectedSize,
  forSampleOrder,
}) => {
  const initialValuesForSelectedVariants = {
    color: '',
    colorHex: '',
    size: '',
    variantId: '',
    cost: 0,
    catalogSKUId: 0,
    printSKUId: '',
  };
  const [selectedVariant, setSelectedVariant] = useState<SelectedVariant>(
    initialValuesForSelectedVariants,
  );
  const [sizes, setSizes] = useState<SelectOption[]>([]);
  const [colors, setColors] = useState<SelectOption[]>([]);
  const dispatch = useDispatch();
  const { selectedProducts } = useAppSelector(getSampleOrderData);

  const handleAddToCart = () => {
    const selectedVariantInfo = product.variants.find(
      (varianst) => varianst.propertyValue === selectedVariant.color,
    );

    if (selectedVariantInfo) {
      let data: SampleOrderCart = {
        catalogId: product.catalogId,
        catalogSKUId: selectedVariant.catalogSKUId,
        cost: selectedVariant.cost,
        printSKUId: selectedVariant.printSKUId,
        productColorID: selectedVariantInfo.meta?.productColorID as number,
        color: `#${selectedVariantInfo.meta?.colorHex}`,
        productId: product._id,
        quantity: 1,
        variantId: selectedVariant.variantId,
        image: product.image,
        size: selectedVariant.size,
        title: product.title,
        printProviderId: product.printProviderId,
        colorName: selectedVariantInfo.propertyValue,
      };

      const existingProductIndex = selectedProducts.findIndex(
        (item) =>
          item.productId === data.productId &&
          item.catalogSKUId === data.catalogSKUId &&
          item.variantId === data.variantId,
      );

      if (existingProductIndex !== -1) {
        const updatedSelectedProducts = selectedProducts.map((item, index) => {
          if (index === existingProductIndex) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        });
        dispatch(setSelectedProduct(updatedSelectedProducts));
      } else {
        dispatch(addSelectedProduct(data));
      }
    }
    setSelectedVariant(initialValuesForSelectedVariants);
  };

  useEffect(() => {
    if (product.variants) {
      const colors: SelectOption[] = [];
      let sizesArray: SelectOption[] = [];
      product.variants.forEach((variant) => {
        const {
          propertyName,
          propertyValue,
          sizes,
          variantId,
          meta,
          cost,
          printSKUId,
          catalogSKUId,
        } = variant;
        if (propertyName === 'color' && meta) {
          colors.push({
            label: propertyValue,
            value: `#${meta.colorHex}`,
            variantId,
            cost,
            catalogSKUId,
            printSKUId,
          });
        }
        if (sizes.length > 0) {
          sizesArray = sizes.map((size) => {
            const { catalogSKUId, cost, printSKUId, value, variantId } = size;
            return {
              label: value,
              value: variantId,
              catalogSKUId,
              cost,
              printSKUId,
              variantId,
            };
          });
        }
      });
      setColors(colors);
      setSizes(sizesArray);
    }
  }, [product.variants]);

  useEffect(() => {
    const foundSizes = product.variants.find(
      (e) => e.propertyValue === selectedVariant.color,
    )?.sizes;
    if (foundSizes) {
      const sizeOptions = foundSizes.map((size) => {
        const { catalogSKUId, cost, printSKUId, value, variantId } = size;
        return {
          label: value,
          value: variantId,
          catalogSKUId,
          cost,
          printSKUId,
          variantId,
        };
      });
      setSizes(sizeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant.color]);
  return (
    <ProductWrapper
      isActive={isActive ? isActive : false}
      forSampleOrder={forSampleOrder ? true : false}
    >
      {forSampleOrder ? (
        <FlexBox
          gap="1rem"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <FlexBox alignItems="center" className="product-info">
            <img className="box" src={product.image} alt={product.title} />
          </FlexBox>
          <FlexBox direction="column" style={{ width: '75%' }}>
            <Typography
              text={product.title}
              fontSize="16px"
              fontWeight="700"
              secondaryColorValue={600}
              className="product-title"
            />

            <FlexBox alignItems="center" justifyContent="space-between">
              <SelectList
                items={colors}
                selectedValue={selectedVariant?.colorHex}
                onChange={(value, selectedOption) => {
                  if (selectedOption) {
                    setSelectedVariant((prev) => ({
                      ...prev,
                      color: selectedOption.label,
                      colorHex: value,
                    }));
                  }
                }}
                listType="color-item"
                width="100%"
                className="order-select"
                label={`${colors.length} colors`}
                gapBetweenInputAndItems={0}
              />
              <SelectList
                items={sizes}
                selectedValue={selectedVariant.variantId}
                onChange={(selectedValue, selectedOption) => {
                  if (selectedOption) {
                    setSelectedVariant((prev) => ({
                      ...prev,
                      ...selectedOption,
                      size: selectedOption.label,
                      variantId: selectedValue,
                    }));
                  }
                }}
                label={
                  !selectedVariant.color ? 'Size' : `${sizes.length} sizes`
                }
                className="order-select select-size"
                width="100%"
                gapBetweenInputAndItems={0}
              />
              <Button
                appearance="primary"
                label="Add to Cart"
                size="large"
                className="confirm-btn"
                disabled={!selectedVariant.color || !selectedVariant.size}
                onClick={() => {
                  handleAddToCart();
                }}
              />
            </FlexBox>
          </FlexBox>{' '}
        </FlexBox>
      ) : (
        <>
          <FlexBox
            onClick={() => {
              onSelect && onSelect(product._id);
            }}
            gap="16px"
            alignItems="center"
            className="product-info"
          >
            <img className="box" src={product.image} alt={product.title} />
            <Typography
              text={product.title}
              fontSize="16px"
              fontWeight="700"
              secondaryColorValue={600}
            />
          </FlexBox>
          {isActive && (
            <FlexBox gap="1rem">
              <SelectList
                items={colors}
                selectedValue={selectedColor}
                onChange={(value) =>
                  setSelectedColor && setSelectedColor(value)
                }
                listType="color-item"
                width="100%"
                className="order-select"
                label="Color"
                gapBetweenInputAndItems={0}
              />
              <SelectList
                items={sizes}
                selectedValue={selectedSize}
                onChange={(value) => setSelectedSize && setSelectedSize(value)}
                label="Size"
                className="order-select select-size"
                width="100%"
                gapBetweenInputAndItems={0}
              />
            </FlexBox>
          )}
        </>
      )}
    </ProductWrapper>
  );
};

const StyledWrapper = styled.div`
  .create-order-modal {
    height: fit-content;
    padding: 0;
    border-radius: 16px;
    .content {
      margin-bottom: 60px;
    }
  }
  .modal-header {
    padding: 0px 20px 0px 20px;
    width: auto;
  }
  .products-list {
    max-height: 360px;
    padding-bottom: 20px;
    .more-products {
      text-align: center;
      text-decoration: underline;
      color: ${colors.blue[900]};
    }
  }

  .main-container {
    position: relative;
    height: 80%;
    padding: 20px;
  }
  .footer {
    position: absolute;
    height: 14%;
    bottom: 0;
    background-color: ${colors.white};
    width: -webkit-fill-available;
    padding: 5px 20px;
    border-top: 1px solid ${colors.grey[300]};
    /* .confirm-btn {
      width: 200px;
    } */
  }
`;

export const ProductWrapper = styled.div<{
  isActive: boolean;
  forSampleOrder: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: ${(props) => (props.forSampleOrder ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: ${(props) => (props.isActive ? colors.blue[100] : 'white')};
  border: ${(props) =>
    props.isActive
      ? `1px solid ${colors.blue[300]}`
      : `1px solid ${colors.grey.border}`};
  border-radius: 6px;

  .product-info {
    img {
      width: ${(props) => (props.forSampleOrder ? '72px' : '64px')};
      height: ${(props) => (props.forSampleOrder ? '72px' : '64px')};
      border-radius: 6px;
      border: 1px solid ${colors.grey[150]};
      box-shadow: none;
      object-fit: contain;
    }
  }
  .product-title {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 11px 0 5px 0;
  }
  .order-select {
    max-height: 12px;
    min-width: 120px;
    max-width: 120px;
    padding: 6px 9px 9px 8px;
    border-radius: 6px;
    .dropdown-body {
      border-radius: 0px 0px 6px 6px;
    }
  }
  .select-size {
    min-width: 100px;
    max-width: 100px;
    .dropdown-item {
      padding: 5px 6px 5px 8px;
      border-bottom: 1px solid ${colors.grey[300]};
      background-color: ${colors.white};
      :last-child {
        border-bottom: none;
      }
    }
  }
`;
