import {
  SingleProductResponse,
  SingleProductVariant,
} from '../interface/product-interface';
import {
  getUniqueColorsAndHexFromVariants,
  getUniqueSizesFromVariants,
} from './getMinimumAndMaximumMRSPFromVariants';

export const productsExtraReducerData = (
  state: any,
  payload: SingleProductResponse,
) => {
  const { data } = payload;
  return {
    ...state,
    images: data.images,
    productDetails: {
      ...state.productDetails,
      variants: data.variants,
      tags: data.tags,
      title: data.title,
      description: data.description ? JSON.parse(data.description) : '',
      descriptionResponse: data.description ? JSON.parse(data.description) : '',
      publishingSettings: {
        ...state.productDetails.publishingSettings,
        showInStore: data.publishingSettings.showInStore,
        variantsVisibility: data.publishingSettings.variantsVisibility,
        syncingOptions: data.publishingSettings.syncingOptions,
        shippingProfile: data.publishingSettings.shippingProfile,
      },
    },
    status: data.status,
    sizeVariants: getUniqueSizesFromVariants(
      data.variants as SingleProductVariant[],
    ),
    productColors: getUniqueColorsAndHexFromVariants(
      data.variants as SingleProductVariant[],
    ),

    shippingProfiles: data.shippingProfiles,
    isPersonalizable: data.isPersonalizable,
    personalizationInstructions: data.personalizationInstructions
      ? data.personalizationInstructions.replace(/\n/g, '<br>')
      : '',
  };
};
