import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AddressPayloadInterface,
  CreateOrderInitialState,
  CreateorderModals,
  Recipient,
} from '../../interface/create-order-interface';
import { RootState } from '..';

const initialState: CreateOrderInitialState = {
  selectedProductDetails: {
    selectedProductId: '',
    selectedColor: '',
    selectedSize: '',
    quantity: 1,
    skuId: '',
    brand: '',
    modelNumber: '',
    productCost: 0,
    mockupImage: '',
    designImage: '',
    sizesOptions: [],
    colorsOptions: [],
    title: '',
    printProviderId: '',
    variants: [],
    productColorID: 0,
  },
  recipient: { fullName: '', email: '', phone: '' },
  billingAddress: {
    country: '',
    state: '',
    firstLine: '',
    address: '',
    city: '',
    zipCode: '',
  },
  shippingAddress: {
    country: '',
    state: '',
    firstLine: '',
    address: '',
    city: '',
    zipCode: '',
  },
  shipping: {
    sendShippingNotification: false,
    shippingType: 'standard_shipping',
  },
  billing: {
    couponCode: '',
    totalAmount: 222,
  },
  sameAsShippingAddress: false,
  createOrderModal: CreateorderModals.None,
};

const createOrderSloce = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setProductDataForCreateOrder: (state, action: PayloadAction<any>) => {
      state.selectedProductDetails = action.payload;
    },
    setModalStatus: (state, { payload }: PayloadAction<CreateorderModals>) => {
      state.createOrderModal = payload;
    },
    updateRecipientInfo: (
      state,
      { payload: { email, fullName, phone } }: PayloadAction<Recipient>,
    ) => {
      state.recipient = {
        ...state.recipient,
        email,
        phone,
        fullName,
      };
    },
    updateAddressInfo: (
      state,
      {
        payload: {
          address,
          city,
          country,
          zipCode,
          sectionName,
          firstLine,
          state: stateName,
        },
      }: PayloadAction<AddressPayloadInterface>,
    ) => {
      state[sectionName] = {
        ...state[sectionName],
        address,
        city,
        country,
        zipCode,
        firstLine,
        state: stateName,
      };
    },
    updateSelectedProductInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        field: 'skuId';
        updatedValue: {
          skuId: string;
          updatedCost: number;
          productColorID: number;
        };
      }>,
    ) => {
      const { quantity } = state.selectedProductDetails;
      state.selectedProductDetails.productColorID =
        payload.updatedValue.productColorID;
      state.selectedProductDetails[payload.field] = payload.updatedValue.skuId;
      state.selectedProductDetails.productCost = Number(
        (payload.updatedValue.updatedCost * quantity).toFixed(2),
      );
    },
    updateSameAsShippingAddress: (
      state,
      { payload }: PayloadAction<{ sameAsShippingAddress: boolean }>,
    ) => {
      state.sameAsShippingAddress = payload.sameAsShippingAddress;
      if (payload.sameAsShippingAddress) {
        state.billingAddress = state.shippingAddress;
      }
    },
    updateQuantity: (
      state,
      {
        payload,
      }: PayloadAction<{ quantity: number; originalProductCost: number }>,
    ) => {
      const { productCost, quantity } = state.selectedProductDetails;
      const { originalProductCost, quantity: newQuantity } = payload;
      if (payload.quantity > quantity) {
        state.selectedProductDetails.productCost = Number(
          (productCost + originalProductCost).toFixed(2),
        );
      } else {
        state.selectedProductDetails.productCost = Number(
          (productCost - originalProductCost).toFixed(2),
        );
      }
      state.selectedProductDetails.quantity = newQuantity;
    },
  },
});

export const {
  setProductDataForCreateOrder,
  setModalStatus,
  updateRecipientInfo,
  updateAddressInfo,
  updateSameAsShippingAddress,
  updateSelectedProductInfo,
  updateQuantity,
} = createOrderSloce.actions;

export const getSelectedProductData = (state: RootState) =>
  state.createOrder.selectedProductDetails;

export default createOrderSloce.reducer;
export const getCreateOrderDetails = (state: RootState) => state.createOrder;
