import { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import {
  CheckIconWhite,
  DeleteIcon,
  DropzonePlaceholder,
  EditImageName,
  SearchIcon,
  UploadIconDynamic,
} from '../../../../assets/icons';
import Button from '../../../common/button';
import SearchBar from '../../../common/search-bar';
import { GalleryImageObjectResponse } from '../../../../interface';
import { FlexBox } from '../../../common/wrappers';
import {
  useAddImageToGalleryMutation,
  useGetGalleryByUserIDQuery,
} from '../../../../store/services/gallery';
import Checkbox from '../../../common/checkbox';
import { useAppSelector } from '../../../../store/hooks';
import {
  getObjects,
  setObjects,
  updatePersonalizationElementId,
} from '../../../../store/slices/editor';
import { Canvas } from 'fabric/fabric-impl';
import { useDispatch } from 'react-redux';
import Loader from '../../../common/loader';

interface Props {
  drawingBoard: Canvas | undefined;
  selectedElementId: any;
}

const ImageContentForPersonalizationModal: React.FC<Props> = ({
  drawingBoard,
  selectedElementId,
}) => {
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const files = useAppSelector(getObjects);
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState<
    GalleryImageObjectResponse[]
  >(
    files.filter((file: any) => file.id === selectedElementId)[0]
      .personalizationData.imageOptions,
  );
  const [editingId, setEditingId] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { data: galleryImages, isLoading } = useGetGalleryByUserIDQuery({
    search: '',
  });
  const [addToGallery, { isLoading: isUpdating }] =
    useAddImageToGalleryMutation();

  const uploadToGallery = (acceptedFiles: any) => {
    if (acceptedFiles) {
      const image = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.addEventListener('load', () => {
        if (reader.result) {
          const formData = new FormData();
          formData.set('images', image);
          addToGallery({
            formData,
          }).unwrap();
        }
        if (inputRef.current) inputRef.current.value = '';
      });
    }
  };

  const handleSelection = (
    checked: boolean,
    image: GalleryImageObjectResponse,
  ) => {
    const imgCopy = { ...image };
    delete imgCopy.userId;
    if (checked) {
      setSelectedImages([...selectedImages, image]);
    } else {
      setSelectedImages(selectedImages.filter((img) => img.id !== image.id));
    }
  };

  const updateName = () => {
    let selectedImgs = [...selectedImages];
    const index = selectedImages.findIndex((img) => img.id === editingId);
    const element = { ...selectedImgs[index] };
    element['name'] = fileName;
    selectedImgs[index] = element;
    setSelectedImages(selectedImgs);
    setEditingId('');
    setFileName('');
  };

  const updatePersonalizationData = () => {
    const filesCopy = [...files];
    const element: any = drawingBoard?.getActiveObject();
    const index = files.findIndex((obj: any) => obj.id === selectedElementId);
    if (element) {
      element?.set({
        personalizationData: {
          ...element.personalizationData,
          imageOptions: selectedImages,
        },
      });
      filesCopy[index] = {
        ...files[index],
        personalizationData: {
          ...files[index].personalizationData,
          imageOptions: selectedImages,
        },
      };
      dispatch(setObjects({ objects: filesCopy }));
      drawingBoard?.renderAll();
      dispatch(updatePersonalizationElementId(''));
    }
  };

  return (
    <>
      {(isLoading || isUpdating) && <Loader />}
      <SearchBar
        value={value}
        icon={SearchIcon}
        onChange={(value) => setValue(value)}
      />
      <ImageSection>
        <div
          className="show-no-scrollbar"
          style={{ marginRight: 20, display: 'flex', flexDirection: 'column' }}
        >
          <p className="fs-16 grey900">Buyer options</p>
          <div className="show-no-scrollbar" style={{ marginRight: 20 }}>
            {(selectedImages || []).map(
              (image: GalleryImageObjectResponse, index: number) => (
                <div key={index} className="selected-image">
                  <div key={index} className="image-wrapper">
                    <img src={image.src} alt={image.name} />
                  </div>
                  {editingId !== image.id ? (
                    <FlexBox
                      style={{ margin: '10px 3px' }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span style={{ fontSize: 12 }} className="fs-14 grey900">
                        {image.name}
                      </span>
                      <div style={{ display: 'flex' }}>
                        <img
                          height={20}
                          width={20}
                          style={{ marginRight: 5 }}
                          onClick={() => handleSelection(false, image)}
                          src={DeleteIcon}
                          className="pointer-cursor"
                          alt="Delete"
                        />
                        <img
                          height={20}
                          width={20}
                          src={EditImageName}
                          onClick={() => {
                            setEditingId(image.id);
                            setFileName(image.name);
                          }}
                          className="pointer-cursor"
                          alt="Edit"
                        />
                      </div>
                    </FlexBox>
                  ) : (
                    <FlexBox
                      className="editing-div"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <input
                        type="text"
                        name="fileName"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                      />
                      {/* <div contentEditable={true} onInput={(e) => setFileName(e.currentTarget.textContent ?? "")}>{image.name}</div> */}
                      <div className="buttons">
                        <div onClick={() => setEditingId('')} className="close">
                          <span>X</span>
                        </div>
                        <div
                          onClick={updateName}
                          className={`check ${fileName === '' ?? 'disabled'}`}
                        >
                          <span>
                            <img src={CheckIconWhite} alt="done" />
                          </span>
                        </div>
                      </div>
                    </FlexBox>
                  )}
                </div>
              ),
            )}
          </div>
        </div>

        <div className="show-no-scrollbar">
          <p className="fs-16 grey900">My images</p>
          {galleryImages?.length === 0 ? (
            <Dropzone
              onDrop={(acceptedFiles) => uploadToGallery(acceptedFiles)}
              accept={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
                'image/svg': ['.svg'],
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <EmptyView {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img
                    height={64}
                    width={64}
                    src={DropzonePlaceholder}
                    alt="Upload"
                  />
                  <span className="fs-14 grey600">
                    Drag and drop your images here
                  </span>
                  <span className="fs-14 grey600">or</span>
                  <Button
                    appearance="primary"
                    onClick={() => console.log('Selecting Image')}
                    label="Browse files"
                  />
                </EmptyView>
              )}
            </Dropzone>
          ) : (
            <div className="listing show-no-scrollbar">
              <input
                multiple={false}
                onChange={(e) => uploadToGallery(e.target.files)}
                ref={inputRef}
                type="file"
                id="personalize-file"
                style={{ display: 'none' }}
                accept="image/*"
              />
              <div
                onClick={() => inputRef?.current?.click()}
                className="upload image-wrapper gallery-section"
              >
                <UploadIconDynamic width={60} height={60} />
                <span className="fs-16">Upload image</span>
              </div>
              {(galleryImages || []).map(
                (image: GalleryImageObjectResponse, index: number) => (
                  <div key={index} className="image-wrapper gallery-section">
                    <Checkbox
                      id={`gallery-${image.id}`}
                      className="checkbox"
                      checked={
                        selectedImages.findIndex(
                          (img) => img.id === image.id,
                        ) >= 0
                      }
                      onChange={(checked) => handleSelection(checked, image)}
                    />
                    <img src={image.src} alt={image.name} />
                  </div>
                ),
              )}
            </div>
          )}
        </div>
      </ImageSection>
      <FlexBox justifyContent="space-between">
        <span>{selectedImages.length} images selected</span>
        <Button
          appearance="primary"
          label="Done"
          onClick={updatePersonalizationData}
          disabled={selectedImages.length === 0}
        ></Button>
      </FlexBox>
    </>
  );
};

export default ImageContentForPersonalizationModal;

const ImageSection = styled.div`
  display: flex;
  height: 100%;
  > div {
    max-height: 100%;
  }
  > :first-child {
    width: 20%;
    border-right: 1px solid ${colors.grey[300]};
    padding: 1px;
    > div {
      display: flex;
      flex-direction: column;
    }
  }
  > :nth-child(2) {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 1px;

    .listing {
      list-style: none;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: inherit;
      flex-flow: row wrap;
      .upload {
        cursor: pointer;
        padding: 3px !important;
        border: 2px solid ${colors.blue[600]};
        line-height: inherit;
        background: ${colors.blue[100]};
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        color: ${colors.blue[900]};
        font-weight: bold;
        font-size: 16px;
        & path {
          stroke: ${colors.blue[900]};
          stroke-width: 1;
        }
      }
      .gallery-section:not(.upload) {
        display: flex;
        justify-content: end;
        .checkbox {
          position: absolute;
        }
      }
    }
  }
  .image-wrapper {
    height: 185px;
    width: 185px;
    background: ${colors.grey[200]};
    padding: 5px;
    border-radius: 6px;
    margin: 7px;
    min-width: 185px;
    min-height: 185px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .selected-image {
    width: 200px;
  }
  .editing-div {
    padding: 5px;
    border-radius: 6px;
    border: 1px solid ${colors.grey[300]};
    > :first-child {
      font-size: 12px;
      color: ${colors.grey[900]};
      font-weight: medium;
      min-width: 10px;
      max-width: 70%;
      width: 75%;
      border: none;
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
    .buttons {
      display: flex;
      width: 25%;
      div {
        border-radius: 6px;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0px 4px;
        cursor: pointer;
        &.close {
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 0px 0px 1px rgba(0, 0, 0, 0.08);
          margin-right: 7px;
        }
        &.check {
          background: ${colors.blue[700]};
          box-shadow: 0px 1px 1px rgba(13, 97, 181, 0.8),
            0px 0px 0px 1px #3490ec, inset 0px 1px 0px rgba(255, 255, 255, 0.3);
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          img {
            width: 13px;
            height: 13px;
          }
        }
        span {
          display: flex;
        }
      }
    }
  }
`;

const EmptyView = styled.div`
  background: ${colors.grey[100]};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > * {
    margin-bottom: 10px;
  }
`;
