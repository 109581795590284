import styled from 'styled-components';
import Details from './details';
import Title from './title';
import { useGetOrderByIdQuery } from '../../store/services/orders';
import { useParams } from 'react-router-dom';
import Loader from '../common/loader';
import { TabletProps } from '../../interface';
import Banner from '../common/banner';
import Typography from '../typography';

const OrderDetails: React.FC<TabletProps> = ({ isTablet }) => {
  const { orderId } = useParams();

  const { data: orderDetails, isLoading } = useGetOrderByIdQuery(
    {
      orderId,
    },
    {
      skip: orderId ? false : true,
    },
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <StyleWrapper className="show-no-scrollbar page-background-grey">
          {orderDetails?.data?.containsNonEverbeePrintsItems && (
            <Banner
              text={
                <Typography
                  text={
                    <>
                      <strong>Note: </strong>
                      This order also contains items that are not fulfilled by
                      EverBee Print. The total payment from the customer also
                      covers items not supplied by EverBee Print
                    </>
                  }
                  fontSize="0.87rem"
                />
              }
              type="info"
              className="banner"
            />
          )}
          <Title orderDetails={orderDetails} />
          <Details isTablet={isTablet} />
        </StyleWrapper>
      )}
    </>
  );
};

export default OrderDetails;

const StyleWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
