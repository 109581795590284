import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
import Input from '../../inputs';
import Button from '../../common/button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getCreateOrderDetails,
  setModalStatus,
  updateRecipientInfo,
} from '../../../store/slices/createOrderSlice';
import {
  CreateorderModals,
  Recipient,
} from '../../../interface/create-order-interface';
interface Props {}
const RecipientModal: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const {
    recipient: { fullName, email, phone },
  } = useAppSelector(getCreateOrderDetails);
  const [recipientData, setRecipientdata] = useState<Recipient>({
    email: email,
    fullName: fullName,
    phone: phone,
  });
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRecipientdata({ ...recipientData, [name]: value });
  };
  return (
    <StyledWrapper>
      <Modal
        title="Recipient"
        onClose={() => dispatch(setModalStatus(CreateorderModals.None))}
        className="recipient-modal"
      >
        <FlexBox direction="column" gap="12px">
          <FlexBox direction="column" gap="6px">
            <Typography text="Full Name" fontSize="14px" />
            <Input
              onChange={changeHandler}
              value={recipientData.fullName}
              name="fullName"
            />
          </FlexBox>
          <FlexBox direction="column" gap="6px">
            <Typography text="Email" fontSize="14px" />
            <Input
              onChange={changeHandler}
              value={recipientData.email}
              name="email"
            />
          </FlexBox>
          <FlexBox direction="column" gap="6px">
            <Typography text="Phone" fontSize="14px" />
            <Input
              onChange={changeHandler}
              value={recipientData.phone}
              name="phone"
            />
          </FlexBox>
          <FlexBox gap="12px">
            <Button
              label="Cancel"
              size="fullWidth"
              appearance="secondary"
              onClick={() => dispatch(setModalStatus(CreateorderModals.None))}
            />
            <Button
              disabled={
                !recipientData.email ||
                !recipientData.fullName ||
                !recipientData.phone
              }
              label="Save Changes"
              size="fullWidth"
              appearance="primary"
              onClick={() => {
                dispatch(updateRecipientInfo(recipientData));
                dispatch(setModalStatus(CreateorderModals.None));
              }}
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default RecipientModal;

const StyledWrapper = styled.div`
  .recipient-modal {
    height: fit-content;
    border-radius: 16px;
  }
`;
