import styled, { css } from 'styled-components';

interface Props {
  direction?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial|inherit';
  alignItems?:
    | 'normal'
    | 'stretch'
    | 'positional'
    | 'alignment'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit'
    | 'center';
  gap?: string;
  height?: string;
  wrap?: 'nowrap' | 'wrap-reverse' | 'wrap';
}

const FlexBox = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? `row`};
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `}
    ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

export { FlexBox };
