import { Fees, ProductCost } from '../interface/orders-interface';

export const billingCalculations = (
  customerPaid?: ProductCost,
  productionCost?: ProductCost,
  fees?: Fees,
) => {
  const costs: any = {};
  //total production costs
  if (productionCost) {
    costs.totalProductionCost = (
      productionCost.shipping + productionCost.total
    ).toFixed(2);
  }
  //total customer paid
  if (customerPaid) {
    costs.orderTotalBeforeTax = (
      customerPaid.shipping + customerPaid.total
    ).toFixed(2);
  }
  //total fess
  if (fees) {
    costs.totalFees = fees.transactionFee.toFixed(2);
  }
  // netProfit
  if (costs.orderTotalBeforeTax && costs.totalProductionCost) {
    costs.netProfit = (
      costs.orderTotalBeforeTax -
      (Number(costs.totalProductionCost) + Number(costs.totalFees))
    ).toFixed(2);
  }
  // netProfitMargin
  if (costs.orderTotalBeforeTax && costs.netProfit) {
    costs.netProfitMargin = (
      (costs.netProfit / costs.orderTotalBeforeTax) *
      100
    ).toFixed(2);
  }
  // totalChartValue entire circle
  if (
    costs.netProfit &&
    costs.totalProductionCost &&
    costs.totalFees &&
    productionCost?.shipping
  ) {
    costs.totalChartValue =
      Number(costs.netProfit) +
      Number(costs.totalProductionCost) +
      Number(costs.totalFees) +
      productionCost?.shipping;
  }
  return costs;
};
