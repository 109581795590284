import React from 'react';
import { Cross } from '../../assets/icons';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
interface Props {
  text: string;
  onClick: (e: string) => void;
}
const FilterLabel: React.FC<Props> = ({ text, onClick }) => {
  return (
    <SelectedOption>
      <span>{text}</span>
      <img alt="Cross" width="15" src={Cross} onClick={() => onClick(text)} />
    </SelectedOption>
  );
};

export default FilterLabel;
const SelectedOption = styled.div`
  display: flex;
  gap: 4px;
  background-color: ${colors.blue[200]};
  padding: 4px 8px;
  border-radius: 6px;

  img {
    cursor: pointer;
  }
`;
